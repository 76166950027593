export const countDiagramsScaleValues = (maximumValue: number, step: number) : number[] => {

    const scalesValues: number[] = [0];

    let i = maximumValue;

    while (i > 0) {
        scalesValues.push(i);
        scalesValues.push(i * -1);
        i -= step;
    }

    scalesValues.sort(
        (v1, v2) => v1 > v2 ? 1 : -1
    ).reverse();

    return scalesValues;

};


export default countDiagramsScaleValues;
