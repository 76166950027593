export * from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable/_Utilities";


import React from "react";


import { Col, Row } from "react-bootstrap";


import GameSummaryTableTeamsComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Teams";


import "./index.scss";


import ApiGameSummaryTeamInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team";


interface GameInformationSummaryTableComponentProps {
    gameHomeTeam: ApiGameSummaryTeamInterface;
    gameAwayTeam: ApiGameSummaryTeamInterface;
    children?: React.ReactNode | React.ReactNode[] | null;
}


export const GameInformationSummaryTableComponent: React.FC<GameInformationSummaryTableComponentProps> = ({
    gameHomeTeam, gameAwayTeam,
    children
}) : JSX.Element | null => {


    return (
        <Col xs={12} className={"game-information-summary game-information-summary-component"}>
            <Row className={"game-information-summary__content"}>

                <GameSummaryTableTeamsComponent
                    gameHomeTeam={gameHomeTeam}
                    gameAwayTeam={gameAwayTeam}
                />

                {children}

            </Row>
        </Col>
    )

};


export default GameInformationSummaryTableComponent;
