import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import LeagueTeamSquadHeaderComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/Header";
import LeagueTeamSquadPlayersListComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayersList";
import LeagueTeamSquadAddPlayerComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayerAdd";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamLeaguesListLeagueTeamSquadComponentProps {
    teamID: number;
    teamLeagueID: number;
    TeamLeagueSquad: TeamLeaguePlayerInterface[] | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const TeamLeaguesListLeagueTeamSquadComponent: React.FC<TeamLeaguesListLeagueTeamSquadComponentProps> = ({
    teamID, teamLeagueID, TeamLeagueSquad, updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    if (!TeamLeagueSquad) {
        return null;
    }


    const FilteredTeamLeagueSquad = TeamLeagueSquad.filter((Player) => {
        const { league_team_player_status: playerStatus } = Player;
        return playerStatus === 1;
    });


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__players__content"}>

                <LeagueTeamSquadHeaderComponent />

                <LeagueTeamSquadPlayersListComponent
                    TeamLeagueSquad={FilteredTeamLeagueSquad}
                    updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
                />

                <LeagueTeamSquadAddPlayerComponent
                    teamID={teamID}
                    teamLeagueID={teamLeagueID}
                    TeamLeagueSquad={FilteredTeamLeagueSquad}
                    updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
                />

            </Row>
        </Col>
    );

};


export default TeamLeaguesListLeagueTeamSquadComponent;
