import SortedBySeasonGamesLeagueInterface from "@PlayersPages/Player/DetailsData/__Types/Games/Sorted/BySeason/League";

import PlayerStatisticsInterface from "@PlayersPages/Player/DetailsData/__Types/Statistics/Season/Player";

import { countEval } from "@Services/Utilities/Games/Players/Statistics/Eval";


interface I {
    SUMMARY: PlayerStatisticsInterface,
    MEDIUM: PlayerStatisticsInterface
}


export const createPlayerGamesStatistics = (
    playerGames: SortedBySeasonGamesLeagueInterface[]
) : I[] | null => {


    const PlayerStatisticsSummaryDefaults: PlayerStatisticsInterface = {
        SEASON: null, TEAM: null,
        PLAYER_GAMES: 0, PLAYER_S5_GAMES: 0, PLAYER_SECONDS: 0,
        PLAYER_POINTS: 0, PLAYER_THROWS: 0,
        PLAYER_2P_THROWS: 0, PLAYER_ACC_2P_THROWS: 0,
        PLAYER_3P_THROWS: 0, PLAYER_ACC_3P_THROWS: 0,
        PLAYER_GAME_THROWS: 0, PLAYER_ACC_GAME_THROWS: 0,
        PLAYER_1P_THROWS: 0, PLAYER_ACC_1P_THROWS: 0,
        PLAYER_OFFENSIVE_REBOUNDS: 0, PLAYER_DEFENSIVE_REBOUNDS: 0, PLAYER_TOTAL_REBOUNDS: 0,
        PLAYER_ASSISTS: 0, PLAYER_BLOCKS: 0, PLAYER_STEALS: 0,
        PLAYER_TURNOVERS: 0, PLAYER_FOULS: 0, PLAYER_EFFECTIVITY: 0,
        PLAYER_EVAL: 0
    };

    const PlayerStatisticsMediumDefaults: PlayerStatisticsInterface = {
        SEASON: null, TEAM: null,
        PLAYER_GAMES: 0, PLAYER_S5_GAMES: 0, PLAYER_SECONDS: 0,
        PLAYER_POINTS: 0, PLAYER_THROWS: 0,
        PLAYER_2P_THROWS: 0, PLAYER_ACC_2P_THROWS: 0, PLAYER_2P_THROWS_EFFECTIVITY: 0,
        PLAYER_3P_THROWS: 0, PLAYER_ACC_3P_THROWS: 0, PLAYER_3P_THROWS_EFFECTIVITY: 0,
        PLAYER_GAME_THROWS: 0, PLAYER_ACC_GAME_THROWS: 0, PLAYER_GAME_THROWS_EFFECTIVITY: 0,
        PLAYER_1P_THROWS: 0, PLAYER_ACC_1P_THROWS: 0, PLAYER_1P_THROWS_EFFECTIVITY: 0,
        PLAYER_OFFENSIVE_REBOUNDS: 0, PLAYER_DEFENSIVE_REBOUNDS: 0, PLAYER_TOTAL_REBOUNDS: 0,
        PLAYER_ASSISTS: 0, PLAYER_BLOCKS: 0, PLAYER_STEALS: 0,
        PLAYER_TURNOVERS: 0, PLAYER_FOULS: 0, PLAYER_EFFECTIVITY: 0,
        PLAYER_EVAL: 0
    };


    const L: I[] = [];

    for (let gamesLeague of playerGames) {

        const {
            // gamesLeagueName,
            gamesLeagueSeason,
            gamesLeagueGames
        } = gamesLeague;

        if (!gamesLeagueSeason || !gamesLeagueGames) continue;

        for (let leagueGamesTeam of Object.values(gamesLeagueGames)) {

            const {teamData, teamGames} = leagueGamesTeam;

            if (!teamGames) continue;

            const GamesSummaryStatistics = {...PlayerStatisticsSummaryDefaults};
            const GamesMediumStatistics = {...PlayerStatisticsMediumDefaults};

            GamesSummaryStatistics.SEASON = gamesLeagueSeason;
            GamesSummaryStatistics.TEAM = teamData;

            GamesMediumStatistics.SEASON = gamesLeagueSeason;
            GamesMediumStatistics.TEAM = teamData;

            for (let leagueGame of teamGames) {

                const {
                    league_game_team_player_first_squad: PLAYER_S5, league_game_team_player_real_seconds: PLAYER_SECONDS,
                    league_game_team_player_2pt: PLAYER_2PT, league_game_team_player_2pta: PLAYER_2PTA,
                    league_game_team_player_3pt: PLAYER_3PT, league_game_team_player_3pta: PLAYER_3PTA,
                    league_game_team_player_ft: PLAYER_1PT, league_game_team_player_fta: PLAYER_1PTA,
                    league_game_team_player_rebound_off: PLAYER_REB_O, league_game_team_player_rebound_def: PLAYER_REB_D,
                    league_game_team_player_ast: PLAYER_AST, league_game_team_player_blk: PLAYER_BLK,
                    league_game_team_player_stl: PLAYER_STL, league_game_team_player_to: PLAYER_TO,
                    league_game_team_player_foul: PLAYER_FOUL, league_game_team_player_eff: PLAYER_EFF
                } = leagueGame;

                GamesSummaryStatistics.PLAYER_GAMES += 1;
                GamesSummaryStatistics.PLAYER_S5_GAMES += PLAYER_S5 ? 1 : 0;
                GamesSummaryStatistics.PLAYER_SECONDS += PLAYER_SECONDS;

                GamesSummaryStatistics.PLAYER_2P_THROWS += PLAYER_2PTA;
                GamesSummaryStatistics.PLAYER_ACC_2P_THROWS += PLAYER_2PT;
                GamesSummaryStatistics.PLAYER_3P_THROWS += PLAYER_3PTA;
                GamesSummaryStatistics.PLAYER_ACC_3P_THROWS += PLAYER_3PT;
                GamesSummaryStatistics.PLAYER_GAME_THROWS += PLAYER_2PTA + PLAYER_3PTA;
                GamesSummaryStatistics.PLAYER_ACC_GAME_THROWS += PLAYER_2PT + PLAYER_3PT;
                GamesSummaryStatistics.PLAYER_1P_THROWS += PLAYER_1PTA;
                GamesSummaryStatistics.PLAYER_ACC_1P_THROWS += PLAYER_1PT;

                GamesSummaryStatistics.PLAYER_2P_THROWS_EFFECTIVITY = (GamesSummaryStatistics.PLAYER_ACC_2P_THROWS / GamesSummaryStatistics.PLAYER_2P_THROWS) * 100;
                GamesSummaryStatistics.PLAYER_3P_THROWS_EFFECTIVITY = (GamesSummaryStatistics.PLAYER_ACC_3P_THROWS / GamesSummaryStatistics.PLAYER_3P_THROWS) * 100;
                GamesSummaryStatistics.PLAYER_GAME_THROWS_EFFECTIVITY = (GamesSummaryStatistics.PLAYER_ACC_GAME_THROWS / GamesSummaryStatistics.PLAYER_GAME_THROWS) * 100;
                GamesSummaryStatistics.PLAYER_1P_THROWS_EFFECTIVITY = (GamesSummaryStatistics.PLAYER_ACC_1P_THROWS / GamesSummaryStatistics.PLAYER_1P_THROWS) * 100;

                GamesSummaryStatistics.PLAYER_OFFENSIVE_REBOUNDS += PLAYER_REB_O;
                GamesSummaryStatistics.PLAYER_DEFENSIVE_REBOUNDS += PLAYER_REB_D;
                GamesSummaryStatistics.PLAYER_TOTAL_REBOUNDS += PLAYER_REB_O + PLAYER_REB_D;

                GamesSummaryStatistics.PLAYER_ASSISTS += PLAYER_AST;
                GamesSummaryStatistics.PLAYER_BLOCKS += PLAYER_BLK;
                GamesSummaryStatistics.PLAYER_STEALS += PLAYER_STL;
                GamesSummaryStatistics.PLAYER_TURNOVERS += PLAYER_TO;
                GamesSummaryStatistics.PLAYER_FOULS += PLAYER_FOUL;
                GamesSummaryStatistics.PLAYER_EFFECTIVITY += PLAYER_EFF;

                GamesSummaryStatistics.PLAYER_EVAL += countEval(
                    2 * PLAYER_2PT + 3 * PLAYER_3PT + PLAYER_1PT,
                    PLAYER_REB_O + PLAYER_REB_D,
                    PLAYER_AST,
                    PLAYER_STL,
                    PLAYER_BLK,
                    PLAYER_TO,
                    PLAYER_2PTA,
                    PLAYER_3PTA,
                    PLAYER_2PT,
                    PLAYER_3PT,
                    PLAYER_1PTA,
                    PLAYER_1PT
                );

            }

            GamesSummaryStatistics.PLAYER_POINTS =
                (GamesSummaryStatistics.PLAYER_ACC_2P_THROWS || 0) * 2 +
                (GamesSummaryStatistics.PLAYER_ACC_3P_THROWS || 0) * 3 +
                (GamesSummaryStatistics.PLAYER_ACC_1P_THROWS || 0) * 1
            ;

            GamesSummaryStatistics.PLAYER_THROWS =
                (GamesSummaryStatistics.PLAYER_2P_THROWS || 0) +
                (GamesSummaryStatistics.PLAYER_3P_THROWS || 0) +
                (GamesSummaryStatistics.PLAYER_1P_THROWS || 0)
            ;

            if (GamesSummaryStatistics.PLAYER_GAMES) {

                // GamesMediumStatistics.PLAYER_GAMES = -1;
                GamesMediumStatistics.PLAYER_GAMES = GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_S5_GAMES = GamesSummaryStatistics.PLAYER_S5_GAMES / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_SECONDS = GamesSummaryStatistics.PLAYER_SECONDS / GamesSummaryStatistics.PLAYER_GAMES;

                GamesMediumStatistics.PLAYER_POINTS = GamesSummaryStatistics.PLAYER_POINTS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_THROWS = GamesSummaryStatistics.PLAYER_THROWS / GamesSummaryStatistics.PLAYER_GAMES;

                GamesMediumStatistics.PLAYER_2P_THROWS = GamesSummaryStatistics.PLAYER_2P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_ACC_2P_THROWS = GamesSummaryStatistics.PLAYER_ACC_2P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_3P_THROWS = GamesSummaryStatistics.PLAYER_3P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_ACC_3P_THROWS = GamesSummaryStatistics.PLAYER_ACC_3P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_GAME_THROWS = GamesSummaryStatistics.PLAYER_GAME_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_ACC_GAME_THROWS = GamesSummaryStatistics.PLAYER_ACC_GAME_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_1P_THROWS = GamesSummaryStatistics.PLAYER_1P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_ACC_1P_THROWS = GamesSummaryStatistics.PLAYER_ACC_1P_THROWS / GamesSummaryStatistics.PLAYER_GAMES;

                GamesMediumStatistics.PLAYER_2P_THROWS_EFFECTIVITY = (GamesMediumStatistics.PLAYER_ACC_2P_THROWS / GamesMediumStatistics.PLAYER_2P_THROWS) * 100;
                GamesMediumStatistics.PLAYER_3P_THROWS_EFFECTIVITY = (GamesMediumStatistics.PLAYER_ACC_3P_THROWS / GamesMediumStatistics.PLAYER_3P_THROWS) * 100;
                GamesMediumStatistics.PLAYER_GAME_THROWS_EFFECTIVITY = (GamesMediumStatistics.PLAYER_ACC_GAME_THROWS / GamesMediumStatistics.PLAYER_GAME_THROWS) * 100;
                GamesMediumStatistics.PLAYER_1P_THROWS_EFFECTIVITY = (GamesMediumStatistics.PLAYER_ACC_1P_THROWS / GamesMediumStatistics.PLAYER_1P_THROWS) * 100;

                GamesMediumStatistics.PLAYER_OFFENSIVE_REBOUNDS = GamesSummaryStatistics.PLAYER_OFFENSIVE_REBOUNDS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_DEFENSIVE_REBOUNDS = GamesSummaryStatistics.PLAYER_DEFENSIVE_REBOUNDS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_TOTAL_REBOUNDS = GamesSummaryStatistics.PLAYER_TOTAL_REBOUNDS / GamesSummaryStatistics.PLAYER_GAMES;

                GamesMediumStatistics.PLAYER_ASSISTS = GamesSummaryStatistics.PLAYER_ASSISTS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_BLOCKS = GamesSummaryStatistics.PLAYER_BLOCKS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_STEALS = GamesSummaryStatistics.PLAYER_STEALS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_TURNOVERS = GamesSummaryStatistics.PLAYER_TURNOVERS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_FOULS = GamesSummaryStatistics.PLAYER_FOULS / GamesSummaryStatistics.PLAYER_GAMES;
                GamesMediumStatistics.PLAYER_EFFECTIVITY = GamesSummaryStatistics.PLAYER_EFFECTIVITY / GamesSummaryStatistics.PLAYER_GAMES;

                GamesMediumStatistics.PLAYER_EVAL = GamesSummaryStatistics.PLAYER_EVAL / GamesSummaryStatistics.PLAYER_GAMES;

            }

            L.push({
                SUMMARY: GamesSummaryStatistics,
                MEDIUM: GamesMediumStatistics
            });

        }

    }

    return L;

};


export default createPlayerGamesStatistics;
