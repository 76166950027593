import React from "react";


import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col } from "react-bootstrap";


import "./index.scss";


interface GameProgressHeaderSeparatorComponentProps {}


export const GameProgressHeaderSeparatorComponent: React.FC<GameProgressHeaderSeparatorComponentProps> = ({}) : JSX.Element | null => {


    const TIME_WORD = UseCommonDictionaryPhrasesSet("TIME_WORD");


    return (
        <Col xs={2} lg={4} className={"game-result-details__progress__header__separator"}>
            <span>{TIME_WORD}</span>
        </Col>
    );

};


export default GameProgressHeaderSeparatorComponent;
