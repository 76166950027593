export const LEAGUE_ROUND_ROUND_LABEL_PL = "Kolejka Ligowa";
export const LEAGUE_ROUND_ROUND_LABEL_EN = "League Round";
export const LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_PL = "Playoffy";
export const LEAGUE_ROUND_ROUND_PLAYOFFS_LABEL_EN = "Playoffs";
export const LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_PL = "Cwierćfinały";
export const LEAGUE_ROUND_ROUND_QUARTER_FINALS_LABEL_EN = "Quarter Finals";
export const LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_PL = "Półfinały";
export const LEAGUE_ROUND_ROUND_SEMI_FINALS_LABEL_EN = "Semi Finals";
export const LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_PL = "Mecz o 3 miejsce";
export const LEAGUE_ROUND_ROUND_SMALL_FINAL_LABEL_EN = "Thrid Place";
export const LEAGUE_ROUND_ROUND_FINAL_LABEL_PL = "Finał";
export const LEAGUE_ROUND_ROUND_FINAL_LABEL_EN = "Final";
