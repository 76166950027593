import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row, Form,} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";


import TeamsTeamComponent from "@Components/Views/Pages/Games/Teams/List/Content/TeamsAndPlayers/Teams/Team";


const TEXTS: any = {
    PL: {
        search_teams_label_text: "wyszukaj drużynę",
        search_teams_input_text: "wpisz nazwę drużyny",
        search_teams_no_results_message_text: "brak drużyn spełniających kryteria"
    },
    EN: {
        search_teams_label_text: "search teams",
        search_teams_input_text: "type in team name",
        search_teams_no_results_message_text: "no teams matching search criteria"
    }
};


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface TeamsAndPlayersTeamsComponentProps {
    teamsList: LeagueTeamInterface[];
    teamsFilterValue: string;
    teamsFilterValueHandler(v: string): void;
}

export const TeamsAndPlayersTeamsComponent: React.FC<TeamsAndPlayersTeamsComponentProps> = ({
    teamsList,
    teamsFilterValue, teamsFilterValueHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        search_teams_label_text: SEARCH_TEAMS_LABEL_TEXT,
        search_teams_input_text: SEARCH_TEAMS_INPUT_TEXT,
        search_teams_no_results_message_text: SEARCH_TEAMS_NO_RESULTS_MESSAGE_TEXT
    } = TEXTS[activeLanguage];


    if (!teamsList) return null;


    const TeamsList = teamsList.map((Team, i) =>
        <TeamsTeamComponent key={i} team={Team} />
    );


    return (
        <Col xs={12} className={"teams-and-players-teams teams-and-players-teams-component"}>
            <Row className={"teams-and-players-teams-content"}>

                <Col xs={12} className={"teams-filters"}>
                    <Row className={"teams-filters-content"}>
                        <Col xs={6} lg={"auto"} className={"teams-filters-label"}>
                            <span>{SEARCH_TEAMS_LABEL_TEXT}</span>
                        </Col>
                        <Col xs={6} lg={"auto"} className={"teams-filters-filter"}>
                            <Form.Control
                                value={teamsFilterValue}
                                onChange={(e) => teamsFilterValueHandler(e.target.value)}
                                placeholder={SEARCH_TEAMS_INPUT_TEXT}
                            />
                        </Col>
                    </Row>
                </Col>

                <Col xs={12} className={"teams-list"}>
                    <Row className={"teams-list-content"}>
                        {!!TeamsList.length ?
                            TeamsList
                            :
                            <MessageComponent messageText={SEARCH_TEAMS_NO_RESULTS_MESSAGE_TEXT} />
                        }
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsAndPlayersTeamsComponent;
