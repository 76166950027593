import { LeagueRoundInterface } from "@Interfaces/Api";


import UseLeagueRoundLabel from "@Texts/Games/Schedule/Rounds/_Hook";


export const getLeagueRoundLabel = (LeagueRound: LeagueRoundInterface) : string => {

    if (!LeagueRound) {
        return "";
    }

    const {
        league_round_type: leagueRoundType,
        league_round_number: leagueRoundNumber, league_round_name: leagueRoundName,
    } = LeagueRound

    let leagueRoundLabel = "";

    if (leagueRoundName) {
        leagueRoundLabel = leagueRoundName;
    } else if (leagueRoundType) {
        leagueRoundLabel = UseLeagueRoundLabel(leagueRoundType);
        if (leagueRoundType === 1) {
            leagueRoundLabel = `${leagueRoundNumber} ${leagueRoundLabel}`;
        }
    }

    return leagueRoundLabel;

};


export default getLeagueRoundLabel;
