import { useEffect, useState } from "react";


import _getDataFromApi from "@Services/API/Requests";

import {
    API_FREE_AGENTS_LIST_ENDPOINT_URL, API_FREE_AGENTS_LIST_FOR_ACTIVE_SEASON_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    ListByLeaguesType
} from "@Interfaces/Api";


export const UseTransfersList = (activeSeason = true) : [ListByLeaguesType | null, ErrorMessageInterface | null] => {


    const API_ENDPOINT_URL = activeSeason ? API_FREE_AGENTS_LIST_FOR_ACTIVE_SEASON_ENDPOINT_URL : API_FREE_AGENTS_LIST_ENDPOINT_URL;

    const [TransfersList, setTransfersList] = useState<ListByLeaguesType | null>(null);
    const [TransfersListError, setTransfersListError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const getTransfersList = async () => {
            await _getDataFromApi(
                API_ENDPOINT_URL,
                setTransfersList, setTransfersListError
            );
        };

        getTransfersList();

        return () => {
            setTransfersList(null);
            setTransfersListError(null);
        };

    }, []);

    return [TransfersList, TransfersListError];

};


export default UseTransfersList;
