import {
    TeamLeagueDataInterface
} from "@Interfaces/Api";


export const generateLeagueName = (League: TeamLeagueDataInterface) : string | null => {

    if (!League) {
        return null;
    }

    const {
        league_name: leagueName, league_short_name: leagueShortName, league_season_data: LeagueSeason
    } = League;

    let fullLeagueName = leagueShortName || leagueName;

    if (LeagueSeason) {
        const { league_season_name: seasonName } = LeagueSeason;
        fullLeagueName += ` (${seasonName})`;
    }

    return fullLeagueName;

};


export default generateLeagueName;
