import React from "react";


import {Row, Col} from "react-bootstrap";

import {FaFacebookSquare, FaYoutubeSquare} from "react-icons/all";


type ResultHeaderStreamsProps = {
    GAME_STREAMS: {FB_STREAM?: string | null, YT_STREAM?: string | null};
};


export const ResultHeaderStreamsComponent: React.FC<ResultHeaderStreamsProps> = ({
    GAME_STREAMS
}): JSX.Element | null => {


    const {FB_STREAM, YT_STREAM} = GAME_STREAMS;


    return (
        <Col xs={3} lg={5} className={"league-game-streams"}>
            <Row className={"league-game-streams-content"}>


                <Col xs={6} lg={6} className={"league-game-stream"}>
                    <span>{`Stream`}</span>
                </Col>

                {!!GAME_STREAMS && (!!FB_STREAM || !!YT_STREAM) &&
	            <Col xs={12} lg={6} className={"league-game-streams-list"}>

                    {!!FB_STREAM &&
		            <a href={FB_STREAM} title={`Facebook`} target="_blank" rel="noreferrer noopener" className={"not-active facebook"}>
			            <FaFacebookSquare />
		            </a>
                    }

                    {!!YT_STREAM &&
		            <a href={YT_STREAM} title={`YouTube`} target="_blank" rel="noreferrer noopener" className={"not-active youtube"}>
			            <FaYoutubeSquare />
		            </a>
                    }

	            </Col>
                }

            </Row>
        </Col>
    );

};


export default ResultHeaderStreamsComponent;
