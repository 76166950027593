import React from "react";


import { Row, Col } from "react-bootstrap";


import HomePageArticlesSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import { SingleContentLoaderComponent } from "@LoadersElements";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import HomePageArticleComponent from "@Pages/Common/HomePage/Articles/ArticlesList/Article";


import { ArticleInterface } from "@Interfaces/Api";


import "./index.scss";


export interface HomePageArticlesListComponentProps {
    Articles: ArticleInterface[] | null;
    ArticlesError: ErrorMessageInterface | null;
}


const SLIDER_SETTINGS = {
    dots: false,
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 5,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
        {
            breakpoint: 1600,
            settings: {
                slidesToShow: 5,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1300,
            settings: {
                slidesToShow: 4,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 1024,
            settings: {
                slidesToShow: 3,
                slidesToScroll: 1,
            }
        },
        {
            breakpoint: 600,
            settings: {
                slidesToShow: 2,
                slidesToScroll: 1
            }
        },
        {
            breakpoint: 480,
            settings: {
                slidesToShow: 1,
                slidesToScroll: 1
            }
        }
    ]
};


export const HomePageArticlesListComponent: React.FC<HomePageArticlesListComponentProps> = ({
    Articles, ArticlesError
}) : JSX.Element | null => {


    if (ArticlesError) {
        return null;
    }

    if (!Articles) {
        return <SingleContentLoaderComponent />;
    }

    if (!Articles?.length) {
        return null;
    }


    const ArticlesList = Articles.map((Article, i) =>
        <HomePageArticleComponent key={i} Article={Article} />
    );


    return (
        <Col xs={12} className={"home-page__articles__list"}>
            <Row className={"home-page__articles__list__content"}>

                <HomePageArticlesSlider {...SLIDER_SETTINGS}>
                    {ArticlesList}
                </HomePageArticlesSlider>

            </Row>
        </Col>
    );

};


export default HomePageArticlesListComponent;
