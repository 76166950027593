import React, { useEffect, useState } from "react";


import { Row, Col } from "react-bootstrap";


import GameProgressQuarterHeaderComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Header";
import GameProgressQuarterEventsComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events";
import GameProgressQuarterFooterComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Footer";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


import "./index.scss";


export interface GameProgressQuarterComponentProps {
    quarterNumber: number;
    homeTeamID: number; awayTeamID: number;
    QuarterEvents: GameProgressEventType[];
    GamePlayers: ApiGameSummaryPlayersInterface;
    gameQuarterDurationTime: number;
    gameExtraTimeDurationTime: number;
}


export const GameProgressQuarterComponent: React.FC<GameProgressQuarterComponentProps> = ({
    homeTeamID, awayTeamID,
    quarterNumber, QuarterEvents,
    GamePlayers,
    gameQuarterDurationTime, gameExtraTimeDurationTime,
}) => {


    const [showQuarterEventsStatus, setShowQuarterEventsStatus] = useState(true);

    useEffect(() => {
        return () => {
            setShowQuarterEventsStatus(false);
        };
    }, []);


    if (!quarterNumber || !QuarterEvents || !GamePlayers) {
        return null;
    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__content"}>

                <GameProgressQuarterHeaderComponent
                    quarterNumber={quarterNumber}
                    showQuarterEventsStatus={showQuarterEventsStatus}
                    showQuarterEventsStatusHandler={setShowQuarterEventsStatus}
                />

                {showQuarterEventsStatus &&
                <GameProgressQuarterEventsComponent
	                homeTeamID={homeTeamID}
	                awayTeamID={awayTeamID}
                    QuarterEvents={QuarterEvents}
                    GamePlayers={GamePlayers}
	                gameQuarterDurationTime={gameQuarterDurationTime}
	                gameExtraTimeDurationTime={gameExtraTimeDurationTime}
                />
                }

                {showQuarterEventsStatus &&
                <GameProgressQuarterFooterComponent quarterNumber={quarterNumber} />
                }

            </Row>
        </Col>
    );

};


export default GameProgressQuarterComponent;
