import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import imagePlaceholder from "@Images/image-placeholder.png";


const TEXTS: any = {
    PL: {go_to_team_page_text: "Przejdź do strony drużyny"},
    EN: {go_to_team_page_text: "Go to Team page"}
};


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";


export interface TeamsTeamComponentProps {
    team: LeagueTeamInterface;
}


export const TeamsTeamComponent: React.FC<TeamsTeamComponentProps> = ({
    team
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {go_to_team_page_text: GO_TO_TEAM_PAGE_TEXT} = TEXTS[activeLanguage];


    if (!team) return null;


    const {
        team_id: teamID, team_slug: teamSlug,
        team_name: teamName, team_logo: teamLogo
    } = team;


    return (
        <Col key={teamID} xs={4} lg={3} xl={2} className={"teams-list-team"}>
            <Row className={"teams-list-team-content"}>

                <Col xs={12} className={"team-logo"}>
                    <NavLink to={`/druzyny/${teamSlug}`} title={GO_TO_TEAM_PAGE_TEXT}>
                        <img src={teamLogo || imagePlaceholder} alt={teamName} />
                    </NavLink>
                </Col>

                <Col xs={12} className={"team-name"}>
                    <NavLink to={`/druzyny/${teamSlug}`} title={GO_TO_TEAM_PAGE_TEXT}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamsTeamComponent;
