import React, { useEffect, useRef } from "react";


import { Container } from "react-bootstrap";


import scrollToDomElement from "@Utilities/Scrolling";


import HomePageSliderComponent from "@Pages/Common/HomePage/Slider";
import HomePageArticlesComponent from "@Pages/Common/HomePage/Articles";
import HomePageLeaguesComponent from "@Pages/Common/HomePage/Leagues";


import "./index.scss";


export interface HomePageComponentProps {}


export const HomePageComponent: React.FC<HomePageComponentProps> = () : JSX.Element | null => {


    const ComponentRef = useRef(null);


    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, 250);
    }, []);


    return (
        <Container fluid={true} className={"home-page-component home-page"} ref={ComponentRef}>

            <HomePageSliderComponent />

            <HomePageArticlesComponent />

            <HomePageLeaguesComponent />

        </Container>
    );

};


export default HomePageComponent;
