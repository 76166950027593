export *  from "@Dictionary/Pages/Leagues/Leagues/Types/Phrases/Set";
export *  from "@Dictionary/Pages/Leagues/Leagues/Phrases";


import LeaguesDictionaryInterface from "@Dictionary/Pages/Leagues/Leagues/Types";


import {
    TABLE_PLACE_SHORT_LABEL_PL,
    TABLE_PLACE_SHORT_LABEL_EN,
    TABLE_PLACE_LABEL_PL,
    TABLE_PLACE_LABEL_EN,
    TABLE_PLACE_TITLE_PL,
    TABLE_PLACE_TITLE_EN,
    TABLE_TEAM_SHORT_LABEL_PL,
    TABLE_TEAM_SHORT_LABEL_EN,
    TABLE_TEAM_LABEL_PL,
    TABLE_TEAM_LABEL_EN,
    TABLE_TEAM_TITLE_PL,
    TABLE_TEAM_TITLE_EN,
    TABLE_POINTS_SHORT_LABEL_PL,
    TABLE_POINTS_SHORT_LABEL_EN,
    TABLE_POINTS_LABEL_PL,
    TABLE_POINTS_LABEL_EN,
    TABLE_POINTS_TITLE_PL,
    TABLE_POINTS_TITLE_EN,
    TABLE_GAMES_SHORT_LABEL_PL,
    TABLE_GAMES_SHORT_LABEL_EN,
    TABLE_GAMES_LABEL_PL,
    TABLE_GAMES_TITLE_PL,
    TABLE_GAMES_TITLE_EN,
    TABLE_GAMES_LABEL_EN,
    TABLE_VICTORIES_SHORT_LABEL_PL,
    TABLE_VICTORIES_SHORT_LABEL_EN,
    TABLE_VICTORIES_LABEL_PL,
    TABLE_VICTORIES_LABEL_EN,
    TABLE_VICTORIES_TITLE_PL,
    TABLE_VICTORIES_TITLE_EN,
    TABLE_DEFEATS_SHORT_LABEL_PL,
    TABLE_DEFEATS_SHORT_LABEL_EN,
    TABLE_DEFEATS_LABEL_PL,
    TABLE_DEFEATS_LABEL_EN,
    TABLE_DEFEATS_TITLE_PL,
    TABLE_DEFEATS_TITLE_EN,
    TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_PL,
    TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_EN,
    TABLE_TEAM_SMALL_POINTS_LABEL_PL,
    TABLE_TEAM_SMALL_POINTS_LABEL_EN,
    TABLE_TEAM_SMALL_POINTS_TITLE_PL,
    TABLE_TEAM_SMALL_POINTS_TITLE_EN,
    TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_PL,
    TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_EN,
    TABLE_OPPONENTS_SMALL_POINTS_LABEL_PL,
    TABLE_OPPONENTS_SMALL_POINTS_LABEL_EN,
    TABLE_OPPONENTS_SMALL_POINTS_TITLE_PL,
    TABLE_OPPONENTS_SMALL_POINTS_TITLE_EN,
    TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_PL,
    TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_EN,
    TABLE_SMALL_POINTS_DIFFERENCE_LABEL_PL,
    TABLE_SMALL_POINTS_DIFFERENCE_LABEL_EN,
    TABLE_SMALL_POINTS_DIFFERENCE_TITLE_PL,
    TABLE_SMALL_POINTS_DIFFERENCE_TITLE_EN,
    NO_GAMES_STATISTICS_MESSAGE_TEXT_PL,
    NO_GAMES_STATISTICS_MESSAGE_TEXT_EN,
    LEAGUE_SCHEDULE_TEXT_PL,
    LEAGUE_SCHEDULE_TEXT_EN,
    NO_LEAGUES_MESSAGE_TEXT_PL,
    NO_LEAGUES_MESSAGE_TEXT_EN,
    NO_SCHEDULE_MESSAGE_TEXT_PL,
    NO_SCHEDULE_MESSAGE_TEXT_EN,
    LEAGUES_TABLES_HEADER_TEXT_PL,
    LEAGUES_TABLES_HEADER_TEXT_EN,
    NO_ROUND_SELECTED_MESSAGE_TEXT_PL,
    NO_ROUND_SELECTED_MESSAGE_TEXT_EN
} from "@Dictionary/Pages/Leagues/Leagues/Phrases";


export const LeaguesDictionary: LeaguesDictionaryInterface = {
    PL: {
        TABLE_PLACE_SHORT_LABEL: TABLE_PLACE_SHORT_LABEL_PL, TABLE_PLACE_LABEL: TABLE_PLACE_LABEL_PL, TABLE_PLACE_TITLE: TABLE_PLACE_TITLE_PL,
        TABLE_TEAM_SHORT_LABEL: TABLE_TEAM_SHORT_LABEL_PL, TABLE_TEAM_LABEL: TABLE_TEAM_LABEL_PL, TABLE_TEAM_TITLE: TABLE_TEAM_TITLE_PL,
        TABLE_POINTS_SHORT_LABEL: TABLE_POINTS_SHORT_LABEL_PL, TABLE_POINTS_LABEL: TABLE_POINTS_LABEL_PL, TABLE_POINTS_TITLE: TABLE_POINTS_TITLE_PL,
        TABLE_GAMES_SHORT_LABEL: TABLE_GAMES_SHORT_LABEL_PL, TABLE_GAMES_LABEL: TABLE_GAMES_LABEL_PL, TABLE_GAMES_TITLE: TABLE_GAMES_TITLE_PL,
        TABLE_VICTORIES_SHORT_LABEL: TABLE_VICTORIES_SHORT_LABEL_PL, TABLE_VICTORIES_LABEL: TABLE_VICTORIES_LABEL_PL, TABLE_VICTORIES_TITLE: TABLE_VICTORIES_TITLE_PL,
        TABLE_DEFEATS_SHORT_LABEL: TABLE_DEFEATS_SHORT_LABEL_PL, TABLE_DEFEATS_LABEL: TABLE_DEFEATS_LABEL_PL, TABLE_DEFEATS_TITLE: TABLE_DEFEATS_TITLE_PL,
        TABLE_TEAM_SMALL_POINTS_SHORT_LABEL: TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_PL, TABLE_TEAM_SMALL_POINTS_LABEL: TABLE_TEAM_SMALL_POINTS_LABEL_PL, TABLE_TEAM_SMALL_POINTS_TITLE: TABLE_TEAM_SMALL_POINTS_TITLE_PL,
        TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL: TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_PL, TABLE_OPPONENTS_SMALL_POINTS_LABEL: TABLE_OPPONENTS_SMALL_POINTS_LABEL_PL, TABLE_OPPONENTS_SMALL_POINTS_TITLE: TABLE_OPPONENTS_SMALL_POINTS_TITLE_PL,
        TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL: TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_PL, TABLE_SMALL_POINTS_DIFFERENCE_LABEL: TABLE_SMALL_POINTS_DIFFERENCE_LABEL_PL, TABLE_SMALL_POINTS_DIFFERENCE_TITLE: TABLE_SMALL_POINTS_DIFFERENCE_TITLE_PL,
        NO_GAMES_STATISTICS_MESSAGE_TEXT: NO_GAMES_STATISTICS_MESSAGE_TEXT_PL,
        LEAGUE_SCHEDULE_TEXT: LEAGUE_SCHEDULE_TEXT_PL, LEAGUES_TABLES_HEADER_TEXT: LEAGUES_TABLES_HEADER_TEXT_PL,
        NO_LEAGUES_MESSAGE_TEXT: NO_LEAGUES_MESSAGE_TEXT_PL,
        NO_SCHEDULE_MESSAGE_TEXT: NO_SCHEDULE_MESSAGE_TEXT_PL, NO_ROUND_SELECTED_MESSAGE_TEXT: NO_ROUND_SELECTED_MESSAGE_TEXT_PL
    },
    EN: {
        TABLE_PLACE_SHORT_LABEL: TABLE_PLACE_SHORT_LABEL_EN, TABLE_PLACE_LABEL: TABLE_PLACE_LABEL_EN, TABLE_PLACE_TITLE: TABLE_PLACE_TITLE_EN,
        TABLE_TEAM_SHORT_LABEL: TABLE_TEAM_SHORT_LABEL_EN, TABLE_TEAM_LABEL: TABLE_TEAM_LABEL_EN, TABLE_TEAM_TITLE: TABLE_TEAM_TITLE_EN,
        TABLE_POINTS_SHORT_LABEL: TABLE_POINTS_SHORT_LABEL_EN, TABLE_POINTS_LABEL: TABLE_POINTS_LABEL_EN, TABLE_POINTS_TITLE: TABLE_POINTS_TITLE_EN,
        TABLE_GAMES_SHORT_LABEL: TABLE_GAMES_SHORT_LABEL_EN, TABLE_GAMES_LABEL: TABLE_GAMES_LABEL_EN, TABLE_GAMES_TITLE: TABLE_GAMES_TITLE_EN,
        TABLE_VICTORIES_SHORT_LABEL: TABLE_VICTORIES_SHORT_LABEL_EN, TABLE_VICTORIES_LABEL: TABLE_VICTORIES_LABEL_EN, TABLE_VICTORIES_TITLE: TABLE_VICTORIES_TITLE_EN,
        TABLE_DEFEATS_SHORT_LABEL: TABLE_DEFEATS_SHORT_LABEL_EN, TABLE_DEFEATS_LABEL: TABLE_DEFEATS_LABEL_EN, TABLE_DEFEATS_TITLE: TABLE_DEFEATS_TITLE_EN,
        TABLE_TEAM_SMALL_POINTS_SHORT_LABEL: TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_EN, TABLE_TEAM_SMALL_POINTS_LABEL: TABLE_TEAM_SMALL_POINTS_LABEL_EN, TABLE_TEAM_SMALL_POINTS_TITLE: TABLE_TEAM_SMALL_POINTS_TITLE_EN,
        TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL: TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_EN, TABLE_OPPONENTS_SMALL_POINTS_LABEL: TABLE_OPPONENTS_SMALL_POINTS_LABEL_EN, TABLE_OPPONENTS_SMALL_POINTS_TITLE: TABLE_OPPONENTS_SMALL_POINTS_TITLE_EN,
        TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL: TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_EN, TABLE_SMALL_POINTS_DIFFERENCE_LABEL: TABLE_SMALL_POINTS_DIFFERENCE_LABEL_EN, TABLE_SMALL_POINTS_DIFFERENCE_TITLE: TABLE_SMALL_POINTS_DIFFERENCE_TITLE_EN,
        NO_GAMES_STATISTICS_MESSAGE_TEXT: NO_GAMES_STATISTICS_MESSAGE_TEXT_EN,
        LEAGUE_SCHEDULE_TEXT: LEAGUE_SCHEDULE_TEXT_EN, LEAGUES_TABLES_HEADER_TEXT: LEAGUES_TABLES_HEADER_TEXT_EN,
        NO_LEAGUES_MESSAGE_TEXT: NO_LEAGUES_MESSAGE_TEXT_EN,
        NO_SCHEDULE_MESSAGE_TEXT: NO_SCHEDULE_MESSAGE_TEXT_EN, NO_ROUND_SELECTED_MESSAGE_TEXT: NO_ROUND_SELECTED_MESSAGE_TEXT_EN,
    }
};


export default LeaguesDictionary;
