import validateFirstName from "@Services/Utilities/Tools/Validators/Name/FirstName";
import validateLastName from "@Services/Utilities/Tools/Validators/Name/LastName";
import validateNickName from "@Services/Utilities/Tools/Validators/Name/NickName";

export {
    validateFirstName, validateLastName, validateNickName
};


export const validateName = (
    name: string | undefined, minLength = 3, maxLength: number | null = null
) : boolean => {

    if (!name || name.length < minLength || (maxLength && name.length > maxLength)) {
        return false;
    }

    return true;

};


export default validateName;
