export const SELECT_ALL_MAN_PL = "Zaznacz wszystkich";
export const SELECT_ALL_MAN_EN = "Select all";
export const UN_SELECT_ALL_MAN_PL = "Odznacz wszystkich";
export const UN_SELECT_ALL_MAN_EN = "Unselect all";

export const I_UNDERSTAND_PHRASE_PL = "Rozumiem";
export const I_UNDERSTAND_PHRASE_EN = "I Understand";
export const TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_PL = "Aby potwierdzić, kliknij ponownie";
export const TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_EN = "To Confirm, click again";

export const REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_PL = "Zgłoszenie zostało zaktualizowane";
export const REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_EN = "Request has been updated";
export const FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_PL = "Nie udało się zaktualizować zgłoszenia";
export const FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_EN = "Failed to update the request";

export const LACK_WORD_PL = "Brak";
export const LACK_WORD_EN = "Lack";
export const ADD_WORD_PL = "Dodaj";
export const ADD_WORD_EN = "Add";
export const ABORT_WORD_PL = "Anuluj";
export const ABORT_WORD_EN = "Cancel";
export const APPROVE_WORD_TEXT_PL = "Zaakceptuj";
export const APPROVE_WORD_TEXT_EN = "Approve";
export const REJECT_WORD_TEXT_PL = "Odrzuć";
export const REJECT_WORD_TEXT_EN = "Reject";
export const EDIT_WORD_TEXT_PL = "Edytuj";
export const EDIT_WORD_TEXT_EN = "Edit";
export const SAVE_WORD_TEXT_PL = "Zapisz";
export const SAVE_WORD_TEXT_EN = "Save";
export const REMOVE_WORD_TEXT_PL = "Usuń";
export const REMOVE_WORD_TEXT_EN = "Remove";
export const NEXT_WORD_TEXT_PL = "Następny";
export const NEXT_WORD_TEXT_EN = "Next";
export const PREVIOUS_WORD_TEXT_PL = "Poprzedni";
export const PREVIOUS_WORD_TEXT_EN = "Previous";

export const DATE_WORD_TEXT_PL = "Data";
export const DATE_WORD_TEXT_EN = "Date";
export const HOUR_WORD_TEXT_PL = "Godzina";
export const HOUR_WORD_TEXT_EN = "Hour";
export const HOUR_SHORT_WORD_TEXT_PL = "Godz";
export const HOUR_SHORT_WORD_TEXT_EN = "Hour";

export const PLACEMENT_WORD_TEXT_PL = "Miejsce";
export const PLACEMENT_WORD_TEXT_EN = "Place";
export const PLACEMENT_WORD_SHORT_TEXT_PL = "M";
export const PLACEMENT_WORD_SHORT_TEXT_EN = "P";

export const AGAINST_WORD_TEXT_PL = "Przeciwko";
export const AGAINST_WORD_TEXT_EN = "Against";

export const DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_PL = "Dane zostały zaktualizowane";
export const DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_EN = "Data has been updated";

export const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_PL = "Wystąpił błąd";
export const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_EN = "An error occurred";
export const DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_PL = "Niepoprawne dane";
export const DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_EN = "Incorrect data";
export const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_PL = "Brak danych";
export const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_EN = "No data";


export const FIRST_NAME_WORD_TEXT_PL = "Imię";
export const FIRST_NAME_WORD_TEXT_EN = "Name";
export const LAST_NAME_WORD_TEXT_PL = "Nazwisko";
export const LAST_NAME_WORD_TEXT_EN = "Surname";
export const EMAIL_ADDRESS_WORD_TEXT_PL = "Email";
export const EMAIL_ADDRESS_WORD_TEXT_EN = "Email";
export const NUMBER_WORD_TEXT_PL = "Numer";
export const NUMBER_WORD_TEXT_EN = "Number";

export const ACTIVE_MASC_WORD_TEXT_PL = "Aktywny";
export const ACTIVE_MASC_WORD_TEXT_EN = "Active";
export const ACTIVE_FEM_WORD_TEXT_PL = "Aktywna";
export const ACTIVE_FEM_WORD_TEXT_EN = "Active";
export const INACTIVE_MASC_WORD_TEXT_PL = "Nieaktywny";
export const INACTIVE_MASC_WORD_TEXT_EN = "Inactive";
export const INACTIVE_FEM_WORD_TEXT_PL = "Nieaktywna";
export const INACTIVE_FEM_WORD_TEXT_EN = "Inactive";
export const ACTIVATED_WORD_TEXT_PL = "Aktywowany";
export const ACTIVATED_WORD_TEXT_EN = "Activated";
export const FORMALS_AGREEMENTS_PHRASE_TEXT_PL = "Zgody formalne";
export const FORMALS_AGREEMENTS_PHRASE_TEXT_EN = "Formal agreements";

export const FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL = "Imię jest zbyt krótkie";
export const FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN = "Name is too short";
export const LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL = "Nazwisko jest zbyt krótkie";
export const LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN = "Surname is too short";
export const EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_PL = "Adres email jest nieprawidłowy";
export const EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_EN = "Incorrect email address";
export const EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_PL = "Podany email jest już zarejestrowany - przejdź do strony logowania";
export const EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_EN = "Email already registered - go to login page";
export const NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_PL = "Nieprawidłowy numer";
export const NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_EN = "Incorrect number";

export const DATE_OF_CREATION_PHRASE_TEXT_PL = "Data utworzenia";
export const DATE_OF_CREATION_PHRASE_TEXT_EN = "Date of creation";

export const CREATE_ACCOUNT_PHRASE_TEXT_PL = "Utwórz konto";
export const CREATE_ACCOUNT_PHRASE_TEXT_EN = "Create account";
export const LOGIN_PHRASE_TEXT_PL = "Zaloguj się";
export const LOGIN_PHRASE_TEXT_EN = "Login";

export const CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_PL = "Aby ustawić hasło, potwierdź adres email";
export const CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_EN = "Confirm your email to set password";

export const DOCUMENT_NAME_TEXT_PL = "Nazwa Dokumentu";
export const DOCUMENT_NAME_TEXT_EN = "Document Name";
export const ADD_NEW_DOCUMENT_TEXT_PL = "Dodaj Dokument";
export const ADD_NEW_DOCUMENT_TEXT_EN = "Add Document";
export const DEALS_AND_DOCUMENTS_TEXT_PL = "Umowy i Dokumenty";
export const DEALS_AND_DOCUMENTS_TEXT_EN = "Deals and Documents";

export const DOWNLOAD_WORD_PL = "pobierz";
export const DOWNLOAD_WORD_EN = "download";

export const NO_FILE_TO_UPLOAD_TEXT_PL = "nie wybrano pliku";
export const NO_FILE_TO_UPLOAD_TEXT_EN = "no file selected";
export const FILE_UPLOAD_SUCCESS_TEXT_PL = "Plik został załadowany";
export const FILE_UPLOAD_SUCCESS_TEXT_EN = "File successfully uploaded";
export const FILE_UPLOAD_ERROR_TEXT_PL = "Błąd ładowania pliku";
export const FILE_UPLOAD_ERROR_TEXT_EN = "File upload error";

export const TIME_WORD_PL = "Czas";
export const TIME_WORD_EN = "Time";

export const START_WORD_PL = "Początek";
export const START_WORD_EN = "Start";
export const FINISH_WORD_PL = "Koniec";
export const FINISH_WORD_EN = "Finish";
export const STATUS_WORD_TEXT_PL = "Status";
export const STATUS_WORD_TEXT_EN = "Status";

export const SHOW_WORD_TEXT_PL = "Pokaż";
export const SHOW_WORD_TEXT_EN = "Show";
export const HIDE_WORD_TEXT_PL = "Ukryj";
export const HIDE_WORD_TEXT_EN = "Hide";
export const DETAILS_WORD_TEXT_PL = "Szczegóły";
export const DETAILS_WORD_TEXT_EN = "Details";

export const SEASON_WORD_TEXT_PL = "Sezon";
export const SEASON_WORD_TEXT_EN = "Season";
export const LEAGUE_WORD_TEXT_PL = "Liga";
export const LEAGUE_WORD_TEXT_EN = "League";
export const LEAGUES_WORD_TEXT_PL = "Ligi";
export const LEAGUES_WORD_TEXT_EN = "Leagues";
export const NO_LEAGUES_PHRASE_TEXT_PL = "brak lig";
export const NO_LEAGUES_PHRASE_TEXT_EN = "no leagues";
export const CHOOSE_LEAGUE_PHRASE_TEXT_PL = "wybierz ligę";
export const CHOOSE_LEAGUE_PHRASE_TEXT_EN = "choose league";
export const NO_LEAGUE_SELECTED_PHRASE_TEXT_PL = "Nie wybrano ligi";
export const NO_LEAGUE_SELECTED_PHRASE_TEXT_EN = "No league chosen";

export const PLAYER_OF_THE_ROUND_PHRASE_TEXT_PL = "Zawodnik Kolejki";
export const PLAYER_OF_THE_ROUND_PHRASE_TEXT_EN = "Player of the Round";

export const PLAYER_WORD_TEXT_PL = "Zawodnik";
export const PLAYER_WORD_TEXT_EN = "Player";
export const PLAYERS_WORD_TEXT_PL = "Zawodnicy";
export const PLAYERS_WORD_TEXT_EN = "Players";

export const CHOOSE_PLAYER_PHRASE_TEXT_PL = "wybierz zawodnika";
export const CHOOSE_PLAYER_PHRASE_TEXT_EN = "choose player";
export const NO_PLAYERS_PHRASE_TEXT_PL = "Brak zawodników";
export const NO_PLAYERS_PHRASE_TEXT_EN = "No Players";
export const NO_PLAYERS_AVAILABLE_PHRASE_TEXT_PL = "Brak dostępnych zawodników";
export const NO_PLAYERS_AVAILABLE_PHRASE_TEXT_EN = "No available Players";
export const ALL_PLAYERS_PHRASE_TEXT_PL = "Wszyscy zawodnicy";
export const ALL_PLAYERS_PHRASE_TEXT_EN = "All Players";

export const NO_LEAGUE_SQUADS_PHRASE_TEXT_PL = "Brak składów ligowych";
export const NO_LEAGUE_SQUADS_PHRASE_TEXT_EN = "No Leagues Squads";
export const LEAGUE_PLAYERS_PHRASE_TEXT_PL = "Zawodnicy Ligowi";
export const LEAGUE_PLAYERS_PHRASE_TEXT_EN = "League Players";
export const NO_LEAGUE_PLAYERS_PHRASE_TEXT_PL = "Brak Zawodników Ligowych";
export const NO_LEAGUE_PLAYERS_PHRASE_TEXT_EN = "No League Players";
export const PLAYER_ADD_PHRASE_TEXT_PL = "Dodaj zawodnika";
export const PLAYER_ADD_PHRASE_TEXT_EN = "Add player";
export const PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_PL = "Zawodnik dodany prawidłowo";
export const PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_EN = "Player added successfully";
export const PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_PL = "Zawodnik jest już w drużynie";
export const PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_EN = "Player already in team";
export const SHOW_TEAM_PLAYERS_PHRASE_TEXT_PL = "Pokaż zawodników";
export const SHOW_TEAM_PLAYERS_PHRASE_TEXT_EN = "Show players";
export const HIDE_TEAM_PLAYERS_PHRASE_TEXT_PL = "Ukryj zawodników";
export const HIDE_TEAM_PLAYERS_PHRASE_TEXT_EN = "Hide players";
export const SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL = "Pokaż historycznych zawodników";
export const SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN = "Show historical players";
export const HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL = "Ukryj historycznych zawodników";
export const HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN = "Hide historical players";
export const ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_PL = "Do skladu ligowego możliwe jest dodanie jedynie zawodników aktywowanych, którzy zaakceptowali regulaminy serwisu";
export const ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_EN = "Only activated players, that accept service regulations can be added to the league squad";

export const INCLUDING_HISTORICAL_PHRASE_TEXT_PL = "w tym historyczni";
export const INCLUDING_HISTORICAL_PHRASE_TEXT_EN = "including historical";

export const GAME_WORD_PL = "Mecz";
export const GAME_WORD_EN = "Game";
export const GAMES_WORD_TEXT_PL = "Mecze";
export const GAMES_WORD_TEXT_EN = "Games";
export const GAME_WORD_GENITIVE_PL = "Meczu";
export const GAME_WORD_GENITIVE_EN = "Game";
export const GAME_START_PHRASE_PL = "Początek Meczu";
export const GAME_START_PHRASE_EN = "Game Start";
export const GAME_FINISH_PHRASE_PL = "Koniec Meczu";
export const GAME_FINISH_PHRASE_EN = "Game Finish";
export const NO_GAMES_PHRASE_TEXT_PL = "Brak meczów";
export const NO_GAMES_PHRASE_TEXT_EN = "No games";
export const INCOMING_GAMES_PHRASE_TEXT_PL = "Nadchodzące mecze";
export const INCOMING_GAMES_PHRASE_TEXT_EN = "Incoming games";

export const WIN_WORD_TEXT_PL = "Zwycięstwo";
export const WIN_WORD_TEXT_EN = "Win";
export const WIN_WORD_SHORT_TEXT_PL = "Zw";
export const WIN_WORD_SHORT_TEXT_EN = "W";
export const WINS_WORD_TEXT_PL = "Zwycięstwa";
export const WINS_WORD_TEXT_EN = "Wins";
export const DEFEAT_WORD_TEXT_PL = "Porażka";
export const DEFEAT_WORD_TEXT_EN = "Defeat";
export const DEFEAT_WORD_SHORT_TEXT_PL = "Por";
export const DEFEAT_WORD_SHORT_TEXT_EN = "Def";
export const DEFEATS_WORD_TEXT_PL = "Porażki";
export const DEFEATS_WORD_TEXT_EN = "Defeats";

export const TEAM_WORD_TEXT_PL = "Drużyna";
export const TEAM_WORD_TEXT_EN = "Team";
export const TEAMS_WORD_TEXT_PL = "Drużyny";
export const TEAMS_WORD_TEXT_EN = "Teams";
export const TEAM_NAME_PHRASE_TEXT_PL = "Nazwa Drużyny";
export const TEAM_NAME_PHRASE_TEXT_EN = "Team Name";
export const TEAM_SHORT_NAME_PHRASE_TEXT_PL = "Skrócona nazwa drużyny";
export const TEAM_SHORT_NAME_PHRASE_TEXT_EN = "Team short name";
export const TEAM_LEAGUE_NAME_PHRASE_TEXT_PL = "Ligowa nazwa drużyny";
export const TEAM_LEAGUE_NAME_PHRASE_TEXT_EN = "Team league name";
export const TEAM_LEAGUE_SHORT_NAME_PHRASE_TEXT_PL = "Skrócona ligowa nazwa drużyny";
export const TEAM_LEAGUE_SHORT_NAME_PHRASE_TEXT_EN = "Team league short name";
export const TEAM_CREW_PHRASE_TEXT_PL = "Członkowie zespołu";
export const TEAM_CREW_PHRASE_TEXT_EN = "Team Crew";
export const TEAM_COACH_WORD_TEXT_PL = "Trener";
export const TEAM_COACH_WORD_TEXT_EN = "Coach";


export const TABLE_WORD_TEXT_PL = "Tabela";
export const TABLE_WORD_TEXT_EN = "Table";
export const TABLES_WORD_TEXT_PL = "Tabele";
export const TABLES_WORD_TEXT_EN = "Tables";
export const FULL_TABLE_PHRASE_PL = "Pełna Tabela";
export const FULL_TABLE_PHRASE_EN = "Full Table";
export const POINTS_WORD_SHORT_TEXT_PL = "Pkt";
export const POINTS_WORD_SHORT_TEXT_EN = "Pts";
export const POINTS_IN_PHRASE_TEXT_PL = "Punkty zdobyte";
export const POINTS_IN_PHRASE_TEXT_EN = "Points in";
export const POINTS_IN_SHORT_PHRASE_TEXT_PL = "Pkt zd";
export const POINTS_IN_SHORT_PHRASE_TEXT_EN = "Pts in";
export const POINTS_OUT_PHRASE_TEXT_PL = "Punkty stracone";
export const POINTS_OUT_PHRASE_TEXT_EN = "Points out";
export const POINTS_OUT_SHORT_PHRASE_TEXT_PL = "Pkt str";
export const POINTS_OUT_SHORT_PHRASE_TEXT_EN = "Pts out";


export const STATISTICS_POINTS_WORD_TEXT_PL = "Punkty";
export const STATISTICS_POINTS_WORD_TEXT_EN = "Points";
export const STATISTICS_POINTS_SHORT_TEXT_PL = "Pkt";
export const STATISTICS_POINTS_SHORT_TEXT_EN = "Pts";
export const STATISTICS_TEAM_POINTS_PHRASE_TEXT_PL = "Punkty zespołu";
export const STATISTICS_TEAM_POINTS_PHRASE_TEXT_EN = "Team points";
export const STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_PL = "Punkty rywali";
export const STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_EN = "Opponents points";
export const STATISTICS_REBOUNDS_WORD_TEXT_PL = "Zbiórki";
export const STATISTICS_REBOUNDS_WORD_TEXT_EN = "Rebounds";
export const STATISTICS_REBOUNDS_SHORT_TEXT_PL = "Zb";
export const STATISTICS_REBOUNDS_SHORT_TEXT_EN = "Reb";
export const STATISTICS_ASSISTS_WORD_TEXT_PL = "Asysty";
export const STATISTICS_ASSISTS_WORD_TEXT_EN = "Assists";
export const STATISTICS_ASSISTS_SHORT_TEXT_PL = "A";
export const STATISTICS_ASSISTS_SHORT_TEXT_EN = "A";
export const STATISTICS_STEALS_WORD_TEXT_PL = "Przechwyty";
export const STATISTICS_STEALS_WORD_TEXT_EN = "Steals";
export const STATISTICS_STEALS_SHORT_TEXT_PL = "P";
export const STATISTICS_STEALS_SHORT_TEXT_EN = "S";
export const STATISTICS_BLOCKS_WORD_TEXT_PL = "Bloki";
export const STATISTICS_BLOCKS_WORD_TEXT_EN = "Blocks";
export const STATISTICS_BLOCKS_SHORT_TEXT_PL = "B";
export const STATISTICS_BLOCKS_SHORT_TEXT_EN = "B";
export const STATISTICS_EVAL_TEXT_PL = "EVAL";
export const STATISTICS_EVAL_TEXT_EN = "EVAL";
export const STATISTICS_BALANCE_WORD_TEXT_PL = "Bilans";
export const STATISTICS_BALANCE_WORD_TEXT_EN = "Balance";
export const STATISTICS_DIFFERENCE_WORD_TEXT_PL = "Różnca";
export const STATISTICS_DIFFERENCE_WORD_TEXT_EN = "Difference";

export const PLAYER_OUT_FROM_GAME_PHRASE_TEXT_PL = "zawodnik opuszcza mecz";
export const PLAYER_OUT_FROM_GAME_PHRASE_TEXT_EN = "player sent off";

export const MY_REQUESTS_PHRASE_TEXT_PL = "Moje Zgłoszenia";
export const MY_REQUESTS_PHRASE_TEXT_EN = "My Requests";
export const NO_REQUESTS_PHRASE_TEXT_PL = "Brak zgłoszeń";
export const NO_REQUESTS_PHRASE_TEXT_EN = "No Requests";

export const TRANSFER_LIST_PHRASE_TEXT_PL = "Lista Transferowa";
export const TRANSFER_LIST_PHRASE_TEXT_EN = "Transfer List";
export const TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_PL = "Lista Wolnych Agentów";
export const TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_EN = "Free Agents List";
export const TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_PL = "Brak wolnych agentów";
export const TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_EN = "No free agents";
export const ADD_TO_TRANSFER_LIST_PHRASE_TEXT_PL = "Dodaj zgłoszenie do listy Transferowej";
export const ADD_TO_TRANSFER_LIST_PHRASE_TEXT_EN = "Add request to Free Agents List";
export const NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_PL = "Brak lig dla których można utworzyć zgłoszenie";
export const NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_EN = "There are no leagues to create request";
export const ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_PL = "Pomyślnie dodano do listy transferowej";
export const ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_EN = "Successfully added to transfer list";
export const ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_PL = "Nie udało się dodać zgłoszenia do listy transferowej";
export const ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_EN = "Failed to add application to transfer list";
