import StatisticsTypesInterface from "@GlobalData/Statistics/Types/Types";


export const StatisticsTypes: StatisticsTypesInterface = {
    TPTS: {PL: {label: "PKT", title: "Punkty"}, EN: {label: "PTS", title: "Points"}},
    EVAL: {PL: {label: "EVAL", title: "EVAL"}, EN: {label: "EVAL", title: "EVAL"}},
    AST: {PL: {label: "AST", title: "Asysty"}, EN: {label: "AST", title: "Assists"}},
    TREB: {PL: {label: "ZBO", title: "Zbiórki"}, EN: {label: "REB", title: "Rebounds"}},
    STL: {PL: {label: "PRZ", title: "Przechwyty"}, EN: {label: "STL", title: "Steals"}},
    BLK: {PL: {label: "BLK", title: "Bloki"}, EN: {label: "BLK", title: "Blocks"}}
};


export default StatisticsTypes;
