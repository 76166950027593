import React, { useEffect, useState } from "react";


import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";

import { AiOutlineClose, AiOutlinePlus } from "react-icons/ai";


import TeamFilesListAddFileFormComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/List/AddFile/Form";


import TeamFileInterface from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Files/_Types/TeamFile";


import "./index.scss";


export interface TeamFilesListAddFileComponentProps {
    fileUploadHandler: (File: TeamFileInterface) => Promise<boolean>;
}


export const TeamFilesListAddFileComponent: React.FC<TeamFilesListAddFileComponentProps> = ({
    fileUploadHandler
}) : JSX.Element | null => {


    const ABORT_WORD = UseCommonDictionaryPhrasesSet("ABORT_WORD");
    const ADD_NEW_DOCUMENT_TEXT = UseCommonDictionaryPhrasesSet("ADD_NEW_DOCUMENT_TEXT");


    const [addNewFileStatus, setAddNewFileStatus] = useState<boolean>(false);

    useEffect(() => {
        return () => {
            setAddNewFileStatus(false);
        };
    }, []);


    if (!fileUploadHandler) {
        return null;
    }

    return (
        <Col xs={12} className={"player-profile__captain-zone__team__team-files__files-list__add-file"}>
            <Row className={"player-profile__captain-zone__team__team-files__files-list__add-file__content"}>

                <Col xs={12} className={"player-profile__captain-zone__team__team-files__files-list__add-file__button"}>
                    <Row className={"player-profile__captain-zone__team__team-files__files-list__add-file__button__content"}>
                        <span className={"text-button"} onClick={() => setAddNewFileStatus(!addNewFileStatus)}>
                            <span className={"text-button__text"}>{addNewFileStatus ? ABORT_WORD : ADD_NEW_DOCUMENT_TEXT}</span>
                            {addNewFileStatus ? <AiOutlineClose className={"text-button__icon"} /> : <AiOutlinePlus className={"text-button__icon"} />}
                        </span>
                    </Row>
                </Col>

                {addNewFileStatus
                    &&
                <TeamFilesListAddFileFormComponent
                    fileUploadHandler={fileUploadHandler}
                    fileFormShowStatusHandler={setAddNewFileStatus}
                />
                }

            </Row>
        </Col>
    );

};


export default TeamFilesListAddFileComponent;
