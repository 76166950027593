import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent, {
    GameProgressEventTypeInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType, {
    GameProgressThrowEventInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterBlockEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterBlockEventDataComponent: React.FC<QuarterBlockEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.BLOCK) {
        return null;
    }

    if (!Event.eventBlockingPlayerID || !Event.eventBlockedPlayerID) {
        return null;
    }

    const BlockEvent = UseProgressEvent("BLK");
    const BlockedEvent = UseProgressEvent("BLK VIC");
    const Pts2AttemptEvent = UseProgressEvent("2PTA");
    const Pts3AttemptEvent = UseProgressEvent("3PTA");

    if (!BlockEvent || !BlockedEvent) {
        return null;
    }


    const EventBlockingPlayer = GamePlayers[Event.eventBlockingPlayerID];
    const EventBlockedPlayer = GamePlayers[Event.eventBlockedPlayerID];

    if (!EventBlockingPlayer || !EventBlockedPlayer) {
        return null;
    }


    const {
        player_first_name: blockingPlayerFirstName,
        player_last_name: blockingPlayerLastName,
        player_nick_name: blockingPlayerNickName,
        player_slug: blockingPlayerSlug,
        player_pseudonymization_status: blockingPlayerPseudonymizationStatus
    } = EventBlockingPlayer;

    let blockingPlayerName = "";

    if (Event.eventBlockingPlayerNumber) {
        blockingPlayerName += `${Event.eventBlockingPlayerNumber} `;
    }

    if (blockingPlayerPseudonymizationStatus) {
        blockingPlayerName += blockingPlayerNickName;
    } else {
        blockingPlayerName += `${blockingPlayerFirstName[0]}. ${blockingPlayerLastName}`
    }


    const {
        player_first_name: blockedPlayerFirstName,
        player_last_name: blockedPlayerLastName,
        player_nick_name: blockedPlayerNickName,
        player_slug: blockedPlayerSlug,
        player_pseudonymization_status: blockedPlayerPseudonymizationStatus
    } = EventBlockedPlayer;


    let blockedPlayerName = "";

    if (Event.eventBlockedPlayerNumber) {
        blockedPlayerName += `${Event.eventBlockedPlayerNumber} `;
    }

    if (blockedPlayerPseudonymizationStatus) {
        blockedPlayerName += blockedPlayerNickName;
    } else {
        blockedPlayerName += `${blockedPlayerFirstName[0]}. ${blockedPlayerLastName}`
    }


    const {
        eventName: { singular: blockEventName },
        eventIcon: blockEventIcon
    } = BlockEvent;

    const {
        eventName: { singular: blockedEventName },
    } = BlockedEvent;


    let HomeTeamData: React.ReactNode = "";
    let AwayTeamData: React.ReactNode = "";


    let ThrowEvent: GameProgressEventTypeInterface | undefined;

    if (Event.eventBlockedPlayerThrowType === "2PTA") {
        ThrowEvent = Pts2AttemptEvent;
    } else if (Event.eventBlockedPlayerThrowType === "3PTA") {
        ThrowEvent = Pts3AttemptEvent;
    }

    let blockedPlayerThrowInformation: string | undefined;

    if (ThrowEvent) {
    blockedPlayerThrowInformation = `
        ${ThrowEvent?.eventName?.singular || ""}
        (${Event.eventBlockedPlayerAccurateThrowsNumber || 0}/${(Event.eventBlockedPlayerThrowsNumber || 0) + (Event.eventBlockedPlayerAccurateThrowsNumber || 0)})
    `.trim();
    }

    if (homeTeamID === Event.eventBlockingPlayerTeamID) {
        HomeTeamData = <>
            {blockEventIcon && <img src={blockEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${blockingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name blocking-player"}>{blockingPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{blockEventName} ({Event.eventBlockingPlayerBlocksNumber})</span>
            {Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}
        </>;
        AwayTeamData = <>
            <NavLink to={`/${PlayersRoute.routeURL}/${blockedPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name blocked-player"}>{blockedPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{blockedPlayerThrowInformation} - {blockedEventName} {!!Event.eventBlockedPlayerBlockedNumber && `(${Event.eventBlockedPlayerBlockedNumber})`}</span>
        </>
    } else if (awayTeamID === Event.eventBlockingPlayerTeamID) {
        AwayTeamData = <>
            {blockEventIcon && <img src={blockEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${blockingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name blocking-player"}>{blockingPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{blockEventName} ({Event.eventBlockingPlayerBlocksNumber})</span>
            {Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}
        </>;
        HomeTeamData = <>
            <NavLink to={`/${PlayersRoute.routeURL}/${blockedPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name blocked-player"}>{blockedPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{blockedPlayerThrowInformation} - {blockedEventName} {!!Event.eventBlockedPlayerBlockedNumber && `(${Event.eventBlockedPlayerBlockedNumber})`}</span>
        </>;
    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--block-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} lg={4} style={{ order: 1 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {HomeTeamData}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={["order-2"]}
                />

                <Col xs={4} lg={4} style={{ order: 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {AwayTeamData}
                    </Row>
                </Col>

            </Row>
        </Col>
    )

};


export default QuarterBlockEventDataComponent;
