import React, { useEffect, useRef } from "react";


import {
    UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import scrollToDomElement from "@Utilities/Scrolling";


import {
    DefaultPageHeaderComponent, PageContainerComponent
} from "@ContentElements";


import PartnersListComponent from "@Pages/Common/PartnersPage/PartnersList";


import "./index.scss";


export interface PartnersPageComponentProps {}


export const PartnersPageComponent: React.FC<PartnersPageComponentProps> = () : JSX.Element | null => {


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef.current) {
            return;
        }
        scrollToDomElement(ComponentRef.current, 250);
    }, []);


    const PARTNERS_PAGE_HEADER_TEXT = UseGlobalsDictionaryPhrasesSet("PARTNERS_PAGE_HEADER_TEXT");


    return (
        <PageContainerComponent id={"partners-page-component"} cssClasses={"partners-page partners-page-component"}>

            <DefaultPageHeaderComponent headerText={PARTNERS_PAGE_HEADER_TEXT} />

            <PartnersListComponent />

        </PageContainerComponent>
    );
};


export default PartnersPageComponent;
