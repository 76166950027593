import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


interface GameTablesTeamHeaderNameComponentProps {
    teamName: string | null;
    teamSlug: string | null;
    teamColor?: string | null;
}


export const GameTablesTeamHeaderNameComponent: React.FC<GameTablesTeamHeaderNameComponentProps> = ({
    teamName, teamSlug, teamColor
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");

    const {
        routeTitle: TEAMS_ROUTE_TITLE, routeURL: TEAMS_ROUTE_URL
    } = TeamsRoute;


    if (!teamName || !teamSlug) return null;


    return (
        <Col xs={9} lg={4} className={"team-header-name"} style={{backgroundColor: teamColor || undefined, borderColor: teamColor || undefined}}>
            <Row className={"team-header-name-content"}>

                <Col xs={12} className={"team-name"}>
                    <NavLink to={`/${TEAMS_ROUTE_URL}/${teamSlug}`} title={TEAMS_ROUTE_TITLE}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <span className={"bg"} style={{borderColor: teamColor || undefined}}></span>

            </Row>
        </Col>
    );

};


export default GameTablesTeamHeaderNameComponent;
