import React from "react";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


import { LeagueGameInterface } from "@Interfaces/Api";


export interface GameArenaInformationComponentProps {
    GameData: LeagueGameInterface;
}


export const GameArenaInformationComponent:React.FC<GameArenaInformationComponentProps> = ({
    GameData
}) => {


    if (!GameData) return null;


    const {
        league_game_arena_data: GameArena
    } = GameData;


    if (!GameArena) return null;


    const {
        league_arena_name: gameArenaName,
        league_arena_village: gameArenaVillage,
        league_arena_address: gameArenaAddress
    } = GameArena;


    let gameArenaDescription: string[] | string = []

    if (gameArenaName) gameArenaDescription.push(gameArenaName);
    if (gameArenaVillage) gameArenaDescription.push(gameArenaVillage);
    if (gameArenaAddress) gameArenaDescription.push(gameArenaAddress);

    gameArenaDescription = gameArenaDescription.join(", ")


    return (
        <Col xs={12} className={"game-result-arena game-result-arena-component"}>
            <Row className={"game-result-arena-content"}>

                <Col xs={12} className={"text-center"}>
                    {/*<span className={"info-label"}>Arena:</span>*/}
                    <span className={"info-value"}>{gameArenaDescription}</span>
                </Col>

            </Row>
        </Col>
    )

};


export default GameArenaInformationComponent;
