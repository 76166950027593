import React from "react";


import {Col, Row} from "react-bootstrap";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import PlayerTeamComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Team";
import PlayerDataComponent from "@Components/Views/Pages/Games/Players/Player/MainData/Player";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerMainDataComponentProps {
    player: LeaguePlayerInterface;
}


export const PlayerMainDataComponent: React.FC<PlayerMainDataComponentProps> = ({
    player
}) : JSX.Element | null => {


    if (!player) return null;


    const {
        player_team: playerTeam
    } = player;


    return (
        <ContainerSectionComponent id={"player-details-main-data-component"}>

            <Col xs={12} className={"player-main-data-component"}>
                <Row className={"main-data-component-content"}>

                    <PlayerTeamComponent Team={playerTeam} />

                    <PlayerDataComponent player={player} />

                </Row>
            </Col>

        </ContainerSectionComponent>
    );

};


export default PlayerMainDataComponent;
