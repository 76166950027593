import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TeamPlayersListTeamNameComponent from "@TeamsPages/Team/Players/PlayersList/TeamName";
import TeamPlayersListTeamSquadComponent from "@TeamsPages/Team/Players/PlayersList/TeamSquad";


import "./index.scss";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


export interface TeamPlayersListComponentProps {
    TeamLeaguesWithSquads?: TeamLeagueInterface[];
    teamCaptainID?: number | null;
    selectedTeamLeagueID: number;
}


export const TeamPlayersListComponent: React.FC<TeamPlayersListComponentProps> = ({
    TeamLeaguesWithSquads, teamCaptainID = null, selectedTeamLeagueID
}) : JSX.Element | null => {


    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");
    const NO_LEAGUE_SQUADS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SQUADS_PHRASE_TEXT");
    const NO_LEAGUE_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_PLAYERS_PHRASE_TEXT");


    if (!TeamLeaguesWithSquads) {
        return null;
    }

    if (!TeamLeaguesWithSquads.length) {
        return <MessageComponent messageText={NO_LEAGUE_SQUADS_PHRASE_TEXT} cssClasses={"padding-15"} />;
    }


    if (!selectedTeamLeagueID) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"padding-15"} />;
    }


    const SelectedLeague = TeamLeaguesWithSquads.filter((League) => {
        const { id: leagueID } = League;
        return leagueID === selectedTeamLeagueID;
    })[0];


    if (!SelectedLeague) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"padding-15"} />;
    }


    const {
        team_league_team_name: teamLeagueTeamName, team_league_team_short_name: teamLeagueTeamShortName,
        team_squad: TeamLeagueTeamSquad
    } = SelectedLeague;


    if (!TeamLeagueTeamSquad?.length) {
        return <MessageComponent messageText={NO_LEAGUE_PLAYERS_PHRASE_TEXT} cssClasses={"padding-15"} />;
    }


    return (
        <Col xs={12} className={"team-details__team-players__list"}>
            <Row className={"team-details__team-players__list__content"}>

                <TeamPlayersListTeamNameComponent
                    teamLeagueTeamName={teamLeagueTeamName}
                    teamLeagueTeamShortName={teamLeagueTeamShortName}
                />

                <TeamPlayersListTeamSquadComponent
                    TeamSquadPlayers={TeamLeagueTeamSquad}
                    teamCaptainID={teamCaptainID}
                />

            </Row>
        </Col>
    );

};


export default TeamPlayersListComponent;
