import ApiGameSummaryQuartersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Quarters";
import ApiGameSummaryQuarterType from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter/Type";


import ThrowsEventsInterface from "@GameResultPageTypes/Summary/GUI/Throws/Teams";


export const createThrowsMapArray = (
    SelectedPlayers: number[] = [],
    SelectedQuarters: ApiGameSummaryQuarterType[],
    GameQuarters: ApiGameSummaryQuartersInterface,
    homeTeamID: number,
    awayTeamID: number
) : ThrowsEventsInterface | null => {


    if (!SelectedQuarters || !GameQuarters) return null;


    if (!homeTeamID || !awayTeamID) return null;


    const GameThrowsObject: ThrowsEventsInterface = {
        homeTeam: {accurate: [], notAccurate: []},
        awayTeam: {accurate: [], notAccurate: []}
    };


    for (let quarterNumber of SelectedQuarters) {

        const QuarterData = GameQuarters[quarterNumber];

        if (!QuarterData) continue;

        const { events_list: QuarterEvents } = QuarterData;

        for (let Event of QuarterEvents) {

            const {
                league_game_event_type: eventType,
                team_id: eventTeamID,
                player_id: eventPlayerID
            } = Event;

            if (
                !eventTeamID || !eventPlayerID ||
                !SelectedPlayers.includes(eventPlayerID)
            ) continue;

            if (!["2PT", "2PTA", "3PT", "3PTA"].includes(eventType)) continue;

            if (eventTeamID === homeTeamID) {

                if (eventType === "2PT" || eventType === "3PT") {
                    GameThrowsObject.homeTeam.accurate.push(Event);
                } else if (eventType === "2PTA" || eventType === "3PTA") {
                    GameThrowsObject.homeTeam.notAccurate.push(Event);
                }

            } else if (eventTeamID === awayTeamID) {

                if (eventType === "2PT" || eventType === "3PT") {
                    GameThrowsObject.awayTeam.accurate.push(Event);
                } else if (eventType === "2PTA" || eventType === "3PTA") {
                    GameThrowsObject.awayTeam.notAccurate.push(Event);
                }

            }

        }

    }


    return GameThrowsObject;


};


export default createThrowsMapArray;
