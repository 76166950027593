import {
    UseLanguage
} from "@States";

import MainStatisticsTypeType from "@GlobalData/Statistics/MainTypes/Type";
import {
    MainStatisticsTypeInterface
} from "@GlobalData/Statistics/MainTypes/Types";

import MainStatisticsTypes from "@GlobalData/Statistics/MainTypes";


export const UseMainStatisticsType = (
    mainStatisticType: MainStatisticsTypeType
) : MainStatisticsTypeInterface | null => {

    const activeLanguage = UseLanguage();

    if (!mainStatisticType) {
        return null;
    }

    const MainStatistic = MainStatisticsTypes[mainStatisticType];

    return MainStatistic[activeLanguage];

};


export default UseMainStatisticsType;
