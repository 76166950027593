import React, { useEffect, useRef } from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    DefaultPageHeaderComponent,
    PageContainerComponent,
    PageContentComponent
} from "@ContentElements";


import scrollToDomElement from "@Utilities/Scrolling";


import TransfersListComponent from "@GamesPages/Transfers/List";


export interface TransfersListPageComponentProps {}


export const TransfersListPageComponent: React.FC<TransfersListPageComponentProps> = ({}) : JSX.Element | null => {


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, 250);
    }, []);


    const TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT");


    return (
        <PageContainerComponent id={"transfers-list-page"} forwardRef={ComponentRef}>

            <DefaultPageHeaderComponent headerText={TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT} />

            <PageContentComponent id={"transfers-list"} cssClasses={"transfers-list"}>

                <TransfersListComponent />

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default TransfersListPageComponent;
