import React from "react";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TeamLeaguesListLeagueNameComponentProps {
    leagueName: string;
    leagueShortName?: string;
}

export const TeamLeaguesListLeagueNameComponent: React.FC<TeamLeaguesListLeagueNameComponentProps> = ({
    leagueName, leagueShortName
}) : JSX.Element | null => {


    return (
        <Col xs={"auto"} className={"player-profile__captain-zone__team__leagues__list__league__information__league-name"}>
            <span className={"player-profile__captain-zone__team__leagues__list__league__information__league-name__name"}>{leagueName}</span>
            {leagueShortName && <span className={"player-profile__captain-zone__team__leagues__list__league__information__league-name__short-name"}>({leagueShortName})</span>}
        </Col>
    );
}


export default TeamLeaguesListLeagueNameComponent;
