import { UseLanguage } from "@States";


import GameProgressEvents from "@GameResultPageSummary/GameDetailsInformation/Progress/_Constants/ProgressEvents";

import { GameProgressEventType } from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event";


export interface GameProgressEventTypeInterface {
    eventIcon: string | null;
    eventName: {
        singular: string;
        plural: string;
        plural_to_4: string;
        label: string;
    }
}

export const UseProgressEvent = (eventType: GameProgressEventType) : GameProgressEventTypeInterface | undefined => {

    const activeLanguage = UseLanguage();

    const Event = GameProgressEvents[eventType];

    if (Event) {

        return {
            eventIcon: Event.eventIcon,
            eventName: Event.eventName[activeLanguage],
        };

    }

    return;

};


export default UseProgressEvent;
