import React from "react";


import { UseLanguage } from "@States";


import _ from "lodash";


import NumberFormat from "react-number-format";


import GameInformationSummaryTableComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable";
import GameSummaryTableDataComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable/Table";
import GameSummaryTableRowComponent from "@GameResultPageSummary/GameDetailsInformation/_Parts/SummaryTable/Table/Row";


import {
    countEval, countPercentageThrowsEffectivity, countTotalPoints
} from "@Services/Utilities/Games";


import { LeagueGameEventInterface } from "@Interfaces/Api";

import ApiGameSummaryInterface from "@GameResultPageTypes/Summary/API/GameSummary";
import ApiGameSummaryQuarterType from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryEventType from "@GameResultPageTypes/Summary/API/GameSummary/Events/Event";
import {formatMinutesFromSeconds} from "@Services/Utilities/Tools/Formatters";


const QUARTER_DURATION_IN_MINUTES = 9;


const TEXTS: any = {
    PL: {
        arrange_section_title: "Zestawienie",
        team_points_text: "Punkty",
        team_counter_points_text: "Punkty z kontry",
        team_coffin_points_text: "Punkty z trumny",
        team_coffin_points_effectivity_text: "Skuteczność z trumny",
        team_first5_points_text: "Punkty pierwszej piątki",
        team_bench_points_text: "Punkty ławki",
        team_second_chance_points_text: "Punkty drugiej szansy",
        team_steals_points_text: "Punkty po przechwycie",
        team_evaluation_text: "Evaluation",
        team_greatest_advantage_text: "Największa przewaga",
        team_greatest_run_text: "Największa ucieczka",
        team_maximum_no_points_time_text: "Maksymalny czas bez kosza z gry",
        team_outside_throws_text: "Seria rzutów niecelnych",
        result_advantage_changes_text: "Zmiany prowadzenia",
        result_draws_text: "Remisy",
    },
    EN: {
        arrange_section_title: "Arrange",
        team_points_text: "Points",
        team_counter_points_text: "Counterattacks points",
        team_coffin_points_text: "Points from coffin",
        team_coffin_points_effectivity_text: "Effectivity from coffin",
        team_first5_points_text: "First 5 players points",
        team_bench_points_text: "Bench players points",
        team_second_chance_points_text: "Second chance points",
        team_steals_points_text: "Points after steal",
        team_evaluation_text: "Evaluation",
        team_greatest_advantage_text: "Greatest advantage",
        team_greatest_run_text: "Greatest run",
        team_maximum_no_points_time_text: "Maximum time without game points",
        team_outside_throws_text: "Outside throws",
        result_advantage_changes_text: "Advantages changes",
        result_draws_text: "Draws",
    }
};


interface GameInformationArrangeComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
}


export const GameInformationArrangeComponent: React.FC<GameInformationArrangeComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics
}) : JSX.Element | null => {

    const activeLanguage = UseLanguage();

    const {
        arrange_section_title: ARRANGE_SECTION_TITLE,
        team_points_text: TEAM_POINTS_TEXT,
        team_counter_points_text: TEAM_COUNTER_POINTS_TEXT,
        team_coffin_points_text: TEAM_COFFIN_POINTS_TEXT,
        team_coffin_points_effectivity_text: TEAM_COFFIN_POINTS_EFFECTIVITY_TEXT,
        team_first5_points_text: TEAM_FIRST5_POINTS_TEXT,
        team_bench_points_text: TEAM_BENCH_POINTS_TEXT,
        team_second_chance_points_text: TEAM_SECOND_CHANCE_POINTS_TEXT,
        team_steals_points_text: TEAM_STEALS_POINTS_TEXT,
        team_evaluation_text: TEAM_EVALUATION_TEXT,
        team_greatest_advantage_text: TEAM_GREATEST_ADVANTAGE_TEXT,
        team_greatest_run_text: TEAM_GREATEST_RUN_TEXT,
        team_maximum_no_points_time_text: TEAM_MAXIMUM_NO_POINTS_TIME_TEXT,
        team_outside_throws_text: TEAM_OUTSIDE_THROWS_TEXT,
        result_advantage_changes_text: RESULT_ADVANTAGE_CHANGES_TEXT,
        result_draws_text: RESULT_DRAWS_TEXT,
    } = TEXTS[activeLanguage];


    if (!SelectedQuarters || !GameSummaryStatistics) return null;


    const { squads: GameSquads } = GameSummaryStatistics;

    const HomeTeam = GameSquads[homeTeamID];
    const AwayTeam = GameSquads[awayTeamID];


    let homeTeam1PTThrows = 0;
    let homeTeam1PTAThrows = 0;
    let awayTeam1PTThrows = 0;
    let awayTeam1PTAThrows = 0;
    let homeTeam2PTThrows = 0;
    let homeTeam2PTAThrows = 0;
    let awayTeam2PTThrows = 0;
    let awayTeam2PTAThrows = 0;
    let homeTeam3PTThrows = 0;
    let homeTeam3PTAThrows = 0;
    let awayTeam3PTThrows = 0;
    let awayTeam3PTAThrows = 0;

    let homeTeamPoints = 0;
    let awayTeamPoints = 0;

    let homeTeamCounterPoints = 0;
    let awayTeamCounterPoints = 0;

    let homeTeamCoffinAccurateThrows = 0;
    let homeTeamCoffinNotAccurateThrows = 0;
    let awayTeamCoffinAccurateThrows = 0;
    let awayTeamCoffinNotAccurateThrows = 0;

    let homeTeamFirst5Points = 0;
    let awayTeamFirst5Points = 0;

    let homeTeamBenchPoints = 0;
    let awayTeamBenchPoints = 0;

    let homeTeamSecondChancePoints = 0;
    let awayTeamSecondChancePoints = 0;

    let homeTeamStealsPoints = 0;
    let awayTeamStealsPoints = 0;

    let homeTeamRebounds = 0
    let awayTeamRebounds = 0

    let homeTeamAssists = 0
    let awayTeamAssists = 0
    let homeTeamBlocks = 0
    let awayTeamBlocks = 0
    let homeTeamSteals = 0
    let awayTeamSteals = 0
    let homeTeamTurnovers = 0
    let awayTeamTurnovers = 0

    let homeTeamGreatestAdvantage: { Q: ApiGameSummaryQuarterType, V: number } | null = null;
    let awayTeamGreatestAdvantage: { Q: ApiGameSummaryQuarterType, V: number } | null = null;

    let homeTeamGreatestRunHomeTeamPoints = 0;
    let homeTeamGreatestRunAwayTeamPoints = 0;
    let homeTeamGreatestRun: { Q: ApiGameSummaryQuarterType, V: number, R: string } | null = null;
    let awayTeamGreatestRunAwayTeamPoints = 0;
    let awayTeamGreatestRunHomeTeamPoints = 0;
    let awayTeamGreatestRun: { Q: ApiGameSummaryQuarterType, V: number, R: string } | null = null;

    let homeTeamLastAccurateGameThrowTime = 0;
    let homeTeamGreatestTimeWithoutPoints: { Q: ApiGameSummaryQuarterType, V: number } | null = null;
    let awayTeamLastAccurateGameThrowTime = 0;
    let awayTeamGreatestTimeWithoutPoints: { Q: ApiGameSummaryQuarterType, V: number } | null = null;

    let homeTeamNotAccurateThrowsSeries = 0;
    let homeTeamGreatestNotAccurateThrowsSeries: { Q: ApiGameSummaryQuarterType, V: number } | null = null;
    let awayTeamNotAccurateThrowsSeries = 0;
    let awayTeamGreatestNotAccurateThrowsSeries: { Q: ApiGameSummaryQuarterType, V: number } | null = null;

    let drawsNumber: number = 0;
    let advantageTeam: "HOME" | "AWAY" | "DRAW" = "DRAW";
    let advantageChangesNumber: number = 0;


    let PreviousEvent: LeagueGameEventInterface | null = null;


    type PreviousEventInfoType = [number, ApiGameSummaryEventType];

    for (let quarterNumber of SelectedQuarters) {

        if (!GameSummaryStatistics?.quarters[quarterNumber]) continue;

        const {
            events_list: QuarterEventsList
        } = GameSummaryStatistics.quarters[quarterNumber];

        const PreviousEvents: PreviousEventInfoType[] = [];

        const Events = _.sortBy(QuarterEventsList, ["league_game_event_idx"]);

        for (let Event of Events) {

            const {
                league_game_event_type: eventType,
                league_game_event_description: eventDescription,
                league_game_event_time: eventTime,
                team_id: eventTeamID,
                player_id: eventPlayerID
            } = Event;

            /**
             * POINTS
             */

            let previousEventType: ApiGameSummaryEventType | null = null;

            if (PreviousEvent) {
                const {league_game_event_type: eventType} = PreviousEvent;
                previousEventType = eventType;
            }

            let eventPlayerFirst5: boolean | null = null;

            if (eventTeamID && eventPlayerID) {
                if (GameSquads[eventTeamID]) {
                    const {team_players: eventTeamPlayers} = GameSquads[eventTeamID];
                    if (eventTeamPlayers[eventPlayerID]) {
                        const {player_first_squad: first5} = eventTeamPlayers[eventPlayerID];
                        eventPlayerFirst5 = first5;
                    }
                }
            }

            let parsedEventTime = 0;

            if (eventTime) {
                const eventMinute = +eventTime?.split(":")[0];
                const eventSecond = +eventTime?.split(":")[1];
                const previousQuartersParsedTime = quarterNumber > 1 ? (quarterNumber - 1) * QUARTER_DURATION_IN_MINUTES * 60 : 0;
                parsedEventTime = previousQuartersParsedTime + eventMinute * 60 + eventSecond;
            }

            if (["1PT", "1PTA", "2PT", "2PTA", "3PT", "3PTA"].includes(eventType)) {

                let i = 0;

                switch (eventType) {
                    case "1PT":

                        if (eventTeamID === homeTeamID) {

                            homeTeam1PTThrows += 1;

                            if (eventPlayerFirst5 === true) homeTeamFirst5Points += 1;
                            if (eventPlayerFirst5 === false) homeTeamBenchPoints += 1;
                            if (eventDescription.counter) homeTeamCounterPoints += 1;

                            // if (homeTeamGreatestRunAwayTeamPoints <= 2) {
                            //     homeTeamGreatestRunHomeTeamPoints += 1;
                            //     if (!homeTeamGreatestRun || homeTeamGreatestRunHomeTeamPoints - homeTeamGreatestRunAwayTeamPoints > homeTeamGreatestRun.V) {
                            //         homeTeamGreatestRun = { Q: quarterNumber, V: homeTeamGreatestRunHomeTeamPoints - homeTeamGreatestRunAwayTeamPoints,  R: `${homeTeamGreatestRunHomeTeamPoints}:${homeTeamGreatestRunAwayTeamPoints}` }
                            //     }
                            // } else {
                            //     homeTeamGreatestRunAwayTeamPoints = 0;
                            // }

                            if (previousEventType === "PLAYER_O_REB") {
                                homeTeamSecondChancePoints += 1;
                            }

                        }

                        if (eventTeamID === awayTeamID) {

                            awayTeam1PTThrows += 1;

                            if (eventPlayerFirst5 === true) awayTeamFirst5Points += 1;
                            if (eventPlayerFirst5 === false) awayTeamBenchPoints += 1;
                            if (eventDescription.counter) awayTeamCounterPoints += 1;

                            if (previousEventType === "PLAYER_O_REB") {
                                awayTeamSecondChancePoints += 1;
                            }

                            // if (awayTeamGreatestRunHomeTeamPoints <= 2) {
                            //     awayTeamGreatestRunAwayTeamPoints += 1;
                            //     if (!awayTeamGreatestRun || awayTeamGreatestRunAwayTeamPoints - awayTeamGreatestRunHomeTeamPoints > awayTeamGreatestRun.V) {
                            //         awayTeamGreatestRun = { Q: quarterNumber, V: awayTeamGreatestRunAwayTeamPoints - homeTeamGreatestRunAwayTeamPoints,  R: `${awayTeamGreatestRunAwayTeamPoints}:${awayTeamGreatestRunHomeTeamPoints}` }
                            //     }
                            // } else {
                            //     awayTeamGreatestRunHomeTeamPoints = 0;
                            // }

                        }

                        i = PreviousEvents.length;
                        if (i >= 0) {
                            while (true) {
                                const previousEventInfo = PreviousEvents[--i];
                                if (
                                    !previousEventInfo || eventTeamID !== previousEventInfo[0]
                                ) break;
                                if (previousEventInfo[1] === "STL") {
                                    if (eventTeamID === homeTeamID) homeTeamStealsPoints += 1;
                                    if (eventTeamID === awayTeamID) awayTeamStealsPoints += 1;
                                }
                            }
                        }

                        break;

                    case "1PTA":

                        if (eventTeamID === homeTeamID) {
                            homeTeam1PTAThrows += 1;
                        }

                        if (eventTeamID === awayTeamID) {
                            awayTeam1PTAThrows += 1;
                        }

                        break;

                    case "2PT":

                        if (eventTeamID === homeTeamID) {

                            homeTeam2PTThrows += 1;
                            if (eventPlayerFirst5 === true) homeTeamFirst5Points += 2;
                            if (eventPlayerFirst5 === false) homeTeamBenchPoints += 2;
                            if (eventDescription.counter) homeTeamCounterPoints += 2;
                            if (eventDescription.coffin) homeTeamCoffinAccurateThrows += 1;

                            const homeTeamTimeFromLastAccurateGameThrowTime = parsedEventTime - homeTeamLastAccurateGameThrowTime;
                            homeTeamLastAccurateGameThrowTime = parsedEventTime;

                            if (!homeTeamGreatestTimeWithoutPoints || homeTeamTimeFromLastAccurateGameThrowTime > homeTeamGreatestTimeWithoutPoints.V) {
                                homeTeamGreatestTimeWithoutPoints = { Q: quarterNumber, V: homeTeamTimeFromLastAccurateGameThrowTime };
                            }

                            homeTeamNotAccurateThrowsSeries = 0;

                            if (previousEventType === "PLAYER_O_REB") {
                                homeTeamSecondChancePoints += 2;
                            }

                        }

                        if (eventTeamID === awayTeamID) {

                            awayTeam2PTThrows += 1;

                            if (eventPlayerFirst5 === true) awayTeamFirst5Points += 2;
                            if (eventPlayerFirst5 === false) awayTeamBenchPoints += 2;
                            if (eventDescription.counter) awayTeamCounterPoints += 2;
                            if (eventDescription.coffin) awayTeamCoffinAccurateThrows += 1;

                            const awayTeamTimeFromLastAccurateGameThrowTime = parsedEventTime - awayTeamLastAccurateGameThrowTime;
                            awayTeamLastAccurateGameThrowTime = parsedEventTime;

                            if (!awayTeamGreatestTimeWithoutPoints || awayTeamTimeFromLastAccurateGameThrowTime > awayTeamGreatestTimeWithoutPoints.V) {
                                awayTeamGreatestTimeWithoutPoints = { Q: quarterNumber, V: awayTeamTimeFromLastAccurateGameThrowTime };
                            }

                            awayTeamNotAccurateThrowsSeries = 0;

                            if (previousEventType === "PLAYER_O_REB") {
                                awayTeamSecondChancePoints += 2;
                            }
                        }

                        i = PreviousEvents.length;
                        if (i >= 0) {
                            while (true) {
                                const previousEventInfo = PreviousEvents[--i];
                                if (
                                    !previousEventInfo || eventTeamID !== previousEventInfo[0]
                                ) break;
                                if (previousEventInfo[1] === "STL") {
                                    if (eventTeamID === homeTeamID) homeTeamStealsPoints += 2;
                                    if (eventTeamID === awayTeamID) awayTeamStealsPoints += 2;
                                }
                            }
                        }

                        break;

                    case "2PTA":

                        if (eventTeamID === homeTeamID) {

                            homeTeam2PTAThrows += 1;

                            homeTeamNotAccurateThrowsSeries += 1;

                            if (!homeTeamGreatestNotAccurateThrowsSeries || homeTeamNotAccurateThrowsSeries > homeTeamGreatestNotAccurateThrowsSeries.V) {
                                homeTeamGreatestNotAccurateThrowsSeries = { Q: quarterNumber, V: homeTeamNotAccurateThrowsSeries };
                            }

                            if (eventDescription.coffin) homeTeamCoffinNotAccurateThrows += 1;

                        }

                        if (eventTeamID === awayTeamID) {

                            awayTeam2PTAThrows += 1;

                            awayTeamNotAccurateThrowsSeries += 1;

                            if (!awayTeamGreatestNotAccurateThrowsSeries || awayTeamNotAccurateThrowsSeries > awayTeamGreatestNotAccurateThrowsSeries.V) {
                                awayTeamGreatestNotAccurateThrowsSeries = { Q: quarterNumber, V: awayTeamNotAccurateThrowsSeries };
                            }

                            if (eventDescription.coffin) awayTeamCoffinNotAccurateThrows += 1;

                        }

                        break;
                    case "3PT":

                        if (eventTeamID === homeTeamID) {

                            homeTeam3PTThrows += 1;

                            if (eventPlayerFirst5 === true) homeTeamFirst5Points += 3;
                            if (eventPlayerFirst5 === false) homeTeamBenchPoints += 3;
                            if (eventDescription.counter) homeTeamCounterPoints += 3;

                            const homeTeamTimeFromLastAccurateGameThrowTime = parsedEventTime - homeTeamLastAccurateGameThrowTime;
                            homeTeamLastAccurateGameThrowTime = parsedEventTime;

                            if (!homeTeamGreatestTimeWithoutPoints || homeTeamTimeFromLastAccurateGameThrowTime > homeTeamGreatestTimeWithoutPoints.V) {
                                homeTeamGreatestTimeWithoutPoints = { Q: quarterNumber, V: homeTeamTimeFromLastAccurateGameThrowTime };
                            }

                            homeTeamNotAccurateThrowsSeries = 0;

                            if (previousEventType === "PLAYER_O_REB") {
                                homeTeamSecondChancePoints += 3;
                            }

                        }

                        if (eventTeamID === awayTeamID) {

                            awayTeam3PTThrows += 1;

                            if (eventPlayerFirst5 === true) awayTeamFirst5Points += 3;
                            if (eventPlayerFirst5 === false) awayTeamBenchPoints += 3;
                            if (eventDescription.counter) awayTeamCounterPoints += 3;

                            const awayTeamTimeFromLastAccurateGameThrowTime = parsedEventTime - awayTeamLastAccurateGameThrowTime;
                            awayTeamLastAccurateGameThrowTime = parsedEventTime;

                            if (!awayTeamGreatestTimeWithoutPoints || awayTeamTimeFromLastAccurateGameThrowTime > awayTeamGreatestTimeWithoutPoints.V) {
                                awayTeamGreatestTimeWithoutPoints = { Q: quarterNumber, V: awayTeamTimeFromLastAccurateGameThrowTime };
                            }

                            awayTeamNotAccurateThrowsSeries = 0;

                            if (previousEventType === "PLAYER_O_REB") {
                                awayTeamSecondChancePoints += 3;
                            }

                        }

                        i = PreviousEvents.length;

                        if (i >= 0) {
                            while (true) {
                                const previousEventInfo = PreviousEvents[--i];
                                if (
                                    !previousEventInfo || eventTeamID !== previousEventInfo[0]
                                ) break;

                                if (previousEventInfo[1] === "STL") {
                                    if (eventTeamID === homeTeamID) homeTeamStealsPoints += 3;
                                    if (eventTeamID === awayTeamID) awayTeamStealsPoints += 3;
                                }
                            }
                        }

                        break;

                    case "3PTA":

                        if (eventTeamID === homeTeamID) {

                            homeTeam3PTAThrows += 1;

                            homeTeamNotAccurateThrowsSeries += 1;

                            if (!homeTeamGreatestNotAccurateThrowsSeries || homeTeamNotAccurateThrowsSeries > homeTeamGreatestNotAccurateThrowsSeries.V) {
                                homeTeamGreatestNotAccurateThrowsSeries = { Q: quarterNumber, V: homeTeamNotAccurateThrowsSeries };
                            }

                        }

                        if (eventTeamID === awayTeamID) {

                            awayTeam3PTAThrows += 1;

                            awayTeamNotAccurateThrowsSeries += 1;

                            if (!awayTeamGreatestNotAccurateThrowsSeries || awayTeamNotAccurateThrowsSeries > awayTeamGreatestNotAccurateThrowsSeries.V) {
                                awayTeamGreatestNotAccurateThrowsSeries = { Q: quarterNumber, V: awayTeamNotAccurateThrowsSeries };
                            }

                        }

                        break;

                }

                if (eventTeamID === homeTeamID) homeTeamPoints = countTotalPoints(homeTeam1PTThrows, homeTeam2PTThrows, homeTeam3PTThrows);
                if (eventTeamID === awayTeamID) awayTeamPoints = countTotalPoints(awayTeam1PTThrows, awayTeam2PTThrows, awayTeam3PTThrows);

                if (homeTeamPoints > awayTeamPoints) {
                    if (!homeTeamGreatestAdvantage || homeTeamPoints - awayTeamPoints > homeTeamGreatestAdvantage.V) {
                        homeTeamGreatestAdvantage = {Q: quarterNumber, V: homeTeamPoints - awayTeamPoints}
                    }
                    if (advantageTeam !== "HOME") {
                        advantageChangesNumber += 1;
                    }
                    advantageTeam = "HOME";
                } else if (awayTeamPoints > homeTeamPoints) {
                    if (!awayTeamGreatestAdvantage || awayTeamPoints - homeTeamPoints > awayTeamGreatestAdvantage.V) {
                        awayTeamGreatestAdvantage = {Q: quarterNumber, V: awayTeamPoints - homeTeamPoints}
                    }
                    if (advantageTeam !== "AWAY") {
                        advantageChangesNumber += 1;
                    }
                    advantageTeam = "AWAY";
                } else {
                    if (
                        advantageTeam !== "DRAW" &&
                        (awayTeamPoints > 0 || homeTeamPoints > 0)
                    ) {
                        drawsNumber += 1;
                        advantageTeam = "DRAW";
                    }
                }

            } else {

                switch (eventType) {

                    case "PLAYER_O_REB":
                        if (eventTeamID === homeTeamID) homeTeamRebounds += 1;
                        if (eventTeamID === awayTeamID) awayTeamRebounds += 1;
                        break;

                    case "PLAYER_D_REB":
                        if (eventTeamID === homeTeamID) homeTeamRebounds += 1;
                        if (eventTeamID === awayTeamID) awayTeamRebounds += 1;
                        break;

                    // case "TEAM_O_REB":
                    //     if (eventTeamID === homeTeamID) homeTeamRebounds += 1;
                    //     if (eventTeamID === awayTeamID) awayTeamRebounds += 1;
                    //     break;
                    //
                    // case "TEAM_D_REB":
                    //     if (eventTeamID === homeTeamID) homeTeamRebounds += 1;
                    //     if (eventTeamID === awayTeamID) awayTeamRebounds += 1;
                    //     break;
                    //
                    // case "TEAM_REB":
                    //     if (eventTeamID === homeTeamID) homeTeamRebounds += 1;
                    //     if (eventTeamID === awayTeamID) awayTeamRebounds += 1;
                    //     break;

                    case "AST":
                        if (eventTeamID === homeTeamID) homeTeamAssists += 1;
                        if (eventTeamID === awayTeamID) awayTeamAssists += 1;
                        break;

                    case "BLK":
                        if (eventTeamID === homeTeamID) homeTeamBlocks += 1;
                        if (eventTeamID === awayTeamID) awayTeamBlocks += 1;
                        break;

                    case "STL":
                        if (eventTeamID === homeTeamID) homeTeamSteals += 1;
                        if (eventTeamID === awayTeamID) awayTeamSteals += 1;
                        break;

                    case "TO":
                        if (eventTeamID === homeTeamID) homeTeamTurnovers += 1;
                        if (eventTeamID === awayTeamID) awayTeamTurnovers += 1;
                        break;

                    // case "TTO":
                    //     if (eventTeamID === homeTeamID) homeTeamTurnovers += 1;
                    //     if (eventTeamID === awayTeamID) awayTeamTurnovers += 1;
                    //     break;

                }

            }

            if (
                eventType === "AST" ||
                eventType === "TO" || eventType === "TTO" ||
                eventType === "SUB_IN" || eventType === "SUB_OUT"
            ) continue;

            PreviousEvent = Object.assign({}, Event);

            if (eventTeamID && eventType) {
                PreviousEvents.push([eventTeamID, eventType]);
            }

        }

    }


    const homeTeamCoffinThrows = homeTeamCoffinAccurateThrows + homeTeamCoffinNotAccurateThrows;
    const awayTeamCoffinThrows = awayTeamCoffinAccurateThrows + awayTeamCoffinNotAccurateThrows;


    const homeTeamEval = countEval(
        homeTeamPoints, homeTeamRebounds, homeTeamAssists, homeTeamSteals, homeTeamBlocks, homeTeamTurnovers,
        homeTeam2PTAThrows + homeTeam2PTThrows,
        homeTeam3PTAThrows + homeTeam3PTThrows,
        homeTeam2PTThrows,
        homeTeam3PTThrows,
        homeTeam1PTAThrows + homeTeam1PTThrows,
        homeTeam1PTThrows
    );

    const awayTeamEval = countEval(
        awayTeamPoints, awayTeamRebounds, awayTeamAssists, awayTeamSteals, awayTeamBlocks, awayTeamTurnovers,
        awayTeam2PTAThrows + awayTeam2PTThrows,
        awayTeam3PTAThrows + awayTeam3PTThrows,
        awayTeam2PTThrows,
        awayTeam3PTThrows,
        awayTeam1PTAThrows + awayTeam1PTThrows,
        awayTeam1PTThrows
    );


    return (
        <GameInformationSummaryTableComponent
            gameHomeTeam={HomeTeam}
            gameAwayTeam={AwayTeam}
        >

            <GameSummaryTableDataComponent
                tableTitle={ARRANGE_SECTION_TITLE}
            >

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamPoints}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamPoints}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_COUNTER_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamCounterPoints}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamCounterPoints}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_COFFIN_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamCoffinAccurateThrows * 2}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamCoffinAccurateThrows * 2}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_COFFIN_POINTS_EFFECTIVITY_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamCoffinAccurateThrows}/${homeTeamCoffinThrows}`,
                        secondaryValue: <><NumberFormat
                            value={countPercentageThrowsEffectivity(homeTeamCoffinAccurateThrows, homeTeamCoffinThrows)}
                            decimalScale={1} fixedDecimalScale={true} displayType={"text"}
                            decimalSeparator={"."}/>%</>,
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamCoffinAccurateThrows}/${awayTeamCoffinThrows}`,
                        secondaryValue: <><NumberFormat
                            value={countPercentageThrowsEffectivity(awayTeamCoffinAccurateThrows, awayTeamCoffinThrows)}
                            decimalScale={1} fixedDecimalScale={true} displayType={"text"}
                            decimalSeparator={"."}/>%</>,
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_FIRST5_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamFirst5Points}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamFirst5Points}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_BENCH_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamBenchPoints}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamBenchPoints}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_SECOND_CHANCE_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamSecondChancePoints}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamSecondChancePoints}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_STEALS_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamStealsPoints}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamStealsPoints}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_EVALUATION_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamEval}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamEval}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_GREATEST_ADVANTAGE_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamGreatestAdvantage?.V || "-",
                        secondaryValue: homeTeamGreatestAdvantage?.Q ? `(${homeTeamGreatestAdvantage?.Q}Q)` : ""
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamGreatestAdvantage?.V || "-",
                        secondaryValue: awayTeamGreatestAdvantage?.Q ? `(${awayTeamGreatestAdvantage?.Q}Q)` : ""
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_GREATEST_RUN_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: "-"
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: "-"
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_MAXIMUM_NO_POINTS_TIME_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamGreatestTimeWithoutPoints?.V ? formatMinutesFromSeconds(homeTeamGreatestTimeWithoutPoints?.V) : "-",
                        secondaryValue: homeTeamGreatestTimeWithoutPoints?.Q ? `(${homeTeamGreatestTimeWithoutPoints?.Q}Q)` : ""
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamGreatestTimeWithoutPoints?.V  ? formatMinutesFromSeconds(awayTeamGreatestTimeWithoutPoints?.V) : "-" ,
                        secondaryValue: awayTeamGreatestTimeWithoutPoints?.Q ? `(${awayTeamGreatestTimeWithoutPoints?.Q}Q)` : ""
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_OUTSIDE_THROWS_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: homeTeamGreatestNotAccurateThrowsSeries?.V ? `0/${homeTeamGreatestNotAccurateThrowsSeries?.V}` : "-",
                        secondaryValue: homeTeamGreatestNotAccurateThrowsSeries?.Q ? `(${homeTeamGreatestNotAccurateThrowsSeries?.Q}Q)` : ""
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: awayTeamGreatestNotAccurateThrowsSeries?.V ? `0/${awayTeamGreatestNotAccurateThrowsSeries?.V}` : "-",
                        secondaryValue: awayTeamGreatestNotAccurateThrowsSeries?.Q ? `(${awayTeamGreatestNotAccurateThrowsSeries?.Q}Q)` : ""
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={`${RESULT_ADVANTAGE_CHANGES_TEXT}: <strong>${advantageChangesNumber}</strong>`}
                    summaryStatisticHomeTeamData={{primaryValue: null}}
                    summaryStatisticAwayTeamData={{primaryValue: null}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={`${RESULT_DRAWS_TEXT}: <strong> ${drawsNumber}</strong>`}
                    summaryStatisticHomeTeamData={{primaryValue: null}}
                    summaryStatisticAwayTeamData={{primaryValue: null}}
                />

            </GameSummaryTableDataComponent>

        </GameInformationSummaryTableComponent>
    );



};


export default GameInformationArrangeComponent;
