import React from "react";


import {
    UseCommonDictionaryPhrasesSet,
    UseErrorMessage
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";
import SingleContentLoaderComponent from "@LoadersElements/SingleContent";


import LeagueTablesGroupComponent from "@LeaguesPages/Tables/Leagues/Tables/Group";


import {
    UseLeagueTables
} from "@Hooks/Api/Requests";


import "./index.scss";


export interface LeagueTablesComponentProps {
    selectedLeagueID: number | null;
}


export const LeagueTablesComponent: React.FC<LeagueTablesComponentProps> = ({
    selectedLeagueID
}) : JSX.Element | null => {


    const REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");


    const [LeagueTables, LeagueTablesError] = UseLeagueTables(selectedLeagueID);


    if (!selectedLeagueID) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} cssClasses={"padding-10"} />;
    }

    if (LeagueTablesError) {
        return <MessageComponent xs={12} lg={8} messageText={REQUEST_ERROR_MESSAGE} messageType={"error"} cssClasses={"align-content-top padding-10"} />;
    }

    if (!LeagueTables) {
        return <SingleContentLoaderComponent xs={12} lg={8} cssClasses={"no-margin-top start"} />;
    }


    const {
        league_groups: LeagueGroups
    } = LeagueTables;


    const LeagueGroupsList = LeagueGroups.map((Group, i) =>
        <LeagueTablesGroupComponent key={i} Group={Group} />
    );

    return (
        <Col xs={12} lg={8} className={"league-tables"}>
            <Row className={"league-tables__content"}>

                {LeagueGroupsList}

            </Row>
        </Col>
    );

};


export default LeagueTablesComponent;
