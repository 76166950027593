import React from "react";


import { UseLoggedUser } from "@States";


import { UseRoute } from "@Hooks/Routes";


import { Redirect } from "react-router-dom";


import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import { UseAuthenticatedUserDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import PlayerUserProfilePageComponent from "@Pages/User/Authenticated/Profile/Player";


import "./index.scss";


export interface UserProfilePageComponentProps {}


export const UserProfilePageComponent: React.FC<UserProfilePageComponentProps> = () : JSX.Element | null => {


    const User = UseLoggedUser();


    const Route = UseRoute("LOGIN");

    const { routeURL: LOGIN_URL } = Route;


    const USER_ZONE_LABEL = UseAuthenticatedUserDictionaryPhrasesSet("USER_ZONE_LABEL");


    if (!User) return <Redirect to={`/${LOGIN_URL}`} />;


    const {
        userID, userData, userType, userToken
    } = User;


    if (!userID || !userData || !userType) return <Redirect to={"/"} />;


    return (
        <PageContainerComponent  id={"user-profile-page"} cssClasses={"user-profile-page user-profile-page-component"}>

            <DefaultPageHeaderComponent headerText={USER_ZONE_LABEL} />

            <PageContentComponent>

                {userType.typeID === 1 &&
                <PlayerUserProfilePageComponent
                    playerID={userID}
                    playerToken={userToken}
                />
                }

            </PageContentComponent>

        </PageContainerComponent>
    )

};


export default UserProfilePageComponent;
