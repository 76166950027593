import React, {useEffect, useRef, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Container} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import DefaultMessageComponent from "@Components/Elements/Messages/Message/_Default";

import DefaultPageHeaderComponent from "@Components/Elements/Content/Page/Header/Default";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";
import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import LeaguesTablesLeaguesComponent from "@Components/Views/Pages/Games/Leagues/Tables/Leagues";


import "./index.scss";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";
import scrollToDomElement from "@Utilities/Scrolling";
import LeagueGroupInterface from "@Interfaces/Api/Leagues/Leagues/Group";
import {UseLeaguesList} from "@Services/Utilities/ApiHooks";
import MessageComponent from "@MessagesElements/Content";
import {UseErrorMessage, UseLeaguesDictionaryPhrasesSet} from "@Hooks/Texts";
import MappedLeaguesBySeasonsInterface from "@LeaguesPages/_Types/Leagues/BySeasons";
import _mapLeaguesBySeasons from "@LeaguesPages/_Utilities/MapLeaguesBySeasons";
import {PageContainerComponent, PageContentComponent} from "@ContentElements";
import LeaguesTablesContentComponent from "@LeaguesPages/Tables/Content";


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
    leagueActiveStatus: boolean;
    leagueOrderNr?: number;
    leagueGroups?: LeagueGroupInterface[] | null;
}

interface LeaguesSeasonInterface {
    [key: number]: {
        seasonID: number;
        seasonName: string | null;
        seasonLeagues: {
            [key: string]: SeasonLeagueInterface
        }
    }
}


export interface LeaguesTablesComponentProps {location: any}


export const LeaguesTablesComponent: React.FC<LeaguesTablesComponentProps> = ({location}) : JSX.Element | null => {


    const ComponentRef = useRef(null);


    const LEAGUES_TABLES_HEADER_TEXT = UseLeaguesDictionaryPhrasesSet("LEAGUES_TABLES_HEADER_TEXT");


    useEffect(() => {
        if (!ComponentRef) return;
        /** Scroll to HTML element */
        scrollToDomElement(ComponentRef.current);
    }, []);



    const inheritedSelectedSeasonID = location?.state?.selectedSeason;
    const inheritedSelectedLeagueID = location?.state?.selectedLeague;


    return (
        <PageContainerComponent id={"leagues-tables-page-component"} forwardRef={ComponentRef} cssClasses={"leagues-tables-page"}>

            <DefaultPageHeaderComponent headerText={LEAGUES_TABLES_HEADER_TEXT} />

            <PageContentComponent id={"leagues-tables"} cssClasses={"leagues-tables"}>

                <LeaguesTablesContentComponent
                    inheritedSelectedSeasonID={inheritedSelectedSeasonID}
                    inheritedSelectedLeagueID={inheritedSelectedLeagueID}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default LeaguesTablesComponent;


