import { UseLanguage } from "@States";


import GamesDictionary, { GamesDictionaryPhrasesSetType } from "@Dictionary/Pages/Leagues/Games";


export const UseGamesDictionaryPhrasesSet = (phraseName: GamesDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return GamesDictionary[ActiveLanguage][phraseName];

};


export default UseGamesDictionaryPhrasesSet;
