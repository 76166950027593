import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";

import LeaguesMessages from "@GlobalData/Messages/GUI/Leagues";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";


import LeaguesStatisticsTableComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Statistics/Tables";


const TEXTS: any = {
    PL: {statistics_header_text: "Statystyki Indywidualne"},
    EN: {statistics_header_text: "Individual Statistics"},
};


export interface HomePageLeaguesStatisticsComponentProps {
    leaguesList: LeagueInterface[];
    selectedLeagueID: number | null;
    selectedLeagueIDHandler(leagueID: number): void;
}


export const HomePageLeaguesStatisticsComponent: React.FC<HomePageLeaguesStatisticsComponentProps> = ({
    leaguesList,
    selectedLeagueID, selectedLeagueIDHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        statistics_header_text: STATISTICS_HEADER_TEXT
    } = TEXTS[activeLanguage];


    if (!leaguesList?.length) return null;


    if (!selectedLeagueID) {
        return (
            <MessageComponent lg={5} messageText={LeaguesMessages.noSelectedLeague[activeLanguage]} />
        );
    }


    return (
        <Col xs={12} lg={4} className={"home-page-leagues-statistics"}>
            <Row className={"leagues-statistics-content"}>

                <Col as={"h3"} xs={12} className={"home-page-leagues-header"}>
                    <span>{STATISTICS_HEADER_TEXT}</span>
                </Col>

                {!!leaguesList &&
                <LeaguesStatisticsTableComponent
                    selectedLeagueID={selectedLeagueID}
                />
                }

            </Row>
        </Col>
    );

};


export default HomePageLeaguesStatisticsComponent;
