import React from "react";


import { UseGlobalData } from "@ApplicationStates";


import REST_API_ENDPOINTS_URLS from "@ApiData";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import "./index.scss";


export interface FooterLogoComponentProps {}


export const FooterLogoComponent: React.FC<FooterLogoComponentProps> = () : JSX.Element | null => {


    const GlobalData = UseGlobalData();


    if (!GlobalData?.settings) return null;


    const {
        league_name: LeagueName, league_logo: LeagueLogo
    } = GlobalData.settings;


    const API_ROOT_URL = REST_API_ENDPOINTS_URLS.API_ROOT_URL;


    return (
        <Col as={`aside`} xs={12} lg={4} className={`service-footer-section__logo`}>

            {!!LeagueLogo &&
			<NavLink to={"/"} className={"league-logo"}>
				<img src={`${API_ROOT_URL}${LeagueLogo.image}`} alt={LeagueName?.value || ""}/>
			</NavLink>
            }

            {!!LeagueName?.value &&
			<span className={"league-name"}>{LeagueName.value}</span>
            }

        </Col>
    )

};


export default FooterLogoComponent;
