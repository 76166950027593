import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import NumberFormat from "react-number-format";


import GameInformationSummaryTableComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable";
import GameSummaryTableDataComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table";
import GameSummaryTableRowComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/_Parts/SummaryTable/Table/Row";


import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import TeamsSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams";
import MessageComponent from "@MessagesElements/Content";
import {countEval, countPercentageThrowsEffectivity, countTotalPoints} from "@Services/Utilities/Games";
import PlayerSummaryStatisticsInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Players/Player/Statistics";
import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryPlayerInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players/Player";


const TEXTS: any = {
    PL: {
        summary_section_title: "Podsumowanie",
        team_points_text: "Punkty",
        player_of_the_game_text: "Zawodnik meczu",
        pt2_effectivity_text: "Skuteczność za 2",
        pt3_effectivity_text: "Skuteczność za 3",
        ptg_effectivity_text: "Skuteczność z gry",
        pt1_effectivity_text: "Osobiste",
        rebounds_total_text: "Zbiórki",
        rebounds_offensive_text: "Zbiórki Atak",
        rebounds_defensive_text: "Zbiórki Obrona",
        assists_text: "Asysty",
        blocks_text: "Bloki",
        steals_text: "Przechwyty",
        turnovers_text: "Straty",
        fouls_text: "Faule",
    },
    EN: {
        summary_section_title: "Summary",
        team_points_text: "Points",
        player_of_the_game_text: "Game Award",
        pt2_effectivity_text: "2pts Throws Effectivity",
        pt3_effectivity_text: "3pts Throws Effectivity",
        ptg_effectivity_text: "Game Throws Effectivity",
        pt1_effectivity_text: "Free Throws Effectivity",
        rebounds_total_text: "Rebounds",
        rebounds_offensive_text: "Offensive Rebounds",
        rebounds_defensive_text: "Defensive Rebounds",
        assists_text: "Assists",
        blocks_text: "Blocks",
        steals_text: "Steals",
        turnovers_text: "Turnovers",
        fouls_text: "Fouls",
    }
};


interface GameInformationSummaryComponentProps {
    homeTeamID: number;
    awayTeamID: number;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface;
    TeamsSummaryData: TeamsSummaryInterface | null;
}


export const GameInformationSummaryComponent: React.FC<GameInformationSummaryComponentProps> = ({
    homeTeamID, awayTeamID,
    SelectedQuarters, GameSummaryStatistics, TeamsSummaryData
}) : JSX.Element | null => {


    const activeLanguageCode = UseLanguage();

    const {
        summary_section_title: SUMMARY_SECTION_TITLE,
        team_points_text: TEAM_POINTS_TEXT, player_of_the_game_text: PLAYER_OF_THE_GAME_TEXT,
        pt2_effectivity_text: PT2_EFFECTIVITY_TEXT, pt3_effectivity_text: PT3_EFFECTIVITY_TEXT, ptg_effectivity_text: PTG_EFFECTIVITY_TEXT, pt1_effectivity_text: PT1_EFFECTIVITY_TEXT,
        rebounds_total_text: REBOUNDS_TOTAL_TEXT, rebounds_offensive_text: REBOUNDS_OFFENSIVE_TEXT, rebounds_defensive_text: REBOUNDS_DEFENSIVE_TEXT,
        assists_text: ASSISTS_TEXT, blocks_text: BLOCKS_TEXT, steals_text: STEALS_TEXT,
        turnovers_text: TURNOVERS_TEXT, fouls_text: FOULS_TEXT
    } = TEXTS[activeLanguageCode];


    if (!GameSummaryStatistics || !TeamsSummaryData || !SelectedQuarters) return null;


    if (!homeTeamID || !awayTeamID) return <MessageComponent messageText={` `} messageType={"error"} />;


    const HomeTeam = TeamsSummaryData[homeTeamID];
    const AwayTeam = TeamsSummaryData[awayTeamID];


    if (!HomeTeam || !AwayTeam) return <MessageComponent messageText={` `} messageType={"error"} />;


    const {
        teamSummaryStatistics: {
            PT1: homeTeamPT1, PT1A: homeTeamPT1A,
            PT2: homeTeamPT2, PT2A: homeTeamPT2A,
            PT3: homeTeamPT3, PT3A: homeTeamPT3A,
            O_REB: homeTeamOffensiveRebounds, D_REB: homeTeamDefensiveRebounds, T_REB: homeTeamTeamRebounds,
            AST: homeTeamAssists, BLK: homeTeamBlocks, STL: homeTeamSteals,
            TO: homeTeamTurnovers,
            FOUL: homeTeamFouls, FOUL_TECH: homeTeamTechnicalFouls, FOUL_NS: homeTeamUnsportsmanlikeFouls, FOUL_DSQ: homeTeamDisqualificationsFouls
        },
        teamPlayers: homeTeamPlayers
    } = HomeTeam

    const homeTeamTotalPTS = countTotalPoints(homeTeamPT1, homeTeamPT2, homeTeamPT3);

    const homeTeamPT1Total = homeTeamPT1 + homeTeamPT1A;
    const homeTeamPT2Total = homeTeamPT2 + homeTeamPT2A;
    const homeTeamPT3Total = homeTeamPT3 + homeTeamPT3A;

    const homeTeamPT1Effectivity = countPercentageThrowsEffectivity(homeTeamPT1, homeTeamPT1Total);
    const homeTeamPT2Effectivity = countPercentageThrowsEffectivity(homeTeamPT2, homeTeamPT2Total);
    const homeTeamPT3Effectivity = countPercentageThrowsEffectivity(homeTeamPT3, homeTeamPT3Total);

    const homeTeamGameAccuratePT = homeTeamPT2 + homeTeamPT3;
    const homeTeamGameNotAccuratePT = homeTeamPT2A + homeTeamPT3A;
    const homeTeamTotalGamePT = homeTeamGameAccuratePT + homeTeamGameNotAccuratePT;
    const homeTeamGamePTEffectivity = countPercentageThrowsEffectivity(homeTeamGameAccuratePT, homeTeamTotalGamePT);

    const homeTeamTotalRebounds = homeTeamOffensiveRebounds + homeTeamDefensiveRebounds;

    const homeTeamTotalFouls = homeTeamFouls + homeTeamTechnicalFouls + homeTeamUnsportsmanlikeFouls + homeTeamDisqualificationsFouls;


    const {
        teamSummaryStatistics: {
            PT1: awayTeamPT1, PT1A: awayTeamPT1A,
            PT2: awayTeamPT2, PT2A: awayTeamPT2A,
            PT3: awayTeamPT3, PT3A: awayTeamPT3A,
            O_REB: awayTeamOffensiveRebounds, D_REB: awayTeamDefensiveRebounds, T_REB: awayTeamTeamRebounds,
            AST: awayTeamAssists, BLK: awayTeamBlocks, STL: awayTeamSteals,
            TO: awayTeamTurnovers,
            FOUL: awayTeamFouls, FOUL_TECH: awayTeamTechnicalFouls, FOUL_NS: awayTeamUnsportsmanlikeFouls, FOUL_DSQ: awayTeamDisqualificationsFouls,
        },
        teamPlayers: awayTeamPlayers,
    } = AwayTeam;

    const awayTeamTotalPTS = countTotalPoints(awayTeamPT1, awayTeamPT2, awayTeamPT3);

    const awayTeamPT1Total = awayTeamPT1 + awayTeamPT1A;
    const awayTeamPT2Total = awayTeamPT2 + awayTeamPT2A;
    const awayTeamPT3Total = awayTeamPT3 + awayTeamPT3A;

    const awayTeamPT1Effectivity = countPercentageThrowsEffectivity(awayTeamPT1, awayTeamPT1Total);
    const awayTeamPT2Effectivity = countPercentageThrowsEffectivity(awayTeamPT2, awayTeamPT2Total);
    const awayTeamPT3Effectivity = countPercentageThrowsEffectivity(awayTeamPT3, awayTeamPT3Total);

    const awayTeamTotalRebounds = awayTeamOffensiveRebounds + awayTeamDefensiveRebounds;

    const awayTeamTotalFouls = awayTeamFouls + awayTeamTechnicalFouls + awayTeamUnsportsmanlikeFouls + awayTeamDisqualificationsFouls;

    const awayTeamGameAccuratePT = awayTeamPT2 + awayTeamPT3;
    const awayTeamGameNotAccuratePT = awayTeamPT2A + awayTeamPT3A;
    const awayTeamTotalGamePT = awayTeamGameAccuratePT + awayTeamGameNotAccuratePT;
    const awayTeamGamePTEffectivity = countPercentageThrowsEffectivity(awayTeamGameAccuratePT, awayTeamTotalGamePT);


    const homeTeamPlayersIDs = Object.values(homeTeamPlayers).map((player) => player.ID);
    const awayTeamPlayersIDs = Object.values(awayTeamPlayers).map((player) => player.ID);


    const GamePlayers = { ...homeTeamPlayers, ...awayTeamPlayers };

    let PlayerOfTheGame: ApiGameSummaryPlayerInterface | null = null;

    const calculatePlayerEvalBySummaryData = (
        Player: PlayerSummaryStatisticsInterface
    ) : number => {

        if (!Player) return 0;

        const {
            PT1, PT1A, PT2, PT2A, PT3, PT3A,
            O_REB, D_REB,
            AST, BLK, STL, TO,
        } = Player;

        return countEval(
            countTotalPoints(PT1, PT2, PT3),
            O_REB + D_REB,
            AST, STL, BLK, TO,
            PT2A + PT2, PT3A + PT3, PT2, PT3, PT1A + PT1, PT1
        );

    };


    let PlayerOfTheGameID = 0;

    if (SelectedQuarters.length >= 4) {

        PlayerOfTheGameID = Object.values(GamePlayers).reduce((player1, player2) => {

            const player1Eval = calculatePlayerEvalBySummaryData(player1);
            const player2Eval = calculatePlayerEvalBySummaryData(player2);

            if (player2Eval > player1Eval) {
                return player2;
            }

            return player1;

        }).ID;

        PlayerOfTheGame = GameSummaryStatistics.players[PlayerOfTheGameID];

    }


    let gameWinner: number | null = null;
    if (homeTeamTotalPTS > awayTeamTotalPTS) gameWinner = awayTeamID;
    else if (homeTeamTotalPTS < awayTeamTotalPTS) gameWinner = awayTeamID;


    const { squads: GameSquads } = GameSummaryStatistics;

    const HomeTeamSquad = GameSquads[homeTeamID];
    const AwayTeamSquad = GameSquads[awayTeamID];

    return (
        <GameInformationSummaryTableComponent
            gameHomeTeam={HomeTeamSquad}
            gameAwayTeam={AwayTeamSquad}
        >

            <GameSummaryTableDataComponent
                tableTitle={SUMMARY_SECTION_TITLE}
            >

                {PlayerOfTheGame &&
                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PLAYER_OF_THE_GAME_TEXT}
                    summaryStatisticHomeTeamData={{ primaryValue: homeTeamPlayersIDs.includes(PlayerOfTheGameID) ? `${PlayerOfTheGame.player_first_name} ${PlayerOfTheGame.player_last_name}` : null }}
                    summaryStatisticAwayTeamData={{ primaryValue: awayTeamPlayersIDs.includes(PlayerOfTheGameID) ? `${PlayerOfTheGame.player_first_name} ${PlayerOfTheGame.player_last_name}` : null }}
                />
                }

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TEAM_POINTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamTotalPTS}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamTotalPTS}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT2_EFFECTIVITY_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamPT2}/${homeTeamPT2Total}`,
                        secondaryValue: <><NumberFormat value={homeTeamPT2Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamPT2}/${awayTeamPT2Total}`,
                        secondaryValue: <><NumberFormat value={awayTeamPT2Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT3_EFFECTIVITY_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamPT3}/${homeTeamPT3Total}`,
                        secondaryValue: <><NumberFormat value={homeTeamPT3Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamPT3}/${awayTeamPT3Total}`,
                        secondaryValue: <><NumberFormat value={awayTeamPT3Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PTG_EFFECTIVITY_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamGameAccuratePT}/${homeTeamTotalGamePT}`,
                        secondaryValue: <><NumberFormat value={homeTeamGamePTEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamGameAccuratePT}/${awayTeamTotalGamePT}`,
                        secondaryValue: <><NumberFormat value={awayTeamGamePTEffectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={PT1_EFFECTIVITY_TEXT}
                    summaryStatisticHomeTeamData={{
                        primaryValue: `${homeTeamPT1}/${homeTeamPT1Total}`,
                        secondaryValue: <><NumberFormat value={homeTeamPT1Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                    summaryStatisticAwayTeamData={{
                        primaryValue: `${awayTeamPT1}/${awayTeamPT1Total}`,
                        secondaryValue: <><NumberFormat value={awayTeamPT1Effectivity} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</>,
                    }}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={REBOUNDS_TOTAL_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamTotalRebounds}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamTotalRebounds}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={REBOUNDS_OFFENSIVE_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamOffensiveRebounds}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamOffensiveRebounds}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={REBOUNDS_DEFENSIVE_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamDefensiveRebounds}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamDefensiveRebounds}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={ASSISTS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamAssists}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamAssists}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={BLOCKS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamBlocks}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamBlocks}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={STEALS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamSteals}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamSteals}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={TURNOVERS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamTurnovers}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamTurnovers}}
                />

                <GameSummaryTableRowComponent
                    summaryStatisticLabel={FOULS_TEXT}
                    summaryStatisticHomeTeamData={{primaryValue: homeTeamTotalFouls}}
                    summaryStatisticAwayTeamData={{primaryValue: awayTeamTotalFouls}}
                />

            </GameSummaryTableDataComponent>

        </GameInformationSummaryTableComponent>
    );

};


export default GameInformationSummaryComponent;
