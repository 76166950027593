import React from "react";

import parse from "html-react-parser";


import {
    Col
} from "react-bootstrap";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleTextComponentProps {
    Article: ArticleInterface;
}


export const ArticleTextComponent: React.FC<ArticleTextComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    const {
        article_content: articleText
    } = Article;


    return (
        <Col xs={12}>
            {parse(articleText)}
        </Col>
    );

};


export default ArticleTextComponent;
