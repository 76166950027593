export * from "@Dictionary/Pages/User/NotAuthenticated/Types/Phrases/Set";
export * from "@Dictionary/Pages/User/NotAuthenticated/Phrases";


import NotAuthenticatedUserDictionaryInterface from "@Dictionary/Pages/User/NotAuthenticated/Types";


import {
    LOGIN_PAGE_PAGE_TITLE_PL, LOGIN_PAGE_PAGE_TITLE_EN,
    LOGIN_PAGE_BUTTON_TEXT_PL, LOGIN_PAGE_BUTTON_TEXT_EN,
    LOGIN_PAGE_RESET_PASSWORD_TEXT_PL, LOGIN_PAGE_RESET_PASSWORD_TEXT_EN,
    INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_PL, INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_EN,
    PASSWORD_RESET_PAGE_TITLE_PL, PASSWORD_RESET_PAGE_TITLE_EN,
    PASSWORD_RESET_PAGE_MESSAGE_TEXT_PL, PASSWORD_RESET_PAGE_MESSAGE_TEXT_EN,
    PASSWORD_RESET_PAGE_BUTTON_TEXT_PL, PASSWORD_RESET_PAGE_BUTTON_TEXT_EN,
    PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_PL, PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_EN,
    PASSWORD_SET_PAGE_TITLE_PL, PASSWORD_SET_PAGE_TITLE_EN,
    PASSWORD_SET_PAGE_MESSAGE_TEXT_PL, PASSWORD_SET_PAGE_MESSAGE_TEXT_EN,
    PASSWORD_SET_PAGE_BUTTON_TEXT_PL, PASSWORD_SET_PAGE_BUTTON_TEXT_EN
} from "@Dictionary/Pages/User/NotAuthenticated/Phrases";


export const NotAuthenticatedUserDictionary: NotAuthenticatedUserDictionaryInterface = {
    PL: {
        LOGIN_PAGE_PAGE_TITLE: LOGIN_PAGE_PAGE_TITLE_PL,
        LOGIN_PAGE_BUTTON_TEXT: LOGIN_PAGE_BUTTON_TEXT_PL,
        LOGIN_PAGE_RESET_PASSWORD_TEXT: LOGIN_PAGE_RESET_PASSWORD_TEXT_PL,
        INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT: INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_PL,
        PASSWORD_RESET_PAGE_TITLE: PASSWORD_RESET_PAGE_TITLE_PL,
        PASSWORD_RESET_PAGE_MESSAGE_TEXT: PASSWORD_RESET_PAGE_MESSAGE_TEXT_PL,
        PASSWORD_RESET_PAGE_BUTTON_TEXT: PASSWORD_RESET_PAGE_BUTTON_TEXT_PL,
        PASSWORD_RESET_SUCCESS_MESSAGE_TEXT: PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_PL,
        PASSWORD_SET_PAGE_TITLE: PASSWORD_SET_PAGE_TITLE_PL,
        PASSWORD_SET_PAGE_MESSAGE_TEXT: PASSWORD_SET_PAGE_MESSAGE_TEXT_PL,
        PASSWORD_SET_PAGE_BUTTON_TEXT: PASSWORD_SET_PAGE_BUTTON_TEXT_PL,
    },
    EN: {
        LOGIN_PAGE_PAGE_TITLE: LOGIN_PAGE_PAGE_TITLE_EN,
        LOGIN_PAGE_BUTTON_TEXT: LOGIN_PAGE_BUTTON_TEXT_EN,
        LOGIN_PAGE_RESET_PASSWORD_TEXT: LOGIN_PAGE_RESET_PASSWORD_TEXT_EN,
        INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT: INCORRECT_LOGIN_DATA_ERROR_MESSAGE_TEXT_EN,
        PASSWORD_RESET_PAGE_TITLE: PASSWORD_RESET_PAGE_TITLE_EN,
        PASSWORD_RESET_PAGE_MESSAGE_TEXT: PASSWORD_RESET_PAGE_MESSAGE_TEXT_EN,
        PASSWORD_RESET_PAGE_BUTTON_TEXT: PASSWORD_RESET_PAGE_BUTTON_TEXT_EN,
        PASSWORD_RESET_SUCCESS_MESSAGE_TEXT: PASSWORD_RESET_SUCCESS_MESSAGE_TEXT_EN,
        PASSWORD_SET_PAGE_TITLE: PASSWORD_SET_PAGE_TITLE_EN,
        PASSWORD_SET_PAGE_MESSAGE_TEXT: PASSWORD_SET_PAGE_MESSAGE_TEXT_EN,
        PASSWORD_SET_PAGE_BUTTON_TEXT: PASSWORD_SET_PAGE_BUTTON_TEXT_EN,
    }
};


export default NotAuthenticatedUserDictionary;
