import React, {useState, useEffect, useRef} from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";


import {Col} from "react-bootstrap";


import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ResultsBarResultComponent from "@Components/Views/Parts/Results/List/Result";


import _restApiRequest from "@Services/HTTP/Requests/Request";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";
import MessageComponent from "@MessagesElements/Content";
import ColumnProps from "@BootstrapInterfaces/Column";
import {BiLeftArrow, BiRightArrow} from "react-icons/all";


const SLIDER_SETTINGS = {
    dots: false, arrows: false, infinite: false, speed: 500,
    slidesToShow: 5, slidesToScroll: 1, initialSlide: 0,
    autoplay: false, autoplaySpeed: 4000, pauseOnHover: true,
    responsive: [
        {breakpoint: 1700, settings: {slidesToShow: 5, slidesToScroll: 1,}},
        {breakpoint: 1400, settings: {slidesToShow: 4, slidesToScroll: 1,}},
        {breakpoint: 1200, settings: {slidesToShow: 3, slidesToScroll: 1,}},
        {breakpoint: 992, settings: {slidesToShow: 2, slidesToScroll: 1,}},
        {breakpoint: 768, settings: {slidesToShow: 2, slidesToScroll: 1,}},
        // {breakpoint: 480, settings: {slidesToShow: 1}},
    ]
};


interface ResultsBarListProps extends ColumnProps {}


export const ResultsBarListComponent: React.FC<ResultsBarListProps> = ({
    xs = 12, lg = 12,
    ...props
}) : JSX.Element | null => {


    const [actualResolution, setActualResolution] = useState(window?.innerWidth || 0);

    useEffect(() => {
        if (!window) return;
        const onResizeListener = (e: Event) => {
            setActualResolution(window?.innerWidth || 0)
        };
        window.addEventListener("resize", onResizeListener);
        return () => {
            window.removeEventListener("resize", onResizeListener);
        };
    }, []);


    const [GAMES_LIST, SET_GAMES_LIST] = useState<LeagueGameInterface[] | null>(null);
    const [GAMES_LIST_ERROR, SET_GAMES_LIST_ERROR] = useState<ErrorMessageInterface | null>(null);

    const [ResultsBarGames, setResultsBarGames] = useState<LeagueGameInterface[] | null>(null);

    const RESULTS_BAR_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.GAMES.GAMES.RESULTS_BAR_URL;


    useEffect(() => {

        const RESULTS_BAR_REST_API_URL: string = `${RESULTS_BAR_API_URL}/`;

        const GET_GAMES_LIST = async () : Promise<void> => {

            try {

                const RESPONSE = await _restApiRequest(RESULTS_BAR_REST_API_URL);

                if (RESPONSE.status === 200) {
                    const RESPONSE_CONTENT: LeagueGameInterface[] = await RESPONSE.json();
                    SET_GAMES_LIST(RESPONSE_CONTENT);
                } else {
                    const ERROR_RESPONSE_CONTENT: ErrorMessageInterface = await RESPONSE.json();
                    SET_GAMES_LIST_ERROR(ERROR_RESPONSE_CONTENT);
                }

            } catch (e) {
                SET_GAMES_LIST_ERROR({errorCode: 0, errorMessage: ""});
            }

        };

        GET_GAMES_LIST();

        return () => SET_GAMES_LIST(null);

    }, []);


    const [actualSlideIndex, setActualSlideIndex] = useState(0);


    useEffect(() => {

        if (!GAMES_LIST) return;

        const NOT_STARTED_GAMES: LeagueGameInterface[] = [];
        const LIVE_GAMES: LeagueGameInterface[] = [];
        const FINISHED_GAMES: LeagueGameInterface[] = [];

        for (let GAME of GAMES_LIST) {
            const {league_game_status: GAME_STATUS} = GAME;
            if (GAME_STATUS === "LIVE") LIVE_GAMES.push(GAME);
            else if (GAME_STATUS === "NS") NOT_STARTED_GAMES.push(GAME);
            else FINISHED_GAMES.push(GAME);
        }

        NOT_STARTED_GAMES.sort((game1, game2) =>
            new Date(game1["league_game_date"] || "") < new Date(game2["league_game_date"] || "") ? -1 : 1
        );

        LIVE_GAMES.sort((game1, game2) =>
            new Date(game1["league_game_date"] || "") < new Date(game2["league_game_date"] || "") ? -1 : 1
        );

        FINISHED_GAMES.sort((game1, game2) =>
            new Date(game1["league_game_date"] || "") < new Date(game2["league_game_date"] || "") ? -1 : 1
        );

        const RESULTS_BAR_GAMES = [...FINISHED_GAMES, ...LIVE_GAMES, ...NOT_STARTED_GAMES];

        let SlidesToSkip = 3;

        if (actualResolution < 1200) {
            SlidesToSkip = 1;
        } else if (actualResolution < 800) {
            SlidesToSkip = 0;
        }

        const initialSlideIndex = FINISHED_GAMES.length && (LIVE_GAMES.length || FINISHED_GAMES.length) ? FINISHED_GAMES.length - SlidesToSkip : 0;

        setActualSlideIndex(initialSlideIndex || 0);
        setResultsBarGames(RESULTS_BAR_GAMES);

    }, [GAMES_LIST]);


    const SliderRef = useRef(null);


    const changeActiveSlideHandler = (changeType: "N" | "P") => {

        if (!ResultsBarGames) return;

        let newActiveSlideIndex = actualSlideIndex;

        let SlidesToSkip = 5;

        if (actualResolution < 1200) {
            SlidesToSkip = 3;
        } else if (actualResolution < 800) {
            SlidesToSkip = 1;
        }

        if (changeType === "P") {
            if (actualSlideIndex === 0) return;
            newActiveSlideIndex -= 1;
        } else if (changeType === "N") {
            if (actualSlideIndex === ResultsBarGames.length - SlidesToSkip) return;
            newActiveSlideIndex += 1;
        }

        setActualSlideIndex(newActiveSlideIndex);

    };


    const changeActiveSlide = () => {
        if (SliderRef?.current) {
            // @ts-ignore
            SliderRef.current.slickGoTo(actualSlideIndex);
        }
    }

    useEffect(() => {
        changeActiveSlide()
    }, [actualSlideIndex]);


    if (!!GAMES_LIST_ERROR) return null;


    if (!GAMES_LIST) return <SingleContentLoaderComponent xs={xs} lg={lg} />;


    if (!GAMES_LIST?.length) return <MessageComponent messageText={`Brak rozegranych meczów`} />;


    if (!ResultsBarGames) return null;


    const GamesList = ResultsBarGames.map((GAME, i) =>
        <ResultsBarResultComponent key={i} GAME_DATA={GAME} />
    );


    if (!GamesList?.length) return null;


    return (
        <Col xs={xs} lg={lg} className={"games-results-component games-results-bar"} {...props}>

            <Col xs={1} onClick={() => changeActiveSlideHandler("P")} className={"navigation-arrow previous-arrow" + (actualSlideIndex === 0 ? " disabled" : "")}>
                <BiLeftArrow />
            </Col>

            <Slider ref={SliderRef} {...SLIDER_SETTINGS} initialSlide={actualSlideIndex} className={"games-results-slider"}>

                {GamesList}

            </Slider>

            <Col xs={1} onClick={() => changeActiveSlideHandler("N")} className={"navigation-arrow next-arrow" + (actualSlideIndex === ResultsBarGames.length - 5 ? " disabled" : "")}>
                <BiRightArrow />
            </Col>

        </Col>
    );

};


export default ResultsBarListComponent;
