import React from "react";


import {
    UseErrorMessage, UseGlobalsDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Row, Col } from "react-bootstrap";


import MainContentLoaderComponent from "@LoadersElements/MainContent";

import MessageComponent from "@MessagesElements/Content";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import FilesGroupComponent from "@Pages/Common/DownloadPage/FilesGroups/FIlesGroup";


import {
    FileGroupInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface FilesGroupsComponentProps {
    FilesGroups: FileGroupInterface[] | null;
    FilesGroupsError: ErrorMessageInterface | null;
}


export const FilesGroupsComponent: React.FC<FilesGroupsComponentProps> = ({
    FilesGroups, FilesGroupsError
}) => {


    const DEFAULT_DATA_REQUEST_ERROR_MESSAGE = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT = UseGlobalsDictionaryPhrasesSet("NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT");


    if (FilesGroupsError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR_MESSAGE} cssClasses={"no-margin"} />;
    }


    if (!FilesGroups) {
        return <MainContentLoaderComponent />;
    }


    if (!FilesGroups.length) {
        return <MessageComponent messageText={NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT} cssClasses={"margin-top-15"} />;
    }


    const FilesGroupsList = FilesGroups.map((FilesGroup, i) =>
        <FilesGroupComponent key={i} FilesGroup={FilesGroup} />
    );


    return (
        <Col xs={12} className={"files-for-download__files-groups"}>
            <Row className={"files-for-download__files-groups__content"}>

                {FilesGroupsList}

            </Row>
        </Col>
    )


};


export default FilesGroupsComponent;
