import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface LeaguesTablesFullTableLinkComponentProps {
    selectedSeasonID: number | null;
    selectedLeagueID: number | null;
}

export const LeaguesTablesFullTableLinkComponent: React.FC<LeaguesTablesFullTableLinkComponentProps> = ({
    selectedSeasonID, selectedLeagueID
}) : JSX.Element | null => {


    const TablesRoute = UseRoute("TABLES");


    const FULL_TABLE_PHRASE = UseCommonDictionaryPhrasesSet("FULL_TABLE_PHRASE");


    if (!selectedSeasonID || !selectedLeagueID) {
        return null;
    }

    if (!TablesRoute || !TablesRoute?.routeURL) {
        return null;
    }


    return (
        <Col xs={12} className={"home-page__leagues__tables__tables__full-table-link"}>
            <Row className={"home-page__leagues__tables__tables__full-table-link__content"}>

                <NavLink
                    to={{
                        pathname: `/${TablesRoute.routeURL}`,
                        state: {
                            selectedSeason: selectedSeasonID,
                            selectedLeague: selectedLeagueID
                        }
                    }}
                    title={TablesRoute.routeTitle}
                >
                    <span>{FULL_TABLE_PHRASE}</span>
                </NavLink>

            </Row>
        </Col>
    );


};


export default LeaguesTablesFullTableLinkComponent;
