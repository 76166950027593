import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiFillCaretDown, AiFillCaretUp
} from "react-icons/all";


import "./index.scss";


export interface TeamPlayersListDisplaySwitcherComponentProps {
    showPlayersList: boolean;
    showPlayersListHandler: (status: boolean) => void;
}


export const TeamPlayersListDisplaySwitcherComponent: React.FC<TeamPlayersListDisplaySwitcherComponentProps> = ({
    showPlayersList, showPlayersListHandler
}) : JSX.Element | null => {


    const SHOW_TEAM_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("SHOW_TEAM_PLAYERS_PHRASE_TEXT");
    const HIDE_TEAM_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("HIDE_TEAM_PLAYERS_PHRASE_TEXT");


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__players-list__display-switcher"}>
            <Row className={"player-profile__captain-zone__team__players__players-list__display-switcher__content"}>

                <span className={"player-profile__captain-zone__team__players__players-list__display-switcher__switcher"} onClick={() => showPlayersListHandler(!showPlayersList)}>
                    <span className={"player-profile__captain-zone__team__players__players-list__display-switcher__switcher__text"}>
                        {showPlayersList ? HIDE_TEAM_PLAYERS_PHRASE_TEXT : SHOW_TEAM_PLAYERS_PHRASE_TEXT}
                    </span>
                    <span className={"player-profile__captain-zone__team__players__players-list__display-switcher__switcher__icon"}>
                        {showPlayersList ? <AiFillCaretUp /> : <AiFillCaretDown />}
                    </span>
                </span>

            </Row>
        </Col>
    );

};


export default TeamPlayersListDisplaySwitcherComponent;
