import {
    useEffect, useState
} from "react";


import _getDataFromApi from "@Services/API/Requests";

import {
    API_LEAGUES_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    LeagueTablesInterface
} from "@Interfaces/Api";


export const UseLeagueTables = (leagueID: number | null) : [LeagueTablesInterface | null, ErrorMessageInterface | null] => {


    const [LeagueTables, setLeagueTables] = useState<LeagueTablesInterface | null>(null);
    const [LeagueTablesError, setLeagueTablesError] = useState<ErrorMessageInterface | null>(null);


    const API_LEAGUE_TABLES_ENDPOINT_URL = `${API_LEAGUES_LIST_ENDPOINT_URL}${leagueID}/league-table/`;


    useEffect(() => {

        const getLeagueTables = async () => {

            if (!leagueID) {
                return;
            }

            await _getDataFromApi(
                API_LEAGUE_TABLES_ENDPOINT_URL,
                setLeagueTables, setLeagueTablesError
            );

        };

        getLeagueTables();

    }, [leagueID]);


    useEffect(() => {
        return () => {
            setLeagueTables(null);
            setLeagueTablesError(null);
        };
    }, []);


    return [LeagueTables, LeagueTablesError];

};


export default UseLeagueTables;
