export *  from "@Dictionary/Pages/Leagues/Players/Types/Phrases/Set";
export *  from "@Dictionary/Pages/Leagues/Players/Phrases";


import PlayerDictionaryInterface from "@Dictionary/Pages/Leagues/Players/Types";


import {
    PLAYED_GAMES_PL, PLAYED_GAMES_EN, PLAYED_GAMES_TITLE_PL, PLAYED_GAMES_TITLE_EN,
    PLAYED_GAMES_LABEL_PL, PLAYED_GAMES_LABEL_EN, PLAYED_GAMES_SHORT_LABEL_PL, PLAYED_GAMES_SHORT_LABEL_EN,
    START5_GAMES_PL, START5_GAMES_EN, START5_GAMES_TITLE_PL, START5_GAMES_TITLE_EN,
    START5_GAMES_LABEL_PL, START5_GAMES_LABEL_EN, START5_GAMES_SHORT_LABEL_PL, START5_GAMES_SHORT_LABEL_EN,
    NOT_ACTIVATED_STATUS_PL, NOT_ACTIVATED_STATUS_EN,
    INACTIVE_STATUS_PL, INACTIVE_STATUS_EN,
    REGULATIONS_NOT_ACCEPTED_STATUS_PL, REGULATIONS_NOT_ACCEPTED_STATUS_EN,
    MY_DATA_LABEL_PL, MY_DATA_LABEL_EN, MY_DESCRIPTION_LABEL_PL, MY_DESCRIPTION_LABEL_EN,
    FORMAL_AGREEMENTS_LABEL_PL, FORMAL_AGREEMENTS_LABEL_EN, CAPTAIN_ZONE_LABEL_PL, CAPTAIN_ZONE_LABEL_EN,
    CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_PL, CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_EN,
    CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_PL, CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_EN,
    CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_PL, CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_EN,
    GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_PL, GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_EN,
} from "@Dictionary/Pages/Leagues/Players/Phrases";


export const PlayersDictionary: PlayerDictionaryInterface = {
    PL: {
        PLAYED_GAMES: PLAYED_GAMES_PL, PLAYED_GAMES_SHORT_LABEL: PLAYED_GAMES_SHORT_LABEL_PL, PLAYED_GAMES_LABEL: PLAYED_GAMES_LABEL_PL, PLAYED_GAMES_TITLE: PLAYED_GAMES_TITLE_PL,
        START5_GAMES: START5_GAMES_PL, START5_GAMES_SHORT_LABEL: START5_GAMES_SHORT_LABEL_PL, START5_GAMES_LABEL: START5_GAMES_LABEL_PL, START5_GAMES_TITLE: START5_GAMES_TITLE_PL,
        INACTIVE_STATUS: INACTIVE_STATUS_PL, NOT_ACTIVATED_STATUS: NOT_ACTIVATED_STATUS_PL, REGULATIONS_NOT_ACCEPTED_STATUS: REGULATIONS_NOT_ACCEPTED_STATUS_PL,
        MY_DATA_LABEL: MY_DATA_LABEL_PL, MY_DESCRIPTION_LABEL: MY_DESCRIPTION_LABEL_PL,
        FORMAL_AGREEMENTS_LABEL: FORMAL_AGREEMENTS_LABEL_PL, CAPTAIN_ZONE_LABEL: CAPTAIN_ZONE_LABEL_PL,
        CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL: CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_PL,
        CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL: CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_PL,
        CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL: CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_PL,
        GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT: GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_PL,
    },
    EN: {
        PLAYED_GAMES: PLAYED_GAMES_EN, PLAYED_GAMES_SHORT_LABEL: PLAYED_GAMES_SHORT_LABEL_EN, PLAYED_GAMES_LABEL: PLAYED_GAMES_LABEL_EN, PLAYED_GAMES_TITLE: PLAYED_GAMES_TITLE_EN,
        START5_GAMES: START5_GAMES_EN, START5_GAMES_SHORT_LABEL: START5_GAMES_SHORT_LABEL_EN, START5_GAMES_LABEL: START5_GAMES_LABEL_EN, START5_GAMES_TITLE: START5_GAMES_TITLE_EN,
        INACTIVE_STATUS: INACTIVE_STATUS_EN, NOT_ACTIVATED_STATUS: NOT_ACTIVATED_STATUS_EN, REGULATIONS_NOT_ACCEPTED_STATUS: REGULATIONS_NOT_ACCEPTED_STATUS_EN,
        MY_DATA_LABEL: MY_DATA_LABEL_EN, MY_DESCRIPTION_LABEL: MY_DESCRIPTION_LABEL_EN,
        FORMAL_AGREEMENTS_LABEL: FORMAL_AGREEMENTS_LABEL_EN,CAPTAIN_ZONE_LABEL: CAPTAIN_ZONE_LABEL_EN,
        CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL: CAPTAIN_SEND_ACTIVATION_EMAIL_LABEL_EN,
        CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL: CAPTAIN_SEND_REGULATIONS_EMAIL_LABEL_EN,
        CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL: CAPTAIN_REMOVE_PLAYER_FROM_TEAM_LABEL_EN,
        GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT: GO_TO_PLAYER_PROFILE_LINK_TITLE_TEXT_EN,
    }
};


export default PlayersDictionary;
