import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import AddTeamSquadPlayerHeaderComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayerAdd/Header";
import AddTeamSquadPlayerFormComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayerAdd/Form";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeagueTeamSquadAddPlayerComponentProps {
    teamID: number;
    teamLeagueID: number;
    TeamLeagueSquad: TeamLeaguePlayerInterface[] | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const LeagueTeamSquadAddPlayerComponent: React.FC<LeagueTeamSquadAddPlayerComponentProps> = ({
    teamID, teamLeagueID, TeamLeagueSquad, updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    const [showAddTeamPlayerForm, setShowAddTeamPlayerForm] = useState(false);

    useEffect(() => {
        return () => {
            setShowAddTeamPlayerForm(false);
        };
    }, []);


    if (!teamID) {
        return null;
    }


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__content"}>

                <AddTeamSquadPlayerHeaderComponent
                    showAddTeamPlayerForm={showAddTeamPlayerForm}
                    showAddTeamPlayerFormHandler={setShowAddTeamPlayerForm}
                />

                {showAddTeamPlayerForm &&
                <AddTeamSquadPlayerFormComponent
                    teamID={teamID}
                    teamLeagueID={teamLeagueID}
                    TeamLeagueSquad={TeamLeagueSquad}
                    updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
                />
                }

            </Row>
        </Col>
    );

};


export default LeagueTeamSquadAddPlayerComponent;
