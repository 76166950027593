import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col} from "react-bootstrap";


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import StatisticsTypeInterface from "@GlobalData/Statistics/Types/Type/Interface";


interface LeaguesStatisticsTableStatisticComponentProps {
    statisticType: StatisticsTypeInterface;
    statisticTypeName: StatisticsType | string;
    selectedStatisticsType: StatisticsType | string;
    selectedStatisticsTypeHandler(type: StatisticsType | string): void;
}


export const LeaguesStatisticsTableStatisticComponent: React.FC<LeaguesStatisticsTableStatisticComponentProps> = ({
    statisticType, statisticTypeName,
    selectedStatisticsType, selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();


    if (!statisticType) return null;


    const {title: statisticsTypeTitle} = statisticType[activeLanguage];


    return (
        <Col key={statisticTypeName} xs={"auto"} className={"statistics-table-data-type" + (selectedStatisticsType === statisticTypeName ? " selected" : "")}>
            <span onClick={() => selectedStatisticsTypeHandler(statisticTypeName)}>{statisticsTypeTitle}</span>
        </Col>
    );


};


export default LeaguesStatisticsTableStatisticComponent;
