import React from "react";

import { UseStatisticsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


interface GameTablesTeamTableHeaderComponentProps {}


export const GameTablesTeamTableHeaderComponent: React.FC<GameTablesTeamTableHeaderComponentProps> = () : JSX.Element | null => {


    const NUMBER_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("NUMBER_SHORT_LABEL");
    const NUMBER_TITLE = UseStatisticsDictionaryPhrasesSet("NUMBER_TITLE");

    const NAME_TITLE = UseStatisticsDictionaryPhrasesSet("NAME_TITLE");

    const POINTS_LABEL = UseStatisticsDictionaryPhrasesSet("POINTS_LABEL");
    const POINTS_TITLE = UseStatisticsDictionaryPhrasesSet("POINTS_TITLE");

    const MINUTES_LABEL = UseStatisticsDictionaryPhrasesSet("MINUTES_LABEL");
    const MINUTES_TITLE = UseStatisticsDictionaryPhrasesSet("MINUTES_TITLE");

    const THROWS_1PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_SHORT_LABEL");
    const THROWS_1PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_TITLE");
    const THROWS_1PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_SHORT_LABEL");
    const THROWS_1PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_TITLE");

    const THROWS_2PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_SHORT_LABEL");
    const THROWS_2PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_TITLE");
    const THROWS_2PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_SHORT_LABEL");
    const THROWS_2PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_TITLE");

    const THROWS_3PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_SHORT_LABEL");
    const THROWS_3PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_TITLE");
    const THROWS_3PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_SHORT_LABEL");
    const THROWS_3PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_TITLE");

    const THROWS_GAME_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_SHORT_LABEL");
    const THROWS_GAME_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_TITLE");
    const THROWS_GAME_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_SHORT_LABEL");
    const THROWS_GAME_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_TITLE");

    const DEFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_SHORT_LABEL");
    const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const OFFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_SHORT_LABEL");
    const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const TOTAL_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_SHORT_LABEL");
    const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");

    const ASSISTS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("ASSISTS_SHORT_LABEL");
    const ASSISTS_TITLE = UseStatisticsDictionaryPhrasesSet("ASSISTS_TITLE");
    const BLOCKS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("BLOCKS_SHORT_LABEL");
    const BLOCKS_TITLE = UseStatisticsDictionaryPhrasesSet("BLOCKS_TITLE");
    const STEALS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("STEALS_SHORT_LABEL");
    const STEALS_TITLE = UseStatisticsDictionaryPhrasesSet("STEALS_TITLE");

    const TURNOVERS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TURNOVERS_SHORT_LABEL");
    const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
    const FOULS_LABEL = UseStatisticsDictionaryPhrasesSet("FOULS_LABEL");
    const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");

    const EFFECTIVITY_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_SHORT_LABEL");
    const EFFECTIVITY_TITLE = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_TITLE");
    const EVAL_LABEL = UseStatisticsDictionaryPhrasesSet("EVAL_LABEL");
    const EVAL_TITLE = UseStatisticsDictionaryPhrasesSet("EVAL_TITLE");

    return (
        <Col xs={12} className={"game-tables-team-table-header game-tables-team-table-header-component game-tables-team-table-row"}>
            <Row className={"game-tables-team-table-header-content game-tables-team-table-row-content"}>

                <Col xs={`auto`} title={NUMBER_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-number"}>
                    <span>{NUMBER_SHORT_LABEL}</span>
                </Col>

                <Col xs={2} title={NAME_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-name"}>
                    <span>{NAME_TITLE}</span>
                </Col>

                <Col xs={`auto`} title={POINTS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-points"}>
                    <span>{POINTS_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={MINUTES_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-minutes"}>
                    <span>{MINUTES_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_2PT_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_2PT_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_2PT_EFF_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_2PT_EFF_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_3PT_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_3PT_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_3PT_EFF_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_3PT_EFF_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_GAME_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_GAME_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_GAME_EFF_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_GAME_EFF_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_1PT_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_1PT_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={THROWS_1PT_EFF_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-throws"}>
                    <span>{THROWS_1PT_EFF_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={OFFENSIVE_REBOUNDS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-rebounds"}>
                    <span>{OFFENSIVE_REBOUNDS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={DEFENSIVE_REBOUNDS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-rebounds"}>
                    <span>{DEFENSIVE_REBOUNDS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={TOTAL_REBOUNDS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-rebounds"}>
                    <span>{TOTAL_REBOUNDS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={ASSISTS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-assists"}>
                    <span>{ASSISTS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={STEALS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-steals"}>
                    <span>{STEALS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={BLOCKS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-blocks"}>
                    <span>{BLOCKS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={TURNOVERS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-turnovers"}>
                    <span>{TURNOVERS_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={FOULS_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-fouls"}>
                    <span>{FOULS_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={EFFECTIVITY_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-effectivity"}>
                    <span>{EFFECTIVITY_SHORT_LABEL}</span>
                </Col>

                <Col xs={`auto`} title={EVAL_TITLE} className={"team-table-header table-header-cell table-row-cell team-player-eval"}>
                    <span>{EVAL_LABEL}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameTablesTeamTableHeaderComponent;
