import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { UseStatisticsDictionaryPhrasesSet } from "@Hooks/Texts";


import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";


import NumberFormat from "react-number-format";


import {
    countPercentageThrowsEffectivity, countTotalPoints, countEval
} from "@Services/Utilities/Games";

import { formatMinutesFromSeconds } from "@Services/Utilities/Tools/Formatters";


import GameSummaryPlayersInformationInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Players";
import PlayerSummaryStatisticsInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Players/Player/Statistics";


import "./index.scss";


interface GameTablesTeamTableSquadPlayerComponentProps {
    Player: PlayerSummaryStatisticsInterface;
    GamePlayers: GameSummaryPlayersInformationInterface;
    teamCaptainID?: number | null;
}


export const GameTablesTeamTableSquadPlayerComponent: React.FC<GameTablesTeamTableSquadPlayerComponentProps> = ({
    Player, GamePlayers, teamCaptainID
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");

    const {
        routeTitle: PLAYERS_ROUTE_TITLE, routeURL: PLAYERS_ROUTE_URL
    } = PlayersRoute;


    const NUMBER_TITLE = UseStatisticsDictionaryPhrasesSet("NUMBER_TITLE");
    const NAME_TITLE = UseStatisticsDictionaryPhrasesSet("NAME_TITLE");

    const POINTS_TITLE = UseStatisticsDictionaryPhrasesSet("POINTS_TITLE");

    const MINUTES_TITLE = UseStatisticsDictionaryPhrasesSet("MINUTES_TITLE");

    const THROWS_1PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_TITLE");
    const THROWS_1PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_TITLE");

    const THROWS_2PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_TITLE");
    const THROWS_2PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_TITLE");
    const THROWS_3PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_TITLE");
    const THROWS_3PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_TITLE");

    const THROWS_GAME_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_TITLE");
    const THROWS_GAME_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_TITLE");

    const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");

    const ASSISTS_TITLE = UseStatisticsDictionaryPhrasesSet("ASSISTS_TITLE");
    const BLOCKS_TITLE = UseStatisticsDictionaryPhrasesSet("BLOCKS_TITLE");
    const STEALS_TITLE = UseStatisticsDictionaryPhrasesSet("STEALS_TITLE");

    const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
    const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");

    const EFFECTIVITY_TITLE = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_TITLE");
    const EVAL_TITLE = UseStatisticsDictionaryPhrasesSet("EVAL_TITLE");


    if (!Player) return null;


    const {
        ID: playerID, NUM: playerNumber, FIRST_5: playerFirstSquadStatus
    } = Player;


    const PlayerData = GamePlayers[playerID];

    if (!PlayerData) {
        return null;
    }


    const {
        player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
        player_slug: playerSlug, player_pseudonymization_status: playerPseudonymizationStatus
    } = PlayerData;


    const playerName = playerPseudonymizationStatus ? `"${playerNickName}"` : `${playerFirstName} ${playerLastName}`;


    const {
        PT1: pt1AccurateThrows, PT1A: pt1NotAccurateThrows,
        PT2: pt2AccurateThrows, PT2A: pt2NotAccurateThrows,
        PT3: pt3AccurateThrows, PT3A: pt3NotAccurateThrows,
        AST: assists, BLK: blocks,
        O_REB: offensiveRebounds, D_REB: defensiveRebounds, STL: steals,
        FOUL: fouls, FOUL_TECH: foulsTech, FOUL_NS: foulsNS, FOUL_DSQ: foulsDSQ, TO: turnovers,
        EFF: effectivity, SECONDS: seconds,
    } = Player;


    const minutes = formatMinutesFromSeconds(seconds);

    const pt1AllThrows = pt1AccurateThrows + pt1NotAccurateThrows;
    const pt2AllThrows = pt2AccurateThrows + pt2NotAccurateThrows;
    const pt3AllThrows = pt3AccurateThrows + pt3NotAccurateThrows;

    const pt1ThrowsEffectivity = countPercentageThrowsEffectivity(pt1AccurateThrows, pt1AllThrows);
    const pt2ThrowsEffectivity = countPercentageThrowsEffectivity(pt2AccurateThrows, pt2AllThrows);
    const pt3ThrowsEffectivity = countPercentageThrowsEffectivity(pt3AccurateThrows, pt3AllThrows);

    const accurateGameThrows = pt2AccurateThrows + pt3AccurateThrows;
    const allGameThrows = pt2AllThrows + pt3AllThrows;
    const gameThrowsEffectivity = countPercentageThrowsEffectivity(accurateGameThrows, allGameThrows);

    const totalPoints = countTotalPoints(
        pt1AccurateThrows, pt2AccurateThrows, pt3AccurateThrows
    );

    const evaluation = countEval(
        totalPoints,
        offensiveRebounds + defensiveRebounds,
        assists,
        steals,
        blocks,
        turnovers,
        pt2AllThrows,
        pt3AllThrows,
        pt2AccurateThrows,
        pt3AccurateThrows,
        pt1AllThrows,
        pt1AccurateThrows
    );


    const foulsCSSClasses = [];

    const totalFouls = fouls + foulsTech + foulsNS + foulsDSQ;

    if (totalFouls >= 5) foulsCSSClasses.push("equal-or-over-5-fouls");
    if (foulsNS) foulsCSSClasses.push("not-sport-foul");
    if (foulsTech) foulsCSSClasses.push("technical-foul");
    if (foulsDSQ) foulsCSSClasses.push("disqualification-foul");


    return (
        <Col xs={12} className={"game-tables-team-table-team-player game-tables-team-table-team-player-component game-tables-team-table-row"}>
            <Row className={"game-tables-team-table-team-player-content game-tables-team-table-row-content"}>

                <Col xs={2} lg={`auto`} title={NUMBER_TITLE} className={"table-player-cell table-row-cell team-player-number"}>
                    <span className={"statistic-value"}>{playerNumber}</span>
                </Col>

                <Col xs={6} lg={2} title={NAME_TITLE} className={"table-player-cell table-row-cell team-player-name" + (playerFirstSquadStatus ? " first-squad" : "")}>
                    <NavLink to={`/${PLAYERS_ROUTE_URL}/${playerSlug}`} title={PLAYERS_ROUTE_TITLE} className={"statistic-value"}>
                        <span>{playerName}</span>
                        {playerID === teamCaptainID && <span> (C) </span>}
                    </NavLink>
                </Col>

                <Col xs={2} lg={`auto`} title={POINTS_TITLE} className={"table-player-cell table-row-cell team-player-points"}>
                    <span className={"statistic-value"}>{totalPoints || ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={MINUTES_TITLE} className={"table-player-cell table-row-cell team-player-minutes"}>
                    <span className={"statistic-value"}>{seconds ? minutes : "DNP"}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_2PT_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-2pt-throws"}>
                    <span className={"statistic-value"}>{pt2AccurateThrows || pt2AllThrows ? `${pt2AccurateThrows}/${pt2AllThrows}` : ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_2PT_EFF_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-2pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        {pt2AccurateThrows || pt2AllThrows ?
                        <>
                            <NumberFormat value={pt2ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true}
                                          displayType={"text"} decimalSeparator={"."}/>
                            <span>%</span>
                        </>
                            :
                        ""
                        }
                    </span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_3PT_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-3pt-throws"}>
                    <span className={"statistic-value"}>{pt3AccurateThrows || pt3AllThrows ? `${pt3AccurateThrows}/${pt3AllThrows}` : ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_3PT_EFF_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-3pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        {pt3AccurateThrows || pt3AllThrows ?
                        <>
                            <NumberFormat value={pt3ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true}
                                          displayType={"text"} decimalSeparator={"."}/>
                            <span>%</span>
                        </>
                            :
                        ""
                        }
                    </span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_GAME_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-game-throws"}>
                    <span className={"statistic-value"}>{accurateGameThrows || allGameThrows ? `${accurateGameThrows}/${allGameThrows}` : ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_GAME_EFF_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-game-throws-eff"}>
                    <span className={"statistic-value"}>
                        {accurateGameThrows || allGameThrows ?
                        <>
                            <NumberFormat value={gameThrowsEffectivity} decimalScale={1} fixedDecimalScale={true}
                                          displayType={"text"} decimalSeparator={"."}/>
                            <span>%</span>
                        </>
                        :
                        ""
                        }
                    </span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_1PT_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-1pt-throws"}>
                    <span className={"statistic-value"}>{pt1AccurateThrows || pt1AllThrows ? `${pt1AccurateThrows}/${pt1AllThrows}` : ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={THROWS_1PT_EFF_TITLE} className={"table-player-cell table-row-cell team-player-throws team-player-1pt-throws-eff"}>
                    <span className={"statistic-value"}>
                        {pt1AccurateThrows || pt1AllThrows ?
                        <>
                            <NumberFormat value={pt1ThrowsEffectivity} decimalScale={1} fixedDecimalScale={true}
                                          displayType={"text"} decimalSeparator={"."}/>
                            <span>%</span>
                        </>
                        :
                        ""
                        }
                    </span>
                </Col>

                <Col xs={1} lg={`auto`} title={OFFENSIVE_REBOUNDS_TITLE} className={"table-player-cell table-row-cell team-player-rebounds"}>
                    <span className={"statistic-value"}>{offensiveRebounds || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={DEFENSIVE_REBOUNDS_TITLE} className={"table-player-cell table-row-cell team-player-rebounds"}>
                    <span className={"statistic-value"}>{defensiveRebounds || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={TOTAL_REBOUNDS_TITLE} className={"table-player-cell table-row-cell team-player-rebounds"}>
                    <span className={"statistic-value"}>{(offensiveRebounds + defensiveRebounds) || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={ASSISTS_TITLE} className={"table-player-cell table-row-cell team-player-assists"}>
                    <span className={"statistic-value"}>{assists || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={STEALS_TITLE} className={"table-player-cell table-row-cell team-player-steals"}>
                    <span className={"statistic-value"}>{steals || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={BLOCKS_TITLE} className={"table-player-cell table-row-cell team-player-blocks"}>
                    <span className={"statistic-value"}>{blocks || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={TURNOVERS_TITLE} className={"table-player-cell table-row-cell team-player-turnovers"}>
                    <span className={"statistic-value"}>{turnovers || ""}</span>
                </Col>

                <Col xs={1} lg={`auto`} title={FOULS_TITLE} className={"table-player-cell table-row-cell team-player-fouls " + (foulsCSSClasses.join(" "))}>
                    <span className={"statistic-value"}>{totalFouls || ""}</span>
                </Col>

                <Col xs={2} lg={`auto`}title={EFFECTIVITY_TITLE} className={"table-player-cell table-row-cell team-player-effectivity"}>
                    <span className={"statistic-value"}>{effectivity || ""}</span>
                </Col>

                <Col xs={2} lg={`auto`} title={EVAL_TITLE} className={"table-player-cell table-row-cell team-player-eval"}>
                    <span className={"statistic-value"}>{seconds ? evaluation : ""}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameTablesTeamTableSquadPlayerComponent;
