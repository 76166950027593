import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import DefaultLeaguesSwitcherHeaderComponent from "@Elements/Games/Leagues/Switchers/DefaultLeaguesSwitcher/Header";
import DefaultLeaguesSwitcherLeaguesListComponent from "@Elements/Games/Leagues/Switchers/DefaultLeaguesSwitcher/List";


import {
    LeagueNameInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface DefaultLeaguesSwitcherComponentProps {
    Leagues: LeagueNameInterface[];
    selectedLeagueID?: number;
    selectedLeagueIDHandler: ((leagueID: number) => void) | null;
    headerText?: string;
}

export const DefaultLeaguesSwitcherComponent: React.FC<DefaultLeaguesSwitcherComponentProps> = ({
    Leagues,
    selectedLeagueID = 0, selectedLeagueIDHandler = null,
    headerText
}) => {


    return (
        <Col xs={12} className={"leagues-switcher leagues-switcher--default"}>
            <Row className={"leagues-switcher__content leagues-switcher--default__content"}>

                <DefaultLeaguesSwitcherHeaderComponent
                    headerText={headerText}
                />

                <DefaultLeaguesSwitcherLeaguesListComponent
                    Leagues={Leagues}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={selectedLeagueIDHandler}
                />

            </Row>
        </Col>
    )

};


export default DefaultLeaguesSwitcherComponent;
