import { Dispatch } from "redux";

import { AuthenticationActionTypes } from "@Action-Types";
import { AuthenticationAction } from "@Actions";

import UserModel from "@Models/Users/User";


export const UseLogUserIn = (User: UserModel) => {

    return (dispatch: Dispatch<AuthenticationAction>) => {
        dispatch({
            type: AuthenticationActionTypes.LOG_USER_IN,
            user: User
        });
    };

};


export default UseLogUserIn;
