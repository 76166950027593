import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {NavLink} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import dateFormat from "dateformat";


const TEXTS: any = {
    PL: {go_to_game_page_text: "Przejdź do strony meczu", go_to_team_page_text: "Przejdź do strony drużynt"},
    EN: {go_to_game_page_text: "Go to Game Page", go_to_team_page_text: "Go to Team page"}
};


import BestScoreInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/_Types/Scores/Score";



export interface TableScoreDataComponentProps {
    scoreData: BestScoreInterface;
}


export const TableScoreDataComponent: React.FC<TableScoreDataComponentProps> = ({
    scoreData
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        go_to_game_page_text: GO_TO_GAME_PAGE_TEXT, go_to_team_page_text: GO_TO_TEAM_PAGE_TEXT
    } = TEXTS[activeLanguage];


    if (!scoreData) return null;


    const {
        scoreValue, scoreValueDescription, playerTeamID, gameData
    } = scoreData;


    const {
        league_game_id: gameID, league_game_date: gameDate,
        league_game_home_team_points: gameHomeTeamPoints, league_game_away_team_points: gameAwayTeamPoints,
        league_game_home_team_data: gameHomeTeam, league_game_away_team_data: gameAwayTeam,
    } = gameData;

    const {
        team_id: homeTeamID
    } = gameHomeTeam;
    // const {team_id: awayTeamID} = gameAwayTeam;

    const opponentTeam = homeTeamID === playerTeamID ? gameAwayTeam : gameHomeTeam;

    const playerTeamPoints = homeTeamID === playerTeamID ? gameHomeTeamPoints : gameAwayTeamPoints;
    const opponentTeamPoints = homeTeamID === playerTeamID ? gameAwayTeamPoints : gameHomeTeamPoints;

    const {team_slug: opponentTeamSlug, team_name: opponentTeamName} = opponentTeam;


    return (
        <Col xs={7} lg={9} className={"best-score-data best-score-data-component"}>
            <Row className={"best-score-data-content"}>

                <Col xs={6} lg={3} className={"best-score-value"}>
                    {!!scoreValue && <span>{scoreValue}</span>}
                    {!!scoreValueDescription && <span>{scoreValueDescription}</span>}
                </Col>

                <Col xs={6} lg={3} className={"best-score-game-date"}>
                    {gameDate &&
                    <NavLink to={`/mecze/${gameID}`} title={GO_TO_GAME_PAGE_TEXT}>
                        <span>{dateFormat(new Date(gameDate), "dd-mm-yyyy HH:MM")}</span>
                    </NavLink>
                    }
                </Col>

                <Col xs={"auto"} lg={4} className={"best-score-opponent-team"}>
                    {!!opponentTeamSlug && !!opponentTeamName &&
                    <NavLink to={`/druzyny/${opponentTeamSlug}`} title={GO_TO_TEAM_PAGE_TEXT}>
                        <span>{opponentTeamName}</span>
                    </NavLink>
                    }
                </Col>

                <Col xs={"auto"} lg={2} className={"best-score-game-result"}>
		            <NavLink to={`/mecze/${gameID}`} title={GO_TO_GAME_PAGE_TEXT}>
			            <span>{playerTeamPoints} : {opponentTeamPoints}</span>
		            </NavLink>
                </Col>

            </Row>
        </Col>
    );

};


export default TableScoreDataComponent;
