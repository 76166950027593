import React from "react";


import {Col, Container, Row} from "react-bootstrap";


export const PageHeaderContainerComponent = ({headerText = ""}) => {

	
	if (!headerText) return null
	
	
	return (
		<Container fluid={true} className={"page-component-header-block"}>
			<Container className={"page-component-header-container"}>
				<Row className={"page-component-header-content"}>
					
					<Col xs={12} as={"h1"} className={"page-component-header"}>
						<span>{headerText}</span>
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default PageHeaderContainerComponent;
