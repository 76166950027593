import React from "react";


import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";

import LeagueGameEventInterface from "@Interfaces/Api/Leagues/Games/Game/Events/Event";


interface GameThrowInterface {
    data: [[number, number]];
    label: string;
    radius: number;
    backgroundColor: string;
}


export const GameThrowsTeamThrow = (
    GamePlayers: ApiGameSummaryPlayersInterface,
    GameThrowEvent: LeagueGameEventInterface,
    gameThrowEventType: "ACCURATE" | "NOT_ACCURATE"
) : GameThrowInterface => {


    const {
        league_game_event_time: throwTime, league_game_event_quarter: throwQuarter,
        league_game_event_player_id: throwPlayerID,
        league_game_event_field_x_position: throwXPosition,
        league_game_event_field_y_position: throwYPosition,
    } = GameThrowEvent;


    let throwPlayer = null;

    if (throwPlayerID) {

        throwPlayer = GamePlayers[throwPlayerID] || null;

        if (throwPlayer) {
            const {player_first_name: playerFirstName, player_last_name: playerLastName} = throwPlayer;
            throwPlayer = `${playerFirstName} ${playerLastName}`;
        }

    }


    const throwPointRadius = 8;

    let throwPointColor: string = "";
    if (gameThrowEventType === "ACCURATE") throwPointColor = "#00953F";
    if (gameThrowEventType === "NOT_ACCURATE") throwPointColor = "#E2000F";


    const throwXFieldPosition = Number(throwYPosition);

    let throwYFieldPosition = 360 - Number(throwXPosition);
    if (Number(throwXPosition) > 360) throwYFieldPosition = 360 - (720 - Math.round(Number(throwXPosition)))


    return {
        data: [[throwXFieldPosition, throwYFieldPosition]],
        backgroundColor: throwPointColor, radius: throwPointRadius,
        label:( throwPlayer ? `${throwPlayer} ` : "") +  `(Q${throwQuarter} ${throwTime})`,
    };

};


export default GameThrowsTeamThrow;
