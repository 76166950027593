import { UseLanguage } from "@States";


import {
    LinksDictionary, LinksDictionaryPhrasesSetType
} from "@Dictionary";


export const UseLinksDictionaryPhrasesSet = (phraseName: LinksDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return LinksDictionary[ActiveLanguage][phraseName];

};


export default UseLinksDictionaryPhrasesSet;
