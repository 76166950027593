import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import {
    UseCommonDictionaryPhrasesSet, UseErrorMessage
} from "@Hooks/Texts";

import {
    UseTransfersList
} from "@Hooks/Api/Requests";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TransfersListLeaguesComponent from "@GamesPages/Transfers/List/Leagues";
import TransfersListPlayersComponent from "@GamesPages/Transfers/List/Players";


export interface TransfersListComponentProps {}


export const TransfersListComponent: React.FC<TransfersListComponentProps> = ({}) : JSX.Element | null => {


    const REQUEST_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");

    const TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT");


    const [TransfersList, TransfersListError] = UseTransfersList();

    const [selectedLeagueID, setSelectedLeagueID] = useState(0);

    useEffect(() => {

        if (!TransfersList || !TransfersList?.length) {
            return;
        }

        if (selectedLeagueID) {
            return;
        }

        const DefaultLeague = TransfersList[0];

        if (!DefaultLeague) {
            return;
        }

        const { league_id: defaultLeagueID } = DefaultLeague;

        setSelectedLeagueID(defaultLeagueID);

    }, [TransfersList]);

    useEffect(() => {
        return () => {
            setSelectedLeagueID(0);
        };
    }, []);


    const selectedLeagueIDHandler = (leagueID: number) => {
        if (leagueID === selectedLeagueID) {
            leagueID = 0;
        }
        setSelectedLeagueID(leagueID);
    }

    if (TransfersListError) {
        return <ErrorMessageComponent messageText={REQUEST_ERROR_MESSAGE_TEXT} cssClasses={"no-margin-top"} />;
    }

    if (!TransfersList) {
        return <SingleContentLoaderComponent />;
    }


    if (!TransfersList?.length) {
        return <MessageComponent messageText={TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT} cssClasses={"margin-top-15"} />;
    }


    const MappedTransfersList = Object.fromEntries(
        TransfersList.map((League) => {
            const { league_id: leagueID } = League;
            return [leagueID, League];
        })
    );


    const SelectedLeague = MappedTransfersList[selectedLeagueID];


    return (
        <Col xs={12} className={"transfers-list__list"}>
            <Row className={"transfers-list__list__content"}>

                <TransfersListLeaguesComponent
                    TransfersList={TransfersList}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={selectedLeagueIDHandler}
                />

                <TransfersListPlayersComponent
                    SelectedLeague={SelectedLeague}
                />

            </Row>
        </Col>
    );

};


export default TransfersListComponent;
