import React from "react";

import parse from "html-react-parser";


import { UseApplicationLogo } from "@Hooks/GUI";


import { NavLink } from "react-router-dom";
import { Col, Row } from "react-bootstrap";


import { ArticleInterface } from "@Interfaces/Api";


import { PLKA_FULL_COLOR_LOGO } from "@Images";


import "./index.scss";


export interface HomePageArticleComponentProps {
    Article: ArticleInterface;
}

export const HomePageArticleComponent: React.FC<HomePageArticleComponentProps> = ({
    Article
}) => {


    let ArticleImage = UseApplicationLogo();


    if (!Article) {
        return null;
    }


    const {
        article_slug: articleSlug, article_title: articleTitle,
        article_short_content: articleShortContent, article_image: articleImage,
        article_published_status: articlePublishedStatus, article_archive_status: articleArchiveStatus,
    } = Article;


    if (!articlePublishedStatus || articleArchiveStatus) {
        return null;
    }


    if (articleImage) {
        ArticleImage = articleImage;
    }


    if (!ArticleImage) {
        ArticleImage = PLKA_FULL_COLOR_LOGO;
    }


    return (
        <Col className={"home-page__articles__list__article home-page-article"}>
            <Row className={"home-page__articles__list__article__content"}>

                <Col xs={12} className={"article-image"}>
                    <NavLink to={`/aktualnosci/${articleSlug}`} title={articleTitle}>
                        <img src={ArticleImage} alt={articleTitle} />
                    </NavLink>
                </Col>

                <Col xs={12} className={"article-title text-truncate"}>
                    <NavLink to={`/aktualnosci/${articleSlug}`} title={articleTitle} className={"article-title-link "}>
                        <span className={"text-truncate"}>{articleTitle}</span>
                    </NavLink>
                </Col>

                <Col xs={12} className={"article-content"}>
                    <span>{!!articleShortContent && parse(articleShortContent)}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default HomePageArticleComponent;
