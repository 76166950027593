import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col } from "react-bootstrap";

import { AiOutlineCheck } from "react-icons/all";


import "./index.scss";


import {
    LeagueGameQuarterNumberType, LeagueGameQuarterInterface
} from "@Interfaces/Api";


type GameSummaryQuarterComponentProps = {
    GameQuarter: LeagueGameQuarterInterface | null;
    gameQuarterNumber: LeagueGameQuarterNumberType | null;
    SelectedQuarters: LeagueGameQuarterNumberType[];
    selectedQuartersHandler(quarter: LeagueGameQuarterNumberType): void;
};


export const GameSummaryQuarterComponent: React.FC<GameSummaryQuarterComponentProps> = ({
    GameQuarter = null, gameQuarterNumber = null,
    SelectedQuarters, selectedQuartersHandler
}) : JSX.Element | null => {


    const QUARTER = UseGamesDictionaryPhrasesSet("QUARTER");


    if (!gameQuarterNumber) return null;


    let homeTeamPoints: string | number = "-";
    let awayTeamPoints: string | number = "-";

    if (GameQuarter) {

        const {
            HOME_TEAM: homeTeamQuarterPoints, AWAY_TEAM: awayTeamQuarterPoints
        } = GameQuarter;

        homeTeamPoints = homeTeamQuarterPoints;
        awayTeamPoints = awayTeamQuarterPoints;

    }


    return (
        <Col xs={3} lg={3} className={"game-result-quarter game-result-cell" + (!GameQuarter ? " disabled" : "")}>

            <span className={"quarter-number cell-name"}>{`${gameQuarterNumber} ${QUARTER}`}</span>

            <span className={"quarter-result cell-value"}>
                <span className={+homeTeamPoints > +awayTeamPoints ? "winner" : ""}>{homeTeamPoints}</span>
                <span>{` : `}</span>
                <span className={+awayTeamPoints > +homeTeamPoints ? "winner" : ""}>{awayTeamPoints}</span>
            </span>

            <span onClick={() => GameQuarter ? selectedQuartersHandler(gameQuarterNumber) : null} className={"checkbox" + (SelectedQuarters.includes(gameQuarterNumber) ? " checked" : "")}>
                {!!SelectedQuarters.includes(gameQuarterNumber) && <AiOutlineCheck />}
            </span>

        </Col>
    );

};


export default GameSummaryQuarterComponent;
