import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";

import {
    AiOutlineMinus, AiOutlinePlus
} from "react-icons/all";


import "./index.scss";


export interface AddTeamSquadPlayerHeaderComponentProps {
    showAddTeamPlayerForm: boolean;
    showAddTeamPlayerFormHandler: (status: boolean) => void;
}


export const AddTeamSquadPlayerHeaderComponent: React.FC<AddTeamSquadPlayerHeaderComponentProps> = ({
    showAddTeamPlayerForm, showAddTeamPlayerFormHandler
}) : JSX.Element | null => {


    const PLAYER_ADD_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_ADD_PHRASE_TEXT")


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__header"}>
            <span onClick={() => showAddTeamPlayerFormHandler(!showAddTeamPlayerForm)} className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__header__switcher"}>
                <span className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__header__switcher__text"}>{PLAYER_ADD_PHRASE_TEXT}</span>
                <span className={"player-profile__captain-zone__team__leagues__list__league__players__add-player__header__switcher__icon"}>
                    {showAddTeamPlayerForm ? <AiOutlineMinus /> : <AiOutlinePlus />}
                </span>
            </span>
        </Col>
    );

};


export default AddTeamSquadPlayerHeaderComponent;
