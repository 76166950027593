import React from "react";


import { Col, Row } from "react-bootstrap";


import GameProgressHeaderTeamComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Progress/Header/Team";
import GameProgressHeaderSeparatorComponent from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Progress/Header/Separator";


import ApiGameSummaryTeamInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team";


import "./index.scss";


interface GameProgressHeaderComponentProps {
    HomeTeam: ApiGameSummaryTeamInterface;
    AwayTeam: ApiGameSummaryTeamInterface;
}


export const GameProgressHeaderComponent: React.FC<GameProgressHeaderComponentProps> = ({
    HomeTeam, AwayTeam
}) : JSX.Element | null => {


    return (
        <Col xs={12} className={"game-result-details__progress__header"}>
            <Row className={"game-result-details__progress__header__content"}>

                <GameProgressHeaderTeamComponent
                    GameTeam={HomeTeam}
                    gameTeamType={"HOME"}
                />

                <GameProgressHeaderSeparatorComponent />

                <GameProgressHeaderTeamComponent
                    GameTeam={AwayTeam}
                    gameTeamType={"AWAY"}
                />

            </Row>
        </Col>
    )

};


export default GameProgressHeaderComponent;
