import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface TeamInformationTeamPhotoComponentProps {
    teamImageURL?: string | null;
}


export const TeamInformationTeamPhotoComponent: React.FC<TeamInformationTeamPhotoComponentProps> = ({
    teamImageURL
}) : JSX.Element | null => {


    return (
        <Col xs={12} lg={4} className={"team-details__team-information__team-photo" + (!teamImageURL ? " empty" : "")}>
            <Row as={"picture"} className={"team-details__team-information__team-photo__picture"}>
                {!!teamImageURL && <img src={teamImageURL} alt={``} className={"team-details__team-information__team-photo__picture__image"} />}
            </Row>
        </Col>
    );

};


export default TeamInformationTeamPhotoComponent;
