import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import RoundBestPlayerHeaderComponent from "@ResultsPages/Rounds/Round/BestPlayer/Header";
import RoundBestPlayerPlayerComponent from "@ResultsPages/Rounds/Round/BestPlayer/Player";


import {
    LeagueRoundBestPlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ResultsListRoundBestPlayerComponentProps {
    BestPlayer: LeagueRoundBestPlayerInterface | null;
}


export const ResultsListRoundBestPlayerComponent: React.FC<ResultsListRoundBestPlayerComponentProps> = ({
    BestPlayer
}) => {


    if (!BestPlayer) {
        return null;
    }


    return (
        <Col xs={12} lg={3} className={"leagues-schedules__league-rounds-list__league_round__best-player"}>
            <Row className={"leagues-schedules__league-rounds-list__league_round__best-player__content"}>

                <RoundBestPlayerHeaderComponent />

                <RoundBestPlayerPlayerComponent BestPlayer={BestPlayer} />

            </Row>
        </Col>
    );

};


export default ResultsListRoundBestPlayerComponent;
