import React, {useEffect, useState} from "react";
import {DefaultPageHeaderComponent, PageContainerComponent, PageContentComponent} from "@ContentElements";
import {FormComponent, FormSubmitFieldComponent, FormTextFieldComponent} from "@FormsElements";
import {Col, Row} from "react-bootstrap";
import {NavLink, Redirect} from "react-router-dom";
import {FormFieldValueErrorInterface} from "@CommonFormsTypes";
import isEmail from "validator/es/lib/isEmail";
import {UseSetPopupMessageAction} from "@StatesActions";
import _restApiRequest from "@Services/HTTP/Requests/Request";
import {API_REGISTER_NEW_PLAYER_ENDPOINT_URL} from "@Services/API/Endpoints";
import {UseLoggedUser} from "@States";
import {UseRoute} from "@Hooks/Routes";
import {UseCommonDictionaryPhrasesSet, UseErrorMessage} from "@Hooks/Texts";
import {CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_PL} from "@Dictionary";


export interface RegisterPageComponentProps {}


export const RegisterPageComponent: React.FC<RegisterPageComponentProps> = ({}) : JSX.Element | null => {


    const UseSetPopupMessage = UseSetPopupMessageAction();

    const LoggedUser = UseLoggedUser();

    const ProfileRoute = UseRoute("PROFILE");


    const REQUEST_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT");

    const CREATE_ACCOUNT_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CREATE_ACCOUNT_PHRASE_TEXT");
    const LOGIN_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("LOGIN_PHRASE_TEXT");

    const FIRST_NAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("FIRST_NAME_WORD_TEXT");
    const LAST_NAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("LAST_NAME_WORD_TEXT");
    const EMAIL_ADDRESS_WORD_TEXT = UseCommonDictionaryPhrasesSet("EMAIL_ADDRESS_WORD_TEXT");

    const FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT");
    const LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT");
    const EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT");
    const EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT");

    const CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT");


    interface FormErrorsInterface {
        userFirstName?: FormFieldValueErrorInterface;
        userLastName?: FormFieldValueErrorInterface;
        userEmailAddress?: FormFieldValueErrorInterface;
    }

    const [FormErrors, setFormErrors] = useState<FormErrorsInterface>({});

    useEffect(() => {
        return () => {
            setFormErrors({});
        };
    }, []);


    const [userFirstName, setUserFirstName] = useState("");
    const [userLastName, setUserLastName] = useState("");
    const [userEmailAddress, setUserEmailAddress] = useState("");

    useEffect(() => {
        return () => {
            setUserFirstName("");
            setUserLastName("");
            setUserEmailAddress("");
        };
    }, []);

    if (LoggedUser) {
        return <Redirect to={ProfileRoute.routeURL} />;
    }

    const changeUserFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userFirstName = e.target.value;
        if (userFirstName.length > 2) {
            setFormErrors({ ...FormErrors, userFirstName: undefined });
        }
        setUserFirstName(userFirstName);
    };

    const changeUserLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userLastName = e.target.value;
        if (userLastName.length > 2) {
            setFormErrors({ ...FormErrors, userLastName: undefined });
        }
        setUserLastName(userLastName);
    };

    const changeUserEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const userEmailAddress = e.target.value;
        if (isEmail(userEmailAddress)) {
            setFormErrors({ ...FormErrors, userEmailAddress: undefined });
        }
        setUserEmailAddress(userEmailAddress);
    };


    const formSubmitHandler = () => {

        const FormErrors: FormErrorsInterface = {};

        if (userFirstName.length < 3) {
            FormErrors.userFirstName = { errorMessage: FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT };
        }
        if (userLastName.length < 3) {
            FormErrors.userLastName = { errorMessage: LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT };
        }
        if (!isEmail(userEmailAddress)) {
            FormErrors.userEmailAddress = { errorMessage: EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT };
        }

        setFormErrors(FormErrors);

        if (Object.entries(FormErrors).length) {
            return;
        }

        submitRequestHandler();

    };

    const submitRequestHandler = async () => {

        const formData = new FormData();

        formData.append("player_first_name", userFirstName);
        formData.append("player_last_name", userLastName);
        formData.append("player_email_address", userEmailAddress);
        formData.append("send_confirmation_email", "1");

        try {

            const response = await _restApiRequest(
                API_REGISTER_NEW_PLAYER_ENDPOINT_URL, formData, "POST"
            );

            if (response.ok) {
                UseSetPopupMessage({ type: "SUCCESS", text: CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT });
                setUserFirstName("");
                setUserLastName("");
                setUserEmailAddress("");
                setFormErrors({});
            } else {
                if (response.status === 406) {
                    UseSetPopupMessage({ type: "ERROR", text: EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT });
                } else {
                    UseSetPopupMessage({ type: "ERROR", text: REQUEST_ERROR_MESSAGE_TEXT });
                }
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: REQUEST_ERROR_MESSAGE_TEXT });
        }

    };


    return (
        <PageContainerComponent id={`register-page`} cssClasses={`register-page register-page-component`}>

            <DefaultPageHeaderComponent
                headerText={CREATE_ACCOUNT_PHRASE_TEXT}
            />

            <PageContentComponent cssClasses={"register-page__content"}>

                <FormComponent cssClasses={"user-form register-form"}>

                    <FormTextFieldComponent
                        fieldValue={userFirstName}
                        fieldValueHandler={changeUserFirstName}
                        fieldPlaceholder={FIRST_NAME_WORD_TEXT}
                        fieldError={FormErrors?.userFirstName}
                    />

                    <FormTextFieldComponent
                        fieldValue={userLastName}
                        fieldValueHandler={changeUserLastName}
                        fieldPlaceholder={LAST_NAME_WORD_TEXT}
                        fieldError={FormErrors?.userLastName}
                    />

                    <FormTextFieldComponent
                        fieldType={"email"}
                        fieldValue={userEmailAddress}
                        fieldValueHandler={changeUserEmailAddress}
                        fieldPlaceholder={EMAIL_ADDRESS_WORD_TEXT}
                        fieldError={FormErrors?.userEmailAddress}
                    />

                    <FormSubmitFieldComponent
                        buttonText={CREATE_ACCOUNT_PHRASE_TEXT}
                        buttonClickHandler={formSubmitHandler}
                    />

                    <Col xs={12}>
                        <Row style={{ justifyContent: "right" }}>
                            <NavLink to={"/logowanie"}>
                                <span>{LOGIN_PHRASE_TEXT}</span>
                            </NavLink>
                        </Row>
                    </Col>

                </FormComponent>

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default RegisterPageComponent;
