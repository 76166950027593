import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import MessageComponent from "@Components/Elements/Messages/Content";


import PlayedGamesHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/PlayedGames/Header";
import PlayedGamesListComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/PlayedGames/Games";


const TEXTS: any = {
    PL: {message_no_player_games_text_text: "Brak rozegranych meczów",},
    EN: {message_no_player_games_text_text: "No pleayed games",},
}


import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


interface PlayerDetailsPlayedGamesComponentProps {
    playerGames: SortedBySeasonGamesLeagueInterface[] | null;
}


export const PlayerDetailsPlayedGamesComponent: React.FC<PlayerDetailsPlayedGamesComponentProps> = ({
    playerGames
}) : JSX.Element | null => {


    const activeLanguageCode = UseLanguage();

    const {
        message_no_player_games_text_text: MESSAGE_NO_PLAYER_GAMES_TEXT
    } = TEXTS[activeLanguageCode];


    const [selectedLeagueID, setSelectedLeagueID] = useState<number | null>(null);
    const [selectedLeague, setSelectedLeague] = useState<SortedBySeasonGamesLeagueInterface | null>(null);

    useEffect(() => {
        if (!playerGames || !playerGames.length) return;
        setSelectedLeagueID(playerGames[0].gamesLeagueID || null);
    }, []);

    useEffect(() => {
        if (!selectedLeagueID) return;
        setSelectedLeague(
            playerGames?.filter(
                (League) => League.gamesLeagueID === selectedLeagueID
            )[0] || null
        );
    }, [selectedLeagueID]);


    if (!playerGames) return <MainContentLoaderComponent loaderColor={"#FFF"} />;


    if (!playerGames.length) return <MessageComponent messageText={MESSAGE_NO_PLAYER_GAMES_TEXT} cssClasses={"white"} />;


    return (
        <Col xs={12} className={"player-details-played-games player-details-played-games-component"}>
            <Row className={"player-details-played-games-content"}>

                <PlayedGamesHeaderComponent
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={setSelectedLeagueID}
                    selectedLeague={selectedLeague}
                    leaguesGames={playerGames}
                />

                {!!selectedLeague &&
	            <PlayedGamesListComponent
		            leagueGames={selectedLeague}
	            />
                }

            </Row>
        </Col>
    );

};


export default PlayerDetailsPlayedGamesComponent;
