import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import LeagueTablesGroupTableTeamComponent from "@LeaguesPages/Tables/Leagues/Tables/Group/Table/Team";
import LeagueTablesGroupTableHeaderComponent from "@LeaguesPages/Tables/Leagues/Tables/Group/Table/Header";


import {
    LeagueTablesTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeagueTablesGroupTableComponentProps {
    GroupTable: LeagueTablesTeamInterface[];
}


export const LeagueTablesGroupTableComponent: React.FC<LeagueTablesGroupTableComponentProps> = ({
    GroupTable
}) : JSX.Element | null => {


    if (!GroupTable) {
        return null;
    }


    const GroupTableTeams = GroupTable.map((Team, i) =>
        <LeagueTablesGroupTableTeamComponent key={i} tablePosition={i + 1} Team={Team} />
    );


    return (
        <Col xs={12} className={"league-tables__group__table"}>
            <Row className={"league-tables__group__table__content"}>

                <LeagueTablesGroupTableHeaderComponent />

                {GroupTableTeams}

            </Row>
        </Col>
    );


};


export default LeagueTablesGroupTableComponent;
