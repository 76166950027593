import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";
import ApiGameSummaryQuartersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters";

import DiagramValuesType from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Types/Values";
import _ from "lodash";


export const formatGameResultsDiagramValues = (
    SelectedQuarters: ApiGameSummaryQuarterType[],
    GameQuarters: ApiGameSummaryQuartersInterface,
    homeTeamID: number, awayTeamID: number
) : [DiagramValuesType, number] | null => {

    if (!SelectedQuarters || !GameQuarters || !homeTeamID || !awayTeamID) return null;


    SelectedQuarters.sort();


    const DiagramValues: DiagramValuesType = {};


    let homeTeamPoints = 0;
    let awayTeamPoints = 0;

    let biggestHomeTeamPointsAdvantage = 0;
    let biggestAwayTeamPointsAdvantage = 0;


    for (let quarterNumber of SelectedQuarters) {

        const QuarterData = GameQuarters[quarterNumber];

        if (!QuarterData) continue;

        DiagramValues[quarterNumber] = [];

        const { events_list: QuarterEvents } = QuarterData;

        const Events = _.sortBy(QuarterEvents, ["league_game_event_idx"]);

        for (let Event of Events) {

            const {
                league_game_event_type: eventType
            } = Event;

            if (!["1PT", "2PT", "3PT"].includes(eventType)) continue;

            const { team_id: teamID, league_game_event_time: eventTime } = Event;

            if (!teamID || !eventTime) continue;

            let eventPoints = 0;

            switch (eventType) {
                case "1PT": eventPoints = 1; break;
                case "2PT": eventPoints = 2; break;
                case "3PT": eventPoints = 3; break;
            }


            if (teamID === homeTeamID) {
                homeTeamPoints += eventPoints;
            } else if (teamID === awayTeamID) {
                awayTeamPoints += eventPoints;
            }

            if (homeTeamPoints > awayTeamPoints) {
                // @ts-ignore
                DiagramValues[quarterNumber].push({
                    scoreValue: homeTeamPoints - awayTeamPoints,
                    scoreTime: eventTime,
                    leadingTeamID: homeTeamID,
                });

                if (biggestHomeTeamPointsAdvantage < homeTeamPoints - awayTeamPoints) {
                    biggestHomeTeamPointsAdvantage = homeTeamPoints - awayTeamPoints;
                }

            }
            else if (awayTeamPoints > homeTeamPoints) {
                // @ts-ignore
                DiagramValues[quarterNumber].push({
                    scoreValue: (awayTeamPoints - homeTeamPoints) * -1,
                    scoreTime: eventTime,
                    leadingTeamID: awayTeamID,
                });

                if (biggestAwayTeamPointsAdvantage < awayTeamPoints - homeTeamPoints) {
                    biggestAwayTeamPointsAdvantage = awayTeamPoints - homeTeamPoints;
                }

            } else {
                // @ts-ignore
                DiagramValues[quarterNumber].push({
                    scoreValue: 0,
                    scoreTime: eventTime,
                    leadingTeamID: 0,
                });
            }

        }

    }


    let biggestAdvantage = 0;

    if (biggestHomeTeamPointsAdvantage > biggestAwayTeamPointsAdvantage) {
        biggestAdvantage = biggestHomeTeamPointsAdvantage;
    } else if (biggestAwayTeamPointsAdvantage > biggestHomeTeamPointsAdvantage) {
        biggestAdvantage = biggestAwayTeamPointsAdvantage;
    }

    return [DiagramValues, biggestAdvantage];

};


export default formatGameResultsDiagramValues;
