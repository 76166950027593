import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleTitleComponentProps {
    Article: ArticleInterface;
}

export const ArticleTitleComponent: React.FC<ArticleTitleComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    const {
        article_title: articleTitle,
    } = Article;


    return (
        <Col xs={12} className={"article-detail__content__header__image-and-title__title"}>
            <Row className={"article-detail__content__header__image-and-title__title__content"}>
                <span className={"article-title-text"}>{articleTitle}</span>
            </Row>
        </Col>
    );

}


export default ArticleTitleComponent;
