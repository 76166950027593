import { Dispatch } from "redux";

import { AuthenticationActionTypes } from "@Action-Types";
import { AuthenticationAction } from "@Actions";


export const UseLogUserOut = () => {

    return (dispatch: Dispatch<AuthenticationAction>) => {
        dispatch({
            type: AuthenticationActionTypes.LOG_USER_OUT
        });
    };

};


export default UseLogUserOut;
