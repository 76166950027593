import React from "react";


import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";


import { AiFillFacebook, AiFillYoutube } from "react-icons/all";


import {
    LeagueGameInterface
} from "@Interfaces/Api";


import dateFormat from "dateformat";


import "./index.scss";


export interface ResultsListRoundGameComponentProps {
    LeagueGame: LeagueGameInterface | null;
}


export const ResultsListRoundGameComponent: React.FC<ResultsListRoundGameComponentProps> = ({
    LeagueGame
}) => {


    if (!LeagueGame) {
        return null;
    }


    const {
        league_game_id: GAME_ID,
        league_game_status: gameStatus,
        league_game_home_team_data: HomeTeamData,
        league_game_home_team_points: homeTeamPoints,
        league_game_away_team_data: AwayTeamData,
        league_game_away_team_points: awayTeamPoints,
        league_game_date: leagueGameDate,
        league_game_fb_stream: leagueGameFbStream, league_game_yt_stream: leagueGameYtStream,
        league_game_arena_data: LeagueGameArena
    } = LeagueGame;


    const {
        team_name: homeTeamName, team_slug: homeTeamSlug
    } = HomeTeamData || {};

    const {
        team_name: awayTeamName, team_slug: awayTeamSlug
    } = AwayTeamData || {};


    let gameArenaDescription = "";

    if (LeagueGameArena) {
        const {
            league_arena_name: gameArenaName, league_arena_village: gameArenaVillage
        } = LeagueGameArena;
        gameArenaDescription = gameArenaName + ", " + gameArenaVillage;
    }

    return (
        <Row className={"results-list-game leagues-schedules__league-rounds-list__league_round__games-list__game"}>

            <Col xs={5} lg={4} className={"game-home-team-name" + (homeTeamPoints > awayTeamPoints ? " winner" : "")}>
                <NavLink to={`/druzyny/${homeTeamSlug || ""}`}>
                    <span>{homeTeamName || "-"}</span>
                </NavLink>
            </Col>

            <Col xs={2} lg={1} className={"game-teams-points"}>
                <NavLink to={`/mecze/${GAME_ID}`}>
                    <span className={"home-team-points"}>{homeTeamPoints || (gameStatus !== "FT_WAL" ? "-" : homeTeamPoints)}</span>
                    <span className={"points-separator"}>{":"}</span>
                    <span className={"away-team-points"}>{awayTeamPoints || (gameStatus !== "FT_WAL" ? "-" : awayTeamPoints)}</span>
                </NavLink>
            </Col>

            <Col xs={5} lg={4} className={"game-away-team-name" + (awayTeamPoints > homeTeamPoints ? " winner" : "")}>
                <NavLink to={`/druzyny/${awayTeamSlug || ""}`}>
                    <span>{awayTeamName || "-"}</span>
                </NavLink>
            </Col>

            <Col xs={12} lg={1} className={"game-arena"}>
                <span>{gameArenaDescription}</span>
            </Col>

            <Col xs={12} lg={2} className={"game-date-time order-first"}>

                {!!leagueGameDate &&
					<NavLink to={`/mecze/${GAME_ID}`}>
						<span>{dateFormat(leagueGameDate, "dd.mm.yyyy HH:MM")}</span>
					</NavLink>
                }
                {!!leagueGameYtStream &&
					<a href={leagueGameYtStream} className={"social-stream-link"} title={"YT Stream"} target={"_blank"} rel={"noreferrer nopener"}>
						<AiFillYoutube color={"#FF0000"} />
					</a>
                }
                {!!leagueGameFbStream &&
					<a href={leagueGameFbStream} className={"social-stream-link"} title={"FB Stream"} target={"_blank"} rel={"noreferrer nopener"}>
						<AiFillFacebook color={"var(--secondary-color)"} />
					</a>
                }

            </Col>

        </Row>
    );

};


export default ResultsListRoundGameComponent;
