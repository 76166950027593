import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { formatDate } from "@Services/Utilities/Tools/Formatters";


import { Col, Row } from "react-bootstrap";

import { AiOutlineCheck } from "react-icons/all";


import "./index.scss";


import {
    LeagueRoundInterface,
    LeagueGameQuartersInterface, LeagueGameQuarterNumberType,
} from "@Interfaces/Api";



type GameSummaryInformationProps = {
    gameDate: string | Date | null;
    gameRound: LeagueRoundInterface | null;
    leagueGameQuarters: LeagueGameQuartersInterface | null;
    selectedQuarters: LeagueGameQuarterNumberType[];
    selectedQuartersHandler(quarter: LeagueGameQuarterNumberType): void;
};


export const GameSummaryInformationComponent: React.FC<GameSummaryInformationProps> = ({
    gameDate, gameRound,
    leagueGameQuarters, selectedQuarters, selectedQuartersHandler
}) : JSX.Element | null => {


    const EXTRA_TIME = UseGamesDictionaryPhrasesSet("EXTRA_TIME_SHORT");
    const ROUND_NUMBER = UseGamesDictionaryPhrasesSet("ROUND_NUMBER");
    const DATE = UseGamesDictionaryPhrasesSet("DATE");


    let gameRoundNumber: number | null = null;

    if (gameRound) {
        const { league_round_number: roundNumber } = gameRound;
        if (roundNumber) {
            gameRoundNumber = roundNumber;
        }
    }


    return (
        <Col xs={12} lg={{span: 4, offset: 6}} className={"game-result-game-information game-result-game-information-component"}>
            <Row className={"game-result-game-information-content"}>

                <Col xs={4} lg={3} className={"result-information-extra-time game-result-cell"}>
                    <span className={"result-information-label"}>{EXTRA_TIME}</span>
                    {leagueGameQuarters && leagueGameQuarters[5] &&
                    <span className={"result-information-value"}>{leagueGameQuarters[5].HOME_TEAM} : {leagueGameQuarters[5].AWAY_TEAM}</span>
                    }
                    {leagueGameQuarters && leagueGameQuarters[5] &&
                    <span onClick={() => selectedQuartersHandler(5)} className={"checkbox" + (selectedQuarters.includes(5) ? " checked" : "")}>
                        {!!selectedQuarters.includes(5) && <AiOutlineCheck />}
                    </span>
                    }
                </Col>

                <Col xs={4} lg={4} className={"result-information-game-round-number game-result-cell"}>
                    <span className={"result-information-label"}>{ROUND_NUMBER}</span>
                    {!!gameRoundNumber &&
	                <span className={"result-information-value"}>{gameRoundNumber}</span>
                    }
                </Col>

	            <Col xs={4} lg={5} className={"result-information-game-date game-result-cell"}>
                    <span className={"result-information-label"}>{DATE}</span>
                    {!!gameDate &&
		            <span className={"result-information-value"}>{formatDate(new Date(gameDate), "dd.mm.yyyy HH:MM")}</span>
                    }
	            </Col>

            </Row>
        </Col>
    );

};


export default GameSummaryInformationComponent;
