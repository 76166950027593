import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import LeagueGameEventType from "@ApiInterfaces/Leagues/Games/Game/Events/Event/EventType";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterPointsEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterPointsEventDataComponent: React.FC<QuarterPointsEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.POINTS) {
        return null;
    }


    if (!Event.eventThrowingPlayerID) {
        return null;
    }


    let throwEventSubType: LeagueGameEventType | null = null;

    if (Event.eventThrowType === 1) {
        throwEventSubType = "1PT";
    } else if (Event.eventThrowType === 2) {
        throwEventSubType = "2PT";
    } else if (Event.eventThrowType === 3) {
        throwEventSubType = "3PT";
    } else {
        return null;
    }

    const PointsEvent = UseProgressEvent(throwEventSubType);
    const AssistEvent = UseProgressEvent("AST");

    if (!PointsEvent) {
        return null;
    }

    const PointsEventPlayer = GamePlayers[Event.eventThrowingPlayerID];

    if (!PointsEventPlayer) {
        return null;
    }

    const {
        player_first_name: pointingPlayerFirstName,
        player_last_name: pointingPlayerLastName,
        player_nick_name: pointingPlayerNickName,
        player_slug: pointingPlayerSlug,
        player_pseudonymization_status: pointingPlayerPseudonymizationStatus
    } = PointsEventPlayer;

    let throwingPlayerName = "";

    if (Event.eventThrowingPlayerNumber) {
        throwingPlayerName += `${Event.eventThrowingPlayerNumber} `;
    }

    if (pointingPlayerPseudonymizationStatus) {
        throwingPlayerName += pointingPlayerNickName;
    } else {
        throwingPlayerName += `${pointingPlayerFirstName[0]}. ${pointingPlayerLastName}`
    }


    const {
        eventName: { singular: pointsEventName, label: pointsEventLabel },
        eventIcon: pointsEventIcon
    } = PointsEvent;


    let AssistingPlayerData: React.ReactNode = null;

    if (Event.eventAssistingPlayerID && AssistEvent) {

        const PointsEventAssistingPlayer = GamePlayers[Event.eventAssistingPlayerID];

        if (PointsEventAssistingPlayer) {

            const {
                player_first_name: assistingPlayerFirstName,
                player_last_name: assistingPlayerLastName,
                player_nick_name: assistingPlayerNickName,
                player_slug: assistingPlayerSlug,
                player_pseudonymization_status: assistingPlayerPseudonymizationStatus
            } = PointsEventAssistingPlayer;

            let assistingPlayerName = "";

            if (Event.eventAssistingPlayerNumber) {
                assistingPlayerName += `${Event.eventAssistingPlayerNumber} `;
            }

            if (assistingPlayerPseudonymizationStatus) {
                assistingPlayerName += assistingPlayerNickName;
            } else {
                assistingPlayerName += `${assistingPlayerFirstName[0]}. ${assistingPlayerLastName}`
            }

            const {
                eventName: { singular: assistEventName, label: assistEventLabel  }
            } = AssistEvent;



            AssistingPlayerData = [
                <span key={0} className={"event-name"}>{assistEventName}</span>,
                <NavLink  key={1} to={`/${PlayersRoute.routeURL}/${assistingPlayerSlug}`} title={PlayersRoute.routeTitle} className={"event-player-name"}>
                    <span>{assistingPlayerName}</span>
                </NavLink>,
                <span key={2} className={"event-number"}>({Event.eventAssistingPlayerAssistNumber} {assistEventLabel})</span>
            ];

        }

    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--points-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventThrowingPlayerTeamID ? 0 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>

                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content throwing-player"}>
                        {pointsEventIcon && <img src={pointsEventIcon} className={"event-icon"} alt={""} />}
                        <NavLink to={`/${PlayersRoute.routeURL}/${pointingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                            <span className={"event-player-name"}>{throwingPlayerName}</span>
                        </NavLink>
                        <span className={"event-label"}>{pointsEventName}</span>
                        <span className={"event-number"}>({Event.eventThrowingPlayerPointsNumber} {pointsEventLabel})</span>
                        {/*{Event?.eventDescription?.text && <span className={"event-description"}>{Event?.eventDescription?.text}</span>}*/}
                    </Row>

                    {AssistingPlayerData &&
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content assisting-player"}>
                        {AssistingPlayerData}
                    </Row>
                    }

                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    pointsEvent={true}
                    gamePoints={Event.eventTeamsPoints}
                    cssClasses={homeTeamID === Event.eventThrowingPlayerTeamID ? "" : "offset-4"}
                />

            </Row>
        </Col>
    );

};


export default QuarterPointsEventDataComponent;
