import { useEffect, useState } from "react";


import _getDataFromApi from "@Services/API/Requests";

import { API_ARTICLES_LIST_ENDPOINT_URL } from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import { ArticleInterface } from "@Interfaces/Api";


export const UseArticlesList = () : [ArticleInterface[] | null, ErrorMessageInterface | null] => {


    const [Articles, setArticles] = useState<ArticleInterface[] | null>(null);
    const [ArticlesError, setArticlesError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        const getArticles = async () => {
            await _getDataFromApi(
                API_ARTICLES_LIST_ENDPOINT_URL,
                setArticles, setArticlesError
            );
        };

        getArticles();

        return () => {
            setArticles(null);
            setArticlesError(null);
        };

    }, []);

    return [Articles, ArticlesError];

};


export default UseArticlesList;
