import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import HeaderPLKALogoComponent from "@Components/Views/Parts/Header/PlkaLogo";
import HeaderLeagueLogoComponent from "@Components/Views/Parts/Header/LeagueLogo";
import HeaderNavigationComponent from "@Components/Views/Parts/Header/Navigation";
import HeaderSocialsComponent from "@Components/Views/Parts/Header/Socials";
import HeaderLanguagesComponent from "@Components/Views/Parts/Header/Languages";
import HeaderUserZoneComponent from "@Components/Views/Parts/Header/UserZone";


import "./index.scss";


export const ApplicationHeaderComponent = () => {


    const {globalData} = useTypedSelector((state) => state.app);


    return (
        <ContainerSectionComponent as={"header"} id={"application-header-component"}>

            <HeaderLeagueLogoComponent
                globalData={globalData}
            />

            <HeaderPLKALogoComponent />

            <HeaderUserZoneComponent xs={2} lg={1} />

            <HeaderLanguagesComponent />

            <HeaderNavigationComponent as={"nav"} xs={{span: 1, offset: 1}} lg={8} />

            <HeaderSocialsComponent xs={{ span: 6, offset: 6 }} lg={{ span: 2, offset: 0 }} />

        </ContainerSectionComponent>
    );

};


export default ApplicationHeaderComponent;
