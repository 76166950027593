export const formatSummaryTableScore = (score?: number, label = "") => {

    if (!score) return "";

    if (label) {
        return `${score} ${label}`;
    }

    return score;

};


export default formatSummaryTableScore;
