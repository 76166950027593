import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";

import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    LeagueTablesTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeaguesTablesGroupTableTeamComponentProps {
    tablePosition: number;
    Team: LeagueTablesTeamInterface;
}


export const LeaguesTablesGroupTableTeamComponent: React.FC<LeaguesTablesGroupTableTeamComponentProps> = ({
    tablePosition, Team
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");

    const POINTS_WORD_SHORT_TEXT = UseCommonDictionaryPhrasesSet("POINTS_WORD_SHORT_TEXT");


    if (!Team) {
        return null;
    }


    const {
        team_played_games: teamGames, team_wins: teamWins, team_lost: teamDefeats,
        team_points: teamPoints,
        team_league_team_data: TeamData,
        team_league_team_name: teamLeagueName, team_league_team_short_name: teamLeagueShortName
    } = Team;

    const {
        team_slug: teamSlug, team_logo: teamLogo,
        team_name: teamMainName, team_short_name: teamMainShortName
    } = TeamData;

    const teamName = teamLeagueName || teamMainName;
    const teamShortName = teamLeagueShortName || teamMainShortName;


    const teamURL = "/" + TeamsRoute.routeURL + "/" + teamSlug;


    return (
        <Col xs={12} className={"home-page__leagues__tables__tables__group__table__team"}>
            <Row className={"home-page__leagues__tables__tables__group__table__team__content"}>

                <Col xs={1} className={"team-table-position"}>
                    <span>{tablePosition}</span>
                </Col>

                <Col xs={2} className={"team-logo"}>
                    {!!teamLogo &&
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle} className={"team-logo__link"}>
                        <img src={teamLogo} alt={teamShortName || ""} className={"team-logo__image"}/>
                    </NavLink>
                    }
                </Col>

                <Col xs={5} className={"team-name"}>
                    <NavLink to={teamURL} title={TeamsRoute.routeTitle} className={"team-name__link text-overflow"}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <Col xs={1} className={"team-played-games"}>
                    <span>{teamGames}</span>
                </Col>

                <Col xs={1} className={"team-games-balance"}>
                    <span>{teamWins} - {teamDefeats}</span>
                </Col>

                <Col xs={2} className={"team-table-points"}>
                    <span>{teamPoints} {POINTS_WORD_SHORT_TEXT}</span>
                </Col>

            </Row>
        </Col>
    );


};


export default LeaguesTablesGroupTableTeamComponent;
