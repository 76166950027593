import React, { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_PLAYER_ADD_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Button, Col, Row
} from "react-bootstrap";


import {
    FormTextFieldComponent
} from "@FormsElements";

import {
    FormFieldValueErrorInterface
} from "@CommonFormsTypes";


import isEmail from "validator/es/lib/isEmail";


import "./index.scss";


export interface TeamPlayersAddPlayerFormComponentProps {
    teamID: number;
    playerAddStatusTrigger: () => void;
}


export const TeamPlayersAddPlayerFormComponent: React.FC<TeamPlayersAddPlayerFormComponentProps> = ({
    teamID, playerAddStatusTrigger
}) : JSX.Element | null => {


    const UsePopupMessage = UseSetPopupMessageAction();


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT");
    const PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT");
    const PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT");

    const PLAYER_ADD_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("PLAYER_ADD_PHRASE_TEXT");

    const FIRST_NAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("FIRST_NAME_WORD_TEXT");
    const FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT");
    const LAST_NAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("LAST_NAME_WORD_TEXT");
    const LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT");
    const EMAIL_ADDRESS_WORD_TEXT = UseCommonDictionaryPhrasesSet("EMAIL_ADDRESS_WORD_TEXT");
    const EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT");
    const NUMBER_WORD_TEXT = UseCommonDictionaryPhrasesSet("NUMBER_WORD_TEXT");
    const NUMBER_DEFAULT_ERROR_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("NUMBER_DEFAULT_ERROR_MESSAGE_TEXT");


    interface FormErrorsInterface {
        teamPlayerFirstName?: FormFieldValueErrorInterface;
        teamPlayerLastName?: FormFieldValueErrorInterface;
        teamPlayerEmailAddress?: FormFieldValueErrorInterface;
        teamPlayerShirtNumber?: FormFieldValueErrorInterface;
    }

    const [FormErrors, setFormErrors] = useState<FormErrorsInterface>({});


    const [teamPlayerFirstName, setTeamPlayerFirstName] = useState("");
    const [teamPlayerLastName, setTeamPlayerLastName] = useState("");
    const [teamPlayerEmailAddress, setTeamPlayerEmailAddress] = useState("");
    const [teamPlayerShirtNumber, setTeamPlayerShirtNumber] = useState("");

    useEffect(() => {
        return () => {
            setTeamPlayerFirstName("");
            setTeamPlayerLastName("");
            setTeamPlayerEmailAddress("");
            setTeamPlayerShirtNumber("");
        };
    }, []);

    const changeTeamPlayerFirstName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 1 && FormErrors.teamPlayerFirstName) {
            setFormErrors({ ...FormErrors, teamPlayerFirstName: undefined });
        }
        setTeamPlayerFirstName(value);
    };

    const changeTeamPlayerLastName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value.length > 2 && FormErrors.teamPlayerLastName) {
            setFormErrors({ ...FormErrors, teamPlayerLastName: undefined });
        }
        setTeamPlayerLastName(value);
    };

    const changeTeamPlayerEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (isEmail(value) && FormErrors.teamPlayerEmailAddress) {
            setFormErrors({ ...FormErrors, teamPlayerEmailAddress: undefined });
        }
        setTeamPlayerEmailAddress(value);
    };

    const changeTeamPlayerShirtNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;
        if (value && +value) {
            const numberValue = +value;
            if (numberValue < 1 || numberValue > 99) {
                return;
            }
        }
        setTeamPlayerShirtNumber(value);
    };


    const addTeamPlayerHandler = () => {

        const FormErrors: FormErrorsInterface = {};

        if (teamPlayerFirstName.length < 2) {
            FormErrors.teamPlayerFirstName = { errorMessage: FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT };
        }

        if (teamPlayerLastName.length < 3) {
            FormErrors.teamPlayerLastName = { errorMessage: LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT };
        }

        if (!isEmail(teamPlayerEmailAddress)) {
            FormErrors.teamPlayerEmailAddress = { errorMessage: EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT };
        }

        if (teamPlayerShirtNumber) {
            const shirtNumber = +teamPlayerShirtNumber;
            if (!shirtNumber || shirtNumber > 99 || shirtNumber < 1) {
                FormErrors.teamPlayerShirtNumber = { errorMessage: NUMBER_DEFAULT_ERROR_MESSAGE_TEXT };
            }
        }

        setFormErrors(FormErrors);

        if (Object.values(FormErrors).length) {
            return;
        }

        addTeamPlayerRequestHandler();

    };


    const addTeamPlayerRequestHandler = async () => {

        const API_ENDPOINT_URL = API_TEAM_PLAYER_ADD_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString());


        const formData = new FormData();

        formData.append("player_first_name", teamPlayerFirstName.trim());
        formData.append("player_last_name", teamPlayerLastName.trim());
        formData.append("player_email_address", teamPlayerEmailAddress.toLowerCase());
        formData.append("player_shirt_number", teamPlayerShirtNumber.trim());

        try {

            const response = await _restApiRequest(
                API_ENDPOINT_URL, formData, "POST"
            );

            if (response.status === 200) {
                UsePopupMessage({ type: "SUCCESS", text: PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT });
                playerAddStatusTrigger();
            } else if (response.status === 204) {
                UsePopupMessage({ type: "DEFAULT", text: PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT });
            } else {
                UsePopupMessage({ type: "ERROR", text: DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT });
            }

        } catch (e) {
            UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
        }

    };


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__player-add__player-form"}>
            <Row className={"player-profile__captain-zone__team__players__player-add__player-form__content"}>

                <Col xs={12} lg={3} className={"player-profile__captain-zone__team__players__player-add__player-form__field"}>
                    <FormTextFieldComponent
                        fieldLabel={FIRST_NAME_WORD_TEXT}
                        fieldValue={teamPlayerFirstName}
                        fieldValueHandler={changeTeamPlayerFirstName}
                        fieldError={FormErrors.teamPlayerFirstName}
                    />
                </Col>

                <Col xs={12} lg={3} className={"player-profile__captain-zone__team__players__player-add__player-form__field"}>
                    <FormTextFieldComponent
                        fieldLabel={LAST_NAME_WORD_TEXT}
                        fieldValue={teamPlayerLastName}
                        fieldValueHandler={changeTeamPlayerLastName}
                        fieldError={FormErrors.teamPlayerLastName}
                    />
                </Col>

                <Col xs={12} lg={4} className={"player-profile__captain-zone__team__players__player-add__player-form__field"}>
                    <FormTextFieldComponent
                        fieldType={"email"}
                        fieldLabel={EMAIL_ADDRESS_WORD_TEXT}
                        fieldValue={teamPlayerEmailAddress}
                        fieldValueHandler={changeTeamPlayerEmailAddress}
                        fieldError={FormErrors.teamPlayerEmailAddress}
                    />
                </Col>

                <Col xs={12} lg={2} className={"player-profile__captain-zone__team__players__player-add__player-form__field"}>
                    <FormTextFieldComponent
                        fieldType={"number"}
                        fieldLabel={NUMBER_WORD_TEXT}
                        fieldValue={teamPlayerShirtNumber}
                        fieldValueHandler={changeTeamPlayerShirtNumber}
                        fieldError={FormErrors.teamPlayerShirtNumber}
                    />
                </Col>

                <Col xs={12} className={"player-profile__captain-zone__team__players__player-add__player-form__submit"}>
                    <Button onClick={addTeamPlayerHandler}>
                        <span>{PLAYER_ADD_PHRASE_TEXT}</span>
                    </Button>
                </Col>

            </Row>
        </Col>
    );

};


export default TeamPlayersAddPlayerFormComponent;
