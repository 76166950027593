import React from "react";


import {Container, Row, Col, Alert} from "react-bootstrap";


export type DefaultMessageComponentProps = {
    messageText: string;
    messageType?: "DEFAULT" | "ERROR" | "SUCCESS";
    cssClasses?: string | string[];
    xs?: number | {span: number, offset: number}; lg?: number | {span: number, offset: number};
};


export const DefaultMessageComponent: React.FC<DefaultMessageComponentProps> = ({
    messageText, messageType = "DEFAULT",
    cssClasses = "",
    xs = 12, lg = 12
}) : JSX.Element | null => {


    if (!messageText) return null;


    let messageVariant: string = "info";


    switch (messageType) {
        case "ERROR":
            messageVariant = "danger";
            break;
        case "SUCCESS":
            messageVariant = "success";
            break;
    }


    if (typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    cssClasses = "system-message-component" + (cssClasses ? ` ${cssClasses}` : "");

    return (
        <Container fluid={true} className={cssClasses}>
            <Container className={"system-message-container"}>
                <Row className={"system-message-content"}>

                    <Col xs={xs} lg={lg} as={Alert} variant={messageVariant} className={"system-message"}>
                        <span>{messageText}</span>
                    </Col>

                </Row>
            </Container>
        </Container>
    );

};


export default DefaultMessageComponent;
