import React from "react";


import { Col, Row } from "react-bootstrap";


import { LeagueInterface } from "@Interfaces/Api";


export interface LeaguesSwitcherLeagueComponentProps {
    League: LeagueInterface;
    selectedLeagueIDHandler: (leagueID: number) => void;
}


export const LeaguesSwitcherLeagueComponent: React.FC<LeaguesSwitcherLeagueComponentProps> = ({
    League, selectedLeagueIDHandler
}) => {


    if (!League) {
        return null;
    }


    const {
        league_id: leagueID, league_name: leagueName
    } = League;

    return (
        <Col xs={12} className={"leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__leagues-list__league"} onClick={() => selectedLeagueIDHandler(leagueID)}>
            <Row className={"leagues-schedules__leagues-and-rounds-switcher__leagues-switcher__leagues-list__league__content"}>
                <span>{leagueName}</span>
            </Row>
        </Col>
    );


};


export default LeaguesSwitcherLeagueComponent;
