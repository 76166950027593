import React from "react";


import { Col } from "react-bootstrap";


export interface SchedulesAdminLeagueScheduleComponentProps {
    leagueScheduleType: 1 | 2 | undefined;
    leagueScheduleStatus: boolean | undefined;
}


export const SchedulesAdminLeagueScheduleComponent: React.FC<SchedulesAdminLeagueScheduleComponentProps> = ({
    leagueScheduleType, leagueScheduleStatus
}) : JSX.Element | null => {


    if (!leagueScheduleType) {
        return null;
    }


    const STATUS1 = "Wygenerowany";
    const STATUS2 = "Niewygenerowany";


    const TYPES = {
        1: "Pojedyncze Mecze", 2: "Mecze i rewanze"
    }


    const typeLabel = TYPES[leagueScheduleType];
    const scheduleStatusLabel = leagueScheduleStatus ? STATUS1 : STATUS2;


    return (
        <Col xs={2}>
            <span>{typeLabel}</span>
            <span>{scheduleStatusLabel}</span>
        </Col>
    );


};


export default SchedulesAdminLeagueScheduleComponent;
