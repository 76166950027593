export * from "@Hooks/Api/Requests/Games/Teams/Team/IdBySlug";
export * from "@Hooks/Api/Requests/Games/Teams/Team/NextGames";


import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_FULL_INFORMATION_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    LeagueTeamInterface
} from "@Interfaces/Api";


export const UseTeamFullInformation = (teamID: number | null, players= false, leagues= false, statistics= false, next_games = 0, prev_games = 0) : [LeagueTeamInterface | null, ErrorMessageInterface | null] => {

    const [Team, setTeam] = useState<LeagueTeamInterface | null>(null)
    const [TeamError, setTeamError] = useState<ErrorMessageInterface | null>(null)

    useEffect(() => {

        if (teamID === 0) {
            return;
        }

        if (!teamID) {
            return setTeamError({ errorCode: 400, errorMessage: "" });
        }

        const requestParams = [];

        if (players) {
            requestParams.push("get_players=1");
        }

        if (leagues) {
            requestParams.push("get_leagues=1");
        }

        if (statistics) {
            requestParams.push("get_statistics=1");
        }

        if (prev_games) {
            requestParams.push(`get_previous_games=${prev_games}`);
        }

        if (next_games) {
            requestParams.push(`get_next_games=${next_games}`);
        }

        let API_EP_URL = `${API_TEAM_FULL_INFORMATION_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString())}`;

        if (requestParams.length) {
            API_EP_URL += `?${requestParams.join("&")}`
        }


        const getTeam = async () => {

            try {
                const response = await _restApiRequest(API_EP_URL);
                if (response.ok) {
                    const responseContent = await response.json();
                    setTeam(responseContent);
                } else {
                    setTeamError({ errorCode: response.status, errorMessage: "" });
                }
            } catch (e) {
                setTeamError({ errorCode: 500, errorMessage: "" });
            }

        };

        getTeam();



    }, [teamID]);

    useEffect(() => {
        return () => {
            setTeam(null);
            setTeamError(null);
        };
    }, []);

    return [Team, TeamError];

};


export default UseTeamFullInformation;
