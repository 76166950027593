import React, {useEffect, useState} from "react";
import LeaguesTablesLeaguesComponent from "@LeaguesPages/Tables/Leagues";
import LeagueInterface from "../../../../../../../Services/Interfaces/Api/Leagues/Leagues/League";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import {UseLeaguesList} from "@Services/Utilities/ApiHooks";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import MainContentLoaderComponent from "@LoadersElements/MainContent";
import MessageComponent from "@MessagesElements/Content";
import _mapLeaguesBySeasons from "@LeaguesPages/_Utilities/MapLeaguesBySeasons";
import {UseErrorMessage, UseLeaguesDictionaryPhrasesSet} from "@Hooks/Texts";
import {SingleContentLoaderComponent} from "@LoadersElements";


export interface LeaguesTablesContentComponentProps {
    inheritedSelectedSeasonID: number | undefined;
    inheritedSelectedLeagueID: number | undefined;
}

export const LeaguesTablesContentComponent: React.FC<LeaguesTablesContentComponentProps> = ({
    inheritedSelectedSeasonID, inheritedSelectedLeagueID
}) : JSX.Element | null => {


    const DEFAULT_DATA_REQUEST_ERROR = UseErrorMessage("DEFAULT_DATA_REQUEST_ERROR");
    const NO_LEAGUES_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_LEAGUES_MESSAGE_TEXT");


    const [Leagues, setLeagues] = useState<LeagueInterface[] | null>(null);
    const [LeaguesError, setLeaguesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        /** Get Leagues in active Season */
        UseLeaguesList(
            setLeagues, setLeaguesError, false
        );

        /** Clear States after unmount */
        return () => {
            setLeagues(null);
            setLeaguesError(null);
        };

    }, []);


    if (LeaguesError) {
        return <ErrorMessageComponent messageText={DEFAULT_DATA_REQUEST_ERROR} />;
    }

    if (!Leagues) {
        return <SingleContentLoaderComponent />;
    }

    if (!Leagues.length) {
        return <MessageComponent messageText={NO_LEAGUES_MESSAGE_TEXT} cssClasses={"margin-top-15"} />;
    }


    const MappedLeaguesBySeasons = _mapLeaguesBySeasons(Leagues);


    return (
        <LeaguesTablesLeaguesComponent
            inheritedSelectedSeasonID={inheritedSelectedSeasonID}
            inheritedSelectedLeagueID={inheritedSelectedLeagueID}
            MappedLeaguesBySeasons={MappedLeaguesBySeasons}
        />
    );

};


export default LeaguesTablesContentComponent;
