import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {NavLink} from "react-router-dom";

import {Col, Row} from "react-bootstrap";


import NumberFormat from "react-number-format";


import PlayerStatisticsInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Statistics/Season/Player";
import {formatMinutesFromSeconds} from "@Services/Utilities/Tools/Formatters";
import {UseStatisticsDictionaryPhrasesSet} from "@Hooks/Texts";
import {UsePlayersDictionaryPhrasesSet} from "@Hooks/Texts/Pages";


interface StatisticsTableDataComponentProps {
    tableData: PlayerStatisticsInterface;
    tableType: "SUMMARY" | "MEDIUM";
}


export const StatisticsTableDataComponent: React.FC<StatisticsTableDataComponentProps> = ({
    tableData, tableType
}) : JSX.Element | null => {


    if (!tableData) return null;


    const {
        SEASON, TEAM,
        PLAYER_GAMES, PLAYER_S5_GAMES, PLAYER_SECONDS, PLAYER_POINTS,
        PLAYER_2P_THROWS, PLAYER_ACC_2P_THROWS, PLAYER_2P_THROWS_EFFECTIVITY,
        PLAYER_3P_THROWS, PLAYER_ACC_3P_THROWS, PLAYER_3P_THROWS_EFFECTIVITY,
        PLAYER_GAME_THROWS, PLAYER_ACC_GAME_THROWS, PLAYER_GAME_THROWS_EFFECTIVITY,
        PLAYER_1P_THROWS, PLAYER_ACC_1P_THROWS, PLAYER_1P_THROWS_EFFECTIVITY,
        PLAYER_OFFENSIVE_REBOUNDS, PLAYER_DEFENSIVE_REBOUNDS, PLAYER_TOTAL_REBOUNDS,
        PLAYER_ASSISTS, PLAYER_BLOCKS, PLAYER_STEALS,
        PLAYER_TURNOVERS, PLAYER_FOULS,
        PLAYER_EFFECTIVITY, PLAYER_EVAL
    } = tableData;

    let teamName = TEAM ? TEAM["team_name"] : "-";
    let teamSlug = TEAM ? TEAM["team_slug"] : "";


    const playerPlayedMinutes = formatMinutesFromSeconds(PLAYER_SECONDS);


    const PLAYED_GAMES_SHORT_LABEL = UsePlayersDictionaryPhrasesSet("PLAYED_GAMES_SHORT_LABEL");
    const PLAYED_GAMES_TITLE = UsePlayersDictionaryPhrasesSet("PLAYED_GAMES_TITLE");

    const START5_GAMES_SHORT_LABEL = UsePlayersDictionaryPhrasesSet("START5_GAMES_SHORT_LABEL");
    const START5_GAMES_TITLE = UsePlayersDictionaryPhrasesSet("START5_GAMES_TITLE");

    const POINTS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("POINTS_SHORT_LABEL");
    const POINTS_TITLE = UseStatisticsDictionaryPhrasesSet("POINTS_TITLE");

    const MINUTES_LABEL = UseStatisticsDictionaryPhrasesSet("MINUTES_LABEL");
    const MINUTES_TITLE = UseStatisticsDictionaryPhrasesSet("MINUTES_TITLE");

    const THROWS_1PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_SHORT_LABEL");
    const THROWS_1PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_TITLE");
    const THROWS_1PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PTA_SHORT_LABEL");
    const THROWS_1PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PTA_TITLE");
    const THROWS_1PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_SHORT_LABEL");
    const THROWS_1PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_TITLE");

    const THROWS_2PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_SHORT_LABEL");
    const THROWS_2PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_TITLE");
    const THROWS_2PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PTA_SHORT_LABEL");
    const THROWS_2PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PTA_TITLE");
    const THROWS_2PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_SHORT_LABEL");
    const THROWS_2PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_TITLE");

    const THROWS_3PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_SHORT_LABEL");
    const THROWS_3PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_TITLE");
    const THROWS_3PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PTA_SHORT_LABEL");
    const THROWS_3PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PTA_TITLE");
    const THROWS_3PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_SHORT_LABEL");
    const THROWS_3PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_TITLE");

    const THROWS_GAME_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_SHORT_LABEL");
    const THROWS_GAME_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_TITLE");
    const THROWS_GAME_A_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_A_SHORT_LABEL");
    const THROWS_GAME_A_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_A_SHORT_LABEL");
    const THROWS_GAME_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_SHORT_LABEL");
    const THROWS_GAME_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_TITLE");

    const DEFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_SHORT_LABEL");
    const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
    const OFFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_SHORT_LABEL");
    const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
    const TOTAL_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_SHORT_LABEL");
    const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");

    const ASSISTS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("ASSISTS_SHORT_LABEL");
    const ASSISTS_TITLE = UseStatisticsDictionaryPhrasesSet("ASSISTS_TITLE");
    const BLOCKS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("BLOCKS_SHORT_LABEL");
    const BLOCKS_TITLE = UseStatisticsDictionaryPhrasesSet("BLOCKS_TITLE");
    const STEALS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("STEALS_SHORT_LABEL");
    const STEALS_TITLE = UseStatisticsDictionaryPhrasesSet("STEALS_TITLE");

    const TURNOVERS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TURNOVERS_SHORT_LABEL");
    const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
    const FOULS_LABEL = UseStatisticsDictionaryPhrasesSet("FOULS_LABEL");
    const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");

    const EFFECTIVITY_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_SHORT_LABEL");
    const EFFECTIVITY_TITLE = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_TITLE");
    const EVAL_LABEL = UseStatisticsDictionaryPhrasesSet("EVAL_LABEL");
    const EVAL_TITLE = UseStatisticsDictionaryPhrasesSet("EVAL_TITLE");


    return (
        <Col xs={12} className={"statistics-table-data statistics-table-data-row statistics-table-row statistics-table-data-component"}>
            <Row className={"statistics-table-data-content statistics-table-row-content"}>

	            <Col xs={3} lg={"auto"} className={"table-data-cell statistics-table-cell season-name-data"}>
                    {!!SEASON && <span className={"statistic-value"}>{SEASON}</span>}
	            </Col>

	            <Col xs={4} lg={"auto"} className={"table-data-cell statistics-table-cell team-name-data"}>
		            <NavLink to={`/druzyny/${teamSlug}`} onClick={(e) => !teamSlug ? e.preventDefault() : null} className={"statistic-value"}>
                        {!!teamName && <span>{teamName}</span>}
                    </NavLink>
	            </Col>

                <Col xs={1} lg={"auto"} title={PLAYED_GAMES_TITLE} className={"table-data-cell statistics-table-cell games-number-data"}>
                    <span className={"statistic-label"}>{PLAYED_GAMES_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>{PLAYER_GAMES}</span>
                </Col>

                <Col xs={1} lg={"auto"} title={START5_GAMES_TITLE} className={"table-data-cell statistics-table-cell start-5-data"}>
                    <span className={"statistic-label"}>{START5_GAMES_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_S5_GAMES} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={tableType === "MEDIUM"} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={2} lg={"auto"} title={MINUTES_TITLE} className={"table-data-cell statistics-table-cell minutes-number-data"}>
                    <span className={"statistic-label"}>{MINUTES_LABEL}</span>
                    <span className={"statistic-value"}>{playerPlayedMinutes}</span>
                </Col>

                <Col xs={1} lg={"auto"} title={POINTS_TITLE} className={"table-data-cell statistics-table-cell points-number-data"}>
                    <span className={"statistic-label"}>{POINTS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_POINTS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={tableType === "MEDIUM"} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={3} lg={1} title={`${THROWS_2PT_TITLE} / ${THROWS_2PTA_TITLE} / ${THROWS_2PT_EFF_TITLE}`} className={"table-data-cell statistics-table-cell pt2-number-data"}>
                    <span className={"statistic-label"}>{THROWS_2PT_SHORT_LABEL} / {THROWS_2PTA_SHORT_LABEL} / {THROWS_2PT_EFF_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_ACC_2P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_2P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_2P_THROWS_EFFECTIVITY} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                    </span>
               </Col>

                <Col xs={3} lg={1} title={`${THROWS_3PT_TITLE} / ${THROWS_3PTA_TITLE} / ${THROWS_3PT_EFF_TITLE}`} className={"table-data-cell statistics-table-cell pt3-number-data"}>
                    <span className={"statistic-label"}>{THROWS_3PT_SHORT_LABEL} / {THROWS_3PTA_SHORT_LABEL} / {THROWS_3PT_EFF_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_ACC_3P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_3P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_3P_THROWS_EFFECTIVITY} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                    </span>
                </Col>


                <Col xs={3} lg={1} title={`${THROWS_GAME_TITLE} / ${THROWS_GAME_A_TITLE} / ${THROWS_GAME_EFF_TITLE}`} className={"table-data-cell statistics-table-cell pt-game-number-data"}>
                    <span className={"statistic-label"}>{THROWS_GAME_SHORT_LABEL} / {THROWS_GAME_A_SHORT_LABEL} / {THROWS_GAME_EFF_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_ACC_GAME_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_GAME_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_GAME_THROWS_EFFECTIVITY} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                    </span>
                </Col>

                <Col xs={3} lg={1} title={`${THROWS_1PT_TITLE} / ${THROWS_1PTA_TITLE} / ${THROWS_1PT_EFF_TITLE}`} className={"table-data-cell statistics-table-cell ft-number-data"}>
                    <span className={"statistic-label"}>{THROWS_1PT_SHORT_LABEL} / {THROWS_1PTA_SHORT_LABEL} / {THROWS_1PT_EFF_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_ACC_1P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_1P_THROWS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_1P_THROWS_EFFECTIVITY} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%
                    </span>
                </Col>

                <Col xs={3} lg={1} title={`${DEFENSIVE_REBOUNDS_TITLE} / ${OFFENSIVE_REBOUNDS_TITLE} / ${TOTAL_REBOUNDS_TITLE}`} className={"table-data-cell statistics-table-cell rebounds-number-data"}>
                    <span className={"statistic-label"}>{DEFENSIVE_REBOUNDS_SHORT_LABEL} / {OFFENSIVE_REBOUNDS_SHORT_LABEL} / {TOTAL_REBOUNDS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_DEFENSIVE_REBOUNDS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_OFFENSIVE_REBOUNDS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        <span className={"separator"}></span>
                        <NumberFormat value={PLAYER_TOTAL_REBOUNDS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={1} lg={"auto"} title={ASSISTS_TITLE} className={"table-data-cell statistics-table-cell assists-number-data"}>
                    <span className={"statistic-label"}>{ASSISTS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_ASSISTS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={1} lg={"auto"} title={BLOCKS_TITLE} className={"table-data-cell statistics-table-cell blocks-number-data"}>
                    <span className={"statistic-label"}>{BLOCKS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_BLOCKS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={1} lg={"auto"} title={STEALS_TITLE} className={"table-data-cell statistics-table-cell steals-number-data"}>
                    <span className={"statistic-label"}>{STEALS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_STEALS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={1} lg={"auto"} title={TURNOVERS_TITLE} className={"table-data-cell statistics-table-cell turnovers-number-value"}>
                    <span className={"statistic-label"}>{TURNOVERS_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_TURNOVERS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={1} lg={"auto"} title={FOULS_TITLE} className={"table-data-cell statistics-table-cell fouls-number-data"}>
                    <span className={"statistic-label"}>{FOULS_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_FOULS} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={2} lg={"auto"} title={EFFECTIVITY_TITLE} className={"table-data-cell statistics-table-cell effectivity-value-data"}>
                    <span className={"statistic-label"}>{EFFECTIVITY_SHORT_LABEL}</span>
                    <span className={"statistic-value"}>
                        {PLAYER_EFFECTIVITY > 0 && <span>+</span>}
                        <NumberFormat value={PLAYER_EFFECTIVITY} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

                <Col xs={2} lg={"auto"} title={EVAL_TITLE} className={"table-data-cell statistics-table-cell eval-value-data"}>
                    <span className={"statistic-label"}>{EVAL_LABEL}</span>
                    <span className={"statistic-value"}>
                        <NumberFormat value={PLAYER_EVAL} decimalScale={tableType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                    </span>
                </Col>

            </Row>
        </Col>
    );


};


export default StatisticsTableDataComponent;
