import React from "react";


import {
    UseHomePageSlides
} from "@Hooks/Api";


import HomePageMainSlider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


import {
    MainContentLoaderComponent
} from "@LoadersElements";


import HomePageSlideComponent from "@Pages/Common/HomePage/Slider/Slide";


import "./index.scss";


const SLIDER_SETTINGS = {
    dots: false, arrows: false, infinite: true, speed: 1000,
    slidesToShow: 1, slidesToScroll: 1, initialSlide: 0,
    autoplay: true, autoplaySpeed: 5000, pauseOnHover: true,
};


export interface HomePageSliderComponentProps {}


export const HomePageSliderComponent: React.FC<HomePageSliderComponentProps> = ({

}) : JSX.Element | null => {


    const [HomePageSlides, HomePageSlidesError] = UseHomePageSlides();


    if (HomePageSlidesError) {
        return null;
    }

    if (!HomePageSlides) {
        return <MainContentLoaderComponent />;
    }

    if (!HomePageSlides?.length) {
        return null;
    }


    const HomePageSlidesList = HomePageSlides.map((HomePageSlide, i) =>
        <HomePageSlideComponent key={i} HomePageSlide={HomePageSlide} />
    );


    return (
        <HomePageMainSlider {...SLIDER_SETTINGS} className={"home-page__slider"}>

            {HomePageSlidesList}

        </HomePageMainSlider>
    );

};


export default HomePageSliderComponent;
