import { UseLanguage } from "@States";


import StatisticsDictionary, { StatisticsDictionaryPhrasesSetType } from "@Dictionary/Sets/Statistics";


export const UseStatisticsDictionaryPhrasesSet = (phraseName: StatisticsDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return StatisticsDictionary[ActiveLanguage][phraseName];

};


export default UseStatisticsDictionaryPhrasesSet;
