import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";


export interface QuarterReboundEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterTeamReboundEventDataComponent: React.FC<QuarterReboundEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event
}) : JSX.Element | null => {


    if (!Event || Event.eventType !== GameProgressEventTypes.TEAM_REBOUND) {
        return null;
    }

    if (!Event.eventReboundTeamID) {
        return null;
    }

    const OffensiveTeamReboundEvent = UseProgressEvent("TEAM_O_REB");
    const DefensiveTeamReboundEvent = UseProgressEvent("TEAM_D_REB");

    let reboundEventName;

    if (Event.eventReboundType === "O") {
        reboundEventName = OffensiveTeamReboundEvent?.eventName?.singular;
    } else if (Event.eventReboundType === "D") {
        reboundEventName = DefensiveTeamReboundEvent?.eventName?.singular;
    }

    if (!reboundEventName) {
        return null;
    }

    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--team-rebound-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} style={{ order: homeTeamID === Event.eventReboundTeamID ? 1 : 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {reboundEventName && <span className={"event-label"}>{reboundEventName}</span>}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={[homeTeamID === Event.eventReboundTeamID ? "" : "offset-4", "order-2"]}
                />

            </Row>
        </Col>
    );

};


export default QuarterTeamReboundEventDataComponent;
