import React from "react";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


import { LeagueTeamInterface } from "@Interfaces/Api";


export interface GameDetailsAdminHeaderTeamComponentProps {
    GameTeam: LeagueTeamInterface;
    gameTeamPoints: number | null;
    gameTeamType: "HOME" | "AWAY";
}


export const GameDetailsAdminHeaderTeamComponent: React.FC<GameDetailsAdminHeaderTeamComponentProps> = ({
    GameTeam, gameTeamPoints, gameTeamType
}) => {


    if (!GameTeam) {
        return null;
    }


    const {
        team_name: gameTeamName, team_logo: gameTeamLogo
    } = GameTeam;


    return (
        <Col xs={6} lg={{ span: 2, offset: gameTeamType === "HOME" ? 3 : 2 }} className={"game-details-admin__header__team"}>
            <Row className={"game-details-admin__header__team__content"}>

                <Col xs={12} className={"game-details-admin__header__team__team-logo"}>
                    <span>
                        {gameTeamLogo && <img src={gameTeamLogo} alt={``} />}
                    </span>
                </Col>

                <Col xs={12} className={"game-details-admin__header__team__team-name"}>
                    <span>{gameTeamName}</span>
                </Col>

                <Col xs={12} className={"game-details-admin__header__team__team-points"}>
                    <span>{gameTeamPoints || 0}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameDetailsAdminHeaderTeamComponent;
