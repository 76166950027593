export * from "@Dictionary/Sets";


import DictionaryType from "@Dictionary/_Types";


export const Dictionary: DictionaryType = {
    PL: {
        pages_regulations_page_title: "Regulamin",
        pages_regulations_page_not_accepted_phrase: "Nie zaakceptowano Regulaminu",
        pages_regulations_page_accept_phrase: "Zaakceptuj Regulamin",
        pages_privacy_policy_page_title: "Polityka Prywatności",
        pages_privacy_policy_page_not_accepted_phrase: "Nie zaakceptowano Polityki Prywatności",
        pages_privacy_policy_page_accept_phrase: "Zaakceptuj Politykę Prywatności",
        headers_download_page_header_text: "Do pobrania",
        headers_login_page_header_text: "Logowanie",
        headers_set_password_page_header_text: "Ustaw hasło",
        formals_i_accept_phrase: "Akceptuję",
        formals_date_of_acceptation_text: "Data akceptacji",
        formals_not_accepted_phrase: "Nie zaakceptowano",
        user_profile_button_title: "Mój Profil",
        user_login_button_title: "Zaloguj",
        user_logout_button_title: "Wyloguj się",
        word_first_name_text: "imię",
        word_last_name_text: "nazwisko",
        word_nick_name_text: "ksywka",
        word_email_text: "email",
        word_pesel_text: "pesel",
        word_edit_text: "edycja",
        word_save_text: "zapisz",
        word_abort_text: "anuluj",
        word_add_text: "dodaj",
        word_league_text: "liga",
        word_schedule_text: "Terminarz",
        word_round_text: "kolejka",
        word_players_text: "zawodnicy",
        word_player_text: "zawodnik",
        word_referee_text: "sędzia",
        word_referees_text: "sędziowie",
        word_commissioner_text: "komisarz",
        word_audience_text: "widzowie",
        word_spectators_text: "widzów",
        word_trainer_text: "trener",
        forms_fields_user_type_label_text: "rodzaj użytkownika",
        forms_fields_password_label_text: "hasło",
        forms_fields_new_password_label_text: "nowe hasło",
        forms_fields_repeat_password_label_text: "powtórz password",
        forms_buttons_login_text: "zaloguj",
        forms_buttons_set_password_text: "ustaw hasło",
        link_go_to_league_page_text: "Przejdź do tabeli ligowej",
        link_go_to_player_page_text: "Przejdź do strony zawodnika",
        link_download_word_text: "pobierz",
        link_click_word_text: "kliknij",
        team_data_team_statistics_label_text: "Drużynowe",
        team_data_team_points_label_text: "Punkty zespołu",
        team_data_opponents_points_label_text: "Punkty rywali",
        team_data_team_assists_label_text: "Asysty",
        team_data_team_rebounds_label_text: "Zbiórki",
        team_data_team_balance_label_text: "Bilans",
        team_data_team_last_game_label_text: "Ostatni mecz",
        team_data_team_next_game_label_text: "Następny mecz",
        statistics_medium_label_text: "Średnie",
        statistics_summary_label_text: "Sumaryczne",
        statistics_points_label_text: "Punkty",
        statistics_eval_label_text: "Eval",
        statistics_assists_label_text: "Asysty",
        statistics_rebounds_label_text: "Zbiórki",
        statistics_steals_label_text: "Przechwyty",
        statistics_blocks_label_text: "Bloki",
        no_files_for_download_message_text: "Brak plików do pobrania",
    },
    EN: {
        pages_regulations_page_title: "Regulations",
        pages_regulations_page_not_accepted_phrase: "Regulations not accepted",
        pages_regulations_page_accept_phrase: "Accept Regulations",
        pages_privacy_policy_page_title: "Privacy Policy",
        pages_privacy_policy_page_not_accepted_phrase: "Privacy Policy not accepted",
        pages_privacy_policy_page_accept_phrase: "Accept Privacy Policy",
        headers_download_page_header_text: "Download Page",
        headers_login_page_header_text: "Login",
        headers_set_password_page_header_text: "Set Password",
        formals_i_accept_phrase: "Accept",
        formals_date_of_acceptation_text: "Date of acceptation",
        formals_not_accepted_phrase: "Not accepted",
        user_profile_button_title: "My Profile",
        user_login_button_title: "Login",
        user_logout_button_title: "Log out",
        word_first_name_text: "first name",
        word_last_name_text: "last name",
        word_nick_name_text: "pseudo",
        word_email_text: "email",
        word_pesel_text: "pesel",
        word_edit_text: "edit",
        word_save_text: "save",
        word_abort_text: "abort",
        word_add_text: "add",
        word_league_text: "league",
        word_schedule_text: "Schedule",
        word_round_text: "round",
        word_players_text: "players",
        word_player_text: "player",
        word_referee_text: "referee",
        word_referees_text: "referees",
        word_commissioner_text: "commissioner",
        word_audience_text: "audience",
        word_spectators_text: "spectators",
        word_trainer_text: "coach",
        forms_fields_user_type_label_text: "user type",
        forms_fields_password_label_text: "password",
        forms_fields_new_password_label_text: "new password",
        forms_fields_repeat_password_label_text: "repeat password",
        forms_buttons_login_text: "login",
        forms_buttons_set_password_text: "set password",
        link_go_to_league_page_text: "Go to league table",
        link_go_to_player_page_text: "Go to player page",
        link_download_word_text: "Download",
        link_click_word_text: "click",
        team_data_team_statistics_label_text: "Team",
        team_data_team_points_label_text: "Team points",
        team_data_opponents_points_label_text: "Opponents points",
        team_data_team_assists_label_text: "Team assists",
        team_data_team_rebounds_label_text: "Team robuonds",
        team_data_team_balance_label_text: "Balance",
        team_data_team_last_game_label_text: "Last Game",
        team_data_team_next_game_label_text: "Next Game",
        statistics_medium_label_text: "Medium",
        statistics_summary_label_text: "Total",
        statistics_points_label_text: "Points",
        statistics_eval_label_text: "Eval",
        statistics_assists_label_text: "Assists",
        statistics_rebounds_label_text: "Rebounds",
        statistics_steals_label_text: "Steals",
        statistics_blocks_label_text: "Blocks",
        no_files_for_download_message_text: "No files for download",
    },
};


export default Dictionary;
