export type GameProgressEventType = ""


export enum GameProgressEventTypes {
    DEFAULT = "DEFAULT",
    POINTS = "POINTS",
    THROW = "THROW",
    REBOUND = "REBOUND",
    TEAM_REBOUND = "TEAM_REBOUND",
    TURNOVER = "TURNOVER",
    BLOCK = "BLOCK",
    SUBSTITUTION = "SUBSTITUTION",
    FOUL = "FOUL",
}


export default GameProgressEventTypes;
