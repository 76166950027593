import {
    LeaguePlayerInterface,
    LeaguePlayerNameInterface
} from "@Interfaces/Api";


export const generatePlayerName = (Player: LeaguePlayerInterface | LeaguePlayerNameInterface) : string => {

    if (!Player) {
        return "";
    }

    const {
        player_first_name: playerFirstName, player_last_name: playerLastName,
        player_nick_name: playerNickName, player_pseudonymization_status: playerPseudonymizationStatus,
    } = Player;

    let playerName = "*****";

    if (playerPseudonymizationStatus) {
        if (playerNickName) {
            playerName = `"${playerNickName}"`;
        }
    } else {
        playerName = `${playerFirstName} ${playerLastName}`;
    }

    return playerName;

};


export default generatePlayerName;
