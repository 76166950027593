import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


import BestScoresTableScoreComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/Score";
import BestScoresTableHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/Header";


import SortedBySeasonGamesTeamsInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/Teams";
import BestScoresInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/_Types/Scores";
import BestScoreInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/_Types/Scores/Score";
import {formatMinutesFromSeconds} from "@Services/Utilities/Tools/Formatters";


const TEXTS: any = {
    PL: {
        best_score_game_time_text: "Czas Gry",
        best_score_2pt_acc_text: "Celny rzut za 2",
        best_score_2pt_att_text: "Wykonywany rzut za 2",
        best_score_2pt_eff_text: "Skuteczność za 2",
        best_score_3pt_acc_text: "Celny rzut za 3",
        best_score_3pt_att_text: "Wykonywany rzut za 3",
        best_score_3pt_eff_text: "Skuteczność za 3",
        best_score_1pt_acc_text: "Celny rzut wolny",
        best_score_1pt_att_text: "Wykonywany rzut wolny",
        best_score_1pt_eff_text: "Skuteczność za 1",
        best_score_gt_acc_text: "Celny rzut z gry",
        best_score_gt_att_text: "Wykonywany rzut z gry",
        best_score_gt_eff_text: "Skuteczność z gry",
        best_score_points_text: "Punkty",
        best_score_off_reb_text: "Zbiórki w ataku",
        best_score_def_reb_text: "Zbiórki w obronie",
        best_score_tot_reb_text: "Zbiórki",
        best_score_assists_text: "Asysty",
        best_score_fouls_text: "Faule",
        best_score_fouled_text: "Faule wymuszone",
        best_score_turnovers_text: "Straty",
        best_score_steals_text: "Przechwyty",
        best_score_blocks_text: "Bloki",
        best_score_effectivity_text: "+/-",
        best_score_eval_text: "Eval"
    },
    EN: {
        best_score_game_time_text: "Game time",
        best_score_2pt_acc_text: "2 Points Accurate",
        best_score_2pt_att_text: "2 Points Attempts",
        best_score_2pt_eff_text: "2 Points Effectivity",
        best_score_3pt_acc_text: "3 Points Accurate",
        best_score_3pt_att_text: "3 Points Attempts",
        best_score_3pt_eff_text: "3 Points Effectivity",
        best_score_1pt_acc_text: "1 Point Accurate",
        best_score_1pt_att_text: "1 Point Attempt",
        best_score_1pt_eff_text: "1 Point Effectivity",
        best_score_gt_acc_text: "Game Throws Accurate",
        best_score_gt_att_text: "Game Throws Attempts",
        best_score_gt_eff_text: "Game Throws Effectivity",
        best_score_points_text: "Points",
        best_score_off_reb_text: "Offensive Rebounds",
        best_score_def_reb_text: "Defensive Rebounds",
        best_score_tot_reb_text: "Rebounds",
        best_score_assists_text: "Assists",
        best_score_fouls_text: "Fouls",
        best_score_fouled_text: "Fouled",
        best_score_turnovers_text: "Turnovers",
        best_score_steals_text: "Steals",
        best_score_blocks_text: "Blocks",
        best_score_effectivity_text: "Effectivity",
        best_score_eval_text: "Eval"
    },
};


export interface BestScoresTableComponentProps {
    playerTeams: SortedBySeasonGamesTeamsInterface;
}


export const BestScoresTableComponent: React.FC<BestScoresTableComponentProps> = ({
    playerTeams
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        best_score_game_time_text: BEST_SCORE_GAME_TIME_TEXT,
        best_score_2pt_acc_text: BEST_SCORE_2PT_ACC_TEXT,
        best_score_2pt_att_text: BEST_SCORE_2PT_ATT_TEXT,
        best_score_2pt_eff_text: BEST_SCORE_2PT_EFF_TEXT,
        best_score_3pt_acc_text: BEST_SCORE_3PT_ACC_TEXT,
        best_score_3pt_att_text: BEST_SCORE_3PT_ATT_TEXT,
        best_score_3pt_eff_text: BEST_SCORE_3PT_EFF_TEXT,
        best_score_1pt_acc_text: BEST_SCORE_1PT_ACC_TEXT,
        best_score_1pt_att_text: BEST_SCORE_1PT_ATT_TEXT,
        best_score_1pt_eff_text: BEST_SCORE_1PT_EFF_TEXT,
        best_score_gt_acc_text: BEST_SCORE_GT_ACC_TEXT,
        best_score_gt_att_text: BEST_SCORE_GT_ATT_TEXT,
        best_score_gt_eff_text: BEST_SCORE_GT_EFF_TEXT,
        best_score_points_text: BEST_SCORE_POINTS_TEXT,
        best_score_off_reb_text: BEST_SCORE_OFF_REB_TEXT,
        best_score_def_reb_text: BEST_SCORE_DEF_REB_TEXT,
        best_score_tot_reb_text: BEST_SCORE_TOT_REB_TEXT,
        best_score_assists_text: BEST_SCORE_AST_TEXT,
        best_score_fouls_text: BEST_SCORE_FOUL_TEXT,
        best_score_fouled_text: BEST_SCORE_FOULS_TEXT,
        best_score_turnovers_text: BEST_SCORE_TO_TEXT,
        best_score_steals_text: BEST_SCORE_STL_TEXT,
        best_score_blocks_text: BEST_SCORE_BLK_TEXT,
        best_score_effectivity_text: BEST_SCORE_EFF_TEXT,
        best_score_eval_text: BEST_SCORE_EVAL_TEXT
    } = TEXTS[activeLanguage];


    if (!playerTeams) return null;


    const PlayerSeasonBestScores: BestScoresInterface = {
        playerMinutes: null,
        player2ptAccurate: null,
        player2ptAttempts: null,
        player2ptEffectivity: null,
        player3ptAccurate: null,
        player3ptAttempts: null,
        player3ptEffectivity: null,
        player1ptAccurate: null,
        player1ptAttempts: null,
        player1ptEffectivity: null,
        playerGThrowsAccurate: null,
        playerGThrowsAttempts: null,
        playerGThrowsEffectivity: null,
        playerPoints: null,
        playerOffensiveRebounds: null,
        playerDefensiveRebounds: null,
        playerTotalRebounds: null,
        playerAssists: null,
        playerFouls: null,
        playerFouled: null,
        playerTurnovers: null,
        playerSteals: null,
        playerBlocks: null,
        playerBlocked: null,
        playerEval: null,
        playerEffectivity: null,
    };


    for (let [TeamID, TeamData] of Object.entries(playerTeams)) {

        const {teamGames} = TeamData;

        for (let Game of teamGames) {

            const {
                league_game_team_player_real_seconds: playerSeconds,
                league_game_team_player_2pt: player2pt,
                league_game_team_player_2pta: player2pta,
                league_game_team_player_3pt: player3pt,
                league_game_team_player_3pta: player3pta,
                league_game_team_player_ft: player1pt,
                league_game_team_player_fta: player1pta,
                league_game_team_player_rebound_off: playerOffReb,
                league_game_team_player_rebound_def: playerDefReb,
                league_game_team_player_ast: playerAst,
                league_game_team_player_foul: playerFoul,
                league_game_team_player_fouled: playerFouled,
                league_game_team_player_to: playerTo,
                league_game_team_player_stl: playerStl,
                league_game_team_player_blk: playerBlk,
                league_game_team_player_eff: playerEff,
                league_game_league_game: leagueGame
            } = Game;

            const GameData: BestScoreInterface = {
                playerTeamID: +TeamID,
                gameData: leagueGame
            };

            if (!!playerSeconds && (!PlayerSeasonBestScores.playerMinutes?.scoreValue || playerSeconds > PlayerSeasonBestScores.playerMinutes?.scoreValue)) {
                PlayerSeasonBestScores.playerMinutes = {scoreValue: playerSeconds, ...GameData};
            }

            const player2ptEff = (player2pt / player2pta) * 100;
            const player3ptEff = (player3pt / player3pta) * 100;
            const player1ptEff = (player1pt / player1pta) * 100;

            if (!!player2pt && (!PlayerSeasonBestScores.player2ptAccurate?.scoreValue || player2pt > PlayerSeasonBestScores.player2ptAccurate?.scoreValue)) {
                PlayerSeasonBestScores.player2ptAccurate = {scoreValue: player2pt, ...GameData};
            }
            if (!!player2pta && (!PlayerSeasonBestScores.player2ptAttempts?.scoreValue || player2pta > PlayerSeasonBestScores.player2ptAttempts?.scoreValue)) {
                PlayerSeasonBestScores.player2ptAttempts = {scoreValue: player2pta, ...GameData};
            }
            if (!!player2ptEff && (!PlayerSeasonBestScores.player2ptEffectivity?.scoreValue || player2ptEff > PlayerSeasonBestScores.player2ptEffectivity?.scoreValue)) {
                PlayerSeasonBestScores.player2ptEffectivity = {scoreValue: player2ptEff, scoreValueDescription: `(${player2pt}/${player2pta})`, ...GameData};
            }

            if (!!player3pt && (!PlayerSeasonBestScores.player3ptAccurate?.scoreValue || player3pt > PlayerSeasonBestScores.player3ptAccurate?.scoreValue)) {
                PlayerSeasonBestScores.player3ptAccurate = {scoreValue: player3pt, ...GameData};
            }
            if (!!player3pta && (!PlayerSeasonBestScores.player3ptAttempts?.scoreValue || player3pta > PlayerSeasonBestScores.player3ptAttempts?.scoreValue)) {
                PlayerSeasonBestScores.player3ptAttempts = {scoreValue: player3pta, ...GameData};
            }
            if (!!player3ptEff && (!PlayerSeasonBestScores.player3ptEffectivity?.scoreValue || player3ptEff > PlayerSeasonBestScores.player3ptEffectivity?.scoreValue)) {
                PlayerSeasonBestScores.player3ptEffectivity = {scoreValue: player3ptEff, scoreValueDescription: `(${player3pt}/${player3pta})` , ...GameData};
            }

            if (!!player1pt && (!PlayerSeasonBestScores.player1ptAccurate?.scoreValue || player1pt > PlayerSeasonBestScores.player1ptAccurate?.scoreValue)) {
                PlayerSeasonBestScores.player1ptAccurate = {scoreValue: player1pt, ...GameData};
            }
            if (!!player1pta && (!PlayerSeasonBestScores.player1ptAttempts?.scoreValue || player1pta > PlayerSeasonBestScores.player1ptAttempts?.scoreValue)) {
                PlayerSeasonBestScores.player1ptAttempts = {scoreValue: player1pta, ...GameData};
            }
            if (!!player1ptEff && (!PlayerSeasonBestScores.player1ptEffectivity?.scoreValue || player1ptEff > PlayerSeasonBestScores.player1ptEffectivity?.scoreValue)) {
                PlayerSeasonBestScores.player1ptEffectivity = {scoreValue: player1ptEff, scoreValueDescription: `(${player1pt}/${player1pta})` , ...GameData};
            }

            const playerAccGameThrows = player2pt + player3pt;
            const playerAllGameThrows = player2pta + player3pta;
            const playerEffGameThrows = (playerAccGameThrows / playerAllGameThrows) * 100;

            if (!!playerAccGameThrows && (!PlayerSeasonBestScores.playerGThrowsAccurate?.scoreValue || playerAccGameThrows > PlayerSeasonBestScores.playerGThrowsAccurate?.scoreValue)) {
                PlayerSeasonBestScores.playerGThrowsAccurate = {scoreValue: playerAccGameThrows, ...GameData};
            }
            if (!!playerAccGameThrows && (!PlayerSeasonBestScores.playerGThrowsAttempts?.scoreValue || playerAllGameThrows > PlayerSeasonBestScores.playerGThrowsAttempts?.scoreValue)) {
                PlayerSeasonBestScores.playerGThrowsAttempts = {scoreValue: playerAllGameThrows, ...GameData};
            }
            if (!!playerEffGameThrows && (!PlayerSeasonBestScores.playerGThrowsEffectivity?.scoreValue || playerEffGameThrows > PlayerSeasonBestScores.playerGThrowsEffectivity?.scoreValue)) {
                PlayerSeasonBestScores.playerGThrowsEffectivity = {scoreValue: playerEffGameThrows, ...GameData};
            }

            const playerPoints = 2 * player2pt + 3 * player3pt + player1pt;

            if (!!playerPoints && (!PlayerSeasonBestScores.playerPoints?.scoreValue || playerPoints > PlayerSeasonBestScores.playerPoints?.scoreValue)) {
                PlayerSeasonBestScores.playerPoints = {scoreValue: playerPoints, ...GameData};
            }

            const playerTotalReb = playerOffReb + playerDefReb;

            if (!!playerOffReb && (!PlayerSeasonBestScores.playerOffensiveRebounds?.scoreValue || playerOffReb > PlayerSeasonBestScores.playerOffensiveRebounds?.scoreValue)) {
                PlayerSeasonBestScores.playerOffensiveRebounds = {scoreValue: playerOffReb, ...GameData};
            }
            if (!!playerDefReb && (!PlayerSeasonBestScores.playerDefensiveRebounds?.scoreValue || playerDefReb > PlayerSeasonBestScores.playerDefensiveRebounds?.scoreValue)) {
                PlayerSeasonBestScores.playerDefensiveRebounds = {scoreValue: playerDefReb, ...GameData};
            }
            if (!!playerTotalReb && (!PlayerSeasonBestScores.playerTotalRebounds?.scoreValue || playerTotalReb > PlayerSeasonBestScores.playerTotalRebounds?.scoreValue)) {
                PlayerSeasonBestScores.playerTotalRebounds = {scoreValue: playerTotalReb, ...GameData};
            }

            if (!!playerAst && (!PlayerSeasonBestScores.playerAssists?.scoreValue || playerAst > PlayerSeasonBestScores.playerAssists?.scoreValue)) {
                PlayerSeasonBestScores.playerAssists = {scoreValue: playerAst, ...GameData};
            }

            if (!!playerBlk && (!PlayerSeasonBestScores.playerBlocks?.scoreValue || playerBlk > PlayerSeasonBestScores.playerBlocks?.scoreValue)) {
                PlayerSeasonBestScores.playerBlocks = {scoreValue: playerBlk, ...GameData};
            }

            if (!!playerStl && (!PlayerSeasonBestScores.playerSteals?.scoreValue || playerStl > PlayerSeasonBestScores.playerSteals?.scoreValue)) {
                PlayerSeasonBestScores.playerSteals = {scoreValue: playerStl, ...GameData};
            }

            if (!!playerTo && (!PlayerSeasonBestScores.playerTurnovers?.scoreValue || playerTo > PlayerSeasonBestScores.playerTurnovers?.scoreValue)) {
                PlayerSeasonBestScores.playerTurnovers = {scoreValue: playerTo, ...GameData};
            }

            if (!!playerFoul && (!PlayerSeasonBestScores.playerFouls?.scoreValue || playerFoul > PlayerSeasonBestScores.playerFouls?.scoreValue)) {
                PlayerSeasonBestScores.playerFouls = {scoreValue: playerFoul, ...GameData};
            }

            if (!!playerFouled && (!PlayerSeasonBestScores.playerFouled?.scoreValue || playerFouled > PlayerSeasonBestScores.playerFouled?.scoreValue)) {
                PlayerSeasonBestScores.playerFouled = {scoreValue: playerFouled, ...GameData};
            }

            if (!!playerEff && (!PlayerSeasonBestScores.playerEffectivity?.scoreValue || playerEff > PlayerSeasonBestScores.playerEffectivity?.scoreValue)) {
                PlayerSeasonBestScores.playerEffectivity = {scoreValue: playerEff, ...GameData};
            }

            const playerEval  =
                (playerPoints + playerTotalReb + playerAst + playerStl + playerBlk)
                -
                playerTo - (player2pta + player3pta - player2pt - player3pt) - (player1pta - player1pt)
            ;

            if (!!playerEval && (!PlayerSeasonBestScores.playerEval?.scoreValue || playerEval > PlayerSeasonBestScores.playerEval?.scoreValue)) {
                PlayerSeasonBestScores.playerEval = {scoreValue: playerEval, ...GameData};
            }

        }

    }


    if (PlayerSeasonBestScores.playerMinutes?.scoreValue) {
        PlayerSeasonBestScores.playerMinutes.scoreValue = formatMinutesFromSeconds(+PlayerSeasonBestScores.playerMinutes.scoreValue);
    }

    if (PlayerSeasonBestScores.player2ptEffectivity?.scoreValue) {
        const formattedValue = `${Math.round(+PlayerSeasonBestScores.player2ptEffectivity.scoreValue).toFixed(1)}%`;
        PlayerSeasonBestScores.player2ptEffectivity.scoreValue = formattedValue;
    }

    if (PlayerSeasonBestScores.player3ptEffectivity?.scoreValue) {
        const formattedValue = `${Math.round(+PlayerSeasonBestScores.player3ptEffectivity.scoreValue).toFixed(1)}%`;
        PlayerSeasonBestScores.player3ptEffectivity.scoreValue = formattedValue;
    }

    if (PlayerSeasonBestScores.player1ptEffectivity?.scoreValue) {
        const formattedValue = `${Math.round(+PlayerSeasonBestScores.player1ptEffectivity.scoreValue).toFixed(1)}%`;
        PlayerSeasonBestScores.player1ptEffectivity.scoreValue = formattedValue;
    }

    if (PlayerSeasonBestScores.playerGThrowsEffectivity?.scoreValue) {
        const formattedValue = `${Math.round(+PlayerSeasonBestScores.playerGThrowsEffectivity.scoreValue).toFixed(1)}%`;
        PlayerSeasonBestScores.playerGThrowsEffectivity.scoreValue = formattedValue;
    }

    return (
        <Col xs={12} className={"player-best-scores-table player-best-scores-table-component"}>
            <Row className={"player-best-scores-table-content"}>

                <BestScoresTableHeaderComponent />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_GAME_TIME_TEXT} scoreData={PlayerSeasonBestScores.playerMinutes}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_2PT_ACC_TEXT} scoreData={PlayerSeasonBestScores.player2ptAccurate}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_2PT_ATT_TEXT} scoreData={PlayerSeasonBestScores.player2ptAttempts}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_2PT_EFF_TEXT} scoreData={PlayerSeasonBestScores.player2ptEffectivity}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_3PT_ACC_TEXT} scoreData={PlayerSeasonBestScores.player3ptAccurate}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_3PT_ATT_TEXT} scoreData={PlayerSeasonBestScores.player3ptAttempts}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_3PT_EFF_TEXT} scoreData={PlayerSeasonBestScores.player3ptEffectivity}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_GT_ACC_TEXT} scoreData={PlayerSeasonBestScores.playerGThrowsAccurate}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_GT_ATT_TEXT} scoreData={PlayerSeasonBestScores.playerGThrowsAttempts}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_GT_EFF_TEXT} scoreData={PlayerSeasonBestScores.playerGThrowsEffectivity}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_1PT_ACC_TEXT} scoreData={PlayerSeasonBestScores.player1ptAccurate}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_1PT_ATT_TEXT} scoreData={PlayerSeasonBestScores.player1ptAttempts}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_1PT_EFF_TEXT} scoreData={PlayerSeasonBestScores.player1ptEffectivity}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_POINTS_TEXT} scoreData={PlayerSeasonBestScores.playerPoints}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_DEF_REB_TEXT} scoreData={PlayerSeasonBestScores.playerDefensiveRebounds}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_OFF_REB_TEXT} scoreData={PlayerSeasonBestScores.playerOffensiveRebounds}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_TOT_REB_TEXT} scoreData={PlayerSeasonBestScores.playerTotalRebounds}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_AST_TEXT} scoreData={PlayerSeasonBestScores.playerAssists}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_FOUL_TEXT} scoreData={PlayerSeasonBestScores.playerFouls}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_FOULS_TEXT} scoreData={PlayerSeasonBestScores.playerFouled}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_TO_TEXT} scoreData={PlayerSeasonBestScores.playerTurnovers}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_STL_TEXT} scoreData={PlayerSeasonBestScores.playerSteals}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_BLK_TEXT} scoreData={PlayerSeasonBestScores.playerBlocks}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_EFF_TEXT} scoreData={PlayerSeasonBestScores.playerEffectivity}
                />

                <BestScoresTableScoreComponent
                    scoreLabel={BEST_SCORE_EVAL_TEXT} scoreData={PlayerSeasonBestScores.playerEval}
                />

            </Row>
        </Col>
    );

};


export default BestScoresTableComponent;
