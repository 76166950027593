import React from "react";


import { NavLink } from "react-router-dom";


import { Row, Col } from "react-bootstrap";


import "./index.scss";


export interface GameResultDiagramsLegendComponentProps {
    homeTeamName: string; homeTeamSlug: string; homeTeamColor?: string;
    awayTeamName: string; awayTeamSlug: string; awayTeamColor?: string;
}


export const GameResultDiagramsLegendComponent: React.FC<GameResultDiagramsLegendComponentProps> = ({
    homeTeamName, homeTeamSlug, homeTeamColor,
    awayTeamName, awayTeamSlug, awayTeamColor
}) : JSX.Element | null => {

    return (
        <Row className={"game-result-details__diagrams__legend game-result-details-diagrams__legend"}>

            <Col xs={"auto"} className={"game-result-details__diagrams__legend__team game-result-details-diagrams__legend__team"}>
                <span style={{backgroundColor: homeTeamColor || undefined}} className={"legend-team-color"}></span>
                <NavLink to={`/druzyny/${homeTeamSlug}`} className={"legend-team-name"}>
                    <span>{homeTeamName}</span>
                </NavLink>
            </Col>

            <Col xs={"auto"} className={"game-result-details__diagrams__legend__team game-result-details-diagrams__legend__team"}>
                <span style={{backgroundColor: awayTeamColor || undefined}} className={"legend-team-color"}></span>
                <NavLink to={`/druzyny/${awayTeamSlug}`} className={"legend-team-name"}>
                    <span>{awayTeamName}</span>
                </NavLink>
            </Col>

        </Row>
    );

};


export default GameResultDiagramsLegendComponent;
