import React from "react";


import {Col} from "react-bootstrap";


interface GameSummaryTableTeamSeparatorComponentProps {}


export const GameSummaryTableTeamSeparatorComponent: React.FC<GameSummaryTableTeamSeparatorComponentProps> = ({}) : JSX.Element | null => {


    return (
        <Col xs={4} className={"summary-teams-separator summary-teams-separator-component"}>
            <span></span>
        </Col>
    );

}


export default GameSummaryTableTeamSeparatorComponent;
