import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_BY_ID_SLUG_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


export const UseTeamIdBySlug = (teamSlug: string, idStartValue: number | null = null) : [number | null, ErrorMessageInterface | null] => {

    const [teamID, setTeamID] = useState<number | null>(idStartValue);
    const [teamIDError, setTeamIDError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!teamSlug) {
            return setTeamIDError({ errorCode: 400, errorMessage: "" });
        }

        const getTeamID = async () => {

            try {
                const response = await _restApiRequest(
                    `${API_TEAM_BY_ID_SLUG_ENDPOINT_URL}?team_slug=${teamSlug}`
                );
                if (response.ok) {
                    setTeamID(await response.json());
                } else {
                    setTeamIDError({ errorCode: response.status, errorMessage: "" });
                }
            } catch (e) {
                setTeamIDError({ errorCode: 500, errorMessage: "" });
            }
        };

        getTeamID();

    }, [teamSlug]);

    useEffect(() => {
        return () => {
            setTeamID(idStartValue);
            setTeamIDError(null);
        };
    }, []);

    return [teamID, teamIDError];

};


export default UseTeamIdBySlug;
