import ExternalURLType from "@External/URLS/Types/URLS/URL";

import { ExternalURLS } from "@External";


export const UseExternalURL = (URLName: ExternalURLType) => {
    return ExternalURLS[URLName];
};


export default UseExternalURL;
