import React from "react";


import {Col, Form, Row} from "react-bootstrap";


import FormFieldValueErrorInterface from "@Components/Elements/Forms/_Common/_Types/Errors/ValueError";


import ColumnProps from "@BootstrapInterfaces/Column";


export interface FormFieldComponentProps extends ColumnProps {
    fieldType: "text" | "email" | "number" | "date" | "password" | "select" | "textarea" | "submit" | "color" | "file";
    fieldCssClasses?: string | string[];
    fieldLabel?: string;
    fieldError?: FormFieldValueErrorInterface | null;
    children: React.ReactNode;
}


export const FormFieldComponent: React.FC<FormFieldComponentProps> = ({
    fieldType = "text", fieldLabel = "",
    fieldCssClasses = "",
    fieldError = null,
    children,
    xs = 12, lg = 12,
}) : JSX.Element | null => {


    if (!children) return null;


    fieldCssClasses = typeof fieldCssClasses === "object" ? fieldCssClasses.join(" ") : fieldCssClasses;
    fieldCssClasses = `form-field form-field-component ${fieldType}-form-field` + (!!fieldCssClasses ? ` ${fieldCssClasses}` : fieldCssClasses);


    return (
        <Form.Group as={Col} xs={xs} lg={lg} className={fieldCssClasses}>

            {!!fieldLabel && (
            <Form.Label as={Row} className={"form-field-label"}>
                <span>{fieldLabel}</span>
            </Form.Label>
            )}

            <Row className={"form-field-content"}>

                {children}

            </Row>

            {!!fieldError && !!fieldError?.errorMessage &&
            <Form.Label as={Row} className={"form-field-label error"}>
                <span>{fieldError.errorMessage}</span>
            </Form.Label>
            }

        </Form.Group>
    )

};


export default FormFieldComponent;
