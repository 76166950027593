import React from "react";


import { Col } from "react-bootstrap";


import { PulseLoader } from "react-spinners";


import ColumnProps from "@BootstrapInterfaces/Column";


export interface SingleContentLoaderProps extends ColumnProps {
    double?: boolean;
    loaderSize?: number;
    loaderColor?: string;
    loaderElementsMargin?: number;
    forwardRef?: React.RefObject<HTMLDivElement> | null;
    cssClasses?: string;
}


export const SingleContentLoaderComponent: React.FC<SingleContentLoaderProps> = ({
    xs = 12, sm, lg = 12, xl,
    double = true, loaderSize = 15, loaderColor = "var(--secondary-color)", loaderElementsMargin = 3,
    forwardRef = null,
    cssClasses = ""
}) : JSX.Element | null => {


    cssClasses = "single-content-loader text-center" + (cssClasses ? ` ${cssClasses}` : "");


    return (
        <Col xs={xs} sm={sm || xs} lg={lg || sm || xs} xl={xl || lg || sm || xs} className={cssClasses} ref={forwardRef}>
            <PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />
            {double && <PulseLoader size={loaderSize} color={loaderColor} margin={loaderElementsMargin} />}
        </Col>
    );

};


export default SingleContentLoaderComponent;
