import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";



import { UsePlayersDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import PlayerProfileNavigationMenuElementComponent from "@Pages/User/Authenticated/Profile/Player/Menu/Element";


import "./index.scss";


import PlayerProfileContentTypeType from "@Pages/User/Authenticated/Profile/Player/_ContentType";



export interface PlayerProfileNavigationMenuComponentProps {
    selectedSubPage: PlayerProfileContentTypeType;
    selectedSubPageHandler: (page: PlayerProfileContentTypeType) => void;
    playerCaptainStatus?: boolean;
}


export const PlayerProfileNavigationMenuComponent: React.FC<PlayerProfileNavigationMenuComponentProps> = ({
    selectedSubPage, selectedSubPageHandler,
    playerCaptainStatus = false
}) : JSX.Element | null => {


    const MY_DATA_LABEL = UsePlayersDictionaryPhrasesSet("MY_DATA_LABEL");
    const MY_DESCRIPTION_LABEL = UsePlayersDictionaryPhrasesSet("MY_DESCRIPTION_LABEL");
    const FORMAL_AGREEMENTS_LABEL = UsePlayersDictionaryPhrasesSet("FORMAL_AGREEMENTS_LABEL");
    const CAPTAIN_ZONE_LABEL = UsePlayersDictionaryPhrasesSet("CAPTAIN_ZONE_LABEL");

    const TRANSFER_LIST_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TRANSFER_LIST_PHRASE_TEXT");


    return (
        <Col xs={4} lg={3} xl={2} className={"user-profile-page-menu"}>
            <Row className={"user-profile-page-menu-content"}>

                <PlayerProfileNavigationMenuElementComponent
                    elementText={MY_DATA_LABEL} elementContentType={"MAIN_DATA"}
                    selectedContentType={selectedSubPage} selectedContentTypeHandler={selectedSubPageHandler}
                />

                <PlayerProfileNavigationMenuElementComponent
                    elementText={MY_DESCRIPTION_LABEL} elementContentType={"DESCRIPTION"}
                    selectedContentType={selectedSubPage} selectedContentTypeHandler={selectedSubPageHandler}
                />

                <PlayerProfileNavigationMenuElementComponent
                    elementText={FORMAL_AGREEMENTS_LABEL} elementContentType={"FORMALS"}
                    selectedContentType={selectedSubPage} selectedContentTypeHandler={selectedSubPageHandler}
                />

                <PlayerProfileNavigationMenuElementComponent
                    elementText={TRANSFER_LIST_PHRASE_TEXT} elementContentType={"FREE_AGENTS"}
                    selectedContentType={selectedSubPage} selectedContentTypeHandler={selectedSubPageHandler}
                />

                {!!playerCaptainStatus &&
                <PlayerProfileNavigationMenuElementComponent
                    elementText={CAPTAIN_ZONE_LABEL} elementContentType={"CAPTAIN"}
                    selectedContentType={selectedSubPage} selectedContentTypeHandler={selectedSubPageHandler}
                />
                }

            </Row>
        </Col>
    );

};


export default PlayerProfileNavigationMenuComponent;
