import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import _ from "lodash";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import {AiOutlineCheck, FaTimes} from "react-icons/all";


import NumberFormat from "react-number-format";


import dateFormat from "dateformat";


import LeagueTeamInterface from "@Interfaces/Api/Teams/Team";
import LeagueGamePlayerInterface from "@Interfaces/Api/Leagues/Games/Game/Player";

import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";
import {UsePlayersDictionaryPhrasesSet} from "@Hooks/Texts/Pages";
import {UseStatisticsDictionaryPhrasesSet} from "@Hooks/Texts";



const TEXTS: any = {
    PL: {
        game_date_label: "Data",
        game_opponent_label: "Przeciwko",
        game_result_label: "Wynik",
        game_s5_label: "S5",
        game_minutes_label: "Min",
        game_points_label: "Pkt",
        accurate_label: "C",
        not_accurate_label: "W",
        game_2pts_label: "Za 2",
        game_3pts_label: "Za 3",
        game_Gpts_label: "Z Gry",
        game_1pts_label: "Za 1",
        game_rebounds_label: "Zb",
        offensive_label: "A",
        defensive_label: "O",
        total_label: "S",
        assists_label: "A",
        blocks_label: "B",
        steals_label: "P",
        fouls_label: "F",
        turnovers_label: "S",
        effectivity_label: "+/-",
        eval_label: "Eval",
    },
    EN: {
        game_date_label: "Date",
        game_opponent_label: "Against",
        game_result_label: "Result",
        game_s5_label: "S5",
        game_minutes_label: "Min",
        game_points_label: "Pts",
        accurate_label: "A",
        not_accurate_label: "M",
        game_2pts_label: "2Pt",
        game_3pts_label: "3Pt",
        game_Gpts_label: "Game",
        game_1pts_label: "1Pt",
        game_rebounds_label: "Reb",
        offensive_label: "O",
        defensive_label: "D",
        total_label: "T",
        assists_label: "A",
        blocks_label: "B",
        steals_label: "S",
        fouls_label: "F",
        turnovers_label: "T",
        effectivity_label: "+/-",
        eval_label: "Eval",
    }
};


interface PlayedGamesListComponentProps {
    leagueGames: SortedBySeasonGamesLeagueInterface;
}


export const PlayedGamesListComponent: React.FC<PlayedGamesListComponentProps> = ({
    leagueGames
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        game_date_label: GAME_DATE_LABEL,
        game_opponent_label: GAME_OPPONENT_LABEL,
        game_result_label: GAME_RESULT_LABEL,
        game_s5_label: S5_LABEL,
        game_minutes_label: MINUTES_LABEL,
        game_points_label: PTS_LABEL,
        accurate_label: ACC_LABEL,
        not_accurate_label: NOT_ACC_LABEL,
        game_2pts_label: PTS2_LABEL,
        game_3pts_label: PTS3_LABEL,
        game_Gpts_label: GPTS_LABEL,
        game_1pts_label: PTS1_LABEL,
        game_rebounds_label: REB_LABEL,
        offensive_label: OFF_LABEL,
        defensive_label: DEF_LABEL,
        total_label: TOT_LABEL,
        assists_label: AST_LABEL,
        blocks_label: BLK_LABEL,
        steals_label: STL_LABEL,
        fouls_label: FOUL_LABEL,
        turnovers_label: TO_LABEL,
        effectivity_label: EFF_LABEL,
        eval_label: EVAL_LABEL,
    } = TEXTS[activeLanguage];


    if (!leagueGames) return null;


    const {gamesLeagueGames} = leagueGames;


    if (!gamesLeagueGames) return null;


    let LeagueGames: any = [];

    for (let [teamID, teamData] of Object.entries(gamesLeagueGames)) {

        const {teamGames} = teamData;

        const sortedTeamGames = _.orderBy(teamGames, "league_game_league_game.league_game_date", "desc");

        const TeamGames = sortedTeamGames.map((TeamGame: LeagueGamePlayerInterface) => {

            const {
                league_game_league_game: GameInfo,
                league_game_team_player_2pt: player2pt, league_game_team_player_2pta: player2pta,
                league_game_team_player_3pt: player3pt, league_game_team_player_3pta: player3pta,
                league_game_team_player_ft: playerFt, league_game_team_player_fta: playerFta,
                league_game_team_player_ast: playerAst, league_game_team_player_blk: playerBlk,
                league_game_team_player_eff: playerEff,
                league_game_team_player_first_squad: playerFirstSquad,
                league_game_team_player_foul: playerFouls, // league_game_team_player_fouled,
                league_game_team_player_real_seconds: playerPlayedTime,
                league_game_team_player_rebound_def: playerDreb, league_game_team_player_rebound_off: playerOreb,
                league_game_team_player_stl: playerStl, league_game_team_player_to: playerTo
            } = TeamGame;

            if (!playerPlayedTime || !+playerPlayedTime) return null;

            const {
                league_game_id: gameID,
                league_game_date: gameDate,
                league_game_home_team_data: gameHomeTeam,
                league_game_home_team_points: gameHomeTeamPoints,
                league_game_away_team_data: gameAwayTeam,
                league_game_away_team_points: gameAwayTeamPoints
            } = GameInfo;


            const {team_id: homeTeamID} = gameHomeTeam;
            const {team_id: awayTeamID} = gameAwayTeam;

            let playerTeam: LeagueTeamInterface | null = null;
            let opponentTeam: LeagueTeamInterface | null = null;
            let playerTeamResult: "W" | "L" | null = null;
            let playerTeamPoints = 0;
            let opponentTeamPoints = 0;

            if (+teamID === homeTeamID) {
                opponentTeam = gameAwayTeam;
                playerTeamPoints = gameHomeTeamPoints;
                opponentTeamPoints = gameAwayTeamPoints;
                if (gameHomeTeamPoints > gameAwayTeamPoints) playerTeamResult = "W";
                if (gameAwayTeamPoints > gameHomeTeamPoints) playerTeamResult = "L";
            }

            if (+teamID === awayTeamID) {
                opponentTeam = gameHomeTeam;
                playerTeamPoints = gameAwayTeamPoints;
                opponentTeamPoints = gameHomeTeamPoints;
                if (gameAwayTeamPoints > gameHomeTeamPoints) playerTeamResult = "W";
                if (gameHomeTeamPoints > gameAwayTeamPoints) playerTeamResult = "L";
            }

            let playerPlayedMinutes: string | number = Math.floor(playerPlayedTime / 60);
            if (playerPlayedMinutes < 10) playerPlayedMinutes = `0${playerPlayedMinutes}`;

            let playerPlayedSeconds: string | number = Math.floor(playerPlayedTime % 60);
            if (playerPlayedSeconds < 10) playerPlayedSeconds = `0${playerPlayedSeconds}`;


            const playerPts = player2pt * 2 + player3pt * 3 + playerFt;

            // const player2PtsThrows = player2pt + player2pta;
            const player2PtsThrows = player2pta;
            const player2PtsEff = player2PtsThrows ? player2pt / player2PtsThrows * 100 : 0;

            // const player3PtsThrows = player3pt + player3pta;
            const player3PtsThrows = player3pta;
            const player3PtsEff = player3PtsThrows ? player3pt / player3PtsThrows * 100 : 0;

            const playerGPts = player2pt + player3pt;
            // const playerGPtsThrows = player2pt + player2pta + player3pt + player3pta;
            const playerGPtsThrows = player2pta + player3pta;
            const playerGPtsEff = playerGPtsThrows ? playerGPts / playerGPtsThrows * 100 : 0;

            // const player1PtsThrows = playerFt + playerFta;
            const player1PtsThrows = playerFta;
            const player1PtsEff = player1PtsThrows ? playerFt / player1PtsThrows * 100 : 0;


            const playerTreb = playerDreb + playerOreb;


            const playerEval  =
                (playerPts + playerTreb + playerAst + playerStl + playerBlk)
                -
                playerTo - (player2pta + player3pta - player2pt - player3pt) - (playerFta - playerFt)
            ;


            const START5_GAMES_SHORT_LABEL = UsePlayersDictionaryPhrasesSet("START5_GAMES_SHORT_LABEL");
            const START5_GAMES_TITLE = UsePlayersDictionaryPhrasesSet("START5_GAMES_TITLE");

            const POINTS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("POINTS_SHORT_LABEL");
            const POINTS_TITLE = UseStatisticsDictionaryPhrasesSet("POINTS_TITLE");

            const MINUTES_LABEL = UseStatisticsDictionaryPhrasesSet("MINUTES_LABEL");
            const MINUTES_TITLE = UseStatisticsDictionaryPhrasesSet("MINUTES_TITLE");

            const THROWS_1PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_SHORT_LABEL");
            const THROWS_1PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_TITLE");
            const THROWS_1PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PTA_SHORT_LABEL");
            const THROWS_1PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PTA_TITLE");
            const THROWS_1PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_SHORT_LABEL");
            const THROWS_1PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_1PT_EFF_TITLE");

            const THROWS_2PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_SHORT_LABEL");
            const THROWS_2PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_TITLE");
            const THROWS_2PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PTA_SHORT_LABEL");
            const THROWS_2PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PTA_TITLE");
            const THROWS_2PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_SHORT_LABEL");
            const THROWS_2PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_2PT_EFF_TITLE");

            const THROWS_3PT_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_SHORT_LABEL");
            const THROWS_3PT_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_TITLE");
            const THROWS_3PTA_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PTA_SHORT_LABEL");
            const THROWS_3PTA_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PTA_TITLE");
            const THROWS_3PT_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_SHORT_LABEL");
            const THROWS_3PT_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_3PT_EFF_TITLE");

            const THROWS_GAME_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_SHORT_LABEL");
            const THROWS_GAME_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_TITLE");
            const THROWS_GAME_A_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_A_SHORT_LABEL");
            const THROWS_GAME_A_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_A_SHORT_LABEL");
            const THROWS_GAME_EFF_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_SHORT_LABEL");
            const THROWS_GAME_EFF_TITLE = UseStatisticsDictionaryPhrasesSet("THROWS_GAME_EFF_TITLE");

            const DEFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_SHORT_LABEL");
            const DEFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("DEFENSIVE_REBOUNDS_TITLE");
            const OFFENSIVE_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_SHORT_LABEL");
            const OFFENSIVE_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("OFFENSIVE_REBOUNDS_TITLE");
            const TOTAL_REBOUNDS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_SHORT_LABEL");
            const TOTAL_REBOUNDS_TITLE = UseStatisticsDictionaryPhrasesSet("TOTAL_REBOUNDS_TITLE");

            const ASSISTS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("ASSISTS_SHORT_LABEL");
            const ASSISTS_TITLE = UseStatisticsDictionaryPhrasesSet("ASSISTS_TITLE");
            const BLOCKS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("BLOCKS_SHORT_LABEL");
            const BLOCKS_TITLE = UseStatisticsDictionaryPhrasesSet("BLOCKS_TITLE");
            const STEALS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("STEALS_SHORT_LABEL");
            const STEALS_TITLE = UseStatisticsDictionaryPhrasesSet("STEALS_TITLE");

            const TURNOVERS_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("TURNOVERS_SHORT_LABEL");
            const TURNOVERS_TITLE = UseStatisticsDictionaryPhrasesSet("TURNOVERS_TITLE");
            const FOULS_LABEL = UseStatisticsDictionaryPhrasesSet("FOULS_LABEL");
            const FOULS_TITLE = UseStatisticsDictionaryPhrasesSet("FOULS_TITLE");

            const EFFECTIVITY_SHORT_LABEL = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_SHORT_LABEL");
            const EFFECTIVITY_TITLE = UseStatisticsDictionaryPhrasesSet("EFFECTIVITY_TITLE");
            const EVAL_LABEL = UseStatisticsDictionaryPhrasesSet("EVAL_LABEL");
            const EVAL_TITLE = UseStatisticsDictionaryPhrasesSet("EVAL_TITLE");



            return (
                <Col xs={12} className={"played-games-list-game"}>
                    <Row className={"played-games-list-game-content"}>

                        <Col xs={2} lg={"auto"} className={"league-game-date league-game-info"}>
                            {!!gameDate &&
	                        <NavLink to={`/mecze/${gameID}`} className={"statistic-value"}>
		                        <span>{dateFormat(gameDate, "dd-mm-yyyy")}</span>
	                        </NavLink>
                            }
                        </Col>

                        <Col xs={4} lg={"auto"} className={"league-game-opponent-team league-game-info"}>
                            <NavLink to={`/druzyny/${opponentTeam?.team_slug}`} className={"statistic-value"}>
                                <span>{opponentTeam?.team_name}</span>
                            </NavLink>
                        </Col>

                        <Col xs={2} lg={"auto"} className={"league-game-result league-game-info" + (playerTeamResult === "W" ? " winner" : "")}>
                            <span className={"game-result-type statistic-value"}>
                                {playerTeamResult === "W" && "Z"}
                                {playerTeamResult === "L" && "P"}
                            </span>
                            <NavLink to={`/mecze/${gameID}`} className={"game-result-value statistic-value"}>
                                <span>{playerTeamPoints} : {opponentTeamPoints}</span>
                            </NavLink>
                        </Col>

                        <Col xs={1} lg={"auto"} title={START5_GAMES_TITLE} className={"league-game-first-squad league-game-info"}>
                            <span className={"statistic-label"}>{START5_GAMES_SHORT_LABEL}</span>
                            <span className={playerFirstSquad ? "first-squad" : ""}>
                                {playerFirstSquad ? <AiOutlineCheck /> : <FaTimes />}
                            </span>
                        </Col>

                        <Col xs={2} lg={"auto"} title={MINUTES_TITLE} className={"league-game-played-time league-game-info"}>
                            <span className={"statistic-label"}>{MINUTES_LABEL}</span>
                            <span className={"statistic-value"}>{playerPlayedMinutes}:{playerPlayedSeconds}</span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={POINTS_TITLE} className={"league-game-points league-game-info"}>
                            <span className={"statistic-label"}>{POINTS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerPts}</span>
                        </Col>

                        <Col xs={3} lg={"auto"} title={`${THROWS_2PT_TITLE} / ${THROWS_2PTA_TITLE} / ${THROWS_2PT_EFF_TITLE}`} className={"league-game-2pts league-game-info"}>
                            <span className={"statistic-label"}>{THROWS_2PT_SHORT_LABEL} / {THROWS_2PTA_SHORT_LABEL} / {THROWS_2PT_EFF_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>
                                <span>{player2pt}</span>
                                <span className={"sep"}></span>
                                <span>{player2PtsThrows}</span>
                                <span className={"sep"}></span>
                                <span><NumberFormat value={player2PtsEff} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</span>
                            </span>
                        </Col>

                        <Col xs={3} lg={"auto"} title={`${THROWS_3PT_TITLE} / ${THROWS_3PTA_TITLE} / ${THROWS_3PT_EFF_TITLE}`} className={"league-game-3pts league-game-info"}>
                            <span className={"statistic-label"}>{THROWS_3PT_SHORT_LABEL} / {THROWS_3PTA_SHORT_LABEL} / {THROWS_3PT_EFF_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>
                                <span>{player3pt}</span>
                                <span className={"sep"}></span>
                                <span>{player3PtsThrows}</span>
                                <span className={"sep"}></span>
                                <span><NumberFormat value={player3PtsEff} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</span>
                            </span>
                        </Col>

                        <Col xs={3} lg={"auto"} title={`${THROWS_GAME_TITLE} / ${THROWS_GAME_A_TITLE} / ${THROWS_GAME_EFF_TITLE}`} className={"league-game-gpts league-game-info"}>
                            <span className={"statistic-label"}>{THROWS_GAME_SHORT_LABEL} / {THROWS_GAME_A_SHORT_LABEL} / {THROWS_GAME_EFF_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>
                                <span>{playerGPts}</span>
                                <span className={"sep"}></span>
                                <span>{playerGPtsThrows}</span>
                                <span className={"sep"}></span>
                                <span><NumberFormat value={playerGPtsEff} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</span>
                            </span>
                        </Col>

                        <Col xs={3} lg={"auto"} title={`${THROWS_1PT_TITLE} / ${THROWS_1PTA_TITLE} / ${THROWS_1PT_EFF_TITLE}`} className={"league-game-1pts league-game-info"}>
                            <span className={"statistic-label"}>{THROWS_1PT_SHORT_LABEL} / {THROWS_1PTA_SHORT_LABEL} / {THROWS_1PT_EFF_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>
                                <span>{playerFt}</span>
                                <span className={"sep"}></span>
                                <span>{player1PtsThrows}</span>
                                <span className={"sep"}></span>
                                <span><NumberFormat value={player1PtsEff} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />%</span>
                            </span>
                        </Col>

                        <Col xs={3} lg={"auto"} title={`${DEFENSIVE_REBOUNDS_TITLE} / ${OFFENSIVE_REBOUNDS_TITLE} / ${TOTAL_REBOUNDS_TITLE}`} className={"league-game-rebounds league-game-info"}>
                            <span className={"statistic-label"}>{DEFENSIVE_REBOUNDS_SHORT_LABEL} / {OFFENSIVE_REBOUNDS_SHORT_LABEL} / {TOTAL_REBOUNDS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>
                                <span>{playerDreb}</span>
                                <span className={"sep"}></span>
                                <span>{playerOreb}</span>
                                <span className={"sep"}></span>
                                <span>{playerTreb}</span>
                            </span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={ASSISTS_TITLE} className={"league-game-assists league-game-info"}>
                            <span className={"statistic-label"}>{ASSISTS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerAst}</span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={STEALS_TITLE} className={"league-game-steals league-game-info"}>
                            <span className={"statistic-label"}>{STEALS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerStl}</span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={BLOCKS_TITLE} className={"league-game-blocks league-game-info"}>
                            <span className={"statistic-label"}>{BLOCKS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerBlk}</span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={TURNOVERS_TITLE} className={"league-game-turnovers league-game-info"}>
                            <span className={"statistic-label"}>{TURNOVERS_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerTo}</span>
                        </Col>

                        <Col xs={1} lg={"auto"} title={FOULS_TITLE} className={"league-game-fouls league-game-info"}>
                            <span className={"statistic-label"}>{FOULS_LABEL}</span>
                            <span className={"statistic-value"}>{playerFouls}</span>
                        </Col>

                        <Col xs={2} lg={"auto"} title={EFFECTIVITY_TITLE} className={"league-game-eff league-game-info"}>
                            <span className={"statistic-label"}>{EFFECTIVITY_SHORT_LABEL}</span>
                            <span className={"statistic-value"}>{playerEff > 0 ? "+" : ""}{playerEff}</span>
                        </Col>

                        <Col xs={2} lg={"auto"} title={EVAL_TITLE} className={"league-game-eval league-game-info"}>
                            <span className={"statistic-label"}>{EVAL_LABEL}</span>
                            <span className={"statistic-value"}>{playerEval}</span>
                        </Col>

                    </Row>
                </Col>
            );

        });

        LeagueGames = LeagueGames.concat(...TeamGames);

    }


    return (
        <Col xs={12} className={"played-games-list played-games-list-component"}>
            <Row className={"played-games-list-content"}>

                <Col xs={12} className={"played-games-list-head"}>
                    <Row className={"played-games-list-head-content"}>
                        <Col className={"league-game-date league-game-info"}>
                            <span>{GAME_DATE_LABEL}</span>
                        </Col>
                        <Col className={"league-game-opponent-team league-game-info"}>
                            <span>{GAME_OPPONENT_LABEL}</span>
                        </Col>
                        <Col className={"league-game-result league-game-info"}>
                            <span>{GAME_RESULT_LABEL}</span>
                        </Col>
                        <Col className={"league-game-first-squad league-game-info"}>
                            <span>{S5_LABEL}</span>
                        </Col>
                        <Col className={"league-game-played-time league-game-info"}>
                            <span>{MINUTES_LABEL}</span>
                        </Col>
                        <Col className={"league-game-points league-game-info"}>
                            <span>{PTS_LABEL}</span>
                        </Col>
                        <Col className={"league-game-2pts league-game-info"}>
                            <span className={"lab"}>{PTS2_LABEL}</span>
                            <span>{ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{NOT_ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>%</span>
                        </Col>
                        <Col className={"league-game-3pts league-game-info"}>
                            <span className={"lab"}>{PTS3_LABEL}</span>
                            <span>{ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{NOT_ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>%</span>
                        </Col>
                        <Col className={"league-game-gpts league-game-info"}>
                            <span className={"lab"}>{GPTS_LABEL}</span>
                            <span>{ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{NOT_ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>%</span>
                        </Col>
                        <Col className={"league-game-1pts league-game-info"}>
                            <span className={"lab"}>{PTS1_LABEL}</span>
                            <span>{ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{NOT_ACC_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>%</span>
                        </Col>
                        <Col className={"league-game-rebounds league-game-info"}>
                            <span className={"lab"}>{REB_LABEL}</span>
                            <span>{DEF_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{OFF_LABEL}</span>
                            <span className={"sep"}></span>
                            <span>{TOT_LABEL}</span>
                        </Col>
                        <Col className={"league-game-assists league-game-info"}>
                            <span>{AST_LABEL}</span>
                        </Col>
                        <Col className={"league-game-steals league-game-info"}>
                            <span>{STL_LABEL}</span>
                        </Col>
                        <Col className={"league-game-blocks league-game-info"}>
                            <span>{BLK_LABEL}</span>
                        </Col>
                        <Col className={"league-game-turnovers league-game-info"}>
                            <span>{TO_LABEL}</span>
                        </Col>
                        <Col className={"league-game-fouls league-game-info"}>
                            <span>{FOUL_LABEL}</span>
                        </Col>
                        <Col className={"league-game-eff league-game-info"}>
                            <span>{EFF_LABEL}</span>
                        </Col>
                        <Col className={"league-game-eval league-game-info"}>
                            <span>{EVAL_LABEL}</span>
                        </Col>
                    </Row>
                </Col>

                {LeagueGames}

            </Row>
        </Col>
    );


};


export default PlayedGamesListComponent;
