import { UseLanguage } from "@States";


import AuthenticatedUserDictionary, { AuthenticatedUserDictionaryPhrasesSetType } from "@Dictionary/Pages/User/Authenticated";


export const UseAuthenticatedUserDictionaryPhrasesSet = (phraseName: AuthenticatedUserDictionaryPhrasesSetType) : string => {

    const ActiveLanguage = UseLanguage()

    return AuthenticatedUserDictionary[ActiveLanguage][phraseName];

};


export default UseAuthenticatedUserDictionaryPhrasesSet;
