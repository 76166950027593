import React from "react";


import {
    UseLeaguesDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Container, Row } from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import MessageComponent from "@Components/Elements/Messages/Content";


import ResultsListRoundComponent from "@Components/Views/Pages/Games/Results/Rounds/Round";


import {
    LeagueRoundInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ResultsPageResultsListComponentProps {
    LeagueRounds: LeagueRoundInterface[] | null;
}


export const ResultsPageResultsListComponent: React.FC<ResultsPageResultsListComponentProps> = ({
    LeagueRounds = null,
}) : JSX.Element | null => {


    const NO_SCHEDULE_MESSAGE_TEXT = UseLeaguesDictionaryPhrasesSet("NO_SCHEDULE_MESSAGE_TEXT");


    if (!LeagueRounds) {
        return <MainContentLoaderComponent />;
    }


    const LeagueRoundsList = LeagueRounds.map((LeagueRound, i) =>
        <ResultsListRoundComponent
            key={i}
            LeagueRound={LeagueRound}
        />
    );


    if (LeagueRoundsList?.length === 0) {
        return <MessageComponent messageText={NO_SCHEDULE_MESSAGE_TEXT} cssClasses={"padding-15 margin-top-15"} />;
    }


    return (
        <Container fluid={true} className={"page-component-content-block results-page-results-component leagues-schedules__league-rounds-list"}>
            <Container className={"page-component-content-container leagues-schedules__league-rounds-list__container"}>
                <Row className={"page-component-content leagues-schedules__league-rounds-list__content"}>

                    {!LeagueRoundsList && <MainContentLoaderComponent />}

                    {LeagueRoundsList}

                </Row>
            </Container>
        </Container>
    );
};


export default ResultsPageResultsListComponent;
