import React, { useEffect } from "react";


import {
    PageContainerComponent, DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";


import TeamPlayersLeaguesSwitcherComponent from "@TeamsPages/Team/Players/PlayersListLeagues";
import TeamPlayersListComponent from "@TeamsPages/Team/Players/PlayersList";


import "./index.scss";


import {
    TeamLeagueInterface
} from "@Interfaces/Api/Teams/Team";
import {UseCommonDictionaryPhrasesSet} from "@Hooks/Texts";


export interface TeamPlayersComponentProps {
    TeamLeaguesWithSquads?: TeamLeagueInterface[];
    teamCaptainID?: number | null;
    selectedTeamLeagueID: number;
    selectedTeamLeagueIDHandler: (leagueID: number) => void;
}

export const TeamPlayersComponent: React.FC<TeamPlayersComponentProps> = ({
    TeamLeaguesWithSquads, teamCaptainID,
    selectedTeamLeagueID, selectedTeamLeagueIDHandler
}) : JSX.Element | null => {


    const PLAYERS_WORD_TEXT = UseCommonDictionaryPhrasesSet("PLAYERS_WORD_TEXT");


    useEffect(() => {

        if (!TeamLeaguesWithSquads?.length || selectedTeamLeagueID) {
            return;
        }

        const DefaultSelectedLeague = TeamLeaguesWithSquads[0];

        const { id: leagueID } = DefaultSelectedLeague;

        selectedTeamLeagueIDHandler(leagueID);

    }, [TeamLeaguesWithSquads]);


    return (
        <PageContainerComponent cssClasses={"team-details__team-players"}>

            <DefaultPageHeaderComponent headerText={PLAYERS_WORD_TEXT} />

            <PageContentComponent cssClasses={"team-details__team-players__content"}>

                <TeamPlayersLeaguesSwitcherComponent
                    TeamLeaguesWithSquads={TeamLeaguesWithSquads}
                    selectedTeamLeagueID={selectedTeamLeagueID}
                    selectedTeamLeagueIDHandler={selectedTeamLeagueIDHandler}
                />

                <TeamPlayersListComponent
                    TeamLeaguesWithSquads={TeamLeaguesWithSquads}
                    teamCaptainID={teamCaptainID}
                    selectedTeamLeagueID={selectedTeamLeagueID}
                />

            </PageContentComponent>

        </PageContainerComponent>

    );


    // return (
    //     <ContainerSectionComponent classes={"team-page__team-players"}>
    //
    //         <TeamPlayersHeaderComponent
    //             TeamLeaguesSquads={TeamLeaguesSquads}
    //             selectedLeagueID={selectedLeagueID}
    //             selectedLeagueIDHandler={setSelectedLeagueID}
    //         />

    //         <TeamPlayersTop5Component
    //             teamID={teamID}
    //             teamLeague={teamLeague}
    //             photoURL={teamImage}
    //         />

    //
    //
    //     </ContainerSectionComponent>
    // );

};


export default TeamPlayersComponent;
