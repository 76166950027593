import MessageLanguageInterface from "@GlobalData/Messages/_Interfaces/Language";


export interface LeaguesMessagesInterface {
    noLeagues: MessageLanguageInterface,
    noSelectedLeague: MessageLanguageInterface
}


export const LeaguesMessages: LeaguesMessagesInterface = {
    noLeagues: {PL: "Brak Lig", EN: "No Leagues"},
    noSelectedLeague: {PL: "Nie Wybrano Ligi", EN: "No Selected League"},
};


export default LeaguesMessages;
