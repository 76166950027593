import React from "react";


import { UseGlobalData } from "@ApplicationStates";

import { UseGlobalsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import FooterInformationElementComponent from "@Components/Views/Parts/Footer/Information/InfoElement";


import {
    BallImageIcon, PlayerImageIcon, PlayersImageIcon
} from "@Icons";


import "./index.scss";


interface FooterInformationComponentProps {}


export const FooterInformationComponent: React.FC<FooterInformationComponentProps> = () : JSX.Element | null => {


    const TEAMS_WORD_TEXT = UseGlobalsDictionaryPhrasesSet("TEAMS_WORD_TEXT");
    const PLAYERS_WORD_TEXT = UseGlobalsDictionaryPhrasesSet("PLAYERS_WORD_TEXT");
    const GAMES_NUMBER_PHRASE_TEXT = UseGlobalsDictionaryPhrasesSet("GAMES_NUMBER_PHRASE_TEXT");


    const GlobalData = UseGlobalData();


    if (!GlobalData?.information) return null;


    const {
        teams_number: TEAMS_NUMBER,
        players_number: PLAYERS_NUMBER,
        games_number: GAMES_NUMBER,
    } = GlobalData.information;


    return (
        <Col as={`aside`} xs={12} lg={5} className={`service-footer-section__information`}>
            <Row as={`ul`} className={`service-footer-section__information__list`}>

                <FooterInformationElementComponent
                    elementLabel={TEAMS_WORD_TEXT}
                    elementValue={TEAMS_NUMBER}
                    elementIcon={PlayersImageIcon}
                />

                <FooterInformationElementComponent
                    elementLabel={PLAYERS_WORD_TEXT}
                    elementValue={PLAYERS_NUMBER}
                    elementIcon={PlayerImageIcon}
                />

                <FooterInformationElementComponent
                    elementLabel={GAMES_NUMBER_PHRASE_TEXT}
                    elementValue={GAMES_NUMBER}
                    elementIcon={BallImageIcon}
                />

            </Row>
        </Col>
    );

};


export default FooterInformationComponent;
