import React from "react";


import {Col, Row} from "react-bootstrap";


import LeaguesStatisticsTableStatisticComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Statistics/Tables/Header/Statistic";


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import StatisticsTypes from "@GlobalData/Statistics/Types";


interface LeaguesStatisticsTableHeaderProps {
    selectedStatisticsType: StatisticsType | string;
    selectedStatisticsTypeHandler(type: StatisticsType): void;
}


export const LeaguesStatisticsTableHeaderComponent: React.FC<LeaguesStatisticsTableHeaderProps> = ({
    selectedStatisticsType = "TPTS",
    selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const StatisticsTableHeader = Object.entries(StatisticsTypes).map(([statisticsTypeName, statisticsTypeData]) =>
        <LeaguesStatisticsTableStatisticComponent
            key={statisticsTypeName}
            statisticType={statisticsTypeData}
            statisticTypeName={statisticsTypeName}
            selectedStatisticsType={selectedStatisticsType}
            selectedStatisticsTypeHandler={selectedStatisticsTypeHandler}
        />
    );


    return (
        <Col xs={3} lg={12} className={"statistics-table-header"}>
            <Row className={"statistics-table-header-content"}>
                {StatisticsTableHeader}
            </Row>
        </Col>
    );

};


export default LeaguesStatisticsTableHeaderComponent;
