import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";

import {
    AiFillCaretDown, AiFillCaretUp
} from "react-icons/all";


import "./index.scss";


export interface TeamLeaguesListLeagueShowContentSwitcherComponentProps {
    showTeamLeagueDetailsStatus: boolean;
    showTeamLeagueDetailsStatusHandler: (status: boolean) => void;
}

export const TeamLeaguesListLeagueShowContentSwitcherComponent: React.FC<TeamLeaguesListLeagueShowContentSwitcherComponentProps> = ({
    showTeamLeagueDetailsStatus, showTeamLeagueDetailsStatusHandler
}) : JSX.Element | null => {


    const SHOW_WORD_TEXT = UseCommonDictionaryPhrasesSet("SHOW_WORD_TEXT");
    const HIDE_WORD_TEXT = UseCommonDictionaryPhrasesSet("HIDE_WORD_TEXT");
    const DETAILS_WORD_TEXT = UseCommonDictionaryPhrasesSet("DETAILS_WORD_TEXT");


    return (
        <Col xs={"auto"} className={"player-profile__captain-zone__team__leagues__list__league__information__show-content-switcher"}>
            <span onClick={() => showTeamLeagueDetailsStatusHandler(!showTeamLeagueDetailsStatus)} className={"player-profile__captain-zone__team__leagues__list__league__information__show-content-switcher__switcher"}>
                <span className={"player-profile__captain-zone__team__leagues__list__league__information__show-content-switcher__switcher__text"}>{showTeamLeagueDetailsStatus ? HIDE_WORD_TEXT : SHOW_WORD_TEXT} {DETAILS_WORD_TEXT}</span>
                <span className={"player-profile__captain-zone__team__leagues__list__league__information__show-content-switcher__switcher__icon"}>
                    {showTeamLeagueDetailsStatus ? <AiFillCaretUp /> : <AiFillCaretDown />}
                </span>
            </span>
        </Col>
    );

};


export default TeamLeaguesListLeagueShowContentSwitcherComponent;
