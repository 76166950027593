import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamNextGamesListHeaderComponent from "@TeamsPages/Team/Information/TeamGames/GamesList/Header";
import TeamNextGamesListGameComponent from "@TeamsPages/Team/Information/TeamGames/GamesList/Game";


import {
    LeagueGameInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamNextGamesListComponentProps {
    teamID: number;
    TeamNextGames: LeagueGameInterface[] | null;
    TeamNextGamesError: ErrorMessageInterface | null;
}

export const TeamNextGamesListComponent: React.FC<TeamNextGamesListComponentProps> = ({
    teamID, TeamNextGames, TeamNextGamesError
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const NO_GAMES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_GAMES_PHRASE_TEXT");


    if (TeamNextGamesError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} cssClasses={"no-margin"} />;
    }

    if (!TeamNextGames) {
        return <SingleContentLoaderComponent />;
    }


    if (!TeamNextGames?.length) {
        return <MessageComponent messageText={NO_GAMES_PHRASE_TEXT} />;
    }


    const TeamNextGamesList = TeamNextGames.map((LeagueGame, i) =>
        <TeamNextGamesListGameComponent key={i} LeagueGame={LeagueGame} teamID={teamID} orderNr={i + 1}  />
    );


    return (
        <Col xs={12} className={"team-details__team-information__team-next-games__games-list"}>
            <Row className={"team-details__team-information__team-next-games__games-list__content"}>

                <TeamNextGamesListHeaderComponent />

                {TeamNextGamesList}

            </Row>
        </Col>
    )

};


export default TeamNextGamesListComponent;
