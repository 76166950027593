import UseLanguage from "@LocalizationStates/Language";

import FormsFieldsDictionary from "@Dictionary/Sets/Forms";
import DictionaryFormFieldType from "@Dictionary/Sets/Forms/Fields/Field";


export const UseFormsDictionaryPhrasesSet = (fieldName: DictionaryFormFieldType) : string => {

    const ActiveLanguage = UseLanguage();

    return FormsFieldsDictionary[ActiveLanguage][fieldName];

};


export default UseFormsDictionaryPhrasesSet;
