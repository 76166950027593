import React from "react";


import {
    Col
} from "react-bootstrap";

import ColumnProps from "@BootstrapInterfaces/Column";


import {
    generateLeagueName
} from "@Services/Utilities/Games";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


export interface TeamLeaguesSwitcherLeagueComponentProps extends ColumnProps {
    TeamLeague: TeamLeagueInterface;
    selectedTeamLeagueIDHandler?: (leagueID: number) => void;
    selectedLeagueStatus?: boolean;
}


export const TeamLeaguesSwitcherLeagueComponent: React.FC<TeamLeaguesSwitcherLeagueComponentProps> = ({
    TeamLeague, selectedTeamLeagueIDHandler, selectedLeagueStatus = false,
    xs = 12, sm, lg, xl
}) : JSX.Element | null => {


    if (!TeamLeague) {
        return null;
    }


    const {
        id: teamLeagueID, team_league_league_data: TeamLeagueData
    } = TeamLeague;

    if (!TeamLeagueData) {
        return null;
    }


    const leagueName = generateLeagueName(TeamLeagueData);


    return (
        <Col xs={xs} sm={sm} lg={lg} xl={xl} onClick={() => selectedTeamLeagueIDHandler? selectedTeamLeagueIDHandler(teamLeagueID) : undefined} className={(selectedLeagueStatus ? "team-leagues-switcher__league" : "team-leagues-switcher__leagues-list__league") + " text-truncate"}>
            <span>{leagueName}</span>
        </Col>
    );

};


export default TeamLeaguesSwitcherLeagueComponent;
