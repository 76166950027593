import React, {useState, useEffect } from "react";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Redirect
} from "react-router-dom";


import {
    Container
} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";

import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";


import {
    UseTeamIdBySlug, UseTeamFullInformation
} from "@Hooks/Api/Requests";


import TeamHeaderComponent from "@Components/Views/Pages/Games/Teams/Team/Header";
import TeamStatisticsComponent from "@Components/Views/Pages/Games/Teams/Team/Statistics";
import TeamInformationComponent from "@TeamsPages/Team/Information";
import TeamPlayersComponent from "@Components/Views/Pages/Games/Teams/Team/Players";


import "./index.scss";
import TeamCrewComponent from "@TeamsPages/Team/Crew";


export interface TeamPageComponentProps {
    match: any;
}


export const TeamPageComponent: React.FC<TeamPageComponentProps> = ({
    match
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const TEAM_SLUG = match?.params?.teamSlug;


    const [teamID, teamIDError] = UseTeamIdBySlug(TEAM_SLUG, 0);

    const [Team, TeamError] = UseTeamFullInformation(teamID, true, true, true, 1, 1);


    const [selectedTeamLeagueID, setSelectedTeamLeagueID] = useState(0);

    useEffect(() => {
        return () => {
            setSelectedTeamLeagueID(0);
        };
    }, []);


    if (!teamID) {
        return <MainContentLoaderComponent />;
    }

    if (teamIDError) {
        if (teamIDError.errorCode === 404) {
            return <Redirect to={`/${TeamsRoute.routeURL}`} />;
        } else {
            return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
        }
    }

    if (!Team) {
        return <MainContentLoaderComponent />;
    }

    if (TeamError) {
        if (TeamError.errorCode === 404) {
            return <Redirect to={`/${TeamsRoute.routeURL}`} />;
        } else {
            return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
        }
    }


    const {
        team_leagues: TeamLeaguesWithSquads,
        team_captain_id: teamCaptainID
    } = Team;


    let selectedLeagueId = 0;

    if (selectedTeamLeagueID && TeamLeaguesWithSquads) {
        const SelectedLeague = TeamLeaguesWithSquads.filter((TeamLeague) => TeamLeague.id === selectedTeamLeagueID)[0];
        if (SelectedLeague) {
            const { team_league_league_data : { league_id: leagueID } } = SelectedLeague;
            selectedLeagueId = leagueID;
        }
    }

    const {
        team_coach: TeamCoach
    } = Team;


    return (
        <Container fluid={true} id={"team-page"} className={"team-details team-page team-page-component"}>

            <TeamHeaderComponent
                Team={Team}
            />

            <TeamStatisticsComponent
                Team={Team}
            />

            <TeamInformationComponent
                Team={Team}
                selectedLeagueId={selectedLeagueId}
            />

            <TeamPlayersComponent
                TeamLeaguesWithSquads={TeamLeaguesWithSquads}
                teamCaptainID={teamCaptainID}
                selectedTeamLeagueID={selectedTeamLeagueID}
                selectedTeamLeagueIDHandler={setSelectedTeamLeagueID}
            />
            
            {!!TeamCoach &&
		        <TeamCrewComponent
			        TeamCoach={TeamCoach}
		        />
            }

        </Container>
        );

};


export default TeamPageComponent;
