import React from "react";


import { PartnerInterface } from "@Interfaces/Api";


import "./index.scss";


export interface SponsorsSponsorComponentProps {
    Sponsor: PartnerInterface;
}


export const SponsorsSponsorComponent: React.FC<SponsorsSponsorComponentProps> = ({
    Sponsor
}) : JSX.Element | null => {


    if (!Sponsor) return null;


    const {
        sponsor_name: sponsorName,
        sponsor_website: sponsorUrl,
        sponsor_logo: sponsorLogo,
        sponsor_active_status: sponsorActiveStatus
    } = Sponsor;


    if (!sponsorActiveStatus || !sponsorLogo) return null;


    return (
        <div className={`service-sponsors-section__carousel__item`}>
            {!!sponsorUrl ?

                <a
                    href={sponsorUrl}
                    title={sponsorName}
                    target={`_blank`}
                    rel={`noreferrer noopener`}
                    onClick={(e) => !!sponsorUrl ? null : e.preventDefault()}
                >
                    <img src={sponsorLogo} alt={sponsorName} />
                </a>

                :

                <span>
				    <img src={sponsorLogo} alt={sponsorName} />
			    </span>

            }
        </div>
    );

};


export default SponsorsSponsorComponent;
