export * from "@Dictionary/Pages/Leagues/Teams/Types/Phrases/Set";
export * from "@Dictionary/Pages/Leagues/Teams/Phrases";


import TeamsDictionaryInterface from "@Dictionary/Pages/Leagues/Teams/Types";


import {
    PLAYERS_HEADER_LABEL_PL, PLAYERS_HEADER_LABEL_EN,
    ADD_PLAYER_LABEL_PL, ADD_PLAYER_LABEL_EN,
    GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT_PL, GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT_EN
} from "@Dictionary/Pages/Leagues/Teams/Phrases";


export const TeamsDictionary: TeamsDictionaryInterface = {
    PL: {
        PLAYERS_HEADER_LABEL: PLAYERS_HEADER_LABEL_PL,
        ADD_PLAYER_LABEL: ADD_PLAYER_LABEL_PL,
        GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT: GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT_PL,
    },
    EN: {
        PLAYERS_HEADER_LABEL: PLAYERS_HEADER_LABEL_EN,
        ADD_PLAYER_LABEL: ADD_PLAYER_LABEL_EN,
        GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT: GO_TO_TEAM_PROFILE_LINK_TITLE_TEXT_EN
    }
};


export default TeamsDictionary;
