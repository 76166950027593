import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";

import NumberFormat from "react-number-format";


import {
    generatePlayerName
} from "@Services/Utilities/Games";


import {
    NO_AVATAR_IMAGE
} from "@Images";


import PlayerLeagueStatisticsInterface from "@ApiInterfaces/Leagues/Statistics/League/Player";


import "./index.scss";


export interface TeamTop5PlayersListPlayerComponentProps {
    Player: PlayerLeagueStatisticsInterface;
    orderNr: number;
}

export const TeamTop5PlayersListPlayerComponent: React.FC<TeamTop5PlayersListPlayerComponentProps> = ({
    Player, orderNr
}) => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }


    const {
        player_score: playerScore, player_data: PlayerData
    } = Player;


    const playerName = generatePlayerName(PlayerData);

    const { player_slug: playerSlug, player_profile_image: playerImage } = PlayerData;


    return (
        <Col xs={12} className={"team-details__team-information__team-top-players__list__players__player"}>
            <Row className={"team-details__team-information__team-top-players__list__players__player__content"}>

                <Col xs={1} className={"team-details__team-information__team-top-players__list__players__player__position"}>
                    <span>{orderNr}</span>
                </Col>

                <Col xs={2} className={"team-details__team-information__team-top-players__list__players__player__image"}>
                    <NavLink to={`/${PlayersRoute.routeURL}/${playerSlug}`} title={PlayersRoute.routeTitle}>
                        <img src={playerImage || NO_AVATAR_IMAGE} alt={""} />
                    </NavLink>
                </Col>

                <Col xs={6} className={"team-details__team-information__team-top-players__list__players__player__name"}>
                    <NavLink to={`/${PlayersRoute.routeURL}/${playerSlug}`} title={PlayersRoute.routeTitle}>
                        <span>{playerName}</span>
                    </NavLink>
                </Col>

                <Col xs={3} className={"team-details__team-information__team-top-players__list__players__player__score"}>
                    <NumberFormat value={playerScore} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                </Col>

            </Row>
        </Col>
    );

};


export default TeamTop5PlayersListPlayerComponent;
