import React, {useEffect, useRef, useState} from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Redirect} from "react-router-dom";


import {Container} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import PlayerMainDataComponent from "@Components/Views/Pages/Games/Players/Player/MainData";
import PlayerInformationDataComponent from "@Components/Views/Pages/Games/Players/Player/InformationData";
import PlayersDetailsDataComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData";


import ErrorMessageComponent from "@Components/Elements/Messages/Message/Error";
import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import scrollToDomElement from "@Utilities/Scrolling";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


type PlayerDetailsProps = {match: any;};


import "./index.scss";


export const PlayerDetailsComponent: React.FC<PlayerDetailsProps> = ({
    match
}) : JSX.Element | null => {


    const COMPONENT_REF = useRef(null);


    const PLAYER_SLUG = match?.params?.playerSlug;


    const [player, setPlayer] = useState<LeaguePlayerInterface | null>(null);
    const [playerError, setPlayerError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!PLAYER_SLUG) return;

        const PLAYER_STATISTICS_API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const getPlayer = async () : Promise<void> => {

            try {

                const response = await _restApiRequest(`${PLAYER_STATISTICS_API_URL}/teams/players/player-by-slug/?player_slug=${PLAYER_SLUG}`);

                if (response.status === 200) {
                    const responseContent: any = await response.json();
                    setPlayer(responseContent);
                } else {
                    setPlayerError({errorCode: response.status, errorMessage: ""});
                }

            } catch (e) {
                setPlayerError({errorCode: 500, errorMessage: ""});
            }

        };

        getPlayer();

        return () => setPlayer(null);
    }, [PLAYER_SLUG]);


    useEffect(() => {
        return () => {
            setPlayer(null);
            setPlayerError(null);
        };
    }, []);


    useEffect(() => {
        scrollToDomElement(COMPONENT_REF.current, 250);
    }, [player]);


    if (!PLAYER_SLUG) return <Redirect to={"/"} />;


    if (!!playerError) return <ErrorMessageComponent messageText={playerError.errorMessage} />;


    if (!player) return <MainContentLoaderComponent />;


    const {player_id: playerID} = player;


    return (
        <Container ref={COMPONENT_REF} fluid={true} id={"players-details-page"} className={"player-page"}>

            {!!player &&
	        <PlayerMainDataComponent
		        player={player}
	        />
            }

            <PlayerInformationDataComponent
                player={player}
            />

            <PlayersDetailsDataComponent
                playerID={playerID}
            />

        </Container>
    );

};


export default PlayerDetailsComponent;
