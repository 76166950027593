import React from "react";


import {Col, Row} from "react-bootstrap";


import StatisticsTableHeaderComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/Statistics/Table/Header";
import StatisticsTableHeadComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/Statistics/Table/Head";
import StatisticsTableDataComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/Statistics/Table/Data";


import PlayerStatisticsInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Statistics/Season/Player";


interface StatisticsTableComponentProps {
    tableType: "MEDIUM" | "SUMMARY";
    tableData: PlayerStatisticsInterface[];
    tableHeaderText?: string;
}


export const StatisticsTableComponent: React.FC<StatisticsTableComponentProps> = ({
    tableType, tableHeaderText  ,
    tableData
}) : JSX.Element | null => {


    if (!tableType || !tableData) return null;


    const TableData = tableData.map((season, i) =>
        <StatisticsTableDataComponent key={i} tableData={season} tableType={tableType} />
    );


    return (
        <Col xs={12} className={"player-statistics-table player-statistics-table-component"}>
            <Row className={"player-statistics-table-content"}>

                {!!tableHeaderText &&
	            <StatisticsTableHeaderComponent headerText={tableHeaderText} />
                }

                <StatisticsTableHeadComponent tableType={tableType} />

                {TableData}

            </Row>
        </Col>
    );


};


export default StatisticsTableComponent;
