import { combineReducers } from "redux";


export * from "@Reducers/Localization";
export * from "@Reducers/Localization";
export * from "@Reducers/Application";


import LocalizationReducer from "@Reducers/Localization";
import AuthenticationReducer from "@Reducers/Authentication";
import ApplicationReducer from "@Reducers/Application";


const ApplicationState = combineReducers({
    loc: LocalizationReducer,
    auth: AuthenticationReducer,
    app: ApplicationReducer
});


export default ApplicationState;
