import React from "react";

import {Redirect, Route, Switch} from "react-router-dom";


import PlayerDetailsComponent from "@Components/Views/Pages/Games/Players/Player";


export interface GamesPlayersComponentProps {}


export const GamesPlayersComponent: React.FC<GamesPlayersComponentProps> = ({}) : JSX.Element | null => {


    return (
        <Switch>

            <Route path={"/zawodnicy/:playerSlug"} exact render={props =>
                <PlayerDetailsComponent {...props} />
            }/>

            <Redirect to={`/druzyny`} />

        </Switch>
    );

};


export default GamesPlayersComponent;
