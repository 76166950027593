import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { UseLinksDictionaryPhrasesSet } from "@Hooks/Texts";



import { NavLink } from "react-router-dom";


import { Col, Row } from "react-bootstrap";


import { IMAGE_PLACEHOLDER } from "@Images";


import ApiGameSummaryTeamInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team";


interface GameSummaryTableTeamComponentProps {
    GameTeam: ApiGameSummaryTeamInterface;
    gameTeamType?: "HOME" | "AWAY";
}


export const GameSummaryTableTeamComponent: React.FC<GameSummaryTableTeamComponentProps> = ({
    GameTeam, gameTeamType
}) : JSX.Element | null => {


    const GO_TO_TEAM_PROFILE_PAGE_TITLE = UseLinksDictionaryPhrasesSet("GO_TO_TEAM_PROFILE_PAGE_TITLE");


    const TeamsRoute = UseRoute("TEAMS");

    const { routeURL: TeamsRouteURL } = TeamsRoute;


    if (!GameTeam) return null;


    const {
        team_data: {
            team_name: teamName,
            team_slug: teamSlug,
            team_logo: teamLogo,
            team_first_color: teamFirstColor,
            team_second_color: teamSecondColor,
        }
    } = GameTeam;

    const teamColor = teamFirstColor || teamSecondColor;


    let cssClasses = "game-information-summary__teams__team";
    if (gameTeamType === "HOME") cssClasses += " home-team";
    if (gameTeamType === "AWAY") cssClasses += " away-team";


    return (
        <Col xs={4} className={cssClasses}>
            <Row className={"summary-teams-team-content"}>

                <Col xs={12} className={"team-name"} style={{borderColor: teamColor || undefined}}>
                    <NavLink to={`/${TeamsRouteURL}/${teamSlug}`} title={GO_TO_TEAM_PROFILE_PAGE_TITLE}>
                        <span>{teamName}</span>
                    </NavLink>
                </Col>

                <Col xs={12} className={"team-logo"}>
                    <NavLink to={`/${TeamsRouteURL}/${teamSlug}`} title={GO_TO_TEAM_PROFILE_PAGE_TITLE}>
                        <img src={teamLogo || IMAGE_PLACEHOLDER} alt={teamName} />
                    </NavLink>
                </Col>

            </Row>
        </Col>
    )


};


export default GameSummaryTableTeamComponent;
