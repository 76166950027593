import React from "react";


export const GLOBAL_DATA = {
	restApiData: {
		// mainRestApiURL: "http://127.0.0.1:8020/api/v1/",
		// mainRestApiURL: "https://plkal.webprosdev.usermd.net/api/v1",
		mainRestApiURL: "https://plka-project-admin.webprosdev.usermd.net/api/v1/",
	},
	routerData: {
		newsPage: {page_url: {PL: "aktualnosci", EN: "aktualnosci"}, page_title: {PL: "Aktualności", EN: "News"}, page_id: 2, page_type: "aktualnosci", page_css: null, page_click_handler: null},
		// teamsPage: {page_url: {PL: "druzyny", EN: "teams"}, page_title: {PL: "Drużyny", EN: "Teams"}, page_id: 3, page_type: "teams-list-page", page_css: null, page_click_handler: null},
		resultsPage: {page_url: {PL: "terminarz", EN: "terminarz"}, page_title: {PL: "Wyniki / Terminarz", EN: "Results / Agenda"}, page_id: 3, page_type: "results-page", page_css: null, page_click_handler: null},
		tablesPage: {page_url: {PL: "tabele", EN: "tabele"}, page_title: {PL: "Tabele / Statystyki", EN: "Tables / Statistics"}, page_id: 3, page_type: "tables-page", page_css: null, page_click_handler: null},
		playersPage: {page_url: {PL: "druzyny", EN: "druzyny"}, page_title: {PL: "Druzyny / Zawodnicy", EN: "Teams / Players"}, page_id: 4, page_type: "players-list-page", page_css: null, page_click_handler: null},
		partnersPage: {page_url: {PL: "partnerzy", EN: "partnerzy"}, page_title: {PL: "Partnerzy", EN: "Partners"}, page_id: 11, page_type: "text-page", page_css: null, page_click_handler: null},
		// shopPage: {page_url: {PL: "sklep", EN: "shop"}, page_title: {PL: "Sklep", EN: "Shop"}, page_id: 12, page_type: "redirect-page", page_css: null, page_click_handler: null},
		contactPage: {page_url: {PL: "kontakt", EN: "kontakt"}, page_title: {PL: "Kontakt", EN: "Contact"}, page_id: 6, page_type: "contact-page", page_css: null, page_click_handler: null},
		downloadPage: {page_url: {PL: "do-pobrania", EN: "do-pobrania"}, page_title: {PL: "Do pobrania", EN: "Download"}, page_id: 6, page_type: "download-page", page_css: null, page_click_handler: null},
		transfersListPage: {page_url: {PL: "wolni-agenci", EN: "wolni-agenci"}, page_title: {PL: "Wolni Agenci", EN: "Free Agents"}, page_id: 12, page_type: "free-agents-page", page_css: null, page_click_handler: null},
		// _page: {page_url: "", page_title: "", page_id: null, page_type: "", page_css: null, page_click_handler: null},
	}
};


const GlobalDataContext = React.createContext(null);


export const GLOBALS = {
	GAMES: {
		FIELD_POSITIONS: {
			"0": "Pozycja",
			"1": "Pozycja",
			"2": "Pozycja",
			"3": "Pozycja",
			"4": "Pozycja",
			"5": "Pozycja",
		}
	}
};


export default GlobalDataContext;
