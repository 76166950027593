import { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_NEXT_GAMES_IN_ACTIVE_SEASON_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import {
    LeagueGameInterface
} from "@Interfaces/Api";


export const UseTeamNextGames = (teamID: number, gamesNumber: number = 5) : [LeagueGameInterface[] | null, ErrorMessageInterface | null] => {

    const [TeamNextGames, setTeamNextGames] = useState<LeagueGameInterface[] | null>(null);
    const [TeamNextGamesError, setTeamNextGamesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!teamID) {
            return setTeamNextGamesError({ errorCode: 400, errorMessage: "" });
        }

        const getTeamNextGames = async () => {

            try {
                const response = await _restApiRequest(
                    `${API_TEAM_NEXT_GAMES_IN_ACTIVE_SEASON_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString())}?games_number=${gamesNumber}&sort_type=league_game_date`
                );
                if (response.ok) {
                    setTeamNextGames(await response.json());
                } else {
                    setTeamNextGamesError({ errorCode: response.status, errorMessage: "" });
                }
            } catch (e) {
                setTeamNextGamesError({ errorCode: 500, errorMessage: "" });
            }
        };

        getTeamNextGames();

    }, [teamID]);

    useEffect(() => {
        return () => {
            setTeamNextGames(null);
            setTeamNextGamesError(null);
        };
    }, []);

    return [TeamNextGames, TeamNextGamesError];

};


export default UseTeamNextGames;
