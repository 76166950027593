import { UseLanguage } from "@States";


import ApplicationUserTypeInterface from "@Models/Users/User/UserType/Interface";


export const UseUserTypeName = (Type: ApplicationUserTypeInterface) => {

    const activeLanguage = UseLanguage();

    return Type.typeName[activeLanguage];

};


export default UseUserTypeName;
