export const TABLE_PLACE_SHORT_LABEL_PL = "M";
export const TABLE_PLACE_SHORT_LABEL_EN = "P";
export const TABLE_PLACE_LABEL_PL = "M";
export const TABLE_PLACE_LABEL_EN = "P";
export const TABLE_PLACE_TITLE_PL = "Miejsce";
export const TABLE_PLACE_TITLE_EN = "Place";
export const TABLE_TEAM_SHORT_LABEL_PL = "D";
export const TABLE_TEAM_SHORT_LABEL_EN = "T";
export const TABLE_TEAM_LABEL_PL = "D";
export const TABLE_TEAM_LABEL_EN = "T";
export const TABLE_TEAM_TITLE_PL = "Drużyna";
export const TABLE_TEAM_TITLE_EN = "Team";
export const TABLE_POINTS_SHORT_LABEL_PL = "P";
export const TABLE_POINTS_SHORT_LABEL_EN = "P";
export const TABLE_POINTS_LABEL_PL = "Pkt";
export const TABLE_POINTS_LABEL_EN = "Pts";
export const TABLE_POINTS_TITLE_PL = "Punkty";
export const TABLE_POINTS_TITLE_EN = "Points";
export const TABLE_GAMES_SHORT_LABEL_PL = "M";
export const TABLE_GAMES_SHORT_LABEL_EN = "G";
export const TABLE_GAMES_LABEL_PL = "M";
export const TABLE_GAMES_LABEL_EN = "G";
export const TABLE_GAMES_TITLE_PL = "Mecze";
export const TABLE_GAMES_TITLE_EN = "Games";
export const TABLE_VICTORIES_SHORT_LABEL_PL = "Z";
export const TABLE_VICTORIES_SHORT_LABEL_EN = "V";
export const TABLE_VICTORIES_LABEL_PL = "Zw";
export const TABLE_VICTORIES_LABEL_EN = "Vic";
export const TABLE_VICTORIES_TITLE_PL = "Zwycięstwa";
export const TABLE_VICTORIES_TITLE_EN = "Victories";
export const TABLE_DEFEATS_SHORT_LABEL_PL = "P";
export const TABLE_DEFEATS_SHORT_LABEL_EN = "D";
export const TABLE_DEFEATS_LABEL_PL = "Por";
export const TABLE_DEFEATS_LABEL_EN = "Def";
export const TABLE_DEFEATS_TITLE_PL = "Porażki";
export const TABLE_DEFEATS_TITLE_EN = "Defeats";
export const TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_PL = "Zd";
export const TABLE_TEAM_SMALL_POINTS_SHORT_LABEL_EN = "In";
export const TABLE_TEAM_SMALL_POINTS_LABEL_PL = "Pkt Zd";
export const TABLE_TEAM_SMALL_POINTS_LABEL_EN = "Pts In";
export const TABLE_TEAM_SMALL_POINTS_TITLE_PL = "Punkty Zdobyte";
export const TABLE_TEAM_SMALL_POINTS_TITLE_EN = "Points In";
export const TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_PL = "St";
export const TABLE_OPPONENTS_SMALL_POINTS_SHORT_LABEL_EN = "Out";
export const TABLE_OPPONENTS_SMALL_POINTS_LABEL_PL = "Pkt St";
export const TABLE_OPPONENTS_SMALL_POINTS_LABEL_EN = "Pts Out";
export const TABLE_OPPONENTS_SMALL_POINTS_TITLE_PL = "Punkty Stracone";
export const TABLE_OPPONENTS_SMALL_POINTS_TITLE_EN = "Points Out";
export const TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_PL = "R";
export const TABLE_SMALL_POINTS_DIFFERENCE_SHORT_LABEL_EN = "D";
export const TABLE_SMALL_POINTS_DIFFERENCE_LABEL_PL = "Różn";
export const TABLE_SMALL_POINTS_DIFFERENCE_LABEL_EN = "Diff";
export const TABLE_SMALL_POINTS_DIFFERENCE_TITLE_PL = "Różnica";
export const TABLE_SMALL_POINTS_DIFFERENCE_TITLE_EN = "Difference";

export const LEAGUE_SCHEDULE_TEXT_PL = "Terminarz";
export const LEAGUE_SCHEDULE_TEXT_EN = "Schedule";
export const LEAGUES_TABLES_HEADER_TEXT_PL = "Tabele Ligowe";
export const LEAGUES_TABLES_HEADER_TEXT_EN = "Leagues Tables";


export const NO_GAMES_STATISTICS_MESSAGE_TEXT_PL = "Brak statystyk meczowych";
export const NO_GAMES_STATISTICS_MESSAGE_TEXT_EN = "No games statistics";
export const NO_LEAGUES_MESSAGE_TEXT_PL = "Brak Lig";
export const NO_LEAGUES_MESSAGE_TEXT_EN = "No Leagues";
export const NO_SCHEDULE_MESSAGE_TEXT_PL = "Brak Terminarza";
export const NO_SCHEDULE_MESSAGE_TEXT_EN = "No league schedule";
export const NO_ROUND_SELECTED_MESSAGE_TEXT_PL = "Nie wybrano rundy";
export const NO_ROUND_SELECTED_MESSAGE_TEXT_EN = "No round selected";
