import React from "react";

import useTypedSelector from "@Store/_Hooks/UseTypedSelector";


import {NavLink} from "react-router-dom";


import {Col} from "react-bootstrap";


import ContainerSectionComponent from "@Components/Elements/Containers/MainSection";


import "./index.scss";


const TEXTS: any = {
    PL: {
        copyrights_word_text: "copyrights",
        developed_by_text: "Developed by"
    },
    EN: {
        copyrights_word_text: "copyrights",
        developed_by_text: "Developed by"
    }
};

const URLS: any = {
    PL: {
        privacyPolicyPage: {page_id: null, page_url: "polityka-prywatnosci", page_title: "Polityka Prywatności"},
        regulationsPage: {page_id: null, page_url: "regulamin", page_title: "Regulamin"}
    },
    EN: {
        privacyPolicyPage: {page_id: null, page_url: "polityka-prywatnosci", page_title: "Privacy Policy"},
        regulationsPage: {page_id: null, page_url: "regulamin", page_title: "Regulations"}
    }
};


export const ApplicationCopyrightsComponent = () => {


    const {activeLanguage} = useTypedSelector((state) => state.loc);

    const {
        copyrights_word_text: copyrightsWordText,
        developed_by_text: developedByText
    } = TEXTS[activeLanguage];


    const SystemPagesLinks = Object.entries(URLS[activeLanguage]).map(([pageName, pageData]: [any, any]) => {
        const {page_url: pageURL, page_title: pageTitle} = pageData;
        return (
            <NavLink key={pageName} to={`/${pageURL}`} title={pageTitle}>
                <span>{pageTitle}</span>
            </NavLink>
        );
    });


    return (
        <ContainerSectionComponent as={"aside"} id={"service-copyrights-component"} classes={"service-copyrights-section"}>

            <Col xs={12} lg={4} className={`service-copyrights-section__links`}>
                {SystemPagesLinks}
            </Col>

            <Col xs={12} lg={4} className={`service-copyrights-section__information text-center`}>
                <span className={`service-copyrights-section__information__label`}>{copyrightsWordText}</span>
                <span className={`service-copyrights-section__information__value`}>{`©${(new Date()).getFullYear()} PLKA`}</span>
            </Col>

            <Col xs={12} lg={{span: 3, offset: 1}} className={`service-copyrights-section__development`}>
                <span className={`service-copyrights-section__development__label`}>{developedByText}:</span>
                <a href={`https://webpros.pl`} title={developedByText} target={`_blank`} rel={`noreferrer noopener`}>webpros.pl</a>
            </Col>

        </ContainerSectionComponent>
    );

};


export default ApplicationCopyrightsComponent;
