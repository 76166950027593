import {
    useEffect, useState
} from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_LEAGUE_STATISTICS_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import MainStatisticsTypeType from "@GlobalData/Statistics/MainTypes/Type";
import StatisticsType from "@GlobalData/Statistics/Types/Type";

import PlayerLeagueStatisticsInterface from "@ApiInterfaces/Leagues/Statistics/League/Player";


export const UseLeagueStatistics = (
    leagueID: number,
    statisticsMainType: MainStatisticsTypeType = "MEDIUM",
    statisticsType: StatisticsType = "TPTS",
    leagueTeamID?: number,
    resultsLimit: number = 10,
) : [PlayerLeagueStatisticsInterface[] | null, ErrorMessageInterface | null] => {


    const [LeagueStatistics, setLeagueStatistics] = useState<PlayerLeagueStatisticsInterface[] | null>(null);
    const [LeagueStatisticsError, setLeagueStatisticsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {
        return () => {
            setLeagueStatistics(null);
            setLeagueStatisticsError(null);
        };
    }, [leagueID]);

    useEffect(() => {

        if (!leagueID) {
            return setLeagueStatisticsError({ errorCode: 400, errorMessage: "" });
        }

        const statisticsFilters = [
            `statistics_type=${statisticsMainType}`,
            `sort_type=${statisticsType}`,
            `results_limit=${resultsLimit}`,
        ];

        if (leagueTeamID) {
            statisticsFilters.push(`team_id=${leagueTeamID}`);
        }

        const statisticsFiltersString = statisticsFilters.join("&");


        const API_EP_URL = API_LEAGUE_STATISTICS_ENDPOINT_URL.replace("{LEAGUE_ID}", leagueID.toString()) + `?${statisticsFiltersString}`;

        const getLeagueStatistics = async () => {

            try {

                const response = await _restApiRequest(API_EP_URL);

                if (response.ok) {
                    const responseContent = await response.json();
                    if (responseContent.error) {
                        setLeagueStatistics([]);
                    } else {
                        setLeagueStatistics(responseContent);
                    }
                } else {
                    setLeagueStatisticsError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setLeagueStatisticsError({ errorCode: 500, errorMessage: "" });
            }

        };

        getLeagueStatistics();

    }, [leagueID, leagueTeamID, statisticsMainType, statisticsType]);


    return [LeagueStatistics, LeagueStatisticsError];

};


export default UseLeagueStatistics;
