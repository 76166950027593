import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Form, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";


const TEXTS: any = {
    PL: {
        seasons_label_text: "Sezon",
        no_seasons_message_text: "Brak sezonów",
    },
    EN: {
        seasons_label_text: "Season",
        no_seasons_message_text: "No seasons"
    },
};


interface Season {
    seasonID: number;
    seasonName: string;
}


export interface SeasonsAndLeaguesSeasonsComponentProps {
    seasonsList: Season[];
    selectedSeasonID: number;
    selectedSeasonIDHandler(id: number): void;
}


export const SeasonsAndLeaguesSeasonsComponent: React.FC<SeasonsAndLeaguesSeasonsComponentProps> = ({
    seasonsList,
    selectedSeasonID, selectedSeasonIDHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        seasons_label_text: SEASONS_LABEL_TEXT,
        no_seasons_message_text: NO_SEASONS_MESSAGE_TEXT
    } = TEXTS[activeLanguage];


    if (!seasonsList) return null;


    if (!seasonsList.length) return <MessageComponent messageText={NO_SEASONS_MESSAGE_TEXT} />;


    const Seasons = seasonsList.map((Season) =>
        <option key={Season.seasonID} value={Season.seasonID}>{Season.seasonName}</option>
    );


    return (
        <Col xs={12} lg={"auto"} className={"leagues-and-seasons-seasons leagues-and-seasons-seasons-component"}>
            <Row className={"leagues-and-seasons-seasons-content"}>

                <Col xs={"auto"} className={"seasons-label"}>
                    <span>{SEASONS_LABEL_TEXT}</span>
                </Col>

                <Col xs={"auto"} className={"seasons-list"}>
                    <Form.Control
                        as={"select"}
                        value={selectedSeasonID}
                        onChange={(e) => selectedSeasonIDHandler(+e.target.value)}
                        disabled={Seasons.length < 2}
                    >
                        {Seasons}
                    </Form.Control>
                </Col>

            </Row>
        </Col>
    );

};


export default SeasonsAndLeaguesSeasonsComponent;
