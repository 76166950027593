import UseLanguage from "@LocalizationStates/Language";

import DictionaryFormFieldType from "@Dictionary/Sets/Forms/Fields/Field";
import FormFieldsErrorsDictionary from "@Dictionary/Sets/Forms/Messages/Errors";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


export const UseFormFieldErrorMessage = (fieldName: DictionaryFormFieldType) : FormFieldValueErrorInterface => {

    const ActiveLanguage = UseLanguage();

    return FormFieldsErrorsDictionary[ActiveLanguage][fieldName];

};


export default UseFormFieldErrorMessage;
