import MessageLanguageInterface from "@GlobalData/Messages/_Interfaces/Language";


const DefaultSystemErrorMessagePL = "Wystąpił błąd - skontaktuj się z administratorem";
const DefaultSystemErrorMessageEN = "An error occurred - please contact admin";


export interface SystemErrorMessagesInterface {
    defaultErrorMessage: MessageLanguageInterface;
}


export const SystemErrorMessages: SystemErrorMessagesInterface = {
    defaultErrorMessage: {
        PL: DefaultSystemErrorMessagePL,
        EN: DefaultSystemErrorMessageEN
    }
};


export default SystemErrorMessages;
