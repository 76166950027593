import React, { ElementType } from "react";


import { Container, Row } from "react-bootstrap";


import "./index.scss";


export interface ContainerSectionComponentProps {
    as?: ElementType;
    id?: string;
    classes?: string | string[];
    children?: React.ReactNode;
    ref?: React.ForwardedRef<any>;
}


export const ContainerSectionComponent: React.FC<ContainerSectionComponentProps> = ({
    as = "div", id = "", classes = "",
    children, ref
}) : JSX.Element | null => {


    if (!children) return null;


    if (typeof classes === "object") classes = classes.join(" ");
    classes = "main-section-component" + (classes ? ` ${classes}` : "");

    return (
        <Container ref={ref} as={as} fluid={true} id={id} className={classes}>
            <Container className={"main-section-container"}>
                <Row className={"main-section-content"}>

                    {children}

                </Row>
            </Container>
        </Container>
    );

};


export default ContainerSectionComponent;
