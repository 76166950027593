import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";
import UseApiRequestStatus from "@Store/_Hooks/_States/Application/ApiRequest";


import {Col, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";

import LeaguesMessages from "@GlobalData/Messages/GUI/Leagues";


import LeagueInterface from "@Interfaces/Api/Leagues/Leagues/League";


export type LeaguesTablesLeaguesListComponentProps = {
    leaguesList: LeagueInterface[];
    selectedLeagueID: number | null;
    selectedLeagueIDHandler(leagueID: number | null): void;
    selectedLeagueHandler: (legue: LeagueInterface | null) => void;
};


export const LeaguesTablesLeaguesListComponent: React.FC<LeaguesTablesLeaguesListComponentProps> = ({
    leaguesList,
    selectedLeagueID = null, selectedLeagueIDHandler,
    selectedLeagueHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const apiRequestStatus = UseApiRequestStatus();


    if (!leaguesList) return null;


    const LeaguesList = leaguesList.map(league => {

        const {league_id: leagueID, league_name: leagueName, league_short_name: leagueShortName} = league;

        return (
            <Row key={leagueID} className={"leagues-list-league"}>
                <Col xs={12} className={"leagues-list-name" + (leagueID === selectedLeagueID ? " selected" : "")}>
                    <span onClick={
                        () => {
                            if (apiRequestStatus) return;
                            selectedLeagueIDHandler(leagueID);
                            selectedLeagueHandler(league);
                        }}>{leagueShortName || leagueName}</span>
                </Col>
            </Row>
        );

    });


    if (!LeaguesList || !LeaguesList.length) {
        return (
            <MessageComponent xs={12} messageText={LeaguesMessages.noLeagues[activeLanguage]} />
        );
    }

    if (!selectedLeagueID) {
        return (
            <MessageComponent xs={12} messageText={LeaguesMessages.noSelectedLeague[activeLanguage]} />
        );
    }


    return (
        <Col xs={3} lg={3} className={"leagues-tables-leagues-list-component"}>
            {LeaguesList}
        </Col>
    );

};


export default LeaguesTablesLeaguesListComponent;
