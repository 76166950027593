import { LeagueRoundInterface } from "@Interfaces/Api";


export const getClosestToToday = (
    LeagueRounds: LeagueRoundInterface[]
) : LeagueRoundInterface | null => {

    if (!LeagueRounds || !LeagueRounds?.length) {
        return null;
    }

    const TodayDate = new Date();

    let ClosestLeagueRound: LeagueRoundInterface | null = null;

    let previousClosestGameTimeDifference = 0;
    let upcomingClosestGameTimeDifference = 0;

    for (let LeagueRound of LeagueRounds) {

        if (!ClosestLeagueRound) {
            ClosestLeagueRound = LeagueRound;
        }

        const {
            league_round_games: LeagueRoundGames
        } = LeagueRound;

        if (!LeagueRoundGames) {
            continue;
        }

        for (let LeagueRoundGame of LeagueRoundGames) {

            const {
                league_game_date: leagueRoundGameDate
            } = LeagueRoundGame;

            if (!leagueRoundGameDate) {
                continue;
            }

            const LeagueRoundGameDate = new Date(leagueRoundGameDate);

            const gameTimeDifference = TodayDate.getTime() - LeagueRoundGameDate.getTime();

            if (gameTimeDifference > 0) {
                if (!previousClosestGameTimeDifference || gameTimeDifference < previousClosestGameTimeDifference) {
                    previousClosestGameTimeDifference = gameTimeDifference;
                    ClosestLeagueRound = LeagueRound;
                }
            } else if (gameTimeDifference < 0) {
                if (!upcomingClosestGameTimeDifference || gameTimeDifference > upcomingClosestGameTimeDifference) {
                    upcomingClosestGameTimeDifference = gameTimeDifference;
                    ClosestLeagueRound = LeagueRound;
                }
            } else {
                ClosestLeagueRound = LeagueRound;
                break;
            }

        }

    }

    return ClosestLeagueRound;

};
