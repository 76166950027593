export const NUMBER_LABEL_PL = "Nr";
export const NUMBER_LABEL_EN = "Nr";
export const NUMBER_SHORT_LABEL_PL = "Nr";
export const NUMBER_SHORT_LABEL_EN = "Nr";
export const NUMBER_TITLE_PL = "Numer";
export const NUMBER_TITLE_EN = "Number";
export const NAME_LABEL_PL = "ZAW";
export const NAME_LABEL_EN = "PLR";
export const NAME_SHORT_LABEL_PL = "Z";
export const NAME_SHORT_LABEL_EN = "PL";
export const NAME_TITLE_PL = "Zawodnik";
export const NAME_TITLE_EN = "Player";

export const POINTS_LABEL_PL = "Pkt";
export const POINTS_LABEL_EN = "Pts";
export const POINTS_SHORT_LABEL_PL = "PT";
export const POINTS_SHORT_LABEL_EN = "PT";
export const POINTS_TITLE_PL = "Punkty";
export const POINTS_TITLE_EN = "Points";
export const POINTS_COUNTER_LABEL_PL = "Pkt Kon";
export const POINTS_COUNTER_LABEL_EN = "Pts Con";
export const POINTS_COUNTER_SHORT_LABEL_PL = "PT KON";
export const POINTS_COUNTER_SHORT_LABEL_EN = "PT CON";
export const POINTS_COUNTER_TITLE_PL = "Punkty z kontry";
export const POINTS_COUNTER_TITLE_EN = "Points from Counter";
export const POINTS_COFFIN_LABEL_PL = "Pkt Tru";
export const POINTS_COFFIN_LABEL_EN = "Pts Cof";
export const POINTS_COFFIN_SHORT_LABEL_PL = "PT TRU";
export const POINTS_COFFIN_SHORT_LABEL_EN = "PT COF";
export const POINTS_COFFIN_TITLE_PL = "Punkty z trumny";
export const POINTS_COFFIN_TITLE_EN = "Points from Coffin";

export const MINUTES_LABEL_PL = "Min";
export const MINUTES_LABEL_EN = "Min";
export const MINUTES_SHORT_LABEL_PL = "M";
export const MINUTES_SHORT_LABEL_EN = "M";
export const MINUTES_TITLE_PL = "Minuty";
export const MINUTES_TITLE_EN = "Minutes";
export const THROWS_LABEL_PL = "RZ";
export const THROWS_LABEL_EN = "THR";
export const THROWS_SHORT_LABEL_PL = "R";
export const THROWS_SHORT_LABEL_EN = "TH";
export const THROWS_TITLE_PL = "Rzuty";
export const THROWS_TITLE_EN = "Throws";
export const THROWS_1PT_LABEL_PL = "1PT";
export const THROWS_1PT_LABEL_EN = "1PT";
export const THROWS_1PT_SHORT_LABEL_PL = "1P";
export const THROWS_1PT_SHORT_LABEL_EN = "1P";
export const THROWS_1PT_TITLE_PL = "Trafione Rzuty Wolne";
export const THROWS_1PT_TITLE_EN = "Accurate Free Throws";
export const THROWS_1PTA_LABEL_PL = "1PTA";
export const THROWS_1PTA_LABEL_EN = "1PTA";
export const THROWS_1PTA_SHORT_LABEL_PL = "1PA";
export const THROWS_1PTA_SHORT_LABEL_EN = "1PA";
export const THROWS_1PTA_TITLE_PL = "Nietrafione Rzuty Wolne";
export const THROWS_1PTA_TITLE_EN = "Not accurate Free Throws";
export const THROWS_1PT_EFF_LABEL_PL = "1PKT%";
export const THROWS_1PT_EFF_LABEL_EN = "1PTS%";
export const THROWS_1PT_EFF_SHORT_LABEL_PL = "1P%";
export const THROWS_1PT_EFF_SHORT_LABEL_EN = "1P%";
export const THROWS_1PT_EFF_TITLE_PL = "Skuteczność za 1";
export const THROWS_1PT_EFF_TITLE_EN = "1PT Throws Effectivity";
export const THROWS_2PT_LABEL_PL = "2PT";
export const THROWS_2PT_LABEL_EN = "2PT";
export const THROWS_2PT_SHORT_LABEL_PL = "2P";
export const THROWS_2PT_SHORT_LABEL_EN = "2P";
export const THROWS_2PT_TITLE_PL = "Trafione rzuty za 2";
export const THROWS_2PT_TITLE_EN = "Accurate 2PT Throws";
export const THROWS_2PTA_LABEL_PL = "2PTA";
export const THROWS_2PTA_LABEL_EN = "2PTA";
export const THROWS_2PTA_SHORT_LABEL_PL = "2PA";
export const THROWS_2PTA_SHORT_LABEL_EN = "2PA";
export const THROWS_2PTA_TITLE_PL = "Nietrafione rzuty za 2";
export const THROWS_2PTA_TITLE_EN = "Not accurate 2PT Throws";
export const THROWS_2PT_EFF_LABEL_PL = "2PKT%";
export const THROWS_2PT_EFF_LABEL_EN = "2PTS%";
export const THROWS_2PT_EFF_SHORT_LABEL_PL = "2P%";
export const THROWS_2PT_EFF_SHORT_LABEL_EN = "2P%";
export const THROWS_2PT_EFF_TITLE_PL = "Skuteczność za 2";
export const THROWS_2PT_EFF_TITLE_EN = "2PT Throws Effectivity";
export const THROWS_3PT_LABEL_PL = "3PT";
export const THROWS_3PT_LABEL_EN = "3PT";
export const THROWS_3PT_SHORT_LABEL_PL = "3P";
export const THROWS_3PT_SHORT_LABEL_EN = "3P";
export const THROWS_3PT_TITLE_PL = "Trafione rzuty za 3";
export const THROWS_3PT_TITLE_EN = "Accurate 3PT Throws";
export const THROWS_3PTA_LABEL_PL = "3PTA";
export const THROWS_3PTA_LABEL_EN = "3PTA";
export const THROWS_3PTA_SHORT_LABEL_PL = "3PA";
export const THROWS_3PTA_SHORT_LABEL_EN = "3PA";
export const THROWS_3PTA_TITLE_PL = "Nietrafione rzuty za 3";
export const THROWS_3PTA_TITLE_EN = "Not accurate 2PT Throws";
export const THROWS_3PT_EFF_LABEL_PL = "3PKT%";
export const THROWS_3PT_EFF_LABEL_EN = "3PTS%";
export const THROWS_3PT_EFF_SHORT_LABEL_PL = "3P%";
export const THROWS_3PT_EFF_SHORT_LABEL_EN = "3P%";
export const THROWS_3PT_EFF_TITLE_PL = "Skuteczność za 3";
export const THROWS_3PT_EFF_TITLE_EN = "3PT Throws Effectivity";
export const THROWS_GAME_LABEL_PL = "PS";
export const THROWS_GAME_LABEL_EN = "PS";
export const THROWS_GAME_SHORT_LABEL_PL = "PS";
export const THROWS_GAME_SHORT_LABEL_EN = "PS";
export const THROWS_GAME_TITLE_PL = "Trafione rzuty z gry";
export const THROWS_GAME_TITLE_EN = "Accurate Game Throws";
export const THROWS_GAME_A_LABEL_PL = "PSA";
export const THROWS_GAME_A_LABEL_EN = "PSA";
export const THROWS_GAME_A_SHORT_LABEL_PL = "PA";
export const THROWS_GAME_A_SHORT_LABEL_EN = "PA";
export const THROWS_GAME_A_TITLE_PL = "Nietrafione rzuty z gry";
export const THROWS_GAME_A_TITLE_EN = "Not accurate Game Throws";
export const THROWS_GAME_EFF_LABEL_PL = "PKT%";
export const THROWS_GAME_EFF_LABEL_EN = "PTS%";
export const THROWS_GAME_EFF_SHORT_LABEL_PL = "PS%";
export const THROWS_GAME_EFF_SHORT_LABEL_EN = "PS%";
export const THROWS_GAME_EFF_TITLE_PL = "Skuteczność z gry";
export const THROWS_GAME_EFF_TITLE_EN = "Game Throws Effectivity";
export const DEFENSIVE_REBOUNDS_LABEL_PL = "ZB O";
export const DEFENSIVE_REBOUNDS_LABEL_EN = "R DEF";
export const DEFENSIVE_REBOUNDS_SHORT_LABEL_PL = "ZO";
export const DEFENSIVE_REBOUNDS_SHORT_LABEL_EN = "RD";
export const DEFENSIVE_REBOUNDS_TITLE_PL = "Zbiórki w obronie";
export const DEFENSIVE_REBOUNDS_TITLE_EN = "Defensive Rebounds";
export const OFFENSIVE_REBOUNDS_LABEL_PL = "ZB A";
export const OFFENSIVE_REBOUNDS_LABEL_EN = "R OFF";
export const OFFENSIVE_REBOUNDS_SHORT_LABEL_PL = "ZA";
export const OFFENSIVE_REBOUNDS_SHORT_LABEL_EN = "RO";
export const OFFENSIVE_REBOUNDS_TITLE_PL = "Zbiórki w Ataku";
export const OFFENSIVE_REBOUNDS_TITLE_EN = "Offensive Rebounds";
export const TOTAL_REBOUNDS_LABEL_PL = "ZBS";
export const TOTAL_REBOUNDS_LABEL_EN = "T REB";
export const TOTAL_REBOUNDS_SHORT_LABEL_PL = "ZS";
export const TOTAL_REBOUNDS_SHORT_LABEL_EN = "RT";
export const TOTAL_REBOUNDS_TITLE_PL = "Suma Zbiórek";
export const TOTAL_REBOUNDS_TITLE_EN = "Rebounds Number";
export const ASSISTS_LABEL_PL = "AST";
export const ASSISTS_LABEL_EN = "AST";
export const ASSISTS_SHORT_LABEL_PL = "A";
export const ASSISTS_SHORT_LABEL_EN = "A";
export const ASSISTS_TITLE_PL = "Asysty";
export const ASSISTS_TITLE_EN = "Assists";
export const BLOCKS_LABEL_PL = "BLK";
export const BLOCKS_LABEL_EN = "BLK";
export const BLOCKS_SHORT_LABEL_PL = "B";
export const BLOCKS_SHORT_LABEL_EN = "B";
export const BLOCKS_TITLE_PL = "Bloki";
export const BLOCKS_TITLE_EN = "Blocks";
export const STEALS_LABEL_PL = "PRZ";
export const STEALS_LABEL_EN = "STL";
export const STEALS_SHORT_LABEL_PL = "P";
export const STEALS_SHORT_LABEL_EN = "S";
export const STEALS_TITLE_PL = "Przechwyty";
export const STEALS_TITLE_EN = "Steals";
export const TURNOVERS_LABEL_PL = "STR";
export const TURNOVERS_LABEL_EN = "TO";
export const TURNOVERS_SHORT_LABEL_PL = "S";
export const TURNOVERS_SHORT_LABEL_EN = "T";
export const TURNOVERS_TITLE_PL = "Straty";
export const TURNOVERS_TITLE_EN = "Turnovers";
export const EFFECTIVITY_LABEL_PL = "PRZ";
export const EFFECTIVITY_LABEL_EN = "EFF";
export const EFFECTIVITY_SHORT_LABEL_PL = "+/-";
export const EFFECTIVITY_SHORT_LABEL_EN = "+/-";
export const EFFECTIVITY_TITLE_PL = "Przydatność";
export const EFFECTIVITY_TITLE_EN = "Effectivity";
export const EVAL_LABEL_PL = "EVAL";
export const EVAL_LABEL_EN = "EVAL";
export const EVAL_SHORT_LABEL_PL = "E";
export const EVAL_SHORT_LABEL_EN = "E";
export const EVAL_TITLE_PL = "Eval";
export const EVAL_TITLE_EN = "Eval";
export const FOUL_PL = "Przewinienie";
export const FOUL_EN = "Foul";
export const FOULS_PL = "Faule";
export const FOULS_EN = "Fouls";
export const FOULS_LABEL_PL = "F";
export const FOULS_LABEL_EN = "F";
export const FOULS_TITLE_PL = "Przewinienia";
export const FOULS_TITLE_EN = "Fouls";
export const FOUL_UNSPORTSMANLIKE_PL = "Niesportowy";
export const FOUL_UNSPORTSMANLIKE_EN = "Unsportsmanlike";
export const FOULS_UNSPORTSMANLIKE_PL = "Niesportowe";
export const FOULS_UNSPORTSMANLIKE_EN = "Unsportsmanlike";
export const FOUL_TECHNICAL_PL = "Techniczny";
export const FOUL_TECHNICAL_EN = "Technical";
export const FOULS_TECHNICAL_PL = "Techniczne";
export const FOULS_TECHNICAL_EN = "Technicals";
export const FOUL_DISQUALIFICATION_PL = "Dyskwalifikacja";
export const FOUL_DISQUALIFICATION_EN = "Disqualification";
export const FOULS_DISQUALIFICATION_PL = "Dyskwalifikacje";
export const FOULS_DISQUALIFICATION_EN = "Disqualifications";

export const TEAM_OR_TRAINER_PL = "Drużynowe / Trener";
export const TEAM_OR_TRAINER_EN = "Team / Coach";

export const TOTAL_PL = "Suma";
export const TOTAL_EN = "Totals";
