import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import LeagueTeamSquadPlayersListPlayerComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers/PlayersList/Player";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";
import MessageComponent from "@MessagesElements/Content";


export interface LeagueTeamSquadPlayersListComponentProps {
    TeamLeagueSquad: TeamLeaguePlayerInterface[] | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const LeagueTeamSquadPlayersListComponent: React.FC<LeagueTeamSquadPlayersListComponentProps> = ({
    TeamLeagueSquad, updateTeamLeaguesTriggerHandler
}) => {


    const NO_PLAYERS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_PLAYERS_PHRASE_TEXT");


    if (!TeamLeagueSquad) {
        return null;
    }

    if (!TeamLeagueSquad?.length) {
        return <MessageComponent messageText={NO_PLAYERS_PHRASE_TEXT} />;
    }


    const TeamLeaguePlayers = TeamLeagueSquad.map((Player, i) =>
        <LeagueTeamSquadPlayersListPlayerComponent
            key={Player.id}
            TeamLeaguePlayer={Player}
            updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
        />
    );


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__content"}>
                {TeamLeaguePlayers}
            </Row>
        </Col>
    );

};


export default LeagueTeamSquadPlayersListComponent;
