import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TransfersListPlayersHeaderComponent from "@GamesPages/Transfers/List/Players/Header";
import TransfersListPlayersListComponent from "@GamesPages/Transfers/List/Players/List";


import "./index.scss";


import {
    ListByLeaguesLeagueInterface
} from "@Interfaces/Api";


export interface TransfersListPlayersComponentProps {
    SelectedLeague?: ListByLeaguesLeagueInterface;
}


export const TransfersListPlayersComponent: React.FC<TransfersListPlayersComponentProps> = ({
    SelectedLeague
}) : JSX.Element | null => {


    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");


    if (!SelectedLeague) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} />;
    }


    const {
        league_requests: TransfersListPlayers
    } = SelectedLeague;


    if (!TransfersListPlayers) {
        return null;
    }


    return (
        <Col xs={12} className={"transfers-list__list__players"}>
            <Row className={"transfers-list__list__players__content"}>

                <TransfersListPlayersHeaderComponent />

                <TransfersListPlayersListComponent
                    Players={TransfersListPlayers}
                />

            </Row>
        </Col>
    );

};


export default TransfersListPlayersComponent;
