import React, { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";
import _getDataFromApi from "@Services/API/Requests";

import {
    API_FREE_AGENTS_LIST_ENDPOINT_URL, API_LEAGUES_LIST_ENDPOINT_URL, API_PLAYER_ENDPOINT_URL,
    API_PLAYER_FREE_AGENTS_REQUEST_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


import FreeAgentRequestsListComponent from "@Pages/User/Authenticated/Profile/Player/Content/FreeAgentsRequests/List";
import CreateFreeAgentRequestComponent from "@Pages/User/Authenticated/Profile/Player/Content/FreeAgentsRequests/Create";


import {
    FreeAgentRequestInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface PlayerProfileFreeAgentsComponentProps {
    playerID: number;
}

export const PlayerProfileFreeAgentsComponent: React.FC<PlayerProfileFreeAgentsComponentProps> = ({
    playerID
}) : JSX.Element | null => {


    const MY_REQUEST_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("MY_REQUESTS_PHRASE_TEXT");


    const [freeAgentsRequestsUpdateTrigger, setFreeAgentsRequestsUpdateTrigger] = useState(false);

    const [FreeAgentRequests, setFreeAgentRequests] = useState<FreeAgentRequestInterface[] | null>(null);
    const [FreeAgentRequestsError, setFreeAgentRequestsError] = useState<ErrorMessageInterface | null>(null);

    const FREE_AGENTS_REQUESTS_API_URL = API_PLAYER_FREE_AGENTS_REQUEST_ENDPOINT_URL.replace("{PLAYER_ID}", playerID.toString());


    useEffect(() => {

        if (FreeAgentRequests && !freeAgentsRequestsUpdateTrigger) {
            return;
        }

        if (freeAgentsRequestsUpdateTrigger) {
            setFreeAgentsRequestsUpdateTrigger(false);
        }

        const getFreeAgentsRequests = async () => {
            try {

                await _getDataFromApi(
                    FREE_AGENTS_REQUESTS_API_URL,
                    setFreeAgentRequests, setFreeAgentRequestsError
                );

            } catch (e) {
                setFreeAgentRequestsError({ errorCode: 500, errorMessage: "" });
            }
        };

        getFreeAgentsRequests();

    }, [freeAgentsRequestsUpdateTrigger]);


    useEffect(() => {
        return () => {
            setFreeAgentsRequestsUpdateTrigger(false);
            setFreeAgentRequests(null);
            setFreeAgentRequestsError(null);
        }
    }, []);


    const freeAgentRequestSubmitHandler = async(freeAgentRequestLeagueID: string) => {

        if (!freeAgentRequestLeagueID) {
            return false;
        }

        const formData = new FormData();

        formData.append("free_agent_request_status", "1");
        formData.append("free_agent_request_player", API_PLAYER_ENDPOINT_URL.replace("{PLAYER_ID}", playerID.toString()));
        formData.append("free_agent_request_league", `${API_LEAGUES_LIST_ENDPOINT_URL}${freeAgentRequestLeagueID}/`);

        try {

            const response = await _restApiRequest(
                API_FREE_AGENTS_LIST_ENDPOINT_URL, formData,
                "POST",
            );

            setFreeAgentsRequestsUpdateTrigger(true);

            return response.ok;

        } catch (e) {
            return false;
        }

        return true;

    };


    const freeAgentRequestChangeHandler = async (requestID: number, requestUpdatedStatus: 1 | 2 | 3 | 4) => {

        const formData = new FormData();
        formData.append("free_agent_request_status", requestUpdatedStatus.toString());

        try {

            const response = await _restApiRequest(
                `${API_FREE_AGENTS_LIST_ENDPOINT_URL}${requestID}/`,
                formData, "PATCH"
            );

            setFreeAgentsRequestsUpdateTrigger(true);

            return response.ok;

        } catch (e) {
            return false;
        }

        return true;

    };


    return (
        <Col xs={12} className={"free-agent-requests"}>
            <Row className={"free-agent-requests__content"}>

                <Col xs={12} className={"free-agent-requests__header"}>
                    <span>{MY_REQUEST_PHRASE_TEXT}</span>
                </Col>

                <FreeAgentRequestsListComponent
                    FreeAgentRequests={FreeAgentRequests}
                    FreeAgentRequestsError={FreeAgentRequestsError}
                    requestUpdateHandler={freeAgentRequestChangeHandler}
                />

                <CreateFreeAgentRequestComponent
                    FreeAgentRequests={FreeAgentRequests}
                    apiRequestHandler={freeAgentRequestSubmitHandler}
                />

            </Row>
        </Col>
    );

};


export default PlayerProfileFreeAgentsComponent;
