import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface DefaultLeaguesSwitcherHeaderComponentProps {
    headerText?: string;
}

export const DefaultLeaguesSwitcherHeaderComponent: React.FC<DefaultLeaguesSwitcherHeaderComponentProps> = ({
    headerText
}) : JSX.Element | null => {


    const CHOOSE_LEAGUE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("CHOOSE_LEAGUE_PHRASE_TEXT");

    if (headerText === undefined) {
        headerText = CHOOSE_LEAGUE_PHRASE_TEXT;
    }

    return (
        <Col xs={12} className={"leagues-switcher__header leagues-switcher--default__header"}>
            <span className={"leagues-switcher__header__text leagues-switcher--default__header__text"}>{headerText}</span>
        </Col>
    );

};


export default DefaultLeaguesSwitcherHeaderComponent;
