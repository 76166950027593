import React from "react";


import {Container} from "react-bootstrap";


export const PageContainerComponent = ({children = null, forwardRef = null, id = "", cssClasses = ""}) => {
	
	
	if (!children) return null;
	
	
	cssClasses = (cssClasses ? `${cssClasses} ` : "") + "page-container-component";
	
	
	return (
		<Container ref={forwardRef} fluid={true} id={id} className={cssClasses}>
			{children}
		</Container>
	)
	
};


export default PageContainerComponent;
