import React, {useEffect, useRef, useState} from "react";


import { UseGlobalsDictionaryPhrasesSet } from "@Hooks/Texts";


import _getDataFromApi from "@Services/API/Requests";

import {
    API_FILES_FOR_DOWNLOAD_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    PageContainerComponent, DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import FilesGroupsComponent from "@Pages/Common/DownloadPage/FilesGroups";


import FileGroupInterface from "@Interfaces/Api/_Common/Files/Group";


import scrollToDomElement from "@Utilities/Scrolling";


import "./index.scss";


export interface FilesForDownloadPageComponentProps {}


export const FilesForDownloadPageComponent: React.FC<FilesForDownloadPageComponentProps> = () : JSX.Element | null => {


    const ComponentRef = useRef(null);

    useEffect(() => {
        if (!ComponentRef?.current) return;
        scrollToDomElement(ComponentRef.current, 250);
    }, []);



    const FILES_FOR_DOWNLOAD_HEADER_TEXT = UseGlobalsDictionaryPhrasesSet("FILES_FOR_DOWNLOAD_HEADER_TEXT");


    const [FilesGroups, setFilesGroups] = useState<FileGroupInterface[] | null>(null);
    const [FilesGroupsError, setFilesGroupsError] = useState<ErrorMessageInterface | null>(null);


    useEffect(
        () => {

            const getFilesGroups = async () => {
                await _getDataFromApi(
                    API_FILES_FOR_DOWNLOAD_ENDPOINT_URL,
                    setFilesGroups, setFilesGroupsError
                );
            }

            getFilesGroups();

        }, []
    );


    return (
        <PageContainerComponent id={"files-for-download-page"} forwardRef={ComponentRef}>

            <DefaultPageHeaderComponent headerText={FILES_FOR_DOWNLOAD_HEADER_TEXT} />

            <PageContentComponent id={"files-for-download"} cssClasses={"files-for-download"}>

                <FilesGroupsComponent
                    FilesGroups={FilesGroups}
                    FilesGroupsError={FilesGroupsError}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );

}


export default FilesForDownloadPageComponent;
