import React from "react";

import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Row, Col
} from "react-bootstrap";


import NumberFormat from "react-number-format";


import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


import NO_AVATAR_IMAGE from "@Images/no-avatar.jpg";


import {
    generatePlayerName
} from "@Services/Utilities/Games";


export interface LeaguesStatisticsTablePlayerComponentProps {
    place: number;
    player: PlayerLeagueStatisticsInterface;
    selectedStatisticsMainType: "MEDIUM" | "TOTAL";
}


export const LeaguesStatisticsTablePlayerComponent: React.FC<LeaguesStatisticsTablePlayerComponentProps> = ({
    place, player, selectedStatisticsMainType
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");
    const PlayersRoute = UseRoute("PLAYERS");


    if (!player) return null;


    const {
        player_score: playerScoreValue,
        player_data: PlayerInformation,
        team_data: PlayerTeam,
    } = player;


    if (!PlayerInformation) {
        return null;
    }


    const { player_slug: playerSlug, player_profile_image: playerProfileImage } = PlayerInformation;

    const playerName = generatePlayerName(PlayerInformation);
    const playerURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;


    const {
        team_league_team_name: teamLeagueName, team_league_team_data: TeamData
    } = PlayerTeam;

    const {
        team_name: teamName, team_slug: playerTeamSlug
    } = TeamData;

    const playerTeamName = teamLeagueName || teamName;
    const playerTeamURL = "/" + TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <Col xs={12} className={"league-statistics-table-player league-statistics-table-player-component"}>
            <Row className={"league-statistics-table-player-content"}>

                <Col xs={1} className={"player-place"}>
                    <span>{place}</span>
                </Col>

                <Col xs={2} className={"player-image"}>
                    <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                        <img src={playerProfileImage || NO_AVATAR_IMAGE} alt={``}/>
                    </NavLink>
                </Col>

                <Col xs={6} className={"player-data"}>
                    <Row className={"player-name-data"}>
                        <Col xs={12} className={"player-name"}>
                            <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                                <span>{playerName}</span>
                            </NavLink>
                        </Col>
                    </Row>
                    {!!playerTeamSlug &&
	                <Row className={"player-team-data"}>
		                <Col xs={12} className={"player-team"}>
			                <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle}>
				                <span>{playerTeamName}</span>
			                </NavLink>
		                </Col>
	                </Row>
                    }
                </Col>

                <Col xs={2} lg={3} className={"player-score"}>
                    <NumberFormat value={playerScoreValue} decimalScale={selectedStatisticsMainType === "MEDIUM" ? 1 : 0} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                </Col>

            </Row>
        </Col>
    );

}


export default LeaguesStatisticsTablePlayerComponent;
