import React from "react";


import {Col} from "react-bootstrap";

import {
    FaFacebookF, FaInstagram, FaTwitter, FaYoutube, IoLogoTiktok
} from "react-icons/all";


const SOCIALS_ICONS : any = {
    facebook: FaFacebookF,
    twitter: FaTwitter,
    youtube: FaYoutube,
    instagram: FaInstagram,
    tiktok: IoLogoTiktok
};


import SocialInterface from "@Interfaces/Api/_Common/Socials/Social";


export interface SocialsItemComponentProps {
    social: SocialInterface;
}


export const SocialsItemComponent: React.FC<SocialsItemComponentProps> = ({
    social
}) : JSX.Element | null => {


    if (!social) return null;


    const {
        social_media_active_status: socialActiveStatus,
        social_media_name: socialTitle,
        social_media_type: socialType,
        social_media_url: socialUrl
    } = social;


    const SocialIcon = SOCIALS_ICONS[socialType];


    if (!socialActiveStatus || !socialUrl) return null;


    return (
        <Col as={`li`} lg={"auto"} className={`socials-list-item`}>
            <a href={socialUrl} title={socialTitle} target={`_blank`} rel={`noreferrer noopener`} className={"socials-list-link"}>
                <SocialIcon />
            </a>
        </Col>
    );

};


export default SocialsItemComponent;
