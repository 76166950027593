import React from "react";


import { Container, Row, Col } from "react-bootstrap";


import "./index.scss";


export interface DefaultPageHeaderComponentProps {
    headerText: string;
    cssClasses?: string | string[];
}

export const DefaultPageHeaderComponent: React.FC<DefaultPageHeaderComponentProps> = ({
    headerText,
    cssClasses= ""
}) : JSX.Element | null => {


    if (!headerText) return null;


    if (!!cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    const classes = "page-header page-header-component default-page-header-component default-page-header" + (!!cssClasses ? ` ${cssClasses}` : "");


    return (
        <Container fluid={true} className={classes}>
            <Container className={"default-page-header-container"}>
                <Row className={"default-page-header-content"}>

                    <Col xs={12} as={"h1"} className={"default-page-header-heading"}>
                        <span>{headerText}</span>
                    </Col>

                </Row>
            </Container>
        </Container>
    );

};


export default DefaultPageHeaderComponent;
