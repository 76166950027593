import { useDispatch } from "react-redux";
import { bindActionCreators } from "redux";

import ActionCreators from "@Action-Creators";


export const UseActions = () => {

    const dispatcher = useDispatch();

    return bindActionCreators(ActionCreators, dispatcher);

};


export default UseActions;
