import React from "react";


import { Col } from "react-bootstrap";


import {
    Chart as ChartJS, CategoryScale, LinearScale,
    BarElement,
    Title, Tooltip, Legend,
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale,BarElement, Title, Tooltip, Legend);


import { Bar } from "react-chartjs-2";


import "./index.scss";


import DiagramValueInterface from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Types/Values/Value";

import GameResultDetailsDiagramsTeamsInterface from "@Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams/Types/Teams";


export interface GameResultDiagramsDiagramComponentProps {
    selectedQuartersLength: number;
    quarterNumber: string;
    QuarterData: DiagramValueInterface[];
    homeTeamID: number;
    awayTeamID: number;
    Teams: GameResultDetailsDiagramsTeamsInterface;
    scalesValues: number[];
    stepValue: number;
}


export const GameResultDiagramsDiagramComponent: React.FC<GameResultDiagramsDiagramComponentProps> = ({
    selectedQuartersLength, quarterNumber, QuarterData,
    homeTeamID, awayTeamID, Teams,
    scalesValues, stepValue
}) : JSX.Element | null => {


    if (
        !quarterNumber || !QuarterData ||
        !homeTeamID || !awayTeamID
    ) return null;


    const HomeTeam = Teams[homeTeamID];
    const AwayTeam = Teams[awayTeamID];


    if (!HomeTeam || !AwayTeam) return null;


    const ResultsValues = QuarterData.map(
        (result) => result.scoreValue
    );

    const ResultsLabels = QuarterData.map(
        (result) => `${Math.abs(result.scoreValue)} PT`
    );

    const ResultsColors = QuarterData.map(
        (result) => {
            if (result.leadingTeamID === 0) return "transparent";
            return result.leadingTeamID === homeTeamID ? HomeTeam.teamColor : AwayTeam.teamColor;
        }
    );


    return (
        <Col xs={12} sm={6} lg={selectedQuartersLength !== 5 ? 12 / selectedQuartersLength : "auto"} key={quarterNumber} className={"diagrams-chart diagrams-chart-component"}>

            <aside className={"diagram-scales"}>
                {scalesValues.map((value, i) =>
                    <span key={i} className={"diagram-scales-value"}>
                        {Math.ceil(Math.abs(value))}
                    </span>
                )}
            </aside>

            <Bar
                options={{
                    responsive: true,
                    plugins: {
                        legend: {
                            display: false
                        },
                        title: {
                            display: false
                        },
                        tooltip: {
                            // enabled: false,

                            callbacks: {
                                label(tooltipItem) {

                                    const ResultData = QuarterData[tooltipItem.dataIndex];

                                    if (!ResultData) return "";

                                    const { leadingTeamID } = ResultData;

                                    const ResultTeam = Teams[leadingTeamID];

                                    if (!ResultTeam) return leadingTeamID.toString();

                                    return ResultTeam.teamShortName || "";

                                }
                            }
                        }
                    },
                    scales: {
                        y: {
                            // display: false,
                            beginAtZero: true,
                            min: scalesValues[0] * -1,
                            max: scalesValues[0],
                            ticks: {
                                // display: i === 0,
                                display: false,
                                color: "D8D9D9",
                                padding: 0,
                                z: -1,
                                font: { size: 9 },
                                maxTicksLimit: 11,
                                stepSize: stepValue
                            },
                            grid: {
                                offset: false,
                                drawTicks: false,
                                borderColor: "var(--default-border-color)",
                                display: true,
                                drawOnChartArea: true
                            },
                            alignToPixels: true,
                            type: "linear"
                        },
                        x: {
                            beginAtZero: true,
                            ticks: {
                                display: false,
                            },
                            grid: {
                                offset: false,
                                display: false,
                                borderWidth: 0,
                                drawBorder: false,
                                drawOnChartArea: true
                            },
                            stacked: true
                        },
                    },
                    layout: {
                        autoPadding: false, padding: 0
                    },
                    elements: {
                        bar: {
                            borderWidth: 0,
                            // inflateAmount: 0,
                        },
                    },
                    // bar: {
                    //     datasets: {}
                    // }
                }}
                data={{
                    labels: [...ResultsLabels],
                    datasets: [{
                        categoryPercentage: 1.0,
                        barPercentage: 1,
                        borderWidth: 0,
                        borderColor: "transparent",
                        data: [...ResultsValues],
                        backgroundColor: [...ResultsColors],
                    }],
                }}
                style={{ minHeight: 250, maxHeight: 250 }}
            />


            <span className={"diagram-chart-legend"}>Q{quarterNumber}</span>


        </Col>
    );

};


export default GameResultDiagramsDiagramComponent;
