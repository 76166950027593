import React, {useEffect, useState} from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import ErrorMessageComponent from "@MessagesElements/Message/Error";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    SingleContentLoaderComponent
} from "@LoadersElements";


import TeamPlayersListDisplaySwitcherComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayersList/ListDisplaySwitcher";
import TeamPlayersListFiltersComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayersList/ListFilters";
import TeamPlayersListTeamPlayersComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Players/PlayersList/ListPlayers";


import {
    TeamPlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamCaptainTeamPlayersListComponentProps {
    TeamPlayers: TeamPlayerInterface[] | null;
    TeamPlayersError: ErrorMessageInterface | null;
    updateTeamPlayersTrigger: (status: boolean) => void;
}


export const TeamCaptainTeamPlayersListComponent: React.FC<TeamCaptainTeamPlayersListComponentProps> = ({
    TeamPlayers, TeamPlayersError, updateTeamPlayersTrigger
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");


    const [showPlayersList, setShowPlayersList] = useState(false);
    const [showPastPlayers, setShowPastPlayers] = useState(false);
    const [playersListSortType, setPlayersListSortType] = useState(1);

    useEffect(() => {
        return () => {
            setShowPlayersList(true);
            setPlayersListSortType(1);
            setShowPastPlayers(false);
        };
    }, []);


    if (TeamPlayersError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!TeamPlayers) {
        return <SingleContentLoaderComponent />;
    }


    let TeamPlayersList = TeamPlayers;

    if (!showPastPlayers) {
        TeamPlayersList = TeamPlayersList.filter((TeamPlayer) => {
            const { team_player_status: teamPlayerStatus } = TeamPlayer;
            return teamPlayerStatus === 1;
        });
    }

    if (playersListSortType !== 1) {}


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__players__players-list"}>
            <Row className={"player-profile__captain-zone__team__players__players-list__content"}>

                <TeamPlayersListDisplaySwitcherComponent
                    showPlayersList={showPlayersList}
                    showPlayersListHandler={setShowPlayersList}
                />

                {showPlayersList &&
                <TeamPlayersListFiltersComponent
                    showPastPlayers={showPastPlayers}
                    showPastPlayersHandler={setShowPastPlayers}
                />
                }

                {showPlayersList &&
                <TeamPlayersListTeamPlayersComponent
                    TeamPlayers={TeamPlayersList}
                    updateTeamPlayersTrigger={updateTeamPlayersTrigger}
                />
                }

            </Row>
        </Col>
    )

};


export default TeamCaptainTeamPlayersListComponent;
