import SuccessMessagesInterface from "@Dictionary/Messages/Success/types";


import {
    DEFAULT_UPDATE_DATA_RESPONSE_SUCCESS_PL, DEFAULT_UPDATE_DATA_RESPONSE_SUCCESS_EN,
    DEFAULT_MESSAGE_SENT_PL, DEFAULT_MESSAGE_SENT_EN,
    DEFAULT_DELETED_STATUS_PL, DEFAULT_DELETED_STATUS_EN
} from "@Dictionary/Messages/Success/consts";


export const SuccessMessages: SuccessMessagesInterface = {
    PL: {
        DEFAULT: "",
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_RESPONSE: "",
        DEFAULT_UPDATE_DATA_RESPONSE: DEFAULT_UPDATE_DATA_RESPONSE_SUCCESS_PL,
        DEFAULT_MESSAGE_SENT_STATUS: DEFAULT_MESSAGE_SENT_PL,
        DEFAULT_DELETED_STATUS: DEFAULT_DELETED_STATUS_PL,
    },
    EN: {
        DEFAULT: "",
        DEFAULT_SYSTEM: "",
        DEFAULT_REQUEST: "",
        DEFAULT_RESPONSE: "",
        DEFAULT_UPDATE_DATA_RESPONSE: DEFAULT_UPDATE_DATA_RESPONSE_SUCCESS_EN,
        DEFAULT_MESSAGE_SENT_STATUS: DEFAULT_MESSAGE_SENT_EN,
        DEFAULT_DELETED_STATUS: DEFAULT_DELETED_STATUS_EN,
    }
};


export default SuccessMessages;
