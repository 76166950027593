import React, { useEffect, useState } from "react";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import { UseLoggedUser } from "@States";

import { UseSetPopupMessageAction } from "@StatesActions";

import { UseRoute } from "@Hooks/Routes";


import {
    UseErrorMessage,
    UseFormFieldErrorMessage,
    UseFormsDictionaryPhrasesSet, UseNotAuthenticatedUserDictionaryPhrasesSet
} from "@Hooks/Texts";


import { Redirect } from "react-router-dom";


import { Col } from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";

import {
    PageContainerComponent,
    DefaultPageHeaderComponent, PageContentComponent
} from "@ContentElements";

import {
    FormComponent,
    FormTextFieldComponent, FormSubmitFieldComponent
} from "@FormsElements";

import { FormFieldValueErrorInterface } from "@CommonFormsTypes";


import { isEmail } from "@Services/Utilities/Tools";

import "./index.scss";


export interface PasswordRemindComponentProps {}


export const PasswordResetComponent: React.FC<PasswordRemindComponentProps> = ({}) => {


    const UseSetPopupMessage = UseSetPopupMessageAction();


    const PASSWORD_RESET_PAGE_TITLE = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_RESET_PAGE_TITLE");
    const PASSWORD_RESET_PAGE_MESSAGE_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_RESET_PAGE_MESSAGE_TEXT");
    const PASSWORD_RESET_PAGE_BUTTON_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_RESET_PAGE_BUTTON_TEXT");
    const PASSWORD_RESET_SUCCESS_MESSAGE_TEXT = UseNotAuthenticatedUserDictionaryPhrasesSet("PASSWORD_RESET_SUCCESS_MESSAGE_TEXT");

    const EMAIL_ADDRESS_LABEL = UseFormsDictionaryPhrasesSet("EMAIL_ADDRESS");

    const EmailAddressErrorMessage = UseFormFieldErrorMessage("EMAIL_ADDRESS");


    const PASSWORD_RESET_RESPONSE_ERROR_MESSAGE_TEXT = UseErrorMessage("DEFAULT");


    const [resetUserPasswordSuccessStatus, setResetUserPasswordSuccessStatus] = useState(false);

    const [userEmailAddress, setUserEmailAddress] = useState("");
    const [userEmailAddressError, setUserEmailAddressError] = useState<FormFieldValueErrorInterface | null>(null);

    const changeUserEmailAddress = (e: React.ChangeEvent<HTMLInputElement>) => {
        const emailAddress = e.target.value;
        if (isEmail(emailAddress) && userEmailAddressError) {
            setUserEmailAddressError(null);
        }
        setUserEmailAddress(emailAddress);
    };

    useEffect(() => {
        return () => {
            setResetUserPasswordSuccessStatus(false);
            setUserEmailAddress("");
            setUserEmailAddressError(null);
        };
    }, []);


    const ProfileRoute = UseRoute("PROFILE");


    const ApplicationUser = UseLoggedUser();

    if (ApplicationUser) return <Redirect to={ProfileRoute.routeURL} />;

    if (resetUserPasswordSuccessStatus) {
        return (
            <MessageComponent
                messageText={PASSWORD_RESET_SUCCESS_MESSAGE_TEXT}
                messageType={"success"}
                cssClasses={"bordered margin-vertical-50 padding-15"}
            />
        );
    }


    const resetUserPasswordHandler = async () => {
        if (!isEmail(userEmailAddress)) {
            return setUserEmailAddressError(EmailAddressErrorMessage);
        }
        await resetUserPasswordRequestHandler();
    };

    const resetUserPasswordRequestHandler = async () => {

        const API_URL = REST_API_ENDPOINTS_URLS.REST_API_ROOT_URL;

        const formData = new FormData();
        formData.append("player_email", userEmailAddress);

        try {

            const response = await _restApiRequest(
                `${API_URL}/teams/players/reset-player-password/`, formData, "POST"
            );

            if (response.ok) {
                setResetUserPasswordSuccessStatus(true);
            } else {
                UseSetPopupMessage({ type: "ERROR", text: PASSWORD_RESET_RESPONSE_ERROR_MESSAGE_TEXT });
            }

        } catch (e) {
            UseSetPopupMessage({ type: "ERROR", text: PASSWORD_RESET_RESPONSE_ERROR_MESSAGE_TEXT });
        }

    };


    return (
        <PageContainerComponent id={"password-remind-page"} cssClasses={"password-remind-page"}>

            <DefaultPageHeaderComponent headerText={PASSWORD_RESET_PAGE_TITLE} />

            <PageContentComponent cssClasses={"password-remind-page__content"}>

                <FormComponent lg={{ span: 6, offset: 3 }} cssClasses={"user-form"}>

                    <MessageComponent messageText={PASSWORD_RESET_PAGE_MESSAGE_TEXT} />

                    <FormTextFieldComponent
                        fieldType={"email"}
                        fieldValue={userEmailAddress}
                        fieldValueHandler={changeUserEmailAddress}
                        fieldPlaceholder={EMAIL_ADDRESS_LABEL}
                        fieldError={userEmailAddressError}
                    />

                    <FormSubmitFieldComponent
                        buttonText={PASSWORD_RESET_PAGE_BUTTON_TEXT}
                        buttonClickHandler={resetUserPasswordHandler}
                    />

                </FormComponent>

            </PageContentComponent>

        </PageContainerComponent>
    )

};


export default PasswordResetComponent;
