import React, { useEffect, useState } from "react";


import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";

import {
    AiOutlineDelete, FaCheck, FaCheckSquare, FcCancel
} from "react-icons/all";


import dateFormat from "dateformat";


import UseFreeAgentRequestStatusLabel from "@ApiInterfaces/Players/Player/FreeAgentRequest/Status";


import {
    FreeAgentRequestInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface FreeAgentRequestsListElementComponentProps {
    FreeAgentRequest: FreeAgentRequestInterface;
    requestUpdateHandler: (requestID: number, requestUpdatedStatus: 1 | 2 | 3 | 4) => Promise<boolean>;
}


export const FreeAgentRequestsListElementComponent: React.FC<FreeAgentRequestsListElementComponentProps> = ({
    FreeAgentRequest, requestUpdateHandler
}) => {


    const UsePopupMessage = UseSetPopupMessageAction();


    const TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT = UseCommonDictionaryPhrasesSet("TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT");
    const REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT = UseCommonDictionaryPhrasesSet("REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT");
    const FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT = UseCommonDictionaryPhrasesSet("FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT");

    const APPROVE_WORD_TEXT = UseCommonDictionaryPhrasesSet("APPROVE_WORD_TEXT");
    const REJECT_WORD_TEXT = UseCommonDictionaryPhrasesSet("REJECT_WORD_TEXT");
    const ABORT_WORD_TEXT = UseCommonDictionaryPhrasesSet("ABORT_WORD");


    const [rejectRequestStatus, setRejectRequestStatus] = useState(false);
    const [approveRequestStatus, setApproveRequestStatus] = useState(false);


    useEffect(() => {
        return () => {
            setRejectRequestStatus(false);
            setApproveRequestStatus(false);
        };
    }, []);


    if (!FreeAgentRequest) {
        return null;
    }


    const {
        id: requestID,
        free_agent_request_league_data: {
            league_name: requestLeagueName,
            league_season_data: requestLeagueSeason
        },
        free_agent_request_status: requestStatus,
        free_agent_request_create_date: requestDate,
    } = FreeAgentRequest;


    if (!requestLeagueSeason) {
        return null;
    }


    const { league_season_name: requestSeasonName } = requestLeagueSeason;


    const requestUpdateRequestHandler = async (updateType: 3 | 4) => {

        let updateStatus = false;

        switch (updateType) {
            case 3:
                if (!approveRequestStatus) {
                    setApproveRequestStatus(true);
                } else {
                    updateStatus = true;
                }
                break;
            case 4:
                if (!rejectRequestStatus) {
                    setRejectRequestStatus(true);
                } else {
                    updateStatus = true;
                }
                break;
        }

        if (!updateStatus) {
            return UsePopupMessage({ type: "INFO", text: TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT });
        }

        const response = await requestUpdateHandler(requestID, updateType);

        if (response) {
            UsePopupMessage({ type: "SUCCESS", text: REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT });
        } else {
            UsePopupMessage({ type: "SUCCESS", text: FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT });
        }

        cancelUpdateRequest();

    };


    const cancelUpdateRequest = () => {
        setApproveRequestStatus(false);
        setRejectRequestStatus(false);
    };


    const requestStatusLabel = UseFreeAgentRequestStatusLabel(requestStatus);


    return (
        <Col xs={12} className={"free-agent-requests__list__element free-agent-requests__list__element--item" + (requestStatus === 1 ? " status-open" : "")}>
            <Row className={"free-agent-requests__list__element__content"}>

                <Col xs={2} className={"free-agent-requests__list__element__request-status" + ` status-${requestStatus}`}>
                    <span>{requestStatusLabel}</span>
                </Col>

                <Col xs={3} className={"free-agent-requests__list__element__request-league"}>
                    <span>{requestLeagueName}</span>
                </Col>

                <Col xs={3} className={"free-agent-requests__list__element__request-season"}>
                    <span>{requestSeasonName}</span>
                </Col>

                <Col xs={2} className={"free-agent-requests__list__element__request-date"}>
                    <span>{requestDate && dateFormat(requestDate, "yyyy-mm-dd HH:MM")}</span>
                </Col>

                {requestStatus === 1 &&
                <Col xs={2} className={"free-agent-requests__list__element__request-actions"}>

                    {!rejectRequestStatus &&
                    <span className={"free-agent-requests__list__element__request-actions__action free-agent-requests__list__element__request-actions__action--approve" + (approveRequestStatus ? " active" : "")} onClick={() => requestUpdateRequestHandler(3)} title={APPROVE_WORD_TEXT}>
                        {approveRequestStatus ? <FaCheckSquare /> : <FaCheck />}
                    </span>
                    }

                    {!approveRequestStatus &&
                    <span className={"free-agent-requests__list__element__request-actions__action free-agent-requests__list__element__request-actions__action--reject" + (rejectRequestStatus ? " active" : "")} onClick={() => requestUpdateRequestHandler(4)} title={REJECT_WORD_TEXT}>
                         <AiOutlineDelete />
                    </span>
                    }

                    {(approveRequestStatus || rejectRequestStatus) &&
                    <span className={"free-agent-requests__list__element__request-actions__action free-agent-requests__list__element__request-actions__action--cancel"} onClick={cancelUpdateRequest} title={ABORT_WORD_TEXT}>
                        <FcCancel/>
                    </span>
                    }

                </Col>
                }

            </Row>
        </Col>
    );

};


export default FreeAgentRequestsListElementComponent;
