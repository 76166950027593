import React from "react";


import {
    UseTeamNextGames
} from "@Hooks/Api/Requests";


import {
    Col, Row
} from "react-bootstrap";


import TeamNextGamesListComponent from "@TeamsPages/Team/Information/TeamGames/GamesList";
import TeamNextGamesHeaderComponent from "@TeamsPages/Team/Information/TeamGames/Header";


import "./index.scss";


export interface TeamInformationTeamNextGamesComponentProps {
    teamID: number;
}

export const TeamInformationTeamNextGamesComponent: React.FC<TeamInformationTeamNextGamesComponentProps> = ({
    teamID
}) : JSX.Element | null => {


    const [TeamNextGames, TeamNextGamesError] = UseTeamNextGames(teamID);


    if (!teamID) {
        return null;
    }


    return (
        <Col xs={12} lg={4} className={"team-details__team-information__team-next-games"}>
            <Row className={"team-details__team-information__team-next-games__content"}>

                <TeamNextGamesHeaderComponent />

                <TeamNextGamesListComponent
                    teamID={teamID}
                    TeamNextGames={TeamNextGames}
                    TeamNextGamesError={TeamNextGamesError}
                />

            </Row>
        </Col>
    );

};


export default TeamInformationTeamNextGamesComponent;
