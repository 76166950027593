import React, {ElementType, useEffect, useState} from "react";

import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Row, Col} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";

import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";


import SocialsItemComponent from "@Components/Views/Parts/Header/Socials/Element";


import "./index.scss";


import SocialInterface from "@Interfaces/Api/_Common/Socials/Social";
import ColumnProps from "@BootstrapInterfaces/Column";


export interface HeaderSocialsComponentProps extends ColumnProps {}


export const HeaderSocialsComponent: React.FC<HeaderSocialsComponentProps> = ({
    xs = 12, lg = 3,
    as = "aside"
}) : JSX.Element | null => {


    const SOCIALS_API_URL = REST_API_ENDPOINTS_URLS.COMMON.SOCIALS.MAIN_URL;


    const [socials, setSocials] = useState<SocialInterface[] | null>(null);
    const [socialsError, setSocialsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!SOCIALS_API_URL) return;

        const getSocials = async () => {

            try {
                const response = await _restApiRequest(`${SOCIALS_API_URL}/`)
                if (response.ok) {
                    const responseContent = await response.json();
                    setSocials(responseContent);
                } else {
                    setSocialsError({errorCode: response.status, errorMessage: ""});
                }
            } catch (e) {
                setSocialsError({errorCode: 501, errorMessage: ""});
            }

        };

        getSocials();

    }, [SOCIALS_API_URL]);


    if (!!socialsError) return null;


    if (!socials) return <SingleContentLoaderComponent loaderColor={"#FFF"} xs={xs} lg={lg} />;


    const SocialsLinksList = socials.map((Social, i) =>
        <SocialsItemComponent key={i} social={Social} />
    ).filter((Social) => Social);


    return (
        <Col as={as} xs={xs} lg={lg} className={"header-socials-component"}>
            <Row as={"ul"} className={"socials-links-list"}>
                {SocialsLinksList}
            </Row>
        </Col>
    );

};


export default HeaderSocialsComponent;
