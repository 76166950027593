import React, { useEffect, useState } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseSetPopupMessageAction
} from "@StatesActions";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";

import {
    AiFillDelete, AiFillEdit, AiFillSave, FcCancel
} from "react-icons/all";


import {
    FormTextFieldComponent
} from "@FormsElements";


import {
    NO_AVATAR_IMAGE
} from "@Images";

import {
    generatePlayerName
} from "@Services/Utilities/Games";

import UseTeamPlayerStatus from "@ApiInterfaces/Teams/Team/TeamPlayer/TeamPlayerStatus/Hook";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeagueTeamSquadPlayersListPlayerComponentProps {
    TeamLeaguePlayer: TeamLeaguePlayerInterface | null;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const LeagueTeamSquadPlayersListPlayerComponent: React.FC<LeagueTeamSquadPlayersListPlayerComponentProps> = ({
    TeamLeaguePlayer, updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    const UsePopupMessage = UseSetPopupMessageAction();


    const PlayerRoute = UseRoute("PLAYERS");


    const DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE = UseCommonDictionaryPhrasesSet("DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE");
    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const TO_CONFIRM_CLICK_AGAIN_MESSAGE_TEXT = UseCommonDictionaryPhrasesSet("TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT");


    const [playerEditableStatus, setPlayerEditableStatus] = useState(false);
    const [playerRemoveStatus, setPlayerRemoveStatus] = useState(false);

    useEffect(() => {
        return () => {
            setPlayerEditableStatus(false);
            setPlayerRemoveStatus(false);
        };
    }, []);


    const [playerEditableShirtNumber, setPlayerEditableShirtNumber] = useState("");

    useEffect(() => {

        if (!TeamLeaguePlayer) {
            return;
        }

        const {
            league_team_player_player_shirt_number: playerShirtNumber
        } = TeamLeaguePlayer;

        if (playerShirtNumber){
            setPlayerEditableShirtNumber(playerShirtNumber.toString());
        }

    }, [TeamLeaguePlayer]);

    useEffect(() => {
        return () => {
            setPlayerEditableShirtNumber("");
        };
    }, []);

    const changePlayerEditableShirtNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
        const updatedPlayerNumber = e.target.value;
        if (+updatedPlayerNumber) {
            if (+updatedPlayerNumber < 0 || +updatedPlayerNumber > 99) {
                return;
            }
        }
        setPlayerEditableShirtNumber(updatedPlayerNumber);
    };


    if (!TeamLeaguePlayer) {
        return null;
    }


    const {
        url: PLAYER_API_URL,
        league_team_player_status: playerTeamStatus,
        league_team_player_player_data: TeamLeaguePlayerData,
    } = TeamLeaguePlayer;


    if (!TeamLeaguePlayerData) {
        return null;
    }


    const {
        player_slug: playerSlug, player_profile_image: playerImage
    } = TeamLeaguePlayerData;


    const playerName = generatePlayerName(TeamLeaguePlayerData);

    const playerURL = PlayerRoute.routeURL + "/" + playerSlug;

    const playerStatus = UseTeamPlayerStatus(playerTeamStatus);


    const cancelPlayerActionHandler = () => {
        setPlayerEditableStatus(false);
        setPlayerRemoveStatus(false);
    };

    const removePlayerHandler = () => {

        if (!playerRemoveStatus) {
            UsePopupMessage({ type: "DEFAULT", text: TO_CONFIRM_CLICK_AGAIN_MESSAGE_TEXT });
            return setPlayerRemoveStatus(true);
        }

        updatePlayerDataHandler("REMOVE");

    };

    const updatePlayerDataHandler = async (updateType: "UPDATE" | "REMOVE") => {

        const formData = new FormData();

        if (updateType === "UPDATE") {
            formData.append("league_team_player_player_shirt_number", playerEditableShirtNumber);
        } else if (updateType === "REMOVE") {
            formData.append("league_team_player_status", "4");
        }

        try {

            const response = await _restApiRequest(
                PLAYER_API_URL, formData, "PATCH"
            );

            if (response.ok) {
                cancelPlayerActionHandler();
                updateTeamLeaguesTriggerHandler(true);
                UsePopupMessage({ type: "SUCCESS", text: DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE });
            } else {
                UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
            }

        } catch (e) {
            UsePopupMessage({ type: "ERROR", text: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT });
        }

    };


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player"}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__content"}>

                <Col xs={1} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-image"}>
                    <NavLink to={playerURL} title={PlayerRoute.routeTitle}>
                        <img src={playerImage || NO_AVATAR_IMAGE} alt={""} />
                    </NavLink>
                </Col>

                <Col xs={5} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-name"}>
                    <NavLink to={playerURL} title={PlayerRoute.routeTitle}>
                        <span>{playerName}</span>
                    </NavLink>
                </Col>

                <Col xs={1} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-number"}>
                    <FormTextFieldComponent
                        fieldValue={playerEditableShirtNumber}
                        fieldValueHandler={changePlayerEditableShirtNumber}
                        fieldDisabledStatus={!playerEditableStatus}
                    />
                </Col>

                <Col xs={3} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-status" + (` status-${playerTeamStatus}`)}>
                    <span>{playerStatus}</span>
                </Col>

                <Col xs={2} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-actions"}>

                    {(!playerEditableStatus && !playerRemoveStatus) &&
                    <span className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-actions__edit-action"} onClick={() => setPlayerEditableStatus(true)}>
                        <AiFillEdit />
                    </span>
                    }

                    {playerEditableStatus &&
                    <span onClick={() => updatePlayerDataHandler("UPDATE")} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-actions__save-action"}>
                        <AiFillSave />
                    </span>
                    }

                    {!playerEditableStatus &&
                    <span onClick={removePlayerHandler} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-actions__remove-action" + (playerRemoveStatus ? " active" : "")}>
                        <AiFillDelete />
                    </span>
                    }

                    {(playerEditableStatus || playerRemoveStatus) &&
                    <span onClick={cancelPlayerActionHandler} className={"player-profile__captain-zone__team__leagues__list__league__players__players-list__player__player-actions__cancel-action"}>
                        <FcCancel />
                    </span>
                    }

                </Col>

            </Row>
        </Col>
    );


};


export default LeagueTeamSquadPlayersListPlayerComponent;
