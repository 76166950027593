import React from "react";


import { Col } from "react-bootstrap";


import ApiGameSummaryTeamPlayerInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Players/Player";
import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";


interface GameThrowsTeamPlayerComponentProps {
    GamePlayer: ApiGameSummaryTeamPlayerInterface;
    GamePlayerID: number,
    GamePlayers: ApiGameSummaryPlayersInterface;
    SelectedPlayersIDs: number[];
    selectedPlayersIDsHandler(playerId: number): void;
}


export const GameThrowsTeamPlayerComponent: React.FC<GameThrowsTeamPlayerComponentProps> = ({
    GamePlayer, GamePlayerID, GamePlayers,
    SelectedPlayersIDs, selectedPlayersIDsHandler
}) : JSX.Element | null => {


    if (!GamePlayer) return null;


    const { player_number: playerNumber } = GamePlayer;


    const GamePlayerData = GamePlayers[GamePlayerID];


    if (!GamePlayerData) return null;


    const {
        player_first_name: playerFirstName, player_last_name: playerLastName, player_nick_name: playerNickName,
        player_pseudonymization_status: playerPseudonymizationStatus
    } = GamePlayers[GamePlayerID];


    const playerName = playerPseudonymizationStatus ? `"${playerNickName}"` : `${playerFirstName} ${playerLastName}`;


    const playerSelectedStatus = SelectedPlayersIDs.includes(GamePlayerID);


    return (
        <Col xs={6} lg={12} className={"team-players-player"} onClick={() => selectedPlayersIDsHandler(GamePlayerID)} >

            <span className={"checkbox" + (playerSelectedStatus ? " checked" : "")}></span>

            <span className={"player-name" + (playerSelectedStatus ? " selected" : "")}>
                {playerName} {playerNumber ? `#${playerNumber}` : ""}
            </span>

        </Col>
    );


};


export default GameThrowsTeamPlayerComponent;
