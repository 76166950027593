import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";

import {
    Col, Row
} from "react-bootstrap";


import {
    generatePlayerName
} from "@Services/Utilities/Games";


import {
    NO_AVATAR_IMAGE
} from "@Images";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamPlayersListPlayerComponentProps {
    Player: TeamLeaguePlayerInterface;
    teamCaptainID?: number | null;
}


export const TeamPlayersListPlayerComponent: React.FC<TeamPlayersListPlayerComponentProps> = ({
    Player, teamCaptainID
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Player) {
        return null;
    }

    const {
        league_team_player_player_shirt_number: playerShirtNumber, league_team_player_status: playerStatus,
        league_team_player_player_data:  PlayerData
    } = Player;


    if (playerStatus !== 1) {
        return null;
    }

    const {
        player_id: playerID, player_slug: playerSlug,
        player_profile_image: playerProfileImage
    } = PlayerData;

    const playerName = generatePlayerName(PlayerData);

    const playerURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;


    return (
        <Col xs={4} lg={2} className={"team-details__team-players__list__players__player"}>
            <Row className={"team-details__team-players__list__players__player__content"}>

                <Col xs={12} className={"team-details__team-players__list__players__player__image"}>
                    <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                        <img src={playerProfileImage || NO_AVATAR_IMAGE} alt={playerName} />
                    </NavLink>
                </Col>

                <Col xs={12} className={"team-details__team-players__list__players__player__name"}>
                    <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                        <span>{playerName}</span>
                        {playerID === teamCaptainID && <span className={"team-details__team-players__list__players__player__name__captain"}>(C)</span>}
                    </NavLink>
                </Col>

                {!!playerShirtNumber &&
                <Col xs={12} className={"team-details__team-players__list__players__player__number"}>
                    <span>#{playerShirtNumber}</span>
                </Col>
                }

            </Row>
        </Col>
    );


};


export default TeamPlayersListPlayerComponent;
