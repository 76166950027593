import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col } from "react-bootstrap";


import "./index.scss";


interface GameTablesTeamHeaderCrewComponentProps {
    teamCoach?: string | null;
    teamCoachAssistant?: string | null;
}


export const GameTablesTeamHeaderCrewComponent: React.FC<GameTablesTeamHeaderCrewComponentProps> = ({
    teamCoach, teamCoachAssistant
}) : JSX.Element | null => {


    const COACH = UseGamesDictionaryPhrasesSet("COACH");
    const COACH_ASSISTANT = UseGamesDictionaryPhrasesSet("COACH_ASSISTANT");


    if (!teamCoach && !teamCoachAssistant) return null;


    return (
        <Col xs={12} lg={{span: 6, offset: 2}} className={"team-header-crew"}>

            {!!teamCoach &&
            <span>{`${COACH}: `} <strong>{teamCoach}</strong></span>
            }

            {!!teamCoachAssistant &&
            <span>{`${COACH_ASSISTANT}: `} <strong>{teamCoachAssistant}</strong></span>
            }

        </Col>
    );

};


export default GameTablesTeamHeaderCrewComponent;
