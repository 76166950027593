import React from "react";

import parse from "html-react-parser";


import { Col } from "react-bootstrap";


import "./index.scss";


import ColumnProps from "@BootstrapInterfaces/Column";


interface MessageComponentProps extends ColumnProps{
    messageText: string;
    messageType?: "default" | "error" | "success" | "warning";
    cssClasses?: string;
}


export const MessageComponent: React.FC<MessageComponentProps> = ({
    messageText, messageType = "default",
    xs = 12, lg = 12,
    cssClasses = ""
}) : JSX.Element | null => {


    if (!messageText) return null;


    const classes = ["message-component"];


    switch (messageType) {
        case "error":
            classes.push("error");
            break;
        case "success":
            classes.push("success");
            break;
        case "warning":
            classes.push("warning");
            break;
    }


    cssClasses = classes.join(" ") + (cssClasses ? ` ${cssClasses}` : "");


    return (
        <Col xs={xs} lg={lg} className={cssClasses}>
            <span>{parse(messageText)}</span>
        </Col>
    );

};


export default MessageComponent;
