export *  from "@Dictionary/Pages/Leagues/Games/Types/Phrases/Set";
export *  from "@Dictionary/Pages/Leagues/Games/Phrases";


import GamesDictionaryInterface from "@Dictionary/Pages/Leagues/Games/Types";


import {
    COACH_PL, COACH_EN, COACH_ASSISTANT_PL, COACH_ASSISTANT_EN,
    REFEREE_PL, REFEREE_EN, REFEREES_PL, REFEREES_EN,
    COMMISSIONER_PL, COMMISSIONER_EN, COMMISSIONERS_PL, COMMISSIONERS_EN,
    SPECTATOR_PL, SPECTATOR_EN, SPECTATORS_PL, SPECTATORS_EN, SPECTATOR_LABEL_PL, SPECTATOR_LABEL_EN,
    ROUND_NUMBER_PL, ROUND_NUMBER_EN, ROUND_NUMBER_SHORT_PL, ROUND_NUMBER_SHORT_EN, ROUND_NUMBER_LABEL_PL, ROUND_NUMBER_LABEL_EN,
    DATE_PL, DATE_EN, DATE_LABEL_PL, DATE_LABEL_EN,
    QUARTER_PL, QUARTER_EN, QUARTER_SHORT_PL, QUARTER_SHORT_EN, QUARTER_LABEL_PL, QUARTER_LABEL_EN,
    EXTRA_TIME_PL, EXTRA_TIME_EN, EXTRA_TIME_SHORT_PL, EXTRA_TIME_SHORT_EN, EXTRA_TIME_LABEL_PL, EXTRA_TIME_LABEL_EN,
    TABLES_PL, TABLES_EN, SUMMARY_PL, SUMMARY_EN, ARRANGE_PL, ARRANGE_EN, POSSESSIONS_PL, POSSESSIONS_EN,
    LEADERS_PL, LEADERS_EN, THROWS_PL, THROWS_EN, DIAGRAMS_PL, DIAGRAMS_TITLE_PL, DIAGRAMS_EN, DIAGRAMS_TITLE_EN, PROGRESS_PL, PROGRESS_EN,
    POINTS_TITLE_PL, POINTS_TITLE_EN, POINTS_LABEL_PL, POINTS_LABEL_EN, POINTS_SHORT_LABEL_PL, POINTS_SHORT_LABEL_EN,
    REBOUNDS_TITLE_PL, REBOUNDS_TITLE_EN, OFFENSIVE_REBOUNDS_TITLE_PL, OFFENSIVE_REBOUNDS_TITLE_EN, DEFENSIVE_REBOUNDS_TITLE_PL, DEFENSIVE_REBOUNDS_TITLE_EN,
    ASSISTS_TITLE_PL, ASSISTS_TITLE_EN, BLOCKS_TITLE_PL, BLOCKS_TITLE_EN, STEALS_TITLE_PL, STEALS_TITLE_EN, TURNOVERS_TITLE_PL, TURNOVERS_TITLE_EN,
    GAME_THROWS_EFFECTIVITY_TITLE_PL, GAME_THROWS_EFFECTIVITY_TITLE_EN, PT3_THROWS_EFFECTIVITY_TITLE_PL, PT3_THROWS_EFFECTIVITY_TITLE_EN, PT2_THROWS_EFFECTIVITY_TITLE_PL, PT2_THROWS_EFFECTIVITY_TITLE_EN, PT1_THROWS_EFFECTIVITY_TITLE_PL, PT1_THROWS_EFFECTIVITY_TITLE_EN,
    PLAYER_OF_THE_GAME_PL, PLAYER_OF_THE_GAME_EN, GAME_PLAY_TIME_TITLE_PL, GAME_PLAY_TIME_TITLE_EN,
    POSSESSIONS_NUMBER_PL, POSSESSIONS_NUMBER_EN, TOTAL_POSSESSIONS_PL, TOTAL_POSSESSIONS_EN, MEDIUM_POSSESSION_TIME_PL, MEDIUM_POSSESSION_TIME_EN,
    POSSESSIONS_FINISHED_BY_THROW_PL, POSSESSIONS_FINISHED_BY_THROW_EN, POSSESSIONS_FINISHED_BY_ACCURATE_THROW_PL, POSSESSIONS_FINISHED_BY_ACCURATE_THROW_EN, POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_PL, POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_EN,
    POSSESSIONS_FINISHED_BY_FAULT_PL, POSSESSIONS_FINISHED_BY_FAULT_EN, POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_PL, POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_EN, POSSESSIONS_FINISHED_BY_PASS_FAULT_PL, POSSESSIONS_FINISHED_BY_PASS_FAULT_EN, POSSESSIONS_FINISHED_BY_TIME_FAULT_PL, POSSESSIONS_FINISHED_BY_TIME_FAULT_EN, POSSESSIONS_FINISHED_BY_OUT_FAULT_PL, POSSESSIONS_FINISHED_BY_OUT_FAULT_EN, POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_PL, POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_EN,
    NO_GAME_EVENTS_MESSAGE_PL, NO_GAME_EVENTS_MESSAGE_EN,
    NO_QUARTERS_SELECTED_MESSAGE_PL, NO_QUARTERS_SELECTED_MESSAGE_EN, NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_PL, NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_EN,
} from "@Dictionary/Pages/Leagues/Games/Phrases";


export const GamesDictionary: GamesDictionaryInterface = {
    PL: {
        COACH: COACH_PL, COACH_ASSISTANT: COACH_ASSISTANT_PL,
        REFEREE: REFEREE_PL, REFEREES: REFEREES_PL, COMMISSIONER: COMMISSIONER_PL, COMMISSIONERS: COMMISSIONERS_PL,
        SPECTATOR: SPECTATOR_PL, SPECTATORS: SPECTATORS_PL, SPECTATORS_LABEL: SPECTATOR_LABEL_PL,
        ROUND_NUMBER: ROUND_NUMBER_PL, ROUND_NUMBER_SHORT: ROUND_NUMBER_SHORT_PL, ROUND_NUMBER_LABEL: ROUND_NUMBER_LABEL_PL,
        DATE: DATE_PL, DATE_LABEL: DATE_LABEL_PL,
        QUARTER: QUARTER_PL, QUARTER_SHORT: QUARTER_SHORT_PL, QUARTER_LABEL: QUARTER_LABEL_PL,
        EXTRA_TIME: EXTRA_TIME_PL, EXTRA_TIME_SHORT: EXTRA_TIME_SHORT_PL, EXTRA_TIME_LABEL: EXTRA_TIME_LABEL_PL,
        TABLES: TABLES_PL, SUMMARY: SUMMARY_PL, ARRANGE: ARRANGE_PL, POSSESSIONS: POSSESSIONS_PL,
        LEADERS: LEADERS_PL, THROWS: THROWS_PL, DIAGRAMS: DIAGRAMS_PL, DIAGRAMS_TITLE: DIAGRAMS_TITLE_PL, PROGRESS: PROGRESS_PL,
        POINTS_TITLE: POINTS_TITLE_PL, POINTS_LABEL: POINTS_LABEL_PL, POINTS_SHORT_LABEL: POINTS_SHORT_LABEL_PL,
        REBOUNDS_TITLE: REBOUNDS_TITLE_PL, OFFENSIVE_REBOUNDS_TITLE: OFFENSIVE_REBOUNDS_TITLE_PL, DEFENSIVE_REBOUNDS_TITLE: DEFENSIVE_REBOUNDS_TITLE_PL,
        ASSISTS_TITLE: ASSISTS_TITLE_PL, BLOCKS_TITLE: BLOCKS_TITLE_PL, STEALS_TITLE: STEALS_TITLE_PL, TURNOVERS_TITLE: TURNOVERS_TITLE_PL,
        GAME_THROWS_EFFECTIVITY_TITLE: GAME_THROWS_EFFECTIVITY_TITLE_PL, PT3_THROWS_EFFECTIVITY_TITLE: PT3_THROWS_EFFECTIVITY_TITLE_PL, PT2_THROWS_EFFECTIVITY_TITLE: PT2_THROWS_EFFECTIVITY_TITLE_PL, PT1_THROWS_EFFECTIVITY_TITLE: PT1_THROWS_EFFECTIVITY_TITLE_PL,
        POSSESSIONS_NUMBER: POSSESSIONS_NUMBER_PL, TOTAL_POSSESSIONS: TOTAL_POSSESSIONS_PL, MEDIUM_POSSESSION_TIME: MEDIUM_POSSESSION_TIME_PL,
        POSSESSIONS_FINISHED_BY_THROW: POSSESSIONS_FINISHED_BY_THROW_PL, POSSESSIONS_FINISHED_BY_ACCURATE_THROW: POSSESSIONS_FINISHED_BY_ACCURATE_THROW_PL, POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW: POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_PL,
        POSSESSIONS_FINISHED_BY_FAULT: POSSESSIONS_FINISHED_BY_FAULT_PL, POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT: POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_PL, POSSESSIONS_FINISHED_BY_PASS_FAULT: POSSESSIONS_FINISHED_BY_PASS_FAULT_PL, POSSESSIONS_FINISHED_BY_TIME_FAULT: POSSESSIONS_FINISHED_BY_TIME_FAULT_PL, POSSESSIONS_FINISHED_BY_OUT_FAULT: POSSESSIONS_FINISHED_BY_OUT_FAULT_PL, POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT: POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_PL,
        PLAYER_OF_THE_GAME: PLAYER_OF_THE_GAME_PL, GAME_PLAY_TIME_TITLE: GAME_PLAY_TIME_TITLE_PL,
        NO_GAME_EVENTS_MESSAGE: NO_GAME_EVENTS_MESSAGE_PL, NO_QUARTERS_SELECTED_MESSAGE: NO_QUARTERS_SELECTED_MESSAGE_PL, NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE: NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_PL,
    },
    EN: {
        COACH: COACH_EN, COACH_ASSISTANT: COACH_ASSISTANT_EN,
        REFEREE: REFEREE_EN, REFEREES: REFEREES_EN, COMMISSIONER: COMMISSIONER_EN, COMMISSIONERS: COMMISSIONERS_EN,
        SPECTATOR: SPECTATOR_EN, SPECTATORS: SPECTATORS_EN, SPECTATORS_LABEL: SPECTATOR_LABEL_EN,
        ROUND_NUMBER: ROUND_NUMBER_EN, ROUND_NUMBER_SHORT: ROUND_NUMBER_SHORT_EN, ROUND_NUMBER_LABEL: ROUND_NUMBER_LABEL_EN,
        DATE: DATE_EN, DATE_LABEL: DATE_LABEL_EN,
        QUARTER: QUARTER_EN, QUARTER_SHORT: QUARTER_SHORT_EN, QUARTER_LABEL: QUARTER_LABEL_EN,
        EXTRA_TIME: EXTRA_TIME_EN, EXTRA_TIME_SHORT: EXTRA_TIME_SHORT_EN, EXTRA_TIME_LABEL: EXTRA_TIME_LABEL_EN,
        TABLES: TABLES_EN, SUMMARY: SUMMARY_EN, ARRANGE: ARRANGE_EN, POSSESSIONS: POSSESSIONS_EN,
        LEADERS: LEADERS_EN, THROWS: THROWS_EN, DIAGRAMS: DIAGRAMS_EN, DIAGRAMS_TITLE: DIAGRAMS_TITLE_EN, PROGRESS: PROGRESS_EN, NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE: NO_PLAYERS_OR_QUARTERS_SELECTED_MESSAGE_EN,
        POINTS_TITLE: POINTS_TITLE_EN, POINTS_LABEL: POINTS_LABEL_EN, POINTS_SHORT_LABEL: POINTS_SHORT_LABEL_EN,
        REBOUNDS_TITLE: REBOUNDS_TITLE_EN, OFFENSIVE_REBOUNDS_TITLE: OFFENSIVE_REBOUNDS_TITLE_EN, DEFENSIVE_REBOUNDS_TITLE: DEFENSIVE_REBOUNDS_TITLE_EN,
        ASSISTS_TITLE: ASSISTS_TITLE_EN, BLOCKS_TITLE: BLOCKS_TITLE_EN, STEALS_TITLE: STEALS_TITLE_EN, TURNOVERS_TITLE: TURNOVERS_TITLE_EN,
        GAME_THROWS_EFFECTIVITY_TITLE: GAME_THROWS_EFFECTIVITY_TITLE_EN, PT3_THROWS_EFFECTIVITY_TITLE: PT3_THROWS_EFFECTIVITY_TITLE_EN, PT2_THROWS_EFFECTIVITY_TITLE: PT2_THROWS_EFFECTIVITY_TITLE_EN, PT1_THROWS_EFFECTIVITY_TITLE: PT1_THROWS_EFFECTIVITY_TITLE_EN,
        POSSESSIONS_NUMBER: POSSESSIONS_NUMBER_EN, TOTAL_POSSESSIONS: TOTAL_POSSESSIONS_EN, MEDIUM_POSSESSION_TIME: MEDIUM_POSSESSION_TIME_EN,
        POSSESSIONS_FINISHED_BY_THROW: POSSESSIONS_FINISHED_BY_THROW_EN, POSSESSIONS_FINISHED_BY_ACCURATE_THROW: POSSESSIONS_FINISHED_BY_ACCURATE_THROW_EN, POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW: POSSESSIONS_FINISHED_BY_NOT_ACCURATE_THROW_EN,
        POSSESSIONS_FINISHED_BY_FAULT: POSSESSIONS_FINISHED_BY_FAULT_EN, POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT: POSSESSIONS_FINISHED_BY_DRIBBLE_FAULT_EN, POSSESSIONS_FINISHED_BY_PASS_FAULT: POSSESSIONS_FINISHED_BY_PASS_FAULT_EN, POSSESSIONS_FINISHED_BY_TIME_FAULT: POSSESSIONS_FINISHED_BY_TIME_FAULT_EN, POSSESSIONS_FINISHED_BY_OUT_FAULT: POSSESSIONS_FINISHED_BY_OUT_FAULT_EN, POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT: POSSESSIONS_FINISHED_BY_OFFENSIVE_FOUL_FAULT_EN,
        PLAYER_OF_THE_GAME: PLAYER_OF_THE_GAME_EN, GAME_PLAY_TIME_TITLE: GAME_PLAY_TIME_TITLE_EN,
        NO_GAME_EVENTS_MESSAGE: NO_GAME_EVENTS_MESSAGE_EN, NO_QUARTERS_SELECTED_MESSAGE: NO_QUARTERS_SELECTED_MESSAGE_EN
    }
};


export default GamesDictionary;
