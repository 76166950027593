import React from "react";


import { Col, Row } from "react-bootstrap";


import dateFormat from "dateformat";


import "./index.scss";


import { LeagueGameStatusType } from "@Interfaces/Api";


export interface GameDetailsAdminHeaderInformationComponentProps {
    gameStatus: LeagueGameStatusType;
    gameDate: string | Date | null;
    GameArena?: {
        league_arena_name: string,
        league_arena_village: string,
        league_arena_address: string,
    }
}


export const GameDetailsAdminHeaderInformationComponent: React.FC<GameDetailsAdminHeaderInformationComponentProps> = ({
    gameStatus, gameDate, GameArena
}) => {


    let gameStatusName = "Mecz zaplanowany";

    switch (gameStatus) {
        case "FT":
            gameStatusName = "Mecz zakończony";
            break;
        case "AOT":
            gameStatusName = "Mecz zakończony po dogrywce";
            break;
        case "FT_WAL":
            gameStatusName = "Mecz zakończony walkowerem";
            break;
    }


    const gameDateTime = gameDate ? new Date(gameDate) : null;


    let gameArenaName = null;

    if (GameArena) {
        const {
            league_arena_name: arenaName, league_arena_village: arenaVillage, league_arena_address: arenaAddress
        } = GameArena;
        gameArenaName = [arenaName, arenaVillage || "", arenaAddress || ""].join(", ");
    }


    return (
        <Col xs={12} className={"game-details-admin__header__information"}>
            <Row className={"game-details-admin__header__information__content"}>

                <Col xs={12} lg={6} className={"game-details-admin__header__information__game-date"}>
                    <span>
                    {gameDateTime ?
                        dateFormat(gameDateTime, "dd-mm-yyyy HH:MM")
                            :
                        "Brak ustalonej daty meczu"
                    }
                    </span>
                </Col>

                <Col xs={12} lg={6} className={"game-details-admin__header__information__game-arena"}>
                    <span>{gameArenaName || "Brak ustalonej lokalizacji"}</span>
                </Col>

                <Col xs={12} className={"game-details-admin__header__information__game-status"}>
                    <span>{gameStatusName}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameDetailsAdminHeaderInformationComponent;
