import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Row, Col } from "react-bootstrap";


import "./index.scss";


export interface GameResultDiagramsHeaderComponentProps {}


export const GameResultDiagramsHeaderComponent: React.FC<GameResultDiagramsHeaderComponentProps> = () : JSX.Element | null => {


    const DIAGRAMS_TITLE = UseGamesDictionaryPhrasesSet("DIAGRAMS_TITLE");


    return (
        <Row className={"game-result-details__diagrams__header game-result-details-diagrams__header"}>
            <Col xs={12} className={"diagrams-header"}>
                <span>{DIAGRAMS_TITLE}</span>
            </Col>
        </Row>
    );

};


export default GameResultDiagramsHeaderComponent;
