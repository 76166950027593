import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamPlayersListPlayerComponent from "@TeamsPages/Team/Players/PlayersList/TeamSquad/TeamSquadPlayer";


import {
    TeamLeaguePlayerInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamPlayersListTeamSquadComponentProps {
    TeamSquadPlayers: TeamLeaguePlayerInterface[];
    teamCaptainID?: number | null;
}


export const TeamPlayersListTeamSquadComponent: React.FC<TeamPlayersListTeamSquadComponentProps> = ({
    TeamSquadPlayers, teamCaptainID
}) : JSX.Element | null => {


    if (!TeamSquadPlayers) {
        return null;
    }


    const TeamLeagueTeamSquadPlayers = TeamSquadPlayers.map((Player) =>
        <TeamPlayersListPlayerComponent key={Player.id} Player={Player} teamCaptainID={teamCaptainID} />
    );


    return (
        <Col xs={12} className={"team-details__team-players__list__players"}>
            <Row className={"team-details__team-players__list__players__content"}>
                {TeamLeagueTeamSquadPlayers}
            </Row>
        </Col>
    );

};


export default TeamPlayersListTeamSquadComponent;
