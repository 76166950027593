import React from "react";


import {Container, Row} from "react-bootstrap";


import "./index.scss";


export interface PageContentComponentProps {
    id?: string;
    cssClasses?: string | string[];
    children?: React.ReactNode | React.ReactNode[] | null;
}


export const PageContentComponent: React.FC<PageContentComponentProps> = ({
    id = "",
    cssClasses= "",
    children = null
}) => {


    if (!!cssClasses && typeof cssClasses === "object") {
        cssClasses = cssClasses.join(" ");
    }

    const classes = "page-content page-content-component" + (!!cssClasses ? ` ${cssClasses}` : "");


    return (
        <Container fluid={true} id={id} className={classes}>
            <Container className={"page-content-container"}>
                <Row className={"page-content-content"}>
                    {children}
                </Row>
            </Container>
        </Container>
    );

};


export default PageContentComponent;
