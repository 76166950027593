import React from "react";


import { Col } from "react-bootstrap";


import { RiseLoader } from "react-spinners";


import { ContainerSectionComponent } from "@ContainersElements";


export interface MainContentLoaderComponentProps {
    loaderText?: string;
    loaderColor?: string;
    loaderSize?: number;
    loaderElementsMargin?: number;
}


export const MainContentLoaderComponent: React.FC<MainContentLoaderComponentProps> = ({
    loaderText = `Loading...`, loaderSize = 15,
    loaderElementsMargin = 2, loaderColor = "var(--secondary-color)"
}) : JSX.Element | null => {


    return (
        <ContainerSectionComponent classes={"main-content-loader main-content-loader-component"}>

            {loaderText &&
			<Col xs={12} className="loader-text" style={{marginBottom: 50, textAlign: "center", color: loaderColor}}>
				<span>{loaderText}</span>
			</Col>
            }

            <Col xs={12} className="loader-animation" style={{textAlign: "center"}}>
                <RiseLoader
                    size={loaderSize}
                    margin={loaderElementsMargin}
                    color={loaderColor}
                />
            </Col>

        </ContainerSectionComponent>

    );

};


export default MainContentLoaderComponent;
