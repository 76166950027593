import React from "react";


import {
    PageContainerComponent, PageContentComponent
} from "@ContentElements";


import TeamInformationTeamPhotoComponent from "@TeamsPages/Team/Information/TeamPhoto";
import TeamInformationTeamNextGamesComponent from "@TeamsPages/Team/Information/TeamGames";
import TeamInformationTeamTop5Component from "@TeamsPages/Team/Information/TeamTopPlayers";


import {
    LeagueTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamInformationComponentProps {
    Team: LeagueTeamInterface;
    selectedLeagueId: number;
}


export const TeamInformationComponent: React.FC<TeamInformationComponentProps> = ({
    Team, selectedLeagueId
}) : JSX.Element | null => {


    if (!Team) {
        return null;
    }


    const {
        team_id: teamID, team_image: teamImageURL
    } = Team;


    return (
        <PageContainerComponent cssClasses={"team-details__team-information"}>

            <PageContentComponent cssClasses={"team-details__team-information__content"}>

                <TeamInformationTeamPhotoComponent
                    teamImageURL={teamImageURL}
                />

                <TeamInformationTeamTop5Component
                    teamID={teamID}
                    selectedLeagueId={selectedLeagueId}
                />

                <TeamInformationTeamNextGamesComponent
                    teamID={teamID}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );



};


export default TeamInformationComponent;
