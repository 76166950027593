import useTypedSelector from "@Store/_Hooks/UseTypedSelector";



export const UseApiRequestStatus = () : boolean => {

    const { apiRequestStatus } = useTypedSelector(
        (state) => state.app
    );

    return apiRequestStatus;

};


export default UseApiRequestStatus;
