export * from "@ContentElements/Page/Header";
export * from "@ContentElements/Page/Content";


import React from "react";


import {Container} from "react-bootstrap";


export interface PageContainerComponentProps {
    children: React.ReactNode;
    forwardRef?: React.ForwardedRef<any>;
    id?: string;
    cssClasses?: string;
}


export const PageContainerComponent: React.FC<PageContainerComponentProps> = ({
    children, forwardRef, id = "", cssClasses = ""
}) : JSX.Element | null => {


    if (!children) return null;


    cssClasses = (cssClasses ? `${cssClasses} ` : "") + "page-container page-container-component";


    return (
        <Container ref={forwardRef} fluid={true} id={id} className={cssClasses}>
            {children}
        </Container>
    )

};


export default PageContainerComponent;
