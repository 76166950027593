export *  from "@Dictionary/Globals/Types/Phrases/Set";
export *  from "@Dictionary/Globals/Phrases";


import GlobalsDictionaryInterface from "@Dictionary/Globals/Types";


import {
    PLKA_PROJECT_TITLE_PL, PLKA_PROJECT_TITLE_EN,
    TEAMS_WORD_TEXT_PL, TEAMS_WORD_TEXT_EN, PLAYERS_WORD_TEXT_PL, PLAYERS_WORD_TEXT_EN, GAMES_NUMBER_PHRASE_TEXT_PL, GAMES_NUMBER_PHRASE_TEXT_EN,
    COOKIE_MESSAGE_PL, COOKIE_MESSAGE_EN,
    WWW_SITE_TEXT_PL, WWW_SITE_TEXT_EN, GO_TO_TEXT_PL, GO_TO_TEXT_EN,
    NEWS_PAGE_HEADER_TEXT_PL, NEWS_PAGE_HEADER_TEXT_EN, NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_PL, NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_EN,
    PARTNERS_PAGE_HEADER_TEXT_PL, PARTNERS_PAGE_HEADER_TEXT_EN, PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_PL, PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_EN,
    FILES_FOR_DOWNLOAD_HEADER_TEXT_PL, FILES_FOR_DOWNLOAD_HEADER_TEXT_EN, DOWNLOAD_FILE_TEXT_PL, DOWNLOAD_FILE_TEXT_EN, NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_PL, NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_EN
} from "@Dictionary/Globals/Phrases";


export const GlobalsDictionary: GlobalsDictionaryInterface = {
    PL: {
        PLKA_PROJECT_TITLE: PLKA_PROJECT_TITLE_PL,
        TEAMS_WORD_TEXT: TEAMS_WORD_TEXT_PL, PLAYERS_WORD_TEXT: PLAYERS_WORD_TEXT_PL, GAMES_NUMBER_PHRASE_TEXT: GAMES_NUMBER_PHRASE_TEXT_PL,
        COOKIE_MESSAGE: COOKIE_MESSAGE_PL,
        WWW_SITE_TEXT: WWW_SITE_TEXT_PL, GO_TO_TEXT: GO_TO_TEXT_PL,
        NEWS_PAGE_HEADER_TEXT: NEWS_PAGE_HEADER_TEXT_PL, NEWS_PAGE_NO_NEWS_MESSAGE_TEXT: NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_PL,
        PARTNERS_PAGE_HEADER_TEXT: PARTNERS_PAGE_HEADER_TEXT_PL, PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT: PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_PL,
        DOWNLOAD_FILE_TEXT: DOWNLOAD_FILE_TEXT_PL,
        FILES_FOR_DOWNLOAD_HEADER_TEXT: FILES_FOR_DOWNLOAD_HEADER_TEXT_PL, NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT: NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_PL,
    },
    EN: {
        PLKA_PROJECT_TITLE: PLKA_PROJECT_TITLE_EN,
        TEAMS_WORD_TEXT: TEAMS_WORD_TEXT_EN, PLAYERS_WORD_TEXT: PLAYERS_WORD_TEXT_EN, GAMES_NUMBER_PHRASE_TEXT: GAMES_NUMBER_PHRASE_TEXT_EN,
        COOKIE_MESSAGE: COOKIE_MESSAGE_EN,
        WWW_SITE_TEXT: WWW_SITE_TEXT_EN, GO_TO_TEXT: GO_TO_TEXT_EN,
        NEWS_PAGE_HEADER_TEXT: NEWS_PAGE_HEADER_TEXT_EN, NEWS_PAGE_NO_NEWS_MESSAGE_TEXT: NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_EN,
        PARTNERS_PAGE_HEADER_TEXT: PARTNERS_PAGE_HEADER_TEXT_EN, PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT: PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_EN,
        DOWNLOAD_FILE_TEXT: DOWNLOAD_FILE_TEXT_EN,
        FILES_FOR_DOWNLOAD_HEADER_TEXT: FILES_FOR_DOWNLOAD_HEADER_TEXT_EN, NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT: NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_EN,
    }
};


export default GlobalsDictionary;
