import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";
import UseGlobalData from "@Store/_Hooks/_States/Application/GlobalData";


import {Col, Row} from "react-bootstrap";


import SuccessMessageComponent from "@Components/Elements/Messages/Message/Success";


import ContactPageInformationComponent from "@Components/Views/Pages/Common/ContactPage/Content/Information";
import ContactPageFormComponent from "@Components/Views/Pages/Common/ContactPage/Form";


const TEXTS: any = {
    PL: {message_sent_text: "Wiadomość została wysłana"},
    EN: {message_sent_text: "Message sent"}
};


export interface ContactPageContentComponentProps {
    formSentStatus: boolean;
    sendMessageHandler(): void;
}


export const ContactPageContentComponent: React.FC<ContactPageContentComponentProps> = ({
    formSentStatus = false, sendMessageHandler = null
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {message_sent_text: messageSentText} = TEXTS[activeLanguage];


    const globalData = UseGlobalData();


    if (!globalData?.settings) return null;


    const {
        main_email_address: mainEmailAddress, main_phone_number: mainPhoneNumber,
        contact_email_address: contactEmailAddress, contact_phone_number: contactPhoneNumber
    } = globalData.settings;

    const phoneNumber = contactPhoneNumber?.value || mainPhoneNumber?.value || "";
    const phoneNumberURL = "+48" + phoneNumber.replaceAll(" ", "");

    const emailAddress = contactEmailAddress?.value || mainEmailAddress?.value || "";

    return (
        <Col xs={12}>

            <Row className={"contact-page-content-sections"}>

                {!!phoneNumber &&
				<ContactPageInformationComponent
					dataLabel={"TEL"}
					dataValue={phoneNumber}
					dataURL={`tel:${phoneNumberURL}`}
                    lg={2}
				/>
                }

                {!!emailAddress &&
				<ContactPageInformationComponent
					dataValue={emailAddress}
					dataURL={`mailto:${emailAddress}`}
                    lg={4}
				/>
                }

                {!formSentStatus ?
                <ContactPageFormComponent
                    sendMessageHandler={sendMessageHandler}
                />
                :
                <SuccessMessageComponent
                    messageText={messageSentText}
                />
                }

            </Row>

        </Col>
    );

};


export default ContactPageContentComponent;
