import React from "react";


import { Col } from "react-bootstrap";


export interface SchedulesAdminLeagueNameComponentProps {
    leagueName: string;
    leagueShortName?: string | null;
}


export const SchedulesAdminLeagueNameComponent: React.FC<SchedulesAdminLeagueNameComponentProps> = ({
    leagueName, leagueShortName
}) : JSX.Element | null => {


    if (!leagueName) {
        return null;
    }

    return (
        <Col xs={3}>
            <span>{leagueName}</span>
            {!!leagueShortName && <span>({leagueShortName})</span>}
        </Col>
    );


};


export default SchedulesAdminLeagueNameComponent;
