export const PLKA_PROJECT_TITLE_PL = "Oficjalna Strona PLKA";
export const PLKA_PROJECT_TITLE_EN = "PLKA Official Site";

export const TEAMS_WORD_TEXT_PL = "Drużyny";
export const TEAMS_WORD_TEXT_EN = "Teams";
export const PLAYERS_WORD_TEXT_PL = "Zawodnicy";
export const PLAYERS_WORD_TEXT_EN = "Players";
export const GAMES_NUMBER_PHRASE_TEXT_PL = "Ilość meczów";
export const GAMES_NUMBER_PHRASE_TEXT_EN = "Games Number";

export const COOKIE_MESSAGE_PL = "Używamy plików cookies na naszej stronie, aby lepiej dostosować ją do Twoich potrzeb. Kontynuując przeglądanie tej strony akceptujesz naszą Politykę Prywatności.";
export const COOKIE_MESSAGE_EN = "We use cookies on our website to better adapt it to your needs. By continuing to browse this site you accept our Privacy Policy.";

export const WWW_SITE_TEXT_PL = "Strona WWW";
export const WWW_SITE_TEXT_EN = "Website";
export const GO_TO_TEXT_PL = "Przejdź";
export const GO_TO_TEXT_EN = "Go to";

export const NEWS_PAGE_HEADER_TEXT_PL = "Aktualności";
export const NEWS_PAGE_HEADER_TEXT_EN = "News";
export const NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_PL = "Brak aktualności";
export const NEWS_PAGE_NO_NEWS_MESSAGE_TEXT_EN = "No news";

export const PARTNERS_PAGE_HEADER_TEXT_PL = "Partnerzy";
export const PARTNERS_PAGE_HEADER_TEXT_EN = "Partners";
export const PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_PL = "Brak partnerów";
export const PARTNERS_PAGE_NO_PARTNERS_MESSAGE_TEXT_EN = "No partners";

export const DOWNLOAD_FILE_TEXT_PL = "pobierz";
export const DOWNLOAD_FILE_TEXT_EN = "download";
export const FILES_FOR_DOWNLOAD_HEADER_TEXT_PL = "Do pobrania";
export const FILES_FOR_DOWNLOAD_HEADER_TEXT_EN = "Download";
export const NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_PL = "Brak plików do pobrania";
export const NO_FILES_FOR_DOWNLOAD_MESSAGE_TEXT_EN = "No files to download";
