import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Row, Col
} from "react-bootstrap";


import "./index.scss";


export interface GameProgressFooterComponentProps {}


export const GameProgressFooterComponent: React.FC<GameProgressFooterComponentProps> = () : JSX.Element | null => {


    const GAME_FINISH_PHRASE = UseCommonDictionaryPhrasesSet("GAME_FINISH_PHRASE");


    return (
        <Col xs={12} className={"game-result-details__progress__footer"}>
            <Row className={"game-result-details__progress__footer__content"}>

                <span>{GAME_FINISH_PHRASE}</span>

            </Row>
        </Col>
    )

};


export default GameProgressFooterComponent;
