import ApplicationUserTypesInterface from "@Models/Users/User/UserType";


export const ApplicationUserTypes: ApplicationUserTypesInterface = {
    PLAYER: {
        typeID: 1,
        typeName: { PL: "Zawodnik", EN: "Player" },
    },
};


export default ApplicationUserTypes;
