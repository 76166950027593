import React from "react";


import {
    Col
} from "react-bootstrap";


import {
    LeagueNameInterface
} from "@Interfaces/Api";


import "./index.scss";


import ColumnProps from "@BootstrapInterfaces/Column";


export interface DefaultLeaguesSwitcherLeaguesListLeagueComponentProps extends ColumnProps {
    League: LeagueNameInterface;
    selectedLeagueID?: number;
    selectedLeagueIDHandler: ((leagueID: number) => void) | null;
}


export const DefaultLeaguesSwitcherLeaguesListLeagueComponent: React.FC<DefaultLeaguesSwitcherLeaguesListLeagueComponentProps> = ({
    League,
    selectedLeagueID = 0, selectedLeagueIDHandler = null,
    xs = "auto" , sm , lg , xl ,
}) => {


    if (!League) {
        return null;
    }


    const {
        league_id: leagueID,
        league_name: leagueName,
        league_short_name: leagueShortName
    } = League;


    return (
        <Col xs={xs} sm={sm} lg={lg} xl={xl} className={"leagues-switcher__leagues-list__league leagues-switcher--default__leagues-list__league" + (leagueID === selectedLeagueID ? " selected" : "")}>
            <span onClick={() => selectedLeagueIDHandler ? selectedLeagueIDHandler(leagueID) : null}>{leagueShortName || leagueName}</span>
        </Col>
    );

};


export default DefaultLeaguesSwitcherLeaguesListLeagueComponent;
