import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import UseProgressEvent, {
    GameProgressEventTypeInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Hooks/ProgressEvent";


import QuarterEventOrderNrComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/OrderNr";
import QuarterEventPointsAndTimeComponent from "@GameResultPageSummary/GameDetailsInformation/Progress/Quarters/Quarter/Events/Event/PointsAndTime";


import ApiGameSummaryPlayersInterface from "@GameResultPageTypes/Summary/API/GameSummary/Players";
import GameProgressEventTypes from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Type";
import GameProgressEventType, {
    GameProgressTurnoverEventInterface
} from "@GameResultPageSummary/GameDetailsInformation/Progress/_Types/Progress/Event/Types";
import {generatePlayerName} from "@Services/Utilities/Games";


export interface QuarterTurnoverEventDataComponentProps {
    eventOrderNr: string,
    homeTeamID: number; awayTeamID: number;
    quarterDuration: number; extraTimeDuration: number;
    Event: GameProgressEventType;
    GamePlayers: ApiGameSummaryPlayersInterface;
}


export const QuarterTurnoverEventDataComponent: React.FC<QuarterTurnoverEventDataComponentProps> = ({
    eventOrderNr,
    homeTeamID, awayTeamID,
    quarterDuration, extraTimeDuration,
    Event, GamePlayers
}) : JSX.Element | null => {


    const PlayersRoute = UseRoute("PLAYERS");


    if (!Event || Event.eventType !== GameProgressEventTypes.TURNOVER) {
        return null;
    }

    if (!Event.eventTurnoverPlayerPlayerID) {
        return null;
    }

    const TurnoverEvent = UseProgressEvent("TO");
    const StealEvent = UseProgressEvent("STL");

    if (!TurnoverEvent || !StealEvent) {
        return null;
    }


    const EventTurnoverPlayer = GamePlayers[Event.eventTurnoverPlayerPlayerID];
    const EventStealingPlayer = Event.eventStealingPlayerPlayerID ? GamePlayers[Event.eventStealingPlayerPlayerID] : null;

    if (!EventTurnoverPlayer) {
        return null;
    }

    const {
        player_slug: turnoverPlayerSlug
    } = EventTurnoverPlayer;

    let turnoverPlayerName = `${Event.eventTurnoverPlayerPlayerNumber || ""}` + " " + generatePlayerName(EventTurnoverPlayer);


    const {
        eventName: { singular: turnoverEventName },
        eventIcon: turnoverEventIcon
    } = TurnoverEvent;


    let HomeTeamData: React.ReactNode = "";
    let AwayTeamData: React.ReactNode = "";


    if (EventStealingPlayer) {

        const {
            eventName: { singular: stealEventName },
            eventIcon: stealEventIcon
        } = StealEvent;

        const {
            player_slug: stealingPlayerSlug
        } = EventStealingPlayer;

        let stealingPlayerName = `${Event.eventStealingPlayerPlayerNumber || ""}` + " " + generatePlayerName(EventStealingPlayer);

        const StealingPlayerComponent = <>
            <NavLink to={`/${PlayersRoute.routeURL}/${stealingPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name stealing-player"}>{stealingPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{stealEventName} ({Event.eventStealingPlayerStealsNumber})</span>
        </>

        if (homeTeamID === Event.evenStealingPlayerTeamID) {
            HomeTeamData = StealingPlayerComponent;
        } else if (awayTeamID === Event.evenStealingPlayerTeamID) {
            AwayTeamData = StealingPlayerComponent;
        }

    }


    if (homeTeamID === Event.eventTurnoverPlayerTeamID) {
        HomeTeamData = <>
            {turnoverEventIcon && <img src={turnoverEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${turnoverPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name turnover-player"}>{turnoverPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{turnoverEventName} ({Event.eventTurnoverPlayerTurnoversNumber})</span>
            {Event?.eventDescription?.text && <span className={"event-description"}> - {Event?.eventDescription?.text}</span>}
        </>;
    } else if (awayTeamID === Event.eventTurnoverPlayerTeamID) {
        AwayTeamData = <>
            {turnoverEventIcon && <img src={turnoverEventIcon} className={"event-icon"} alt={""} />}
            <NavLink to={`/${PlayersRoute.routeURL}/${turnoverPlayerSlug}`} title={PlayersRoute.routeTitle}>
                <span className={"event-player-name turnover-player"}>{turnoverPlayerName}</span>
            </NavLink>
            <span className={"event-label"}>{turnoverEventName} ({Event.eventTurnoverPlayerTurnoversNumber})</span>
            {Event?.eventDescription?.text && <span className={"event-description"}> - {Event?.eventDescription?.text}</span>}
        </>;
    }


    return (
        <Col xs={12} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content game-result-details__game-progress__quarters__quarter__events__event__event-content--block-event"}>
            <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__content"}>

                <QuarterEventOrderNrComponent
                    eventOrderNr={eventOrderNr}
                />

                <Col xs={4} lg={4} style={{ order: 1 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {HomeTeamData}
                    </Row>
                </Col>

                <QuarterEventPointsAndTimeComponent
                    eventTime={Event.eventTime}
                    quarterDuration={quarterDuration}
                    extraTimeDuration={extraTimeDuration}
                    cssClasses={["order-2"]}
                />

                <Col xs={4} lg={4} style={{ order: 3 }} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player"}>
                    <Row className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__event-player__content"}>
                        {AwayTeamData}
                    </Row>
                </Col>

            </Row>
        </Col>
    )

};


export default QuarterTurnoverEventDataComponent;
