import React from "react";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface LeagueTablesGroupHeaderComponentProps {
    groupName: string;
}


export const LeagueTablesGroupHeaderComponent: React.FC<LeagueTablesGroupHeaderComponentProps> = ({
    groupName
}) : JSX.Element | null => {


    if (!groupName) {
        return null;
    }


    return (
        <Col xs={12} className={"league-tables__group__header"}>
            <span>{groupName}</span>
        </Col>
    );


};


export default LeagueTablesGroupHeaderComponent;
