import {
    PLKA_FULL_COLOR_LOGO, PLKA_HALF_COLOR_LOGO, PLKA_WHITE_LOGO
} from "@Images";


export const UsePLKALogo = (logoType: "COLOR" | "HALF_COLOR" | "WHITE" = "COLOR") => {

    switch (logoType) {

        case "COLOR":
            return PLKA_FULL_COLOR_LOGO;

        case "HALF_COLOR":
            return PLKA_HALF_COLOR_LOGO;

        case "WHITE":
            return PLKA_WHITE_LOGO;

    }

};


export default UsePLKALogo;
