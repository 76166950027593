import React from "react";


import {Col, Row} from "react-bootstrap";


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerNameComponentProps {
    player: LeaguePlayerInterface;
}


export const PlayerNameComponent: React.FC<PlayerNameComponentProps> = ({
    player
}) : JSX.Element | null => {


    if (!player) return null;


    const {
        player_first_name: playerFirstName,
        player_last_name: playerLastName,
        player_nick_name: playerNickName,
        player_pseudonymization_status: playerPseudonymizationStatus
    } = player;


    return (
        <Row className={"player-name"}>
            <Col xs={12} className={"player-full-name"}>
                {!playerPseudonymizationStatus && <span>{playerFirstName}</span>}
                {!!playerNickName && <span className={"nick-name"}>"{playerNickName}"</span>}
                {!playerPseudonymizationStatus && <span>{playerLastName}</span>}
            </Col>
        </Row>
    );

};


export default PlayerNameComponent;
