import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import LeaguesTablesGroupHeaderComponent from "@Pages/Common/HomePage/Leagues/Tables/Tables/Group/Header";
import LeaguesTablesGroupTableComponent from "@Pages/Common/HomePage/Leagues/Tables/Tables/Group/Table";


import {
    LeagueTablesGroupInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeaguesTablesGroupComponentProps {
    Group: LeagueTablesGroupInterface
}


export const LeaguesTablesGroupComponent: React.FC<LeaguesTablesGroupComponentProps> = ({
    Group
}) : JSX.Element | null => {


    if (!Group) {
        return null;
    }


    const {
        group_name: groupName, group_table: GroupTable
    } = Group;


    return (
        <Col xs={12} className={"home-page__leagues__tables__tables__group"}>
            <Row className={"home-page__leagues__tables__tables__group__content"}>

                <LeaguesTablesGroupHeaderComponent groupName={groupName} />

                <LeaguesTablesGroupTableComponent GroupTable={GroupTable} />

            </Row>
        </Col>
    );


};


export default LeaguesTablesGroupComponent;
