import React from "react";

import {Container, Row, Col} from "react-bootstrap";
 
import {BarLoader} from "react-spinners";


export const GlobalApiRequestLoaderComponent = () => {
	
	
	return (
		<Container fluid={true} style={{position: "fixed", top: 0, left: 0, width: "100%", height: "100%", zIndex: 99999, backgroundColor: "rgba(0,0,0,0.5)"}}>
			<Container style={{height: "100%", paddingBottom: 100}}>
				<Row className={"align-content-center"} style={{height: "100%"}}>
					
					<Col xs={12} style={{position: "relative", alignSelf: "center"}}>
						<Row className={"justify-content-center"}>
							<div style={{alignSelf: "center", padding: "20px 50px 30px", background: "#FFF"}}>
								<Row className={"justify-content-center"} style={{marginBottom: 20}}>
									<span style={{color: "#17366E", fontWeight: 700}}>LOADING...</span>
								</Row>
								<Row className={"justify-content-center"}>
									<BarLoader
										className={"global-loader-component"}
										width={300}
										height={25}
										color={"#17366E"}
									/>
								</Row>
							</div>
						</Row>
						
					</Col>
					
				</Row>
			</Container>
		</Container>
	);
	
};


export default GlobalApiRequestLoaderComponent;
