import { UseLanguage } from "@States";


import GameQuarters from "@GameResultPageConstants/GameQuarters";


import { ApiGameSummaryQuarterType } from "@GameResultPageTypes/Summary/API/GameSummary/Quarters/Quarter";


export type GameQuarterType = {
    quarterName: { singular: string; plural: string; genitive?: string; };
    quarterNumberLabel: string;
};


export const UseGameQuarter = (quarterNumber: ApiGameSummaryQuarterType) : GameQuarterType | null => {

    const activeLanguage = UseLanguage();

    const Quarter = GameQuarters[quarterNumber];

    if (Quarter) {
        return {
            quarterName: Quarter.quarterName[activeLanguage],
            quarterNumberLabel: Quarter.quarterNumberLabel,
        };
    }

    return null;


};


export default UseGameQuarter;
