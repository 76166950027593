import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import "./index.scss";


export interface TeamNextGamesListHeaderComponentProps {}


export const TeamNextGamesListHeaderComponent: React.FC<TeamNextGamesListHeaderComponentProps> = ({}) : JSX.Element | null => {


    const DATE_WORD_TEXT = UseCommonDictionaryPhrasesSet("DATE_WORD_TEXT");
    const HOUR_SHORT_WORD_TEXT = UseCommonDictionaryPhrasesSet("HOUR_SHORT_WORD_TEXT");
    const PLACEMENT_WORD_TEXT = UseCommonDictionaryPhrasesSet("PLACEMENT_WORD_TEXT");
    const AGAINST_WORD_TEXT = UseCommonDictionaryPhrasesSet("AGAINST_WORD_TEXT");


    return (
        <Col xs={12} className={"team-details__team-information__team-next-games__games-list__header"}>
            <Row className={"team-details__team-information__team-next-games__games-list__header__content"}>
                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__header__game-order-nr"}>
                    <span></span>
                </Col>
                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__header__game-date-day"}>
                    <span>{DATE_WORD_TEXT}</span>
                </Col>
                <Col xs={1} className={"team-details__team-information__team-next-games__games-list__header__game-date-hour"}>
                    <span>{HOUR_SHORT_WORD_TEXT}.</span>
                </Col>
                <Col xs={5} className={"team-details__team-information__team-next-games__games-list__header__game-arena"}>
                    <span>{PLACEMENT_WORD_TEXT}</span>
                </Col>
                <Col xs={4} className={"team-details__team-information__team-next-games__games-list__header__game-opponent"}>
                    <span>{AGAINST_WORD_TEXT}</span>
                </Col>
            </Row>
        </Col>
    );

};


export default TeamNextGamesListHeaderComponent;
