import React from "react";


import { UseApplicationLogo } from "@Hooks/GUI";


import parse from "html-react-parser";


import { NavLink } from "react-router-dom";


import { Row, Col} from "react-bootstrap";


import { PLKA_FULL_COLOR_LOGO } from "@Images";


import ArticleInterface from "@Interfaces/Api/_Common/Articles/Article";


import "./index.scss";


export interface ArticlesListItemComponentProps {
    Article: ArticleInterface | null;
}


export const ArticlesListArticleComponent: React.FC<ArticlesListItemComponentProps> = ({
    Article
}) : JSX.Element | null => {


    let ArticleImage = UseApplicationLogo();


    if (!Article) {
        return null;
    }


    const {
        article_title: articleTitle,
        article_slug: articleSlug,
        article_short_content: articleShortContent,
        article_image: articleImage,
        article_published_status: articlePublishedStatus,
        article_archive_status: articleArchivedStatus,
    } = Article;


    if (!articlePublishedStatus || articleArchivedStatus) {
        return null;
    }


    if (articleImage) {
        ArticleImage = articleImage;
    }

    if (!ArticleImage) {
        ArticleImage = PLKA_FULL_COLOR_LOGO;
    }


    return (
        <Col xs={12} lg={4} as={"article"} className={"articles-list-page__articles-list__article"}>
            <Row className={"articles-list-article-content"}>

                <Col xs={12} className={"articles-list-page__articles-list__article__image"}>
                    <NavLink to={`/aktualnosci/${articleSlug}`} title={articleTitle}>
                        <img src={ArticleImage} alt={articleTitle}/>
                    </NavLink>
                </Col>

                <Col xs={12} className={"articles-list-page__articles-list__article__content"}>
                    <Row className={"articles-list-page__articles-list__article__content__content"}>

                    <Col xs={12} className={"article-title"}>
                        <NavLink to={`/aktualnosci/${articleSlug}`} title={articleTitle} className={"text-truncate"}>
                            <span>{articleTitle}</span>
                        </NavLink>
                    </Col>

                    {!!articleShortContent &&
                    <Col xs={12} className={"article-short-content"}>
                        <NavLink to={`/aktualnosci/${articleSlug}`} title={articleTitle}>
                            {parse(articleShortContent)}
                        </NavLink>
                    </Col>
                    }

                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default ArticlesListArticleComponent;
