import _getDataFromApi from "@Services/API/Requests";

import {
    API_LEAGUES_LIST_ENDPOINT_URL
} from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import {
    LeagueInterface
} from "@Interfaces/Api";



type UseLeaguesListType = (
    setLeagues: (Leagues: LeagueInterface[] | null) => void,
    setLeaguesError: (LeaguesError: ErrorMessageInterface | null) => void,
    onlyActiveSeason?: boolean, onlyActiveLeagues?: boolean,
    seasonsID?: number
) => void;


export const UseLeaguesList: UseLeaguesListType = async (
    setLeagues, setLeaguesError,
    onlyActiveSeason = true, onlyActiveLeagues = true,
    seasonsID
) => {

    const queryParameters: string[] = [];

    if (onlyActiveSeason) {
        queryParameters.push("league_season__league_season_active_status=1");
    }

    if (onlyActiveSeason) {
        queryParameters.push("league_active_status=1");
    }

    if (seasonsID) {
        queryParameters.push(`league_season=${seasonsID}`);
    }

    const queryParametersString = queryParameters.length ? "?" + queryParameters.join("&") : "";

    try {
        await _getDataFromApi(
            `${API_LEAGUES_LIST_ENDPOINT_URL}${queryParametersString}`,
            setLeagues, setLeaguesError
        );
    } catch (e) {
        setLeaguesError( { errorMessage: "", errorCode: 500 } );
    }

};


export default UseLeaguesList;
