import React from "react";


import { Col, Row } from "react-bootstrap";


import { LeagueInterface } from "@Interfaces/Api";
import SchedulesAdminLeagueNameComponent from "@ResultsPages/_Admin/Leagues/League/LeagueName";
import SchedulesAdminLeagueOrderNrComponent from "@ResultsPages/_Admin/Leagues/League/LeagueOrderNr";
import SchedulesAdminLeagueStatusComponent from "@ResultsPages/_Admin/Leagues/League/LeagueStatus";
import SchedulesAdminLeagueSeasonComponent from "@ResultsPages/_Admin/Leagues/League/LeagueSeason";
import SchedulesAdminLeagueScheduleComponent from "@ResultsPages/_Admin/Leagues/League/LeagueScheduleStatus";


export interface LeaguesSchedulesAdminLeagueComponentProps {
    League: LeagueInterface;
    orderNr: number;
}


export const LeaguesSchedulesAdminLeagueComponent: React.FC<LeaguesSchedulesAdminLeagueComponentProps> = ({
    League, orderNr
}) : JSX.Element | null => {


    if (!League) {
        return null;
    }


    const {
        league_id: leagueID, league_slug: leagueSlug,
        league_name: leagueName, league_short_name: leagueShortName,
        league_active_status: leagueActiveStatus, league_status: leagueStatus,
        league_season_data: LeagueSeason,
        league_schedule_type: leagueScheduleType, league_schedule_generated_status: leagueScheduleGeneratedStatus,
    } = League;


    return (
        <Col xs={12} >
            <Row>

                <SchedulesAdminLeagueOrderNrComponent leagueOrderNr={orderNr} />

                <SchedulesAdminLeagueNameComponent leagueName={leagueName} leagueShortName={leagueShortName} />

                <SchedulesAdminLeagueStatusComponent leagueActiveStatus={leagueActiveStatus} leagueStatus={leagueStatus} />

                <SchedulesAdminLeagueSeasonComponent LeagueSeason={LeagueSeason} />

                <SchedulesAdminLeagueScheduleComponent leagueScheduleType={leagueScheduleType} leagueScheduleStatus={leagueScheduleGeneratedStatus} />

                <Col xs={2}></Col>

            </Row>
        </Col>
    );

};


export default LeaguesSchedulesAdminLeagueComponent;
