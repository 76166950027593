import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

import ApplicationState from "@Reducers";


export * from "@Action-Types";
export * from "@Actions";
export * from "@Reducers";


export const ApplicationStore = createStore(ApplicationState, applyMiddleware(thunk));


export type ApplicationStateType = ReturnType<typeof ApplicationState>;


export default ApplicationStore;
