import React from "react";


import {
    PageContainerComponent, PageContentComponent
} from "@ContentElements";


import TeamStatisticsStatisticsComponent from "@TeamsPages/Team/Statistics/Statistics";
import TeamStatisticsGamesComponent from "@TeamsPages/Team/Statistics/Games";


import {
    LeagueTeamInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamStatisticsComponentProps {
    Team: LeagueTeamInterface;
}


export const TeamStatisticsComponent: React.FC<TeamStatisticsComponentProps> = ({
    Team
}) : JSX.Element | null => {


    if (!Team) {
        return null;
    }


    const {
        team_id: teamID,
        team_statistics: TeamStatistics, team_games: TeamGames
    } = Team;


    return (
        <PageContainerComponent cssClasses={"team-details__team-statistics"}>

            <PageContentComponent cssClasses={"team-details__team-statistics__content"}>

                <TeamStatisticsStatisticsComponent
                    TeamStatistics={TeamStatistics}
                />

                {!!TeamGames &&
                <TeamStatisticsGamesComponent
                    teamID={teamID}
                    TeamGames={TeamGames}
                />
                }

            </PageContentComponent>

        </PageContainerComponent>
    );

};


export default TeamStatisticsComponent;
