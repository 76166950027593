import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        go_to_team_page_text: "Przejdź do strony drużyny",
        player_number_text: "numer"
    },
    EN: {
        go_to_team_page_text: "Go to Team Page",
        player_number_text: "number"
    }
};


import LeaguePlayerInterface from "@Interfaces/Api/Players/Player";


export interface PlayerFieldInformationComponentProps {
    player: LeaguePlayerInterface;
}


export const PlayerFieldInformationComponent: React.FC<PlayerFieldInformationComponentProps> = ({
    player
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        go_to_team_page_text: GO_TO_TEAM_PAGE_TEXT,
        player_number_text: PLAYER_NUMBER_TEXT
    } = TEXTS[activeLanguage];


    if (!player) return null;


    const {
        player_position: playerFieldPosition, player_number: playerFieldNumber,
        player_team: playerTeam
    } = player;


    return (
        <Row className={"player-field-information"}>
            <Col xs={12} className={"player-information"}>

                {!!playerTeam &&
	            <NavLink to={`/druzyny/${playerTeam["team_slug"]}`} title={GO_TO_TEAM_PAGE_TEXT} className={"player-team-name"}>
		            <span>{playerTeam["team_name"]}</span>
	            </NavLink>
                }

                {(!!playerFieldPosition || playerFieldPosition === 0) &&
			    <span className={"player-field-position"}>{playerFieldPosition}</span>
                }

                {(!!playerFieldNumber || playerFieldNumber === 0) &&
			    <span className={"player-field-number"}>
                    <span>{PLAYER_NUMBER_TEXT}</span>
                    <span className={"field-number"}>{playerFieldNumber}</span>
                </span>
                }

            </Col>
        </Row>
    );

};


export default PlayerFieldInformationComponent;
