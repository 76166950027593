import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col} from "react-bootstrap";


const TEXTS: any = {
    PL: {
        load_all_text: "Załaduj wszystkich",
        load_top_text: "Załaduj Top 10",
    },
    EN: {
        load_all_text: "Load all",
        load_top_text: "Load Top 10",
    }
};


export interface LeagueStatisticsNumberComponentProps {
    statisticsNumber: number;
    statisticsNumberHandler: (n: number) => void;
}


export const LeagueStatisticsNumberComponent: React.FC<LeagueStatisticsNumberComponentProps> = ({
    statisticsNumber, statisticsNumberHandler
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        load_all_text: LOAD_ALL_TEXT,
        load_top_text: LOAD_TOP_TEXT
    } = TEXTS[activeLanguage];


    return (
        <Col xs={12} className={"league-statistics-number league-statistics-number-component"}>
            <span onClick={() => statisticsNumberHandler(statisticsNumber === 10 ? -1 : 10)}>
                {statisticsNumber === 10 ? LOAD_ALL_TEXT : LOAD_TOP_TEXT}
            </span>
        </Col>
    );

};


export default LeagueStatisticsNumberComponent;
