import MainStatisticsTypesInterface from "@GlobalData/Statistics/MainTypes/Types";


export const MainStatisticsTypes: MainStatisticsTypesInterface = {
    MEDIUM: {
        PL: { label: "Śr.", title: "Średnie" },
        EN: { label: "Med.", title: "" },
    },
    TOTAL: {
        PL: { label: "Sum.", title: "Sumaryczne" },
        EN: { label: "Tot.", title: "Total" },
    },
};


export default MainStatisticsTypes;
