import React from "react";


import {Col, Row} from "react-bootstrap";


interface SeasonLeagueInterface {
    leagueID: number;
    leagueName: string;
    leagueShortName?: string;
}


export interface SeasonsAndLeaguesLeaguesComponentProps {
    leaguesList: SeasonLeagueInterface[];
    selectedLeagueID: number;
    selectedLeagueIDHandler(ids: number): void;
}


export const SeasonsAndLeaguesLeaguesComponent: React.FC<SeasonsAndLeaguesLeaguesComponentProps> = ({
    leaguesList,
    selectedLeagueID, selectedLeagueIDHandler
}) : JSX.Element | null => {


    if (!selectedLeagueID && selectedLeagueID !== 0) return null;


    const changeSelectedLeagueID = (leagueID: number) => {
        selectedLeagueIDHandler(selectedLeagueID === leagueID ? 0 : leagueID);
    };

    const Leagues = leaguesList.map((League) => {

        const LeagueSelectedStatus = selectedLeagueID === League.leagueID;

        return (
            <Col xs={"auto"} key={League.leagueID} className={"leagues-list-league" + (LeagueSelectedStatus ? " selected" : "")}>
                <span onClick={() => changeSelectedLeagueID(League.leagueID)}>
                    {League.leagueShortName || League.leagueName}
                </span>
            </Col>
        );

    });


    return (
        <Col xs={8} lg={9} className={"leagues-and-seasons-leagues leagues-and-seasons-leagues-component"}>
            <Row className={"leagues-and-seasons-leagues-content"}>

                <Col xs={12} className={"leagues-list"}>
                    <Row className={"leagues-list-content"}>
                        {Leagues}
                    </Row>
                </Col>

            </Row>
        </Col>
    )

};


export default SeasonsAndLeaguesLeaguesComponent;
