import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import {
    SingleContentLoaderComponent
} from "@LoadersElements";

import MessageComponent from "@MessagesElements/Content";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";


import TeamTop5PlayersListHeaderComponent from "@TeamsPages/Team/Information/TeamTopPlayers/PlayersList/Header";
import TeamTop5PlayersListPlayersComponent from "@TeamsPages/Team/Information/TeamTopPlayers/PlayersList/Players";


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import PlayerLeagueStatisticsInterface from "@ApiInterfaces/Leagues/Statistics/League/Player";


import "./index.scss";


export interface TeamTop5PlayersListComponentProps {
    selectedLeagueId: number;
    LeagueStatistics: PlayerLeagueStatisticsInterface[] | null;
    LeagueStatisticsError: ErrorMessageInterface | null;
    selectedStatisticsType: StatisticsType;
    selectedStatisticsTypeHandler: (type: StatisticsType) => void;
}


export const TeamTop5PlayersListComponent: React.FC<TeamTop5PlayersListComponentProps> = ({
    selectedLeagueId,
    LeagueStatistics, LeagueStatisticsError,
    selectedStatisticsType, selectedStatisticsTypeHandler
}) : JSX.Element | null => {


    const DEFAULT_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_ERROR_MESSAGE_PHRASE_TEXT");
    const NO_LEAGUE_SELECTED_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("NO_LEAGUE_SELECTED_PHRASE_TEXT");


    if (!selectedLeagueId) {
        return <MessageComponent messageText={NO_LEAGUE_SELECTED_PHRASE_TEXT} />;
    }

    if (LeagueStatisticsError) {
        return <ErrorMessageComponent messageText={DEFAULT_ERROR_MESSAGE_PHRASE_TEXT} />;
    }

    if (!LeagueStatistics) {
        return <SingleContentLoaderComponent />;
    }

    return (
        <Col xs={12} className={"team-details__team-information__team-top-players__list"}>
            <Row className={"team-details__team-information__team-top-players__list__content"}>

                <TeamTop5PlayersListHeaderComponent
                    selectedStatisticsType={selectedStatisticsType}
                    selectedStatisticsTypeHandler={selectedStatisticsTypeHandler}
                />

                <TeamTop5PlayersListPlayersComponent
                    LeagueStatistics={LeagueStatistics}
                />

            </Row>
        </Col>
    );

};


export default TeamTop5PlayersListComponent;
