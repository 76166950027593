import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import UseMainStatisticsType from "@GlobalData/Statistics/MainTypes/Hooks/StatisticsType";
import MainStatisticsTypeType from "@GlobalData/Statistics/MainTypes/Type";


import "./index.scss";


export interface TeamTop5PlayersHeaderComponentProps {
    selectedStatisticsMainType: MainStatisticsTypeType;
    selectedStatisticsMainTypeHandler: (type: MainStatisticsTypeType) => void;
    selectedLeagueId: number;
}


export const TeamTop5PlayersHeaderComponent: React.FC<TeamTop5PlayersHeaderComponentProps> = ({
    selectedStatisticsMainType, selectedStatisticsMainTypeHandler, selectedLeagueId
}) : JSX.Element | null => {


    const MediumStatisticsObject = UseMainStatisticsType("MEDIUM");
    const SummaryStatisticsObject = UseMainStatisticsType("TOTAL");


    return (
        <Col xs={12} className={"team-details__team-information__team-top-players__header"}>
            <Row className={"team-details__team-information__team-top-players__header__content"}>

                <Col xs={3} lg={3} className={"team-details__team-information__team-top-players__header__title"}>
                    <span>Top 5</span>
                </Col>

                {selectedLeagueId &&
                <Col xs={9} lg={9} className={"team-details__team-information__team-top-players__header__switcher"}>
		            <span className={"statistic-type-switcher" + (selectedStatisticsMainType === "MEDIUM" ? " active" : "")} onClick={() => selectedStatisticsMainTypeHandler("MEDIUM")}>
                        {MediumStatisticsObject?.title}
                    </span>
		            <span className={"statistics-type-separator"}>/</span>
		            <span className={"statistic-type-switcher" + (selectedStatisticsMainType === "TOTAL" ? " active" : "")} onClick={() => selectedStatisticsMainTypeHandler("TOTAL")}>
                        {SummaryStatisticsObject?.title}
                    </span>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default TeamTop5PlayersHeaderComponent;
