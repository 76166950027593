import React from "react";


import { Col } from "react-bootstrap";


import "./index.scss";


export interface QuarterEventOrderNrComponentProps {
    eventOrderNr: string;
}


export const QuarterEventOrderNrComponent: React.FC<QuarterEventOrderNrComponentProps> = ({
    eventOrderNr
}) : JSX.Element | null => {


    return (
        <Col xs={12} lg={1} className={"game-result-details__game-progress__quarters__quarter__events__event__event-content__order-nr"}>
            <span>{eventOrderNr}</span>
        </Col>
    );

};


export default QuarterEventOrderNrComponent;
