import MessageLanguageInterface from "@GlobalData/Messages/_Interfaces/Language";


const NoDataMessagePL = "Brak Danych";
const NoDataMessageEn = "No Data";


export interface DataMessagesInterface {
    noData: MessageLanguageInterface;
}


export const DataMessages: DataMessagesInterface = {
    noData: {
        PL: NoDataMessagePL,
        EN: NoDataMessageEn
    }
};


export default DataMessages;
