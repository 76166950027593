import React from "react";


import { Row, Col } from "react-bootstrap";


import GameThrowsTeamNameComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Name";
import GameThrowsTeamPlayersComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Players";
import GameThrowsTeamThrowsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/Chart";
import GameThrowsTeamSelectAllComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws/Teams/Team/SelectAll";


import ApiGameSummaryPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Players";
import ApiGameSummaryTeamDataInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Data";
import ApiGameSummaryTeamPlayersInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Teams/Team/Players";

import ThrowsEventsTeamInterface from "@Components/Views/Pages/Games/Games/Game/_Types/Summary/GUI/Throws/Teams/Team";


interface GameThrowsTeamComponentProps {
    GameTeam: ApiGameSummaryTeamDataInterface;
    gameTeamType?: "HOME" | "AWAY";
    GameTeamPlayers: ApiGameSummaryTeamPlayersInterface;
    GameTeamThrows: ThrowsEventsTeamInterface;
    GamePlayers: ApiGameSummaryPlayersInterface;
    SelectedPlayers: number[];
    selectedPlayersHandler(playerId: number | number[]): void;
}


export const GameThrowsTeamComponent: React.FC<GameThrowsTeamComponentProps> = ({
    GameTeam, gameTeamType, GameTeamPlayers,
    GameTeamThrows,
    GamePlayers,
    SelectedPlayers, selectedPlayersHandler
}) : JSX.Element | null => {


    if (!GameTeam || !GameTeamPlayers) return null;


    let cssClasses = "game-information-throws-team game-information-throws-team-component";
    if (gameTeamType === "HOME") cssClasses += " throws-home-team";
    if (gameTeamType === "AWAY") cssClasses += " throws-away-team";


    return (
        <Col xs={12} lg={6} className={cssClasses}>
            <Row className={"game-information-throws-team-content"}>

                <GameThrowsTeamNameComponent
                    GameTeam={GameTeam}
                    gameTeamType={gameTeamType}
                />

                <GameThrowsTeamPlayersComponent
                    GameTeamPlayers={GameTeamPlayers}
                    GamePlayers={GamePlayers}
                    SelectedPlayers={SelectedPlayers}
                    selectedPlayersHandler={selectedPlayersHandler}
                />

                <GameThrowsTeamThrowsComponent
                    GamePlayers={GamePlayers}
                    GameTeamThrows={GameTeamThrows}
                />

                <GameThrowsTeamSelectAllComponent
                    GameTeamPlayers={GameTeamPlayers}
                    GamePlayers={GamePlayers}
                    SelectedPlayers={SelectedPlayers}
                    selectedPlayersHandler={selectedPlayersHandler}
                />

            </Row>
        </Col>
    );


};


export default GameThrowsTeamComponent;
