import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TeamNextGamesHeaderComponentProps {}


export const TeamNextGamesHeaderComponent: React.FC<TeamNextGamesHeaderComponentProps> = ({}) : JSX.Element | null => {


    const INCOMING_GAMES_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("INCOMING_GAMES_PHRASE_TEXT");


    return (
        <Col xs={12} className={"team-details__team-information__team-next-games__header"}>
            <span>{INCOMING_GAMES_PHRASE_TEXT}</span>
        </Col>
    );

};


export default TeamNextGamesHeaderComponent;
