export const countPercentageThrowsEffectivity = (
    accurateThrows: number, allThrows: number
) : number => {

    if (!allThrows) return 0;

    return accurateThrows / allThrows * 100;

};


export default countPercentageThrowsEffectivity;
