export const countDiagramsScaleStep = (biggestAdvantage: number) : number => {

    let diagramPointsStep = 1;

    if (biggestAdvantage > 80) {
        diagramPointsStep = 20
    } else if (biggestAdvantage > 60) {
        diagramPointsStep = 15;
    } else if (biggestAdvantage > 50) {
        diagramPointsStep = 13;
    } else if (biggestAdvantage > 44) {
        diagramPointsStep = 10;
    } else if (biggestAdvantage > 39) {
        diagramPointsStep = 9;
    } else if (biggestAdvantage > 35) {
        diagramPointsStep = 8;
    } else if (biggestAdvantage > 29) {
        diagramPointsStep = 7;
    } else if (biggestAdvantage > 24) {
        diagramPointsStep = 6;
    } else if (biggestAdvantage > 19) {
        diagramPointsStep = 5;
    } else if (biggestAdvantage > 17) {
        diagramPointsStep = 4;
    } else if (biggestAdvantage > 14) {
        diagramPointsStep = 3.5;
    } else if (biggestAdvantage > 12) {
        diagramPointsStep = 3;
    } else if (biggestAdvantage > 9) {
        diagramPointsStep = 2.5;
    }

    return diagramPointsStep;

};


export default countDiagramsScaleStep;
