import React, { useEffect } from "react";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import LeaguesTablesLeaguesListComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Leagues";
import LeaguesTablesTablesComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Tables/Tables";


import {
    LeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export type HomePageLeaguesTablesComponentProps = {
    LeaguesList: LeagueInterface[];
    selectedSeasonID: number | null;
    selectedSeasonIDHandler: (seasonID: number) => void;
    selectedLeagueID: number | null;
    selectedLeagueIDHandler(leagueID: number | null): void;
    selectedLeagueHandler: (League: LeagueInterface | null) => void;
};


export const HomePageLeaguesTablesComponent: React.FC<HomePageLeaguesTablesComponentProps> = ({
    LeaguesList,
    selectedSeasonID, selectedSeasonIDHandler,
    selectedLeagueID = null, selectedLeagueIDHandler,
    selectedLeagueHandler
}) : JSX.Element | null => {


    const TABLES_WORD_TEXT = UseCommonDictionaryPhrasesSet("TABLES_WORD_TEXT");


    useEffect(() => {

        if (!LeaguesList || !LeaguesList?.length || !!selectedLeagueID) return;

        const {
            league_id: leagueID, league_season_id: leagueSeasonID
        } = LeaguesList[0];

        selectedLeagueIDHandler(leagueID);
        selectedLeagueHandler(LeaguesList[0]);
        selectedSeasonIDHandler(leagueSeasonID || 0);

    }, [LeaguesList]);


    return (
        <Col xs={12} lg={!!LeaguesList && !LeaguesList?.length ? 12 : 8} className={"home-page__leagues__tables"}>
            <Row className={"home-page__leagues__tables__content"}>

                <Col as={"h3"} xs={12} className={"home-page-leagues-header home-page__leagues__tables__header"}>
                    <span>{TABLES_WORD_TEXT}</span>
                </Col>

                <LeaguesTablesLeaguesListComponent
                    leaguesList={LeaguesList}
                    selectedLeagueID={selectedLeagueID}
                    selectedLeagueIDHandler={selectedLeagueIDHandler}
                    selectedLeagueHandler={selectedLeagueHandler}
                />

                <LeaguesTablesTablesComponent
                    selectedSeasonID={selectedSeasonID}
                    selectedLeagueID={selectedLeagueID}
                    noLeagues={!LeaguesList?.length}
                />

            </Row>
        </Col>
    );

};


export default HomePageLeaguesTablesComponent;
