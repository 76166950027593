import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Switch, Route, Redirect} from "react-router-dom";


import GameResultComponent from "@Components/Views/Pages/Games/Games/Game";

import LeagueGameAdminComponent from "@Components/Views/Pages/Games/Games/__GameAdmin";
import GameDetailsAdminComponent from "@GameResultPage/__Admin";


interface PAGES_INTERFACE {
    [key: string]: {
        URL: {
            [key: string]: string
        },
        TITLE: {
            [key: string]: string
        }
    }
}


const PAGES: PAGES_INTERFACE = {
    games_results_page: {
        URL: {PL: "terminarz", EN: "schedule"},
        TITLE: {PL: "Terminarz", EN: "Schedule"}
    },
    game_result_page: {
        URL: {PL: "mecze/:gameId", EN: "mecze/:gameId"},
        TITLE: {PL: "Podsumowanie Meczu", EN: "Game Summary"}
    }
};


type GamesResultsComponentProps = {};


export const GamesResultsComponent: React.FC<GamesResultsComponentProps> = () : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {
        games_results_page: GAMES_RESULTS_PAGE, game_result_page: GAME_RESULT_PAGE
    } = PAGES;

    const GAMES_RESULTS_URL: string = GAMES_RESULTS_PAGE.URL[activeLanguage];
    const GAME_SUMMARY_URL: string = GAME_RESULT_PAGE.URL[activeLanguage];


    return (
        <Switch>

            <Route
                path={`/${GAME_SUMMARY_URL}/game-admin`}
                render={({match}) =>
                    <GameDetailsAdminComponent match={match} />
                }
            />

            <Route
                path={`/${GAME_SUMMARY_URL}`}
                render={({match}) =>
                    <GameResultComponent match={match} />
                }
            />

            <Redirect to={`/${GAMES_RESULTS_URL}`} />

        </Switch>
    );

};


export default GamesResultsComponent;
