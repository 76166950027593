import React from "react";


import {
    UseRoute
} from "@Hooks/Routes";

import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    NavLink
} from "react-router-dom";


import {
    Col, Row
} from "react-bootstrap";


import dateFormat from "dateformat";


import {
    LeagueGameInterface, TeamLeagueInterface
} from "@Interfaces/Api";


export interface TeamStatisticsNextGameComponentProps {
    teamID: number;
    Game: LeagueGameInterface;
}


export const TeamStatisticsNextGameComponent: React.FC<TeamStatisticsNextGameComponentProps> = ({
    teamID, Game
}) : JSX.Element | null => {


    const GamesRoute = UseRoute("GAMES");

    const NEXT_WORD_TEXT = UseCommonDictionaryPhrasesSet("NEXT_WORD_TEXT");
    const GAME_WORD_TEXT = UseCommonDictionaryPhrasesSet("GAME_WORD");


    if (!teamID || !Game) {
        return null;
    }


    const {
        league_game_league_away_team_data: AwayTeam, league_game_league_home_team_data: HomeTeam,
        league_game_id: gameID, league_game_date: gameDate,
        } = Game;

    if (!HomeTeam || !AwayTeam) {
        return null;
    }


    const { team_league_team_data: HomeTeamData } = HomeTeam;
    const { team_league_team_data: AwayTeamData } = AwayTeam;

    if (!HomeTeamData || !AwayTeamData) {
        return null;
    }

    const { team_id: homeTeamID } = HomeTeamData;
    const { team_id: awayTeamID } = AwayTeamData;

    let OpponentTeam: TeamLeagueInterface | null = null;

    if (homeTeamID === teamID) {
        OpponentTeam = AwayTeam;

    } else if (awayTeamID === teamID) {
        OpponentTeam = HomeTeam;
    } else {
        return null;
    }


    const { team_league_team_name: opponentTeamName } = OpponentTeam;


    return (
        <Col xs={6} className={"team-details__team-statistics__games__game team-details__team-statistics__games__game--next-game"}>
            <Row className={"team-details__team-statistics__games__game__content"}>

                <Col xs={12} className={"team-details__team-statistics__games__game__label"}>
                    <span>{NEXT_WORD_TEXT} {GAME_WORD_TEXT}</span>
                </Col>

                <Col xs={12} className={"team-details__team-statistics__games__game__data"}>
                    <NavLink to={`/${GamesRoute.routeURL}/${gameID}`}title={GamesRoute.routeTitle} className={"team-details__team-statistics__games__game__data__game-link"}>
                        <span className={"team-details__team-statistics__games__game__data__game-link__opponent-name text-truncate"}>{opponentTeamName}</span>
                    </NavLink>
                </Col>

                {gameDate &&
                <Col xs={12} className={"team-details__team-statistics__games__game__date"}>
                    <span>{dateFormat(gameDate, "dd-mm-yyyy HH:MM")}</span>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default TeamStatisticsNextGameComponent;
