import React, { useEffect, useState } from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamLeaguesListLeagueDataComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/LeagueInformation";
import TeamLeaguesListLeagueTeamInformationComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamInformation";
import TeamLeaguesListLeagueTeamSquadComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/TeamPlayers";


import {
    TeamLeagueInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamLeaguesListLeagueComponentProps {
    teamID: number;
    TeamLeague: TeamLeagueInterface;
    updateTeamLeaguesTriggerHandler: (status: boolean) => void;
}


export const TeamLeaguesListLeagueComponent: React.FC<TeamLeaguesListLeagueComponentProps> = ({
    teamID, TeamLeague, updateTeamLeaguesTriggerHandler
}) : JSX.Element | null => {


    const [showTeamLeagueDetailsStatus, setShowTeamLeagueDetailsStatus] = useState(false);

    useEffect(() => {
        return () => {
            setShowTeamLeagueDetailsStatus(false);
        };
    }, []);


    if (!TeamLeague) {
        return null;
    }


    const {
        id: teamLeagueID,
        team_league_active_status: teamLeagueTeamActiveStatus,
        team_league_team_name: teamLeagueTeamName, team_league_team_short_name: teamLeagueTeamShortName,
        team_league_league_data: TeamLeagueData,
        team_squad: TeamLeagueSquad
    } = TeamLeague;


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league" + (showTeamLeagueDetailsStatus ? " opened" : "")}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__content"}>

                <TeamLeaguesListLeagueDataComponent
                    TeamLeagueData={TeamLeagueData}
                    showTeamLeagueDetailsStatus={showTeamLeagueDetailsStatus}
                    showTeamLeagueDetailsStatusHandler={setShowTeamLeagueDetailsStatus}
                />

                {showTeamLeagueDetailsStatus &&
                <TeamLeaguesListLeagueTeamInformationComponent
                    teamLeagueTeamName={teamLeagueTeamName}
                    teamLeagueTeamShortName={teamLeagueTeamShortName}
                    teamLeagueTeamActiveStatus={teamLeagueTeamActiveStatus}
                />
                }

                {showTeamLeagueDetailsStatus &&
                <TeamLeaguesListLeagueTeamSquadComponent
	                teamID={teamID}
	                teamLeagueID={teamLeagueID}
                    TeamLeagueSquad={TeamLeagueSquad}
                    updateTeamLeaguesTriggerHandler={updateTeamLeaguesTriggerHandler}
                />
                }

            </Row>
        </Col>
    );

};


export default TeamLeaguesListLeagueComponent;
