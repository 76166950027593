import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col, Row
} from "react-bootstrap";


import MessageComponent from "@MessagesElements/Content";


import TeamTop5PlayersListPlayerComponent from "@TeamsPages/Team/Information/TeamTopPlayers/PlayersList/Players/Player";


import PlayerLeagueStatisticsInterface from "@ApiInterfaces/Leagues/Statistics/League/Player";


import "./index.scss";


export interface TeamTop5PlayersListPlayerComponentProps {
    LeagueStatistics: PlayerLeagueStatisticsInterface[] | null;
}


export const TeamTop5PlayersListPlayersComponent: React.FC<TeamTop5PlayersListPlayerComponentProps> = ({
    LeagueStatistics
}) => {


    const DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT");


    if (!LeagueStatistics) {
        return null;
    }

    if (!LeagueStatistics?.length) {
        return <MessageComponent messageText={DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT} />;
    }


    const PlayersList = LeagueStatistics.map((Player, i) =>
        <TeamTop5PlayersListPlayerComponent key={i} Player={Player} orderNr={i + 1} />
    );


    return (
        <Col xs={12} className={"team-details__team-information__team-top-players__list__players"}>
            <Row className={"team-details__team-information__team-top-players__list__players__content"}>

                {PlayersList}

            </Row>
        </Col>
    )

};


export default TeamTop5PlayersListPlayersComponent;
