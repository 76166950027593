import React from "react";


import { UseRoute } from "@Hooks/Routes";


import { NavLink } from "react-router-dom";


import { Col } from "react-bootstrap";


import { IMAGE_PLACEHOLDER } from "@Images";


import "./index.scss";


interface GameTablesTeamHeaderLogoComponentProps {
    teamName: string;
    teamSlug: string;
    teamLogoURL?: string | null;
}


export const GameTablesTeamHeaderLogoComponent: React.FC<GameTablesTeamHeaderLogoComponentProps> = ({
    teamName, teamSlug, teamLogoURL = null
}) : JSX.Element | null => {


    const TeamsRoute = UseRoute("TEAMS");

    const {
        routeTitle: TEAMS_ROUTE_TITLE, routeURL: TEAMS_ROUTE_URL
    } = TeamsRoute;


    if (!teamName || !teamSlug) return null;


    return (
        <Col xs={3} lg={2} className={"team-header-logo"}>
            <NavLink to={`/${TEAMS_ROUTE_URL}/${teamSlug}`} title={TEAMS_ROUTE_TITLE}>
                <img src={teamLogoURL || IMAGE_PLACEHOLDER} alt={teamName} />
            </NavLink>
        </Col>
    );

};


export default GameTablesTeamHeaderLogoComponent;
