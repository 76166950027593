import React from "react";


import {
    UseCommonDictionaryPhrasesSet
} from "@Hooks/Texts";


import {
    Col
} from "react-bootstrap";


import "./index.scss";


export interface TransfersListPlayersHeaderComponentProps {}


export const TransfersListPlayersHeaderComponent: React.FC<TransfersListPlayersHeaderComponentProps> = ({}) : JSX.Element | null => {


    const TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT = UseCommonDictionaryPhrasesSet("TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT");


    return (
        <Col xs={12} className={"transfers-list__list__players__header"}>
            <span className={"transfers-list__list__players__header__text"}>{TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT}</span>
        </Col>
    );

};


export default TransfersListPlayersHeaderComponent;
