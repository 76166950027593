import React from "react";


import { UseStatisticsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


export interface GameTablesTeamLegendComponentProps {}


export const GameTablesTeamLegendComponent: React.FC<GameTablesTeamLegendComponentProps> = () : JSX.Element | null => {


    const FOUL = UseStatisticsDictionaryPhrasesSet("FOUL");
    const UNSPORTSMANLIKE = UseStatisticsDictionaryPhrasesSet("FOULS_UNSPORTSMANLIKE");
    const TECHNICAL = UseStatisticsDictionaryPhrasesSet("FOULS_TECHNICAL");
    const DISQUALIFICATION = UseStatisticsDictionaryPhrasesSet("FOUL_DISQUALIFICATION");


    return (
        <Col xs={12} className={"game-tables-team-table-legend"}>
            <Row className={"game-tables-team-table-legend-content"}>

                <Col xs={"auto"} className={"legend-label"}>
                    <span>{FOUL}:</span>
                </Col>

                <Col xs={"auto"} className={"legend-element fouls-technical"}>
                    <span>{TECHNICAL}</span>
                </Col>

                <Col xs={"auto"} className={"legend-element fouls-not-sport"}>
                    <span>{UNSPORTSMANLIKE}</span>
                </Col>

                <Col xs={"auto"} className={"legend-element fouls-disqualification"}>
                    <span>{DISQUALIFICATION}</span>
                </Col>

            </Row>
        </Col>
    );

};


export default GameTablesTeamLegendComponent;
