import React from "react";


import {Row, Col} from "react-bootstrap";


interface GameSummaryTableHeaderComponentProps {
    tableTitle: string;
}


export const GameSummaryTableHeaderComponent: React.FC<GameSummaryTableHeaderComponentProps> = ({
    tableTitle,
}) : JSX.Element | null => {


    if (!tableTitle) return null;


    return (
        <Row className={"summary-table-header-row summary-table-header-component"}>
            <Col xs={{span: 4, offset: 4}} className={"summary-table-header"}>
                <span>{tableTitle}</span>
            </Col>
        </Row>
    );

};


export default GameSummaryTableHeaderComponent;
