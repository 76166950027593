import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import REST_API_ENDPOINTS_URLS from "@ApiData";
import _restApiRequest from "@Services/HTTP/Requests/Request";


import {Col, Row} from "react-bootstrap";


import SingleContentLoaderComponent from "@Components/Elements/Loaders/SingleContent";


import ErrorMessageInterface from "@Interfaces/Gui/Messages/Error";

import MessageComponent from "@Components/Elements/Messages/Content";

import HttpResponseErrorMessages from "@GlobalData/Messages/HTTP/Response/Errors";

import SystemErrorMessages from "@GlobalData/Messages/System/Errors";

import DataMessages from "@GlobalData/Messages/System/Communicates/Data";

import LeaguesMessages from "@GlobalData/Messages/GUI/Leagues";


import LeaguesStatisticsTableHeaderComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Statistics/Tables/Header";
import LeaguesStatisticsTablePlayerComponent from "@Components/Views/Pages/Common/HomePage/Leagues/Statistics/Tables/Player";

import StatisticsType from "@GlobalData/Statistics/Types/Type";
import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


export interface LeaguesStatisticsTableComponentProps {
    selectedLeagueID: number | null;
}


export const LeaguesStatisticsTableComponent: React.FC<LeaguesStatisticsTableComponentProps> = ({
    selectedLeagueID = null
}) : JSX.Element | null => {


    const LEAGUE_API_URL = REST_API_ENDPOINTS_URLS.GAMES.LEAGUES.LEAGUES.LEAGUE.MAIN_URL;


    const activeLanguage = UseLanguage();


    const [selectedStatisticsType, setSelectedStatisticsType] = useState<StatisticsType>("TPTS");


    const [leagueStatistics, setLeagueStatistics] = useState<PlayerLeagueStatisticsInterface[] | null>(null);
    const [leagueStatisticsError, setLeagueStatisticsError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!selectedLeagueID || !LEAGUE_API_URL) return;

        setLeagueStatistics(null);

        const getLeagueStatistics = async () => {

            setLeagueStatisticsError(null);

            try {

                const apiResponse = await _restApiRequest(
                    `${LEAGUE_API_URL}/${selectedLeagueID}/league_statistics/?sort_type=${selectedStatisticsType}&statistics_type=MEDIUM&results_limit=10`
                );

                if (apiResponse.ok) {
                    const responseContent = await apiResponse.json();
                    if (responseContent?.error) {
                        if (responseContent?.code === 404) {
                            setLeagueStatistics([]);
                        } else {
                            setLeagueStatisticsError({errorCode: apiResponse.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                        }
                    } else {
                        setLeagueStatistics(responseContent);
                    }
                } else {
                    setLeagueStatisticsError({errorCode: apiResponse.status, errorMessage: HttpResponseErrorMessages.defaultErrorMessage[activeLanguage]});
                }

            } catch (e) {
                setLeagueStatisticsError({errorCode: 500, errorMessage: SystemErrorMessages.defaultErrorMessage[activeLanguage]});
            }

        };
        getLeagueStatistics();

    }, [selectedLeagueID, selectedStatisticsType]);


    if (!selectedLeagueID) {
        return (
            <MessageComponent messageText={LeaguesMessages.noSelectedLeague[activeLanguage]} />
        );
    }

    if (!leagueStatistics) {
        return (
            <SingleContentLoaderComponent />
        );
    }


    if (leagueStatisticsError) {
        return (
            <MessageComponent messageText={leagueStatisticsError.errorMessage} cssClasses={"no-margin-top"} />
        );
    }

    if (leagueStatistics?.length === 0) {
        return (
            <MessageComponent messageText={DataMessages.noData[activeLanguage]} cssClasses={"no-margin-top"} />
        );
    }


    const StatisticsTable = leagueStatistics.slice(0, 10).map((player, i) =>
        <LeaguesStatisticsTablePlayerComponent key={i} index={i}
            playerData={player}
            selectedStatisticsType={selectedStatisticsType}
            selectedLeagueID={selectedLeagueID}
        />
    );


    return (
        <Col xs={12} className={"leagues-statistics-table"}>
            <Row className={"leagues-statistics-table-content"}>

                <LeaguesStatisticsTableHeaderComponent
                    selectedStatisticsType={selectedStatisticsType}
                    selectedStatisticsTypeHandler={setSelectedStatisticsType}
                />

                <Col xs={9} lg={12} className={"statistics-table-content"}>
                    <Row className={"statistics-table-content-content"}>
                        {StatisticsTable}
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default LeaguesStatisticsTableComponent;
