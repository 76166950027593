import React from "react";


import parse from "html-react-parser";


import { UseGlobalsDictionaryPhrasesSet } from "@Hooks/Texts";


import { Row, Col } from "react-bootstrap";


import PartnerInterface from "@ApiInterfaces/_Common/Partners/Partner";


import "./index.scss";


export interface PartnersPartnerComponentProps {
    Partner: PartnerInterface | null;
}


export const PartnersListPartnerComponent: React.FC<PartnersPartnerComponentProps> = ({
    Partner
}) : JSX.Element | null => {


    const WWW_SITE_TEXT = UseGlobalsDictionaryPhrasesSet("WWW_SITE_TEXT");
    const GO_TO_TEXT = UseGlobalsDictionaryPhrasesSet("GO_TO_TEXT");


    if (!Partner) {
        return null;
    }


    const {
        // partner_id: partnerID,
        sponsor_name: partnerName,
        sponsor_description: partnerDescription,
        sponsor_website: partnerUrl,
        sponsor_logo: partnerLogo,
        sponsor_active_status: partnerActiveStatus
    } = Partner;


    if (!partnerActiveStatus) return null;


    let partnerShortDescription = partnerDescription;

    if (partnerDescription && partnerDescription.length < 300) {
        partnerShortDescription = partnerDescription.slice(0, 300) + '...'
    }


    return (
        <Col xs={12} md={6} lg={4} className={"partners-page__partners-list__partner"}>

	        <Row className={"partners-page__partners-list__partner__header"}>
		        <Col xs={12} className={"partner-title"}>
			        <span>{partnerName}</span>
		        </Col>
                {!!partnerLogo &&
		        <Col as={"picture"} xs={12} className={"partner-logo"}>
			        <img src={partnerLogo} alt={partnerName}/>
		        </Col>
                }
	        </Row>

            {!!partnerShortDescription &&
	        <Row className={"partners-page__partners-list__partner__content"}>
		        <Col xs={12} className={"partner-description"}>
			        <p>{parse(partnerShortDescription)}</p>
		        </Col>
	        </Row>
            }

            {!!partnerUrl &&
			<Row className={"partners-page__partners-list__partner__footer"}>
				<Col xs={12} className={"partner-www"}>
					<span>{WWW_SITE_TEXT}:</span>
					<a href={partnerUrl} title={partnerName} rel="noreferrer noopener" target="_blank">{GO_TO_TEXT}</a>
				</Col>
			</Row>
            }

        </Col>
    );

};


export default PartnersListPartnerComponent;
