import React, {useEffect, useState} from "react";
import {Redirect} from "react-router-dom";
import {UseRoute} from "@Hooks/Routes";
import {UseLoggedUser} from "@States";
import _getDataFromApi from "@Services/API/Requests";
import {API_LEAGUES_LIST_ENDPOINT_URL} from "@Services/API/Endpoints";
import {LeagueInterface} from "@Interfaces/Api";
import {UseErrorMessage} from "@Hooks/Texts";
import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";
import ErrorMessageComponent from "@MessagesElements/Message/Error";
import {Col, Row} from "react-bootstrap";
import {DefaultPageHeaderComponent, PageContainerComponent, PageContentComponent} from "@ContentElements";
import PageHeaderContainerComponent from "../../../../../../UI/Containers/HeaderContainer";
import {SingleContentLoaderComponent} from "@LoadersElements";
import LeaguesScheduleAdminLeaguesComponent from "@ResultsPages/_Admin/Leagues";


export interface LeagueScheduleAdminComponentProps {}


export const LeagueScheduleAdminComponent: React.FC<LeagueScheduleAdminComponentProps> = ({

}) : JSX.Element | null => {


    const LoggedUser = UseLoggedUser();

    // useEffect(() => {
    //
    //     if (!LoggedUser?.userAdminStatus) {
    //         return;
    //     }
    //
    //     const checkUserAdminStatus = async () => {};
    //
    //     checkUserAdminStatus();
    //
    // }, [LoggedUser]);


    // const ScheduleRoute = UseRoute("SCHEDULES");



    const [Leagues, setLeagues] = useState<LeagueInterface[] | null>(null);
    const [LeaguesError, setLeaguesError] = useState<ErrorMessageInterface | null>(null);

    useEffect(() => {

        if (!LoggedUser?.userAdminStatus) {
            return;
        }

        const getLeagues = async () => {
            await _getDataFromApi(
                API_LEAGUES_LIST_ENDPOINT_URL, setLeagues, setLeaguesError
            );
        };

        getLeagues();

    }, [LoggedUser]);

    useEffect(() => {
        return () => {
            setLeagues(null);
            setLeaguesError(null);
        };
    }, []);


    if (!LoggedUser?.userAdminStatus) {
        return <Redirect to={"/"} />;
    }


    return (
        <PageContainerComponent>

            <DefaultPageHeaderComponent headerText={`Zarządzanie Terminarzami`} />

            <PageContentComponent>

                <LeaguesScheduleAdminLeaguesComponent
                    Leagues={Leagues}
                    LeaguesError={LeaguesError}
                />

            </PageContentComponent>

        </PageContainerComponent>
    );


};


export default LeagueScheduleAdminComponent;
