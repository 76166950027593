export * from "@Dictionary/Sets/Common/Types/Phrases/Set";
export * from "@Dictionary/Sets/Common/Phrases";


import CommonDictionaryInterface from "@Dictionary/Sets/Common/Types";

import {
    SELECT_ALL_MAN_PL,
    SELECT_ALL_MAN_EN,
    UN_SELECT_ALL_MAN_PL,
    UN_SELECT_ALL_MAN_EN,
    I_UNDERSTAND_PHRASE_PL,
    I_UNDERSTAND_PHRASE_EN,
    TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_PL,
    TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_EN,
    REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_PL,
    REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_EN,
    FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_PL,
    FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_EN,
    DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_PL,
    DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_EN,
    DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_PL,
    DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_EN,
    DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_PL,
    DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_EN,
    DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_PL,
    DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_EN,
    DATE_WORD_TEXT_PL, DATE_WORD_TEXT_EN,
    HOUR_WORD_TEXT_PL, HOUR_WORD_TEXT_EN, HOUR_SHORT_WORD_TEXT_PL, HOUR_SHORT_WORD_TEXT_EN,
    PLACEMENT_WORD_TEXT_PL, PLACEMENT_WORD_TEXT_EN, PLACEMENT_WORD_SHORT_TEXT_PL, PLACEMENT_WORD_SHORT_TEXT_EN,
    AGAINST_WORD_TEXT_PL, AGAINST_WORD_TEXT_EN,
    LACK_WORD_PL, LACK_WORD_EN,
    ADD_WORD_PL, ADD_WORD_EN,
    ABORT_WORD_PL, ABORT_WORD_EN,
    FIRST_NAME_WORD_TEXT_PL, FIRST_NAME_WORD_TEXT_EN, LAST_NAME_WORD_TEXT_PL, LAST_NAME_WORD_TEXT_EN,
    EMAIL_ADDRESS_WORD_TEXT_PL, EMAIL_ADDRESS_WORD_TEXT_EN,
    NUMBER_WORD_TEXT_PL, NUMBER_WORD_TEXT_EN,
    FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL, FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN,
    LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL, LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN,
    EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_PL, EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_EN,
    EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_PL, EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_EN,
    NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_PL, NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_EN,
    ACTIVE_MASC_WORD_TEXT_PL,
    ACTIVE_MASC_WORD_TEXT_EN,
    ACTIVE_FEM_WORD_TEXT_PL,
    ACTIVE_FEM_WORD_TEXT_EN,
    INACTIVE_MASC_WORD_TEXT_PL,
    INACTIVE_MASC_WORD_TEXT_EN,
    INACTIVE_FEM_WORD_TEXT_PL,
    INACTIVE_FEM_WORD_TEXT_EN,
    ACTIVATED_WORD_TEXT_PL,
    ACTIVATED_WORD_TEXT_EN,
    FORMALS_AGREEMENTS_PHRASE_TEXT_PL,
    FORMALS_AGREEMENTS_PHRASE_TEXT_EN,
    CREATE_ACCOUNT_PHRASE_TEXT_PL,
    CREATE_ACCOUNT_PHRASE_TEXT_EN,
    LOGIN_PHRASE_TEXT_PL,
    LOGIN_PHRASE_TEXT_EN,
    CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_PL,
    CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_EN,
    APPROVE_WORD_TEXT_PL,
    APPROVE_WORD_TEXT_EN,
    REJECT_WORD_TEXT_PL,
    REJECT_WORD_TEXT_EN,
    EDIT_WORD_TEXT_PL,
    EDIT_WORD_TEXT_EN,
    SAVE_WORD_TEXT_PL,
    SAVE_WORD_TEXT_EN,
    REMOVE_WORD_TEXT_PL,
    REMOVE_WORD_TEXT_EN,
    NEXT_WORD_TEXT_PL,
    NEXT_WORD_TEXT_EN,
    PREVIOUS_WORD_TEXT_PL,
    PREVIOUS_WORD_TEXT_EN,
    SHOW_WORD_TEXT_PL,
    SHOW_WORD_TEXT_EN,
    HIDE_WORD_TEXT_PL,
    HIDE_WORD_TEXT_EN,
    DETAILS_WORD_TEXT_PL,
    DETAILS_WORD_TEXT_EN,
    DATE_OF_CREATION_PHRASE_TEXT_PL,
    DATE_OF_CREATION_PHRASE_TEXT_EN,
    DOWNLOAD_WORD_PL,
    DOWNLOAD_WORD_EN,
    DOCUMENT_NAME_TEXT_PL,
    DOCUMENT_NAME_TEXT_EN,
    ADD_NEW_DOCUMENT_TEXT_PL,
    ADD_NEW_DOCUMENT_TEXT_EN,
    DEALS_AND_DOCUMENTS_TEXT_PL,
    DEALS_AND_DOCUMENTS_TEXT_EN,
    NO_FILE_TO_UPLOAD_TEXT_PL,
    NO_FILE_TO_UPLOAD_TEXT_EN,
    FILE_UPLOAD_SUCCESS_TEXT_PL,
    FILE_UPLOAD_SUCCESS_TEXT_EN,
    FILE_UPLOAD_ERROR_TEXT_PL,
    FILE_UPLOAD_ERROR_TEXT_EN,
    TIME_WORD_PL,
    TIME_WORD_EN,
    START_WORD_PL,
    START_WORD_EN,
    FINISH_WORD_PL,
    FINISH_WORD_EN,
    STATUS_WORD_TEXT_PL,
    STATUS_WORD_TEXT_EN,
    SEASON_WORD_TEXT_PL,
    SEASON_WORD_TEXT_EN,
    LEAGUE_WORD_TEXT_PL,
    LEAGUE_WORD_TEXT_EN,
    LEAGUES_WORD_TEXT_PL,
    LEAGUES_WORD_TEXT_EN,
    NO_LEAGUES_PHRASE_TEXT_PL,
    NO_LEAGUES_PHRASE_TEXT_EN,
    CHOOSE_LEAGUE_PHRASE_TEXT_PL,
    CHOOSE_LEAGUE_PHRASE_TEXT_EN,
    NO_LEAGUE_SELECTED_PHRASE_TEXT_PL,
    NO_LEAGUE_SELECTED_PHRASE_TEXT_EN,
    TEAM_WORD_TEXT_PL, TEAM_WORD_TEXT_EN, TEAMS_WORD_TEXT_PL, TEAMS_WORD_TEXT_EN,
    TEAM_NAME_PHRASE_TEXT_PL, TEAM_NAME_PHRASE_TEXT_EN, TEAM_SHORT_NAME_PHRASE_TEXT_PL, TEAM_SHORT_NAME_PHRASE_TEXT_EN,
    TEAM_LEAGUE_NAME_PHRASE_TEXT_PL, TEAM_LEAGUE_NAME_PHRASE_TEXT_EN,
    TEAM_CREW_PHRASE_TEXT_PL, TEAM_CREW_PHRASE_TEXT_EN, TEAM_COACH_WORD_TEXT_PL, TEAM_COACH_WORD_TEXT_EN,
    PLAYER_OF_THE_ROUND_PHRASE_TEXT_PL, PLAYER_OF_THE_ROUND_PHRASE_TEXT_EN,
    PLAYER_WORD_TEXT_PL, PLAYER_WORD_TEXT_EN, PLAYERS_WORD_TEXT_PL, PLAYERS_WORD_TEXT_EN,
    CHOOSE_PLAYER_PHRASE_TEXT_PL,
    CHOOSE_PLAYER_PHRASE_TEXT_EN,
    NO_PLAYERS_PHRASE_TEXT_PL,
    NO_PLAYERS_PHRASE_TEXT_EN,
    NO_PLAYERS_AVAILABLE_PHRASE_TEXT_PL,
    NO_PLAYERS_AVAILABLE_PHRASE_TEXT_EN,
    ALL_PLAYERS_PHRASE_TEXT_PL,
    ALL_PLAYERS_PHRASE_TEXT_EN,
    NO_LEAGUE_SQUADS_PHRASE_TEXT_PL,
    NO_LEAGUE_SQUADS_PHRASE_TEXT_EN,
    LEAGUE_PLAYERS_PHRASE_TEXT_PL,
    LEAGUE_PLAYERS_PHRASE_TEXT_EN,
    NO_LEAGUE_PLAYERS_PHRASE_TEXT_PL,
    NO_LEAGUE_PLAYERS_PHRASE_TEXT_EN,
    PLAYER_ADD_PHRASE_TEXT_PL,
    PLAYER_ADD_PHRASE_TEXT_EN,
    PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_PL,
    PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_EN,
    PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_PL,
    PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_EN,
    ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_PL,
    ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_EN,
    SHOW_TEAM_PLAYERS_PHRASE_TEXT_PL,
    SHOW_TEAM_PLAYERS_PHRASE_TEXT_EN,
    HIDE_TEAM_PLAYERS_PHRASE_TEXT_PL,
    HIDE_TEAM_PLAYERS_PHRASE_TEXT_EN,
    INCLUDING_HISTORICAL_PHRASE_TEXT_PL,
    INCLUDING_HISTORICAL_PHRASE_TEXT_EN,
    SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL,
    SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN,
    HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL,
    HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN,
    GAME_WORD_PL, GAME_WORD_EN, GAME_WORD_GENITIVE_PL, GAME_WORD_GENITIVE_EN, GAMES_WORD_TEXT_PL, GAMES_WORD_TEXT_EN,
    GAME_START_PHRASE_PL, GAME_START_PHRASE_EN, GAME_FINISH_PHRASE_PL, GAME_FINISH_PHRASE_EN,
    NO_GAMES_PHRASE_TEXT_PL, NO_GAMES_PHRASE_TEXT_EN,
    INCOMING_GAMES_PHRASE_TEXT_PL, INCOMING_GAMES_PHRASE_TEXT_EN,
    WIN_WORD_TEXT_PL, WIN_WORD_TEXT_EN, WIN_WORD_SHORT_TEXT_PL, WIN_WORD_SHORT_TEXT_EN, WINS_WORD_TEXT_PL, WINS_WORD_TEXT_EN,
    DEFEAT_WORD_TEXT_PL, DEFEAT_WORD_TEXT_EN, DEFEAT_WORD_SHORT_TEXT_PL, DEFEAT_WORD_SHORT_TEXT_EN, DEFEATS_WORD_TEXT_PL, DEFEATS_WORD_TEXT_EN,
    TABLE_WORD_TEXT_PL, TABLE_WORD_TEXT_EN, TABLES_WORD_TEXT_PL, TABLES_WORD_TEXT_EN, FULL_TABLE_PHRASE_PL, FULL_TABLE_PHRASE_EN,
    POINTS_WORD_SHORT_TEXT_PL, POINTS_WORD_SHORT_TEXT_EN, POINTS_IN_PHRASE_TEXT_PL, POINTS_IN_PHRASE_TEXT_EN, POINTS_IN_SHORT_PHRASE_TEXT_PL, POINTS_IN_SHORT_PHRASE_TEXT_EN, POINTS_OUT_PHRASE_TEXT_PL, POINTS_OUT_PHRASE_TEXT_EN, POINTS_OUT_SHORT_PHRASE_TEXT_PL, POINTS_OUT_SHORT_PHRASE_TEXT_EN,
    STATISTICS_POINTS_WORD_TEXT_PL,
    STATISTICS_POINTS_WORD_TEXT_EN,
    STATISTICS_POINTS_SHORT_TEXT_PL,
    STATISTICS_POINTS_SHORT_TEXT_EN,
    STATISTICS_TEAM_POINTS_PHRASE_TEXT_PL,
    STATISTICS_TEAM_POINTS_PHRASE_TEXT_EN,
    STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_PL,
    STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_EN,
    STATISTICS_REBOUNDS_WORD_TEXT_PL,
    STATISTICS_REBOUNDS_WORD_TEXT_EN,
    STATISTICS_REBOUNDS_SHORT_TEXT_PL,
    STATISTICS_REBOUNDS_SHORT_TEXT_EN,
    STATISTICS_ASSISTS_WORD_TEXT_PL,
    STATISTICS_ASSISTS_WORD_TEXT_EN,
    STATISTICS_ASSISTS_SHORT_TEXT_PL,
    STATISTICS_ASSISTS_SHORT_TEXT_EN,
    STATISTICS_STEALS_WORD_TEXT_PL,
    STATISTICS_STEALS_WORD_TEXT_EN,
    STATISTICS_STEALS_SHORT_TEXT_PL,
    STATISTICS_STEALS_SHORT_TEXT_EN,
    STATISTICS_BLOCKS_WORD_TEXT_PL,
    STATISTICS_BLOCKS_WORD_TEXT_EN,
    STATISTICS_BLOCKS_SHORT_TEXT_PL,
    STATISTICS_BLOCKS_SHORT_TEXT_EN,
    STATISTICS_EVAL_TEXT_PL,
    STATISTICS_EVAL_TEXT_EN,
    STATISTICS_BALANCE_WORD_TEXT_PL, STATISTICS_BALANCE_WORD_TEXT_EN, STATISTICS_DIFFERENCE_WORD_TEXT_PL, STATISTICS_DIFFERENCE_WORD_TEXT_EN,
    PLAYER_OUT_FROM_GAME_PHRASE_TEXT_PL,
    PLAYER_OUT_FROM_GAME_PHRASE_TEXT_EN,
    MY_REQUESTS_PHRASE_TEXT_PL,
    MY_REQUESTS_PHRASE_TEXT_EN,
    NO_REQUESTS_PHRASE_TEXT_PL,
    NO_REQUESTS_PHRASE_TEXT_EN,
    TRANSFER_LIST_PHRASE_TEXT_PL,
    TRANSFER_LIST_PHRASE_TEXT_EN,
    TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_PL,
    TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_EN,
    TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_PL,
    TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_EN,
    ADD_TO_TRANSFER_LIST_PHRASE_TEXT_PL,
    ADD_TO_TRANSFER_LIST_PHRASE_TEXT_EN,
    NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_PL,
    NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_EN,
    ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_PL,
    ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_EN,
    ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_PL,
    ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_EN,
} from "@Dictionary/Sets/Common/Phrases";


export const CommonDictionary: CommonDictionaryInterface = {
    PL: {
        SELECT_ALL_MAN: SELECT_ALL_MAN_PL, UN_SELECT_ALL_MAN: UN_SELECT_ALL_MAN_PL,
        I_UNDERSTAND_PHRASE: I_UNDERSTAND_PHRASE_PL, TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT: TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_PL,
        DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE: DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_PL,
        DEFAULT_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_PL, DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_PL, DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_PL,
        REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT: REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_PL, FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT: FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_PL,
        DATE_WORD_TEXT: DATE_WORD_TEXT_PL, HOUR_WORD_TEXT: HOUR_WORD_TEXT_PL, HOUR_SHORT_WORD_TEXT: HOUR_SHORT_WORD_TEXT_PL,
        PLACEMENT_WORD_TEXT: PLACEMENT_WORD_TEXT_PL, PLACEMENT_WORD_SHORT_TEXT: PLACEMENT_WORD_SHORT_TEXT_PL,
        AGAINST_WORD_TEXT: AGAINST_WORD_TEXT_PL,
        LACK_WORD: LACK_WORD_PL, ADD_WORD: ADD_WORD_PL, ABORT_WORD: ABORT_WORD_PL,
        FIRST_NAME_WORD_TEXT: FIRST_NAME_WORD_TEXT_PL, LAST_NAME_WORD_TEXT: LAST_NAME_WORD_TEXT_PL, EMAIL_ADDRESS_WORD_TEXT: EMAIL_ADDRESS_WORD_TEXT_PL, NUMBER_WORD_TEXT: NUMBER_WORD_TEXT_PL,
        FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT: FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL, LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT: LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_PL, EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT: EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_PL, EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT: EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_PL, NUMBER_DEFAULT_ERROR_MESSAGE_TEXT: NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_PL,
        ACTIVE_MASC_WORD_TEXT: ACTIVE_MASC_WORD_TEXT_PL, ACTIVE_FEM_WORD_TEXT: ACTIVE_FEM_WORD_TEXT_PL, INACTIVE_MASC_WORD_TEXT: INACTIVE_MASC_WORD_TEXT_PL, INACTIVE_FEM_WORD_TEXT: INACTIVE_FEM_WORD_TEXT_PL, ACTIVATED_WORD_TEXT: ACTIVATED_WORD_TEXT_PL, FORMALS_AGREEMENTS_PHRASE_TEXT: FORMALS_AGREEMENTS_PHRASE_TEXT_PL,
        CREATE_ACCOUNT_PHRASE_TEXT: CREATE_ACCOUNT_PHRASE_TEXT_PL, LOGIN_PHRASE_TEXT: LOGIN_PHRASE_TEXT_PL,
        CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT: CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_PL,
        APPROVE_WORD_TEXT: APPROVE_WORD_TEXT_PL, REJECT_WORD_TEXT: REJECT_WORD_TEXT_PL, EDIT_WORD_TEXT: EDIT_WORD_TEXT_PL, SAVE_WORD_TEXT: SAVE_WORD_TEXT_PL, REMOVE_WORD_TEXT: REMOVE_WORD_TEXT_PL, NEXT_WORD_TEXT: NEXT_WORD_TEXT_PL, PREVIOUS_WORD_TEXT: PREVIOUS_WORD_TEXT_PL,
        SHOW_WORD_TEXT: SHOW_WORD_TEXT_PL, HIDE_WORD_TEXT: HIDE_WORD_TEXT_PL, DETAILS_WORD_TEXT: DETAILS_WORD_TEXT_PL,
        DOWNLOAD_WORD: DOWNLOAD_WORD_PL,
        DATE_OF_CREATION_PHRASE_TEXT: DATE_OF_CREATION_PHRASE_TEXT_PL,
        DOCUMENT_NAME_TEXT: DOCUMENT_NAME_TEXT_PL, ADD_NEW_DOCUMENT_TEXT: ADD_NEW_DOCUMENT_TEXT_PL,
        DEALS_AND_DOCUMENTS_TEXT: DEALS_AND_DOCUMENTS_TEXT_PL,
        NO_FILE_TO_UPLOAD_TEXT: NO_FILE_TO_UPLOAD_TEXT_PL, FILE_UPLOAD_SUCCESS_TEXT: FILE_UPLOAD_SUCCESS_TEXT_PL, FILE_UPLOAD_ERROR_TEXT: FILE_UPLOAD_ERROR_TEXT_PL,
        TIME_WORD: TIME_WORD_PL, START_WORD: START_WORD_PL, FINISH_WORD: FINISH_WORD_PL, STATUS_WORD_TEXT: STATUS_WORD_TEXT_PL,
        SEASON_WORD_TEXT: SEASON_WORD_TEXT_PL, LEAGUE_WORD_TEXT: LEAGUE_WORD_TEXT_PL, LEAGUES_WORD_TEXT: LEAGUES_WORD_TEXT_PL, NO_LEAGUES_PHRASE_TEXT: NO_LEAGUES_PHRASE_TEXT_PL, CHOOSE_LEAGUE_PHRASE_TEXT: CHOOSE_LEAGUE_PHRASE_TEXT_PL, NO_LEAGUE_SELECTED_PHRASE_TEXT: NO_LEAGUE_SELECTED_PHRASE_TEXT_PL,
        TEAM_WORD_TEXT: TEAM_WORD_TEXT_PL, TEAMS_WORD_TEXT: TEAMS_WORD_TEXT_PL, TEAM_NAME_PHRASE_TEXT: TEAM_NAME_PHRASE_TEXT_PL, TEAM_SHORT_NAME_PHRASE_TEXT: TEAM_SHORT_NAME_PHRASE_TEXT_PL, TEAM_LEAGUE_NAME_PHRASE_TEXT: TEAM_LEAGUE_NAME_PHRASE_TEXT_PL, TEAM_CREW_PHRASE_TEXT: TEAM_CREW_PHRASE_TEXT_PL, TEAM_COACH_WORD_TEXT: TEAM_COACH_WORD_TEXT_PL,
        PLAYER_OF_THE_ROUND_PHRASE_TEXT: PLAYER_OF_THE_ROUND_PHRASE_TEXT_PL,
        PLAYER_WORD_TEXT: PLAYER_WORD_TEXT_PL, CHOOSE_PLAYER_PHRASE_TEXT: CHOOSE_PLAYER_PHRASE_TEXT_PL,
        PLAYERS_WORD_TEXT: PLAYERS_WORD_TEXT_PL, NO_PLAYERS_PHRASE_TEXT: NO_PLAYERS_PHRASE_TEXT_PL, NO_PLAYERS_AVAILABLE_PHRASE_TEXT: NO_PLAYERS_AVAILABLE_PHRASE_TEXT_PL, ALL_PLAYERS_PHRASE_TEXT: ALL_PLAYERS_PHRASE_TEXT_PL, NO_LEAGUE_SQUADS_PHRASE_TEXT: NO_LEAGUE_SQUADS_PHRASE_TEXT_PL, LEAGUE_PLAYERS_PHRASE_TEXT: LEAGUE_PLAYERS_PHRASE_TEXT_PL, NO_LEAGUE_PLAYERS_PHRASE_TEXT: NO_LEAGUE_PLAYERS_PHRASE_TEXT_PL, PLAYER_ADD_PHRASE_TEXT: PLAYER_ADD_PHRASE_TEXT_PL, PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT: PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_PL, PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT: PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_PL, ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT: ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_PL,
        SHOW_TEAM_PLAYERS_PHRASE_TEXT: SHOW_TEAM_PLAYERS_PHRASE_TEXT_PL, HIDE_TEAM_PLAYERS_PHRASE_TEXT: HIDE_TEAM_PLAYERS_PHRASE_TEXT_PL, INCLUDING_HISTORICAL_PHRASE_TEXT: INCLUDING_HISTORICAL_PHRASE_TEXT_PL, SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT: SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL, HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT: HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_PL,
        GAME_WORD: GAME_WORD_PL, GAME_WORD_GENITIVE: GAME_WORD_GENITIVE_PL, GAMES_WORD_TEXT: GAMES_WORD_TEXT_PL, GAME_START_PHRASE: GAME_START_PHRASE_PL, GAME_FINISH_PHRASE: GAME_FINISH_PHRASE_PL, NO_GAMES_PHRASE_TEXT: NO_GAMES_PHRASE_TEXT_PL, INCOMING_GAMES_PHRASE_TEXT: INCOMING_GAMES_PHRASE_TEXT_PL,
        WIN_WORD_TEXT: WIN_WORD_TEXT_PL, WIN_WORD_SHORT_TEXT: WIN_WORD_SHORT_TEXT_PL, WINS_WORD_TEXT: WINS_WORD_TEXT_PL, DEFEAT_WORD_TEXT: DEFEAT_WORD_TEXT_PL, DEFEAT_WORD_SHORT_TEXT: DEFEAT_WORD_SHORT_TEXT_PL, DEFEATS_WORD_TEXT: DEFEATS_WORD_TEXT_PL,
        TABLE_WORD_TEXT: TABLE_WORD_TEXT_PL, TABLES_WORD_TEXT: TABLES_WORD_TEXT_PL, FULL_TABLE_PHRASE: FULL_TABLE_PHRASE_PL,
        POINTS_WORD_SHORT_TEXT: POINTS_WORD_SHORT_TEXT_PL, POINTS_IN_PHRASE_TEXT: POINTS_IN_PHRASE_TEXT_PL, POINTS_IN_SHORT_PHRASE_TEXT: POINTS_IN_SHORT_PHRASE_TEXT_PL, POINTS_OUT_PHRASE_TEXT: POINTS_OUT_PHRASE_TEXT_PL, POINTS_OUT_SHORT_PHRASE_TEXT: POINTS_OUT_SHORT_PHRASE_TEXT_PL,
        STATISTICS_POINTS_WORD_TEXT: STATISTICS_POINTS_WORD_TEXT_PL, STATISTICS_POINTS_SHORT_TEXT: STATISTICS_POINTS_SHORT_TEXT_PL, STATISTICS_TEAM_POINTS_PHRASE_TEXT: STATISTICS_TEAM_POINTS_PHRASE_TEXT_PL, STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT: STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_PL,
        STATISTICS_REBOUNDS_WORD_TEXT: STATISTICS_REBOUNDS_WORD_TEXT_PL, STATISTICS_REBOUNDS_SHORT_TEXT: STATISTICS_REBOUNDS_SHORT_TEXT_PL,
        STATISTICS_ASSISTS_WORD_TEXT: STATISTICS_ASSISTS_WORD_TEXT_PL, STATISTICS_ASSISTS_SHORT_TEXT: STATISTICS_ASSISTS_SHORT_TEXT_PL,
        STATISTICS_STEALS_WORD_TEXT: STATISTICS_STEALS_WORD_TEXT_PL, STATISTICS_STEALS_SHORT_TEXT: STATISTICS_STEALS_SHORT_TEXT_PL,
        STATISTICS_BLOCKS_WORD_TEXT: STATISTICS_BLOCKS_WORD_TEXT_PL, STATISTICS_BLOCKS_SHORT_TEXT: STATISTICS_BLOCKS_SHORT_TEXT_PL,
        STATISTICS_EVAL_TEXT: STATISTICS_EVAL_TEXT_PL, STATISTICS_BALANCE_WORD_TEXT: STATISTICS_BALANCE_WORD_TEXT_PL, STATISTICS_DIFFERENCE_WORD_TEXT: STATISTICS_DIFFERENCE_WORD_TEXT_PL,
        PLAYER_OUT_FROM_GAME_PHRASE_TEXT: PLAYER_OUT_FROM_GAME_PHRASE_TEXT_PL,
        MY_REQUESTS_PHRASE_TEXT: MY_REQUESTS_PHRASE_TEXT_PL, NO_REQUESTS_PHRASE_TEXT: NO_REQUESTS_PHRASE_TEXT_PL,
        TRANSFER_LIST_PHRASE_TEXT: TRANSFER_LIST_PHRASE_TEXT_PL, TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT: TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_PL, TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT: TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_PL, ADD_TO_TRANSFER_LIST_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_PHRASE_TEXT_PL, NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT: NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_PL, ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_PL, ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_PL,
    },
    EN: {
        SELECT_ALL_MAN: SELECT_ALL_MAN_EN, UN_SELECT_ALL_MAN: UN_SELECT_ALL_MAN_EN,
        I_UNDERSTAND_PHRASE: I_UNDERSTAND_PHRASE_EN, TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT: TO_CONFIRM_CLICK_AGAIN_COMMUNICATE_TEXT_EN,
        DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE: DEFAULT_DATA_UPDATE_SUCCESS_MESSAGE_EN,
        DEFAULT_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_ERROR_MESSAGE_PHRASE_TEXT_EN, DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_VALUE_ERROR_MESSAGE_PHRASE_TEXT_EN, DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT: DEFAULT_NO_DATA_ERROR_MESSAGE_PHRASE_TEXT_EN,
        REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT: REQUEST_HAS_BEEN_UPDATED_COMMUNICATE_TEXT_EN, FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT: FAILED_TO_UPDATE_REQUEST_COMMUNICATE_TEXT_EN,
        DATE_WORD_TEXT: DATE_WORD_TEXT_EN, HOUR_WORD_TEXT: HOUR_WORD_TEXT_EN, HOUR_SHORT_WORD_TEXT: HOUR_SHORT_WORD_TEXT_EN,
        PLACEMENT_WORD_TEXT: PLACEMENT_WORD_TEXT_EN, PLACEMENT_WORD_SHORT_TEXT: PLACEMENT_WORD_SHORT_TEXT_EN,
        AGAINST_WORD_TEXT: AGAINST_WORD_TEXT_EN,
        LACK_WORD: LACK_WORD_EN, ADD_WORD: ADD_WORD_EN, ABORT_WORD: ABORT_WORD_EN,
        FIRST_NAME_WORD_TEXT: FIRST_NAME_WORD_TEXT_EN, LAST_NAME_WORD_TEXT: LAST_NAME_WORD_TEXT_EN, EMAIL_ADDRESS_WORD_TEXT: EMAIL_ADDRESS_WORD_TEXT_EN, NUMBER_WORD_TEXT: NUMBER_WORD_TEXT_EN,
        FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT: FIRST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN, LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT: LAST_NAME_DEFAULT_ERROR_MESSAGE_TEXT_EN, EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT: EMAIL_ADDRESS_DEFAULT_ERROR_MESSAGE_TEXT_EN, EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT: EMAIL_ADDRESS_ALREADY_REGISTERED_ERROR_MESSAGE_TEXT_EN, NUMBER_DEFAULT_ERROR_MESSAGE_TEXT: NUMBER_DEFAULT_ERROR_MESSAGE_TEXT_EN,
        ACTIVE_MASC_WORD_TEXT: ACTIVE_MASC_WORD_TEXT_EN, ACTIVE_FEM_WORD_TEXT: ACTIVE_FEM_WORD_TEXT_EN, INACTIVE_MASC_WORD_TEXT: INACTIVE_MASC_WORD_TEXT_EN, INACTIVE_FEM_WORD_TEXT: INACTIVE_FEM_WORD_TEXT_EN, ACTIVATED_WORD_TEXT: ACTIVATED_WORD_TEXT_EN, FORMALS_AGREEMENTS_PHRASE_TEXT: FORMALS_AGREEMENTS_PHRASE_TEXT_EN,
        CREATE_ACCOUNT_PHRASE_TEXT: CREATE_ACCOUNT_PHRASE_TEXT_EN, LOGIN_PHRASE_TEXT: LOGIN_PHRASE_TEXT_EN,
        CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT: CONFIRM_YOUR_EMAIL_TO_SET_PASSWORD_MESSAGE_TEXT_EN,
        APPROVE_WORD_TEXT: APPROVE_WORD_TEXT_EN, REJECT_WORD_TEXT: REJECT_WORD_TEXT_EN, EDIT_WORD_TEXT: EDIT_WORD_TEXT_EN, SAVE_WORD_TEXT: SAVE_WORD_TEXT_EN, REMOVE_WORD_TEXT: REMOVE_WORD_TEXT_EN, NEXT_WORD_TEXT: NEXT_WORD_TEXT_EN, PREVIOUS_WORD_TEXT: PREVIOUS_WORD_TEXT_EN,
        SHOW_WORD_TEXT: SHOW_WORD_TEXT_EN, HIDE_WORD_TEXT: HIDE_WORD_TEXT_EN, DETAILS_WORD_TEXT: DETAILS_WORD_TEXT_EN,
        DOWNLOAD_WORD: DOWNLOAD_WORD_EN,
        DATE_OF_CREATION_PHRASE_TEXT: DATE_OF_CREATION_PHRASE_TEXT_EN,
        DOCUMENT_NAME_TEXT: DOCUMENT_NAME_TEXT_EN, ADD_NEW_DOCUMENT_TEXT: ADD_NEW_DOCUMENT_TEXT_EN,
        DEALS_AND_DOCUMENTS_TEXT: DEALS_AND_DOCUMENTS_TEXT_EN,
        NO_FILE_TO_UPLOAD_TEXT: NO_FILE_TO_UPLOAD_TEXT_EN, FILE_UPLOAD_SUCCESS_TEXT: FILE_UPLOAD_SUCCESS_TEXT_EN, FILE_UPLOAD_ERROR_TEXT: FILE_UPLOAD_ERROR_TEXT_EN,
        TIME_WORD: TIME_WORD_EN, START_WORD: START_WORD_EN, FINISH_WORD: FINISH_WORD_EN, STATUS_WORD_TEXT: STATUS_WORD_TEXT_EN,
        SEASON_WORD_TEXT: SEASON_WORD_TEXT_EN, LEAGUE_WORD_TEXT: LEAGUE_WORD_TEXT_EN, LEAGUES_WORD_TEXT: LEAGUES_WORD_TEXT_EN, NO_LEAGUES_PHRASE_TEXT: NO_LEAGUES_PHRASE_TEXT_EN, CHOOSE_LEAGUE_PHRASE_TEXT: CHOOSE_LEAGUE_PHRASE_TEXT_EN, NO_LEAGUE_SELECTED_PHRASE_TEXT: NO_LEAGUE_SELECTED_PHRASE_TEXT_EN,
        TEAM_WORD_TEXT: TEAM_WORD_TEXT_EN, TEAMS_WORD_TEXT: TEAMS_WORD_TEXT_EN, TEAM_NAME_PHRASE_TEXT: TEAM_NAME_PHRASE_TEXT_EN, TEAM_SHORT_NAME_PHRASE_TEXT: TEAM_SHORT_NAME_PHRASE_TEXT_EN, TEAM_LEAGUE_NAME_PHRASE_TEXT: TEAM_LEAGUE_NAME_PHRASE_TEXT_EN, TEAM_CREW_PHRASE_TEXT: TEAM_CREW_PHRASE_TEXT_EN, TEAM_COACH_WORD_TEXT: TEAM_COACH_WORD_TEXT_EN,
        PLAYER_OF_THE_ROUND_PHRASE_TEXT: PLAYER_OF_THE_ROUND_PHRASE_TEXT_EN,
        PLAYER_WORD_TEXT: PLAYER_WORD_TEXT_EN, CHOOSE_PLAYER_PHRASE_TEXT: CHOOSE_PLAYER_PHRASE_TEXT_EN,
        PLAYERS_WORD_TEXT: PLAYERS_WORD_TEXT_EN, NO_PLAYERS_PHRASE_TEXT: NO_PLAYERS_PHRASE_TEXT_EN, NO_PLAYERS_AVAILABLE_PHRASE_TEXT: NO_PLAYERS_AVAILABLE_PHRASE_TEXT_EN, ALL_PLAYERS_PHRASE_TEXT: ALL_PLAYERS_PHRASE_TEXT_EN, NO_LEAGUE_SQUADS_PHRASE_TEXT: NO_LEAGUE_SQUADS_PHRASE_TEXT_EN, LEAGUE_PLAYERS_PHRASE_TEXT: LEAGUE_PLAYERS_PHRASE_TEXT_EN, NO_LEAGUE_PLAYERS_PHRASE_TEXT: NO_LEAGUE_PLAYERS_PHRASE_TEXT_EN, PLAYER_ADD_PHRASE_TEXT: PLAYER_ADD_PHRASE_TEXT_EN, PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT: PLAYER_ADDED_SUCCESSFULLY_MESSAGE_PHRASE_TEXT_EN, PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT: PLAYER_ALREADY_IN_TEAM_MESSAGE_PHRASE_TEXT_EN, ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT: ADD_PLAYER_TO_LEAGUE_SQUAD_LEGEND_MESSAGE_PHRASE_TEXT_EN,
        SHOW_TEAM_PLAYERS_PHRASE_TEXT: SHOW_TEAM_PLAYERS_PHRASE_TEXT_EN, HIDE_TEAM_PLAYERS_PHRASE_TEXT: HIDE_TEAM_PLAYERS_PHRASE_TEXT_EN, INCLUDING_HISTORICAL_PHRASE_TEXT: INCLUDING_HISTORICAL_PHRASE_TEXT_EN, SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT: SHOW_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN, HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT: HIDE_HISTORICAL_TEAM_PLAYERS_PHRASE_TEXT_EN,
        GAME_WORD: GAME_WORD_EN, GAME_WORD_GENITIVE: GAME_WORD_GENITIVE_EN, GAMES_WORD_TEXT: GAMES_WORD_TEXT_EN, GAME_START_PHRASE: GAME_START_PHRASE_EN, GAME_FINISH_PHRASE: GAME_FINISH_PHRASE_EN, NO_GAMES_PHRASE_TEXT: NO_GAMES_PHRASE_TEXT_EN, INCOMING_GAMES_PHRASE_TEXT: INCOMING_GAMES_PHRASE_TEXT_EN,
        WIN_WORD_TEXT: WIN_WORD_TEXT_EN, WIN_WORD_SHORT_TEXT: WIN_WORD_SHORT_TEXT_EN, WINS_WORD_TEXT: WINS_WORD_TEXT_EN, DEFEAT_WORD_TEXT: DEFEAT_WORD_TEXT_EN, DEFEAT_WORD_SHORT_TEXT: DEFEAT_WORD_SHORT_TEXT_EN, DEFEATS_WORD_TEXT: DEFEATS_WORD_TEXT_EN,
        TABLE_WORD_TEXT: TABLE_WORD_TEXT_EN, TABLES_WORD_TEXT: TABLES_WORD_TEXT_EN, FULL_TABLE_PHRASE: FULL_TABLE_PHRASE_EN,
        POINTS_WORD_SHORT_TEXT: POINTS_WORD_SHORT_TEXT_EN, POINTS_IN_PHRASE_TEXT: POINTS_IN_PHRASE_TEXT_EN, POINTS_IN_SHORT_PHRASE_TEXT: POINTS_IN_SHORT_PHRASE_TEXT_EN, POINTS_OUT_PHRASE_TEXT: POINTS_OUT_PHRASE_TEXT_EN, POINTS_OUT_SHORT_PHRASE_TEXT: POINTS_OUT_SHORT_PHRASE_TEXT_EN,
        STATISTICS_POINTS_WORD_TEXT: STATISTICS_POINTS_WORD_TEXT_EN, STATISTICS_POINTS_SHORT_TEXT: STATISTICS_POINTS_SHORT_TEXT_EN, STATISTICS_TEAM_POINTS_PHRASE_TEXT: STATISTICS_TEAM_POINTS_PHRASE_TEXT_EN, STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT: STATISTICS_OPPONENTS_POINTS_PHRASE_TEXT_EN,
        STATISTICS_REBOUNDS_WORD_TEXT: STATISTICS_REBOUNDS_WORD_TEXT_EN, STATISTICS_REBOUNDS_SHORT_TEXT: STATISTICS_REBOUNDS_SHORT_TEXT_EN,
        STATISTICS_ASSISTS_WORD_TEXT: STATISTICS_ASSISTS_WORD_TEXT_EN, STATISTICS_ASSISTS_SHORT_TEXT: STATISTICS_ASSISTS_SHORT_TEXT_EN,
        STATISTICS_STEALS_WORD_TEXT: STATISTICS_STEALS_WORD_TEXT_EN, STATISTICS_STEALS_SHORT_TEXT: STATISTICS_STEALS_SHORT_TEXT_EN,
        STATISTICS_BLOCKS_WORD_TEXT: STATISTICS_BLOCKS_WORD_TEXT_EN, STATISTICS_BLOCKS_SHORT_TEXT: STATISTICS_BLOCKS_SHORT_TEXT_EN,
        STATISTICS_EVAL_TEXT: STATISTICS_EVAL_TEXT_EN, STATISTICS_BALANCE_WORD_TEXT: STATISTICS_BALANCE_WORD_TEXT_EN, STATISTICS_DIFFERENCE_WORD_TEXT: STATISTICS_DIFFERENCE_WORD_TEXT_EN,
        PLAYER_OUT_FROM_GAME_PHRASE_TEXT: PLAYER_OUT_FROM_GAME_PHRASE_TEXT_EN,
        MY_REQUESTS_PHRASE_TEXT: MY_REQUESTS_PHRASE_TEXT_EN, NO_REQUESTS_PHRASE_TEXT: NO_REQUESTS_PHRASE_TEXT_EN,
        TRANSFER_LIST_PHRASE_TEXT: TRANSFER_LIST_PHRASE_TEXT_EN, TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT: TRANSFER_LIST_FREE_AGENTS_PHRASE_TEXT_EN, TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT: TRANSFER_LIST_NO_FREE_AGENTS_PHRASE_TEXT_EN, ADD_TO_TRANSFER_LIST_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_PHRASE_TEXT_EN, NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT: NO_LEAGUES_FOR_TRANSFER_LEAGUES_REQUEST_PHRASE_TEXT_EN, ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_REQUEST_SUCCESS_PHRASE_TEXT_EN, ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT: ADD_TO_TRANSFER_LIST_REQUEST_ERROR_PHRASE_TEXT_EN,
    }
};


export default CommonDictionary;
