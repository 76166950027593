import React from "react";


import { UseCommonDictionaryPhrasesSet } from "@Hooks/Texts";


import { Col } from "react-bootstrap";

import { AiOutlineFile } from "react-icons/ai";


import { LeagueTeamFileInterface } from "@Interfaces/Api";


import "./index.scss";


export interface TeamFilesListFileComponentProps {
    File: LeagueTeamFileInterface;
}


export const TeamFilesListFileComponent: React.FC<TeamFilesListFileComponentProps> = ({
    File
}) : JSX.Element | null => {


    const DOWNLOAD_WORD = UseCommonDictionaryPhrasesSet("DOWNLOAD_WORD");


    if (!File) {
        return null;
    }


    const {
        id: teamFileID,
        team_file_name: teamFileName,
        team_file_file: teamFileURL
    } = File;


    return (
        <Col key={teamFileID} xs={12} className={"player-profile__captain-zone__team__team-files__files-list__file"}>
            <span className={"file-name"}>{teamFileName || "brak nazwy"}</span>
            <a href={teamFileURL} className={"file-download-link"} target={"_blank"} title={DOWNLOAD_WORD}>
                <span>{DOWNLOAD_WORD}</span>
                <AiOutlineFile />
            </a>
        </Col>
    );

};


export default TeamFilesListFileComponent;
