import { useEffect, useState } from "react";


import _getDataFromApi from "@Services/API/Requests";

import { API_ARTICLE_BY_SLUG_ENDPOINT_URL } from "@Services/API/Endpoints";


import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";

import { ArticleInterface } from "@Interfaces/Api";


export const UseArticleDetail = (articleSlug: string) : [ArticleInterface | null, ErrorMessageInterface | null] => {


    const [Article, setArticle] = useState<ArticleInterface | null>(null);
    const [ArticleError, setArticleError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!articleSlug) {
            return setArticleError({ errorCode: 403, errorMessage: "" });
        }

        const getArticle = async () => {
            await _getDataFromApi(
                API_ARTICLE_BY_SLUG_ENDPOINT_URL + `?article_slug=${articleSlug}`,
                setArticle, setArticleError
            );
        };

        getArticle();

        return () => {
            setArticle(null);
            setArticleError(null);
        };

    }, []);


    return [Article, ArticleError];


};


export default UseArticleDetail;
