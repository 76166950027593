import React from "react";


import { Col, Row } from "react-bootstrap";


import { SingleContentLoaderComponent } from "@LoadersElements";


import MessageComponent from "@MessagesElements/Content";


import InformationSectionTypes from "@Components/Views/Pages/Games/Games/Game/_Types/Sections";


import GameInformationTablesComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Tables";
import GameInformationSummaryComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Summary";
import GameInformationArrangeComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Arrange";
import GameInformationPossessionsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Possessions";
import GameInformationLeadersComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Leaders";
import GameInformationThrowsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Throws";
import GameResultDetailsDiagramsComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Diagrams";
import GameDetailsGameProgressComponent from "@Components/Views/Pages/Games/Games/Game/Summary/GameDetailsInformation/Progress";


import "./index.scss";


import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";

import ApiGameSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary";
import ApiGameSummaryQuarterType from "@Pages/Games/Games/Game/_Types/Summary/API/GameSummary/Quarters/Quarter/Type";

import TeamsSummaryInterface from "@Pages/Games/Games/Game/_Types/Summary/GUI/_Main/Teams";



type GameInformationProps = {
    GameData: LeagueGameInterface;
    SelectedSection: InformationSectionTypes;
    SelectedQuarters: ApiGameSummaryQuarterType[];
    GameSummaryStatistics: ApiGameSummaryInterface | null;
    TeamsSummaryData: TeamsSummaryInterface | null;
};


export const GameInformationComponent: React.FC<GameInformationProps> = ({
    GameData,
    SelectedSection, SelectedQuarters = [],
    GameSummaryStatistics, TeamsSummaryData
}) : JSX.Element | null => {


    if (!GameData || !SelectedSection) return <SingleContentLoaderComponent />;


    const {
        league_game_home_team_data: HomeTeam,
        league_game_away_team_data: AwayTeam,
        league_game_status: gameStatus,
        league_game_quarter_duration_time: gameQuarterDuration,
        league_game_extra_time_duration_time: gameExtraTimeDuration
    } = GameData;

    const gameQuarterDurationTime = +gameQuarterDuration || 10;
    const gameExtraTimeDurationTime = +gameExtraTimeDuration || 3;


    if (["NS", "POST", "CANC"].includes(gameStatus)) {
        return <MessageComponent messageText={`Mecz się jeszcze nie odbył`} />;
    }

    if (gameStatus === "FT_WAL") {
        return <MessageComponent messageType={"error"} messageText={`Mecz zakończony walkowerem`} />;
    }


    if (!HomeTeam || !AwayTeam) return null;


    const { team_id: homeTeamID } = HomeTeam;
    const { team_id: awayTeamID } = AwayTeam;


    if (!GameSummaryStatistics) return <SingleContentLoaderComponent />;


    return (
        <Col xs={12} className={"game-result-details game-result-details-component"}>
            <Row className={"game-result-details-content"}>

                {SelectedSection === InformationSectionTypes.TABLES &&
	            <GameInformationTablesComponent
                    homeTeamID={homeTeamID}
                    awayTeamID={awayTeamID}
                    GameSummaryStatistics={GameSummaryStatistics}
                    TeamsSummaryData={TeamsSummaryData}
	            />
                }

                {SelectedSection === InformationSectionTypes.SUMMARY &&
	            <GameInformationSummaryComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
                    SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
		            TeamsSummaryData={TeamsSummaryData}
	            />
                }

                {SelectedSection === InformationSectionTypes.ARRANGE &&
	            <GameInformationArrangeComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
	            />
                }

                {SelectedSection === InformationSectionTypes.POSSESSION &&
	            <GameInformationPossessionsComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
		            TeamsSummaryData={TeamsSummaryData}
	            />
                }

                {SelectedSection === InformationSectionTypes.LEADERS &&
	            <GameInformationLeadersComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
		            TeamsSummaryData={TeamsSummaryData}
	            />
                }

                {SelectedSection === InformationSectionTypes.DIAGRAMS &&
	            <GameResultDetailsDiagramsComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
	            />
                }

                {SelectedSection === InformationSectionTypes.THROWS &&
	            <GameInformationThrowsComponent
		            homeTeamID={homeTeamID}
		            awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
	            />
                }

                {SelectedSection === InformationSectionTypes.EVENTS &&
	            <GameDetailsGameProgressComponent
		            homeTeamID={homeTeamID}
                    awayTeamID={awayTeamID}
		            SelectedQuarters={SelectedQuarters}
		            GameSummaryStatistics={GameSummaryStatistics}
		            gameQuarterDurationTime={gameQuarterDurationTime}
		            gameExtraTimeDurationTime={gameExtraTimeDurationTime}
	            />
                }

            </Row>
        </Col>
    );

};


export default GameInformationComponent;
