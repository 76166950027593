import DictionaryType from "@Dictionary/_Types";

// export type FormalsDictionaryPhrasesNames =
//     "not_all_formals_accepted_message_text" |
//     "regulations_acceptation_statement_message_text" |
//     "regulations_accepted_message_text" |
//     "regulations_i_accept_message_text" |
//     "privacy_policy_acceptation_statement_message_text" |
//     "privacy_policy_accepted_message_text" |
//     "privacy_policy_i_accept_message_text"
//
// ;

export const FormalsDictionary: DictionaryType = {
    PL: {
        not_all_formals_accepted_message_text: "Nie wszystkie regulaminy i zgody zostały zaakceptowane. Aby konto było aktywne, przeczytaj i zaakceptuj niezbędne treści.",
        regulations_acceptation_statement_message_text: "Oświadczam że zapoznałem się z regulaminem i akceptuję go",
        regulations_accepted_message_text: "Regulamin został zaakceprowany",
        regulations_i_accept_message_text: "Akceptuję Regulamin",
        privacy_policy_acceptation_statement_message_text: "Oświadczam że zapoznałem się z polityką prywatności i akceptuję ją",
        privacy_policy_accepted_message_text: "Polityka prywatności została zaakceprowana",
        privacy_policy_i_accept_message_text: "Akceptuję politykę prywatności"
    },
    EN: {
        not_all_formals_accepted_message_text: "Not all regulations or agreements has been accepted. To activate account please accept oblique content.",
        regulations_acceptation_statement_message_text: "I declare that I have read the regulations and accept them",
        regulations_accepted_message_text: "Regulations has been accepted",
        regulations_i_accept_message_text: "I accept Regulations",
        privacy_policy_acceptation_statement_message_text: "I declare that I have read the privacy policy and accept it",
        privacy_policy_accepted_message_text: "Privacy Policy has been accepted",
        privacy_policy_i_accept_message_text: "I accept the privacy policy",
    }
};


export default FormalsDictionary;
