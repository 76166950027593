import React, {useEffect, useState} from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {Col, Row} from "react-bootstrap";


import MainContentLoaderComponent from "@Components/Elements/Loaders/MainContent";


import MessageComponent from "@Components/Elements/Messages/Content";


import StatisticsTableComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/Statistics/Table";


import createPlayerGamesStatistics from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Utilities/Statistics/Games/CreatePlayerGamesStatistics";


import SortedBySeasonGamesLeagueInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/__Types/Games/Sorted/BySeason/League";


const TEXTS: any = {
    PL: {
        summary_statistics_header_text: "Statystyki Sumy",
        medium_statistics_header_text: "Statystyki Średnie",
        message_no_player_games_text_text: "Brak rozegranych meczów",
    },
    EN: {
        summary_statistics_header_text: "Summary Statistics",
        medium_statistics_header_text: "Medium Statistics",
        message_no_player_games_text_text: "No pleayed games",
    }
};


interface PlayerDetailsStatisticsComponentProps {
    playerGames: SortedBySeasonGamesLeagueInterface[] | null;
}


export const PlayerDetailsStatisticsComponent: React.FC<PlayerDetailsStatisticsComponentProps> = ({
    playerGames
}) : JSX.Element | null => {


    const activeLanguageCode = UseLanguage();

    const {
        summary_statistics_header_text: SUMMARY_STATISTICS_HEADER_TEXT,
        medium_statistics_header_text: MEDIUM_STATISTICS_HEADER_TEXT,
        message_no_player_games_text_text: MESSAGE_NO_PLAYER_GAMES_TEXT
    } = TEXTS[activeLanguageCode];


    const [playerGamesList, setPlayerGamesList] = useState<any>(null);

    useEffect(() => {
        setPlayerGamesList(playerGames);
    }, [playerGames]);


    if (!playerGames) return <MainContentLoaderComponent loaderColor={"#FFF"} />;


    if (!playerGamesList) return null;


    if (!playerGamesList?.length) return <MessageComponent messageText={MESSAGE_NO_PLAYER_GAMES_TEXT} cssClasses={"white"} />;


    const PlayerGamesList = createPlayerGamesStatistics(playerGamesList);


    const SummaryStatisticsData = PlayerGamesList?.map((season) => season.SUMMARY);
    const MediumStatisticsData = PlayerGamesList?.map((season) => season.MEDIUM);


    return (
        <Col xs={12} className={"player-details-statistics player-details-statistics-component"}>
            <Row className={"player-details-statistics-content"}>

                {!!SummaryStatisticsData &&
	            <StatisticsTableComponent
		            tableType={"SUMMARY"}
		            tableHeaderText={SUMMARY_STATISTICS_HEADER_TEXT}
		            tableData={SummaryStatisticsData}
	            />
                }

                {!!MediumStatisticsData &&
	            <StatisticsTableComponent
		            tableType={"MEDIUM"}
		            tableHeaderText={MEDIUM_STATISTICS_HEADER_TEXT}
		            tableData={MediumStatisticsData}
	            />
                }

            </Row>
        </Col>
    );

};


export default PlayerDetailsStatisticsComponent;
