import React from "react";


import {Col, Row} from "react-bootstrap";


import ResultHeaderComponent from "@Components/Views/Parts/Results/List/Result/Header";
import ResultFooterComponent from "@Components/Views/Parts/Results/List/Result/Footer";
import ResultContentComponent from "@Components/Views/Parts/Results/List/Result/Content";


import LeagueGameInterface from "@Interfaces/Api/Leagues/Games/Game";


type ResultsBarResultProps = {
    GAME_DATA: LeagueGameInterface | null;
};


export const ResultsBarResultComponent: React.FC<ResultsBarResultProps> = ({
    GAME_DATA = null
}): JSX.Element | null => {


    if (!GAME_DATA) return null;


    const {
        league_game_id: gameID,
        league_game_status: gameStatus,
        league_game_date: gameDate,
        league_game_information_bar: gameInformation,
        league_game_fb_stream: gameFBStream,
        league_game_yt_stream: gameYTStream,
        league_game_home_team_data: homeTeamData,
        league_game_home_team_points: homeTeamPoints,
        league_game_away_team_data: awayTeamData,
        league_game_away_team_points: awayTeamPoints,
        league_game_arena_data: LeagueGameArena
    } = GAME_DATA;


    const gameDateObject = gameDate ? new Date(gameDate) : undefined;


    const gameFinishedStatus = ["FT", "FT_WAL", "AOT"].includes(gameStatus);


    let leagueGameDescription: string | null | undefined = gameInformation;

    if (LeagueGameArena && !leagueGameDescription) {
        const {
            league_arena_name: leagueGameArenaName, league_arena_village: leagueGameArenaVillage
        } = LeagueGameArena;
        leagueGameDescription = `${leagueGameArenaName}, ${leagueGameArenaVillage}`;
    }

    return (
        <Col xs={12} className={"results-bar-item-component" + (gameStatus === "LIVE" ? " live-result" : "")}>
            <Row className={"results-bar-item-content"}>

                <ResultHeaderComponent
                    gameDate={gameDateObject}
                    gameStreams={{
                        FB_STREAM: gameFBStream,
                        YT_STREAM: gameYTStream
                    }}
                />

                <ResultContentComponent
                    gameID={gameID}
                    gameFinishedStatus={gameFinishedStatus}
                    homeTeamData={homeTeamData}
                    homeTeamPoints={homeTeamPoints}
                    awayTeamData={awayTeamData}
                    awayTeamPoints={awayTeamPoints}
                    gameStatus={gameStatus}
                />

                <ResultFooterComponent
                    gameInformation={leagueGameDescription}
                />

            </Row>

        </Col>
    );

};


export default ResultsBarResultComponent;
