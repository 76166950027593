import {
    UseLanguage
} from "@States";


import {
    TeamPlayerStatusType
} from "@Interfaces/Api";


import TeamPlayerStatus from "@ApiInterfaces/Teams/Team/TeamPlayer/TeamPlayerStatus/Values";


export const UseTeamPlayerStatus = (status: TeamPlayerStatusType) : string | null => {

    const activeLanguage = UseLanguage();

    const Status = TeamPlayerStatus[status];

    if (!Status) {
        return null;
    }

    return Status[activeLanguage];

};


export default UseTeamPlayerStatus;
