import React from "react";


import {Col} from "react-bootstrap";
import NumberFormat from "react-number-format";


interface GameSummaryTableRowTeamInterface {
    label?: string;
    labelSize?: "DEFAULT" | "FULL";
    primaryValue?: React.ReactNode;
    secondaryValue?: React.ReactNode;
}


interface GameSummaryTableRowTeamComponentProps {
    gameTeamData?: GameSummaryTableRowTeamInterface;
    gameTeamType?: "HOME" | "AWAY";
    statisticType?: "number" | "percentage";
}


export const GameSummaryTableRowTeamComponent: React.FC<GameSummaryTableRowTeamComponentProps> = ({
    gameTeamData, gameTeamType, statisticType = "number"
}) => {


    if (!gameTeamData) return null;


    const {
        label, labelSize,
        primaryValue, secondaryValue
    } = gameTeamData;


    let cssClasses = "summary-table-value";
    if (gameTeamType === "HOME") cssClasses += " summary-table-home-team";
    if (gameTeamType === "AWAY") cssClasses += " summary-table-away-team";


    if (
        !label &&
        !primaryValue && primaryValue !== 0 &&
        !secondaryValue && secondaryValue !== 0
    ) cssClasses += " empty-content";


    return (
        <Col xs={4} className={cssClasses}>

            {!!label &&
	        <span className={"table-value-label" + (labelSize === "FULL" ? "full-width" : "")}>{label}</span>
            }

            {(!!primaryValue || primaryValue === 0) &&
            <span className={"table-value-value table-primary-value"}>{primaryValue}</span>
            }

            {(!!secondaryValue || secondaryValue === 0) &&
            <span className={"table-value-value table-primary-value"}>
            {statisticType === "number" && <span>{secondaryValue}</span>}
            {statisticType === "percentage" && typeof secondaryValue === "number" &&
            <>
                <NumberFormat value={secondaryValue} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                <span>%</span>
            </>
            }
            </span>
	        }

        </Col>
    );

};


export default GameSummaryTableRowTeamComponent;
