import { useState, useEffect } from "react";


import _restApiRequest from "@Services/HTTP/Requests/Request";

import {
    API_TEAM_PLAYERS_ENDPOINT_URL
} from "@Services/API/Endpoints";


import {
    TeamPlayerInterface
} from "@Interfaces/Api";

import ErrorMessageInterface from "@GuiInterfaces/Messages/Error";


export const UseTeamPlayers = (teamID: number, updateTrigger = false) : [TeamPlayerInterface[] | null, ErrorMessageInterface | null] => {


    const [TeamPlayers, setTeamPlayers] = useState<TeamPlayerInterface[] | null>(null);
    const [TeamPlayersError, setTeamPlayersError] = useState<ErrorMessageInterface | null>(null);


    useEffect(() => {

        if (!teamID) {
            return setTeamPlayersError({ errorCode: 400, errorMessage: "No Team ID" });
        }

        if (TeamPlayers && !updateTrigger) {
            return;
        }

        const API_EP_URL = API_TEAM_PLAYERS_ENDPOINT_URL.replace("{TEAM_ID}", teamID.toString());

        const getTeamPlayers = async () => {

            try {

                const response = await _restApiRequest(API_EP_URL);

                if (response.status === 200) {
                    const responseContent = await response.json();
                    setTeamPlayers(responseContent);
                } else {
                    setTeamPlayersError({ errorCode: response.status, errorMessage: "" });
                }

            } catch (e) {
                setTeamPlayersError({ errorCode: 500, errorMessage: "" });
            }

        };

        getTeamPlayers();

    }, [teamID, updateTrigger]);


    useEffect(() => {
        return () => {
            setTeamPlayers(null);
            setTeamPlayersError(null);
        };
    }, []);


    return [TeamPlayers, TeamPlayersError];

};


export default UseTeamPlayers;
