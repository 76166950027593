import React from "react";


import {Redirect, Route, Switch} from "react-router-dom";


import GamesLeaguesComponent from "@Components/Views/Pages/Games/Leagues";
import GamesResultsComponent from "@Components/Views/Pages/Games/Games";
import TeamsPageComponent from "@Components/Views/Pages/Games/Teams";
import GamesPlayersComponent from "@Components/Views/Pages/Games/Players";


export interface ApplicationGamesComponentProps {}


export const ApplicationGamesComponent: React.FC<ApplicationGamesComponentProps> = () : JSX.Element | null => {


    return (
        <Switch>

            <Route path={"/ligi"} render={props =>
                <GamesLeaguesComponent {...props} />
            }
            />

            <Route path={"/druzyny"} render={props =>
                <TeamsPageComponent />
            }
            />

            <Route path={"/zawodnicy"} render={props =>
                <GamesPlayersComponent {...props} />
            }
            />

            <Route path={"/mecze"} render={() =>
                <GamesResultsComponent />
            }
            />

            <Redirect to={"/"} />

        </Switch>
    );

};


export default ApplicationGamesComponent;
