import React, {useState} from "react";

import isEmail from "validator/es/lib/isEmail";
import isMobilePhone from "validator/es/lib/isMobilePhone";


import {Row, Col, Form, Button} from "react-bootstrap";


export const ContactPageFormComponent = ({sendMessageHandler}) => {
	
	
	const [messageSenderNameValue, setMessageSenderNameValue] = useState("");
	const [messageSubjectValue, setMessageSubjectValue] = useState("");
	const [messageSenderPhoneValue, setMessageSenderPhoneValue] = useState("");
	const [messageSenderEmailValue, setMessageSenderEmailValue] = useState("");
	const [messageContentValue, setMessageContentValue] = useState("");
	const [messageFormErrors, setMessageFormErrors] = useState({});
	
	
	const changeMessageSenderNameValue = (value) => {
		if (value.length > 10) setMessageFormErrors({...messageFormErrors, messageContent: null});
		setMessageSenderNameValue(value);
	};
	
	const changeMessageSubjectValue = (value) => {
		if (value.length > 5) setMessageFormErrors({...messageFormErrors, messageSubject: null});
		setMessageSubjectValue(value);
	};
	
	const changeMessageSenderPhoneValue = (value) => {
		if (isMobilePhone(value)) setMessageFormErrors({...messageFormErrors, messageSenderPhone: null});
		setMessageSenderPhoneValue(value);
	};
	
	const changeMessageSenderEmailValue = (value) => {
		if (isEmail(value)) setMessageFormErrors({...messageFormErrors, messageSenderEmail: null});
		setMessageSenderEmailValue(value);
	};
	
	const changeMessageContentValue = (value) => {
		if (value.length > 10) setMessageFormErrors({...messageFormErrors, messageContent: null});
		setMessageContentValue(value);
	};
	
	
	const formSubmitHandler = () => {
		
		const errors = {};
		
		if (messageSenderNameValue.length < 6) {
			errors.messageSenderName = "Zbyt krótkie Imię i Nazwisko";
		}
		
		if (messageSubjectValue.length < 6) {
			errors.messageSubject = "Zbyt krótki temat wiadomości";
		}
		
		if (!isMobilePhone(messageSenderPhoneValue)) {
			errors.messageSenderPhone = "Niepoprawny Numer Telefonu";
		}
		
		if (!isEmail(messageSenderEmailValue)) {
			errors.messageSenderEmail = "Niepoprawny Email";
		}
		
		if (messageContentValue.length < 11) {
			errors.messageContent = "Zbyt krótka treść wiadomości";
		}
		
		setMessageFormErrors(errors);
		
		if (Object.entries(errors).length) {
			return null;
		}
		
		sendMessageHandler({
			messageSenderName: messageSenderNameValue,
			messageSubject: messageSubjectValue,
			messageSenderPhone: messageSenderPhoneValue,
			messageSenderEmail: messageSenderEmailValue,
			messageContentValue
		});
		
	};
	
	
	return (
		<Col xs={12} lg={6} className={"contact-page-form"}>
			
			<Row className={"contact-form-header"}>
				<Col xs={12} className={"contact-form-header-content"}>
					<span>Napisz do nas</span>
				</Col>
			</Row>
			
			<Row className={"contact-form-fields"}>
				
				<Col xs={12} as={Form.Group} className={"contact-form-email"}>
					<Form.Control
						placeholder={"Imię i Nazwisko *"}
						value={messageSenderNameValue}
						onChange={e => changeMessageSenderNameValue(e.target.value)}
					/>
					{messageFormErrors?.messageSenderName &&
					<Form.Label className={"error"}>{messageFormErrors?.messageSenderName}</Form.Label>
					}
				</Col>
				
				<Col xs={12} as={Form.Group} className={"contact-form-email"}>
					<Form.Control
						placeholder={"Temat"}
						value={messageSubjectValue}
						onChange={e => changeMessageSubjectValue(e.target.value)}
					/>
					{messageFormErrors?.messageSubject &&
					<Form.Label className={"error"}>{messageFormErrors?.messageSubject}</Form.Label>
					}
				</Col>
				
				<Col xs={12} as={Form.Group} className={"contact-form-email"}>
					<Form.Control
						placeholder={"Telefon *"}
						type={"phone"}
						value={messageSenderPhoneValue}
						onChange={e => changeMessageSenderPhoneValue(e.target.value)}
					/>
					{messageFormErrors?.messageSenderPhone &&
					<Form.Label className={"error"}>{messageFormErrors?.messageSenderPhone}</Form.Label>
					}
				</Col>
				
				<Col xs={12} as={Form.Group} className={"contact-form-email"}>
					<Form.Control
						placeholder={"Email *"}
						type={"email"}
						value={messageSenderEmailValue}
						onChange={e => changeMessageSenderEmailValue(e.target.value)}
					/>
					{messageFormErrors?.messageSenderEmail &&
					<Form.Label className={"error"}>{messageFormErrors?.messageSenderEmail}</Form.Label>
					}
				</Col>
				
				<Col xs={12} as={Form.Group} className={"contact-form-message"}>
					<Form.Control
						placeholder={"Treść *"}
						as={"textarea"}
						value={messageContentValue}
						onChange={e => changeMessageContentValue(e.target.value)}
					/>
					{messageFormErrors?.messageContent &&
					<Form.Label className={"error"}>{messageFormErrors?.messageContent}</Form.Label>
					}
				</Col>
				
				<Col xs={12} as={Form.Group} className={"contact-form-submit"}>
					<Button onClick={formSubmitHandler}>Wyślij</Button>
				</Col>
				
			</Row>
			
		</Col>
	);
	
};


export default ContactPageFormComponent;
