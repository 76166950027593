import UseLanguage from "@LocalizationStates/Language";


import ErrorsMessages from "@Dictionary/Messages/Errors";
import ErrorMessageType from "@Dictionary/Messages/Errors/Messages/Message";


export const UseErrorMessage = (messageType: ErrorMessageType) => {

    const ActiveLanguage = UseLanguage();

    return ErrorsMessages[ActiveLanguage][messageType];

};


export default UseErrorMessage;
