export const countSumOfAnArrayElements = (arr: number[]) => {

    if (!arr?.length) return 0;

    if (arr.length === 1) return arr[0];

    return arr.reduce((v1, v2) => v1 + v2);

};


export default countSumOfAnArrayElements;
