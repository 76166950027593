import React, {useEffect, useRef} from "react";


import { Col, Row } from "react-bootstrap";


import GameDetailsAdminHeaderInformationComponent from "@GameResultPage/__Admin/Header/Information";
import GameDetailsAdminHeaderLeagueComponent from "@GameResultPage/__Admin/Header/League";
import GameDetailsAdminHeaderTeamComponent from "@GameResultPage/__Admin/Header/Team";


import scrollToDomElement from "@Utilities/Scrolling";


import { LeagueGameInterface } from "@Interfaces/Api";


import "./index.scss";


export interface GameDetailsAdminHeaderComponentProps {
    GameDetails: LeagueGameInterface;
}


export const GameDetailsAdminHeaderComponent: React.FC<GameDetailsAdminHeaderComponentProps> = ({
    GameDetails
}) : JSX.Element | null => {


    const headerRef = useRef(null);

    useEffect(() => {
        if (headerRef?.current) {
            scrollToDomElement(headerRef.current);
        }
    }, []);


    if (!GameDetails) return null;


    const {
        league_game_status: gameStatus, league_game_arena_data: GameArena, league_game_date: gameDate,
        league_game_home_team_data: GameHomeTeam, league_game_away_team_data: GameAwayTeam,
        league_game_home_team_points: gameHomeTeamPoints, league_game_away_team_points: gameAwayTeamPoints,
        // league_game_quarters,
        league_game_league_data: gameLeague,
    } = GameDetails;


    return (
        <Col ref={headerRef} xs={12} className={"game-details-admin__header"}>
            <Row className={"game-details-admin__header__content"}>

                <GameDetailsAdminHeaderInformationComponent
                    gameStatus={gameStatus}
                    gameDate={gameDate}
                    GameArena={GameArena}
                />

                <GameDetailsAdminHeaderLeagueComponent
                    GameLeague={gameLeague}
                />

                <GameDetailsAdminHeaderTeamComponent
                    GameTeam={GameHomeTeam}
                    gameTeamPoints={gameHomeTeamPoints}
                    gameTeamType={"HOME"}
                />

                <GameDetailsAdminHeaderTeamComponent
                    GameTeam={GameAwayTeam}
                    gameTeamPoints={gameAwayTeamPoints}
                    gameTeamType={"AWAY"}
                />

            </Row>
        </Col>
    )

};


export default GameDetailsAdminHeaderComponent;
