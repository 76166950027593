import React from "react";

import UseLanguage from "@Store/_Hooks/_States/Localization/Language";


import {
    UseRoute
} from "@Hooks/Routes";


import {NavLink} from "react-router-dom";


import {Col, Row} from "react-bootstrap";


import NumberFormat from "react-number-format";


const TEXTS: any = {
    PL: {game_word_text: "mecz"}, EN: {game_word_text: "game"},
};


import StatisticsType from "@GlobalData/Statistics/Types/Type";
import StatisticsTypes from "@GlobalData/Statistics/Types";


import PlayerLeagueStatisticsInterface from "@Interfaces/Api/Leagues/Statistics/League/Player";


import {
    generatePlayerName
} from "@Services/Utilities/Games";


import {
    NO_AVATAR_IMAGE
} from "@Images";


interface LeaguesStatisticsTablePlayerComponentProps {
    index: number;
    selectedLeagueID: number;
    selectedStatisticsType: StatisticsType;
    playerData: PlayerLeagueStatisticsInterface;
}


export const LeaguesStatisticsTablePlayerComponent: React.FC<LeaguesStatisticsTablePlayerComponentProps> = ({
    index = 0, selectedLeagueID,
    selectedStatisticsType = "TPTS",
    playerData
}) : JSX.Element | null => {


    const activeLanguage = UseLanguage();

    const {game_word_text: GAME_WORD_TEXT} = TEXTS[activeLanguage];


    const TeamsRoute = UseRoute("TEAMS");
    const PlayersRoute = UseRoute("PLAYERS");


    if (!playerData || !selectedStatisticsType) return null;


    const {
        player_score: playerScoreValue,
        player_data: PlayerInformation,
        team_data: PlayerTeam,
    } = playerData;

    if (!PlayerInformation) return null;


    const { player_slug: playerSlug, player_profile_image: playerProfileImage } = PlayerInformation;

    const playerName = generatePlayerName(PlayerInformation);
    const playerURL = "/" + PlayersRoute.routeURL + "/" + playerSlug;


    const {
        team_league_team_name: teamLeagueName, team_league_team_data: TeamData
    } = PlayerTeam;

    const {
        team_name: teamName, team_slug: playerTeamSlug
    } = TeamData;

    const playerTeamName = teamLeagueName || teamName;
    const playerTeamURL = "/" + TeamsRoute.routeURL + "/" + playerTeamSlug;


    return (
        <Col xs={12} className={"statistics-table-player"}>
            <Row className={"statistics-table-player-content"}>

                <Col xs={1} className={"statistics-table-position"}>
                    <span>{index + 1}</span>
                </Col>

                <Col xs={8} lg={7} className={"statistics-table-player-data"}>
                    <Row className={"player-data-content"}>

                        <Col xs={3} lg={3} className={"player-avatar"}>
                            <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                                <img src={playerProfileImage || NO_AVATAR_IMAGE} alt={""} />
                            </NavLink>
                        </Col>

                        <Col xs={9} lg={9} className={"player-information"}>
                            <Row className={"player-name-data"}>
                                <Col xs={12} className={"player-name"}>
                                    <NavLink to={playerURL} title={PlayersRoute.routeTitle}>
                                        <span>{playerName}</span>
                                    </NavLink>
                                </Col>
                            </Row>
                            <Row className={"player-team-data"}>
                                {!!playerTeamSlug && !!playerTeamName &&
                                <Col xs={12} className={"player-team-name"}>
                                    <NavLink to={playerTeamURL} title={TeamsRoute.routeTitle}>
                                        <span>{playerTeamName}</span>
                                    </NavLink>
                                </Col>
                                }
                            </Row>
                        </Col>

                    </Row>
                </Col>

                <Col xs={3} lg={4} className={"statistics-table-data"}>
                    <Row className={"statistics-table-data-content"}>
                        <Col xs={5} className={"statistics-data-value"}>
                            <NumberFormat value={playerScoreValue} decimalScale={1} fixedDecimalScale={true} displayType={"text"} decimalSeparator={"."} />
                        </Col>
                        <Col xs={7} className={"statistics-data-label"}>
                            <span>{StatisticsTypes[selectedStatisticsType][activeLanguage].label} / {GAME_WORD_TEXT}</span>
                        </Col>
                    </Row>
                </Col>

            </Row>
        </Col>
    );

};


export default LeaguesStatisticsTablePlayerComponent;
