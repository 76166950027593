export const NAME_PL = { errorMessage: "Nazwa jest zbyt krótka" };
export const NAME_EN = { errorMessage: "Name is too short" };
export const SHORT_NAME_PL = { errorMessage: "Skrócona nazwa jest niepoprawna" };
export const SHORT_NAME_EN = { errorMessage: "Short name is invalid" };
export const FIRST_NAME_PL = { errorMessage: "Imię jest zbyt krótkie" };
export const FIRST_NAME_EN = { errorMessage: "First name is too short" };
export const LAST_NAME_PL = { errorMessage: "Nazwisko jest zbyt krótkie" };
export const LAST_NAME_EN = { errorMessage: "Last Name is too short" };
export const NICK_NAME_PL = { errorMessage: "Pseudo powinno składać się z 2-15 znaków" };
export const NICK_NAME_EN = { errorMessage: "Pseudo should contains 2-15 characters" };
export const EMAIL_ADDRESS_PL = { errorMessage: "Niepoprawny adres email" };
export const WEBSITE_PL = { errorMessage: "Niepoprawny adres WWW" };
export const WEBSITE_EN = { errorMessage: "Incorrect WWW Address" };
export const EMAIL_ADDRESS_EN = { errorMessage: "Incorrect Email Address" };
export const SOCIAL_ID_PL = { errorMessage: "Niepoprawny numer Pesel" };
export const SOCIAL_ID_EN = { errorMessage: "Incorrect PESEL number" };
export const CITY_PL = { errorMessage: "Nieprawidłowa nazwa Miejscowości" };
export const CITY_EN = { errorMessage: "Incorrect City Name" };

export const DESCRIPTION_PL = { errorMessage: "" };
export const DESCRIPTION_EN = { errorMessage: "" };
export const BIRTHDATE_PL = { errorMessage: "" };
export const BIRTHDATE_EN = { errorMessage: "" };
export const WEIGHT_PL = { errorMessage: "" };
export const WEIGHT_EN = { errorMessage: "" };
export const HEIGHT_PL = { errorMessage: "" };
export const HEIGHT_EN = { errorMessage: "" };

export const COLOR_PL = { errorMessage: "" };
export const COLOR_EN = { errorMessage: "" };
export const FIRST_COLOR_PL = { errorMessage: "" };
export const FIRST_COLOR_EN = { errorMessage: "" };
export const SECOND_COLOR_PL = { errorMessage: "" };
export const SECOND_COLOR_EN = { errorMessage: "" };
export const NUMBER_PL = { errorMessage: "Nieprawidłowy numer" };
export const NUMBER_EN = { errorMessage: "Incorrect number" };
