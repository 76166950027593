import LanguageCodeType from "@GlobalData/Localization/Languages";

import LocalizationActionTypes from "@Action-Types/Localization";
import LocalizationAction from "@Actions/Localization";


interface LocalizationReducerStateInterface {
   activeLanguage: LanguageCodeType;
   activeTimeZone: string;
}


const LocalizationReducerState: LocalizationReducerStateInterface = {
   activeLanguage: "PL",
   activeTimeZone: "CET"
};


export const LocalizationReducer = (
    state = LocalizationReducerState,
    action: LocalizationAction
) : LocalizationReducerStateInterface => {

   switch (action.type) {

      case LocalizationActionTypes.CHANGE_LANGUAGE:
         return {...state, activeLanguage: action.language};

      case LocalizationActionTypes.CHANGE_TIMEZONE:
         return state;

      default:
         return state;

   }

};


export default LocalizationReducer;
