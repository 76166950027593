import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import TeamLeaguesListLeagueSeasonComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/LeagueInformation/LeagueSeason";
import TeamLeaguesListLeagueNameComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/LeagueInformation/LeagueName";
import TeamLeaguesListLeagueShowContentSwitcherComponent from "@Pages/User/Authenticated/Profile/Player/Content/CaptainArea/Team/Leagues/LeaguesList/League/LeagueInformation/ShowContentSwitcher";


import {
    TeamLeagueDataInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface TeamLeaguesListLeagueDataComponentProps {
    TeamLeagueData: TeamLeagueDataInterface;
    showTeamLeagueDetailsStatus: boolean;
    showTeamLeagueDetailsStatusHandler: (status: boolean) => void;
}


export const TeamLeaguesListLeagueDataComponent: React.FC<TeamLeaguesListLeagueDataComponentProps> = ({
    TeamLeagueData,
    showTeamLeagueDetailsStatus, showTeamLeagueDetailsStatusHandler
}) : JSX.Element | null => {


    if (!TeamLeagueData) {
        return null;
    }


    const {
        league_name: teamLeagueName, league_short_name: teamLeagueShortName,
        league_season_data: TeamLeagueSeasonData,
    } = TeamLeagueData;


    return (
        <Col xs={12} className={"player-profile__captain-zone__team__leagues__list__league__information" + (showTeamLeagueDetailsStatus ? " opened" : "")}>
            <Row className={"player-profile__captain-zone__team__leagues__list__league__information__content"}>

                <TeamLeaguesListLeagueNameComponent
                    leagueName={teamLeagueName}
                    leagueShortName={teamLeagueShortName}
                />

                <TeamLeaguesListLeagueSeasonComponent
                    LeagueSeasonData={TeamLeagueSeasonData}
                />

                <TeamLeaguesListLeagueShowContentSwitcherComponent
                    showTeamLeagueDetailsStatus={showTeamLeagueDetailsStatus}
                    showTeamLeagueDetailsStatusHandler={showTeamLeagueDetailsStatusHandler}
                />

            </Row>
        </Col>
    )

};


export default TeamLeaguesListLeagueDataComponent;
