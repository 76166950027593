import React from "react";


import {Row, Col} from "react-bootstrap";


interface StatisticsTableHeaderComponentProps {
    headerText: string;
}


export const StatisticsTableHeaderComponent: React.FC<StatisticsTableHeaderComponentProps> = ({
    headerText
}) : JSX.Element | null => {


    if (!headerText) return null;


    return (
        <Col xs={12} className={"player-statistics-table-header player-statistics-table-header-component"}>
            <Row className={"player-statistics-table-header-content"}>
                <Col xs={"auto"} className={"statistics-table-header"}>
                    <span>{headerText}</span>
                    <span className={"bg bg_l"}></span>
                    <span className={"bg bg_r"}></span>
                </Col>
            </Row>
        </Col>
    );

};


export default StatisticsTableHeaderComponent;
