import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import LeagueTablesGroupTableComponent from "@LeaguesPages/Tables/Leagues/Tables/Group/Table";
import LeagueTablesGroupHeaderComponent from "@LeaguesPages/Tables/Leagues/Tables/Group/Header";


import {
    LeagueTablesGroupInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface LeagueTablesGroupComponentProps {
    Group: LeagueTablesGroupInterface;
}


export const LeagueTablesGroupComponent: React.FC<LeagueTablesGroupComponentProps> = ({
    Group
}) : JSX.Element | null => {


    if (!Group) {
        return null;
    }


    const {
        group_name: groupName, group_table: GroupTable
    } = Group;


    return (
        <Col xs={12} className={"league-tables__group"}>
            <Row className={"league-tables__group__content"}>

                <LeagueTablesGroupHeaderComponent groupName={groupName} />

                <LeagueTablesGroupTableComponent GroupTable={GroupTable} />

            </Row>
        </Col>
    );

};


export default LeagueTablesGroupComponent;
