import React from "react";


import {
    Col, Row
} from "react-bootstrap";


import ArticleImageComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent/ArticleHeader/ArticleImage";
import ArticleTitleComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent/ArticleHeader/ArticleTitle";
import ArticleShortTextComponent from "@Pages/Common/ArticlesPage/ArticleDetail/ArticleContent/ArticleHeader/ArticleShortText";


import {
    ArticleInterface
} from "@Interfaces/Api";


import "./index.scss";


export interface ArticleHeaderComponentProps {
    Article: ArticleInterface;
}

export const ArticleHeaderComponent: React.FC<ArticleHeaderComponentProps> = ({
    Article
}) : JSX.Element | null => {


    if (!Article) {
        return null;
    }


    return (
        <Col xs={12} className={"article-detail__content__header"}>
            <Row className={"article-detail__content__header__content"}>

                <Col xs={12} lg={8} className={"article-detail__content__header__image-and-title"}>
                    <Row className={"article-detail__content__header__image-and-title__content"}>

                        <ArticleImageComponent Article={Article} />

                        <ArticleTitleComponent Article={Article} />

                    </Row>
                </Col>

                <ArticleShortTextComponent Article={Article} />

            </Row>
        </Col>
    );


};


export default ArticleHeaderComponent;
