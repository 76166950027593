import React from "react";


import { UseGamesDictionaryPhrasesSet } from "@Hooks/Texts/Pages";


import { Col, Row } from "react-bootstrap";


import "./index.scss";


import { LeagueGameInterface } from "@Interfaces/Api";


export interface GameInformationCastComponentProps {
    GameData: LeagueGameInterface;
}


export const GameInformationCastComponent: React.FC<GameInformationCastComponentProps> = ({
    GameData
}) : JSX.Element | null => {


    const REFEREES = UseGamesDictionaryPhrasesSet("REFEREES");
    const COMMISSIONER = UseGamesDictionaryPhrasesSet("COMMISSIONER");
    const SPECTATORS = UseGamesDictionaryPhrasesSet("SPECTATORS_LABEL");


    if (!GameData) return null;


    const {
        league_game_spectators: gameSpectators,
        league_game_commissioner: gameCommissioner,
        league_game_referees: gameReferees
    } = GameData;


    let gameRefereesColumnsOffset = 2;
    let gameCommissionerColumnsOffset = 0;
    let gameSpectatorsColumnsOffset = 0;

    if (!gameReferees) {
        gameCommissionerColumnsOffset += 4;
        gameSpectatorsColumnsOffset += 1;
    }

    if (!gameCommissioner) {
        gameRefereesColumnsOffset += 1;
        gameSpectatorsColumnsOffset += !gameReferees ? 4 : 3;
    }

    if (!gameReferees && !gameCommissioner) {
        gameSpectatorsColumnsOffset += 5;
    }

    if (!gameReferees && !gameSpectators) {
        gameCommissionerColumnsOffset = 0;
    }

    if (!gameReferees && !gameSpectators && !gameCommissioner) {
        return null;
    }

    return (
        <Col xs={12} className={"game-result-cast game-result-cast-component"}>
            <Row className={"game-result-cast-content"}>

                {!!gameReferees &&
                <Col xs={12} lg={{span: 4, offset: gameRefereesColumnsOffset}} className={"game-referees"}>
                    <span className={"info-label"}>{REFEREES}:</span>
                    <span className={"info-value"}>{gameReferees}</span>
                </Col>
                }

                {!!gameCommissioner &&
                <Col xs={12} lg={{span: 4, offset: gameCommissionerColumnsOffset}} className={"game-commissioner" + (gameCommissionerColumnsOffset === 0 ? " standalone" : "")}>
                    <span className={"info-label"}>{COMMISSIONER}:</span>
                    <span className={"info-value"}>{gameCommissioner}</span>
                </Col>
                }

                {!!gameSpectators &&
                <Col xs={12} lg={{span: 2, offset: gameSpectatorsColumnsOffset}} className={"game-spectators"}>
                    <span className={"info-label"}>{SPECTATORS}:</span>
                    <span className={"info-value"}>{gameSpectators}</span>
                </Col>
                }

            </Row>
        </Col>
    );

};


export default GameInformationCastComponent;
