import React from "react";


import {Col, Row} from "react-bootstrap";


import MessageComponent from "@Components/Elements/Messages/Content";


import TableScoreDataComponent from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/Score/Data";


import BestScoreInterface from "@Components/Views/Pages/Games/Players/Player/DetailsData/BestScores/Table/_Types/Scores/Score";


export interface BestScoresTableScoreComponentProps {
    scoreLabel: string;
    scoreData: BestScoreInterface | null;
}


export const BestScoresTableScoreComponent: React.FC<BestScoresTableScoreComponentProps> = ({
    scoreLabel, scoreData
}) : JSX.Element | null => {


    return (
        <Col xs={12} className={"player-best-scores-table-score player-best-scores-table-score-component"}>
            <Row className={"player-best-scores-table-score-content"}>

                <Col xs={5} lg={3} className={"best-score-label"}>
                    <span>{scoreLabel}</span>
                </Col>

                {scoreData && scoreData?.scoreValue ?
                <TableScoreDataComponent scoreData={scoreData} />
                :
                <MessageComponent xs={7} lg={9} messageText={`-`} />
                }

            </Row>
        </Col>
    );

};


export default BestScoresTableScoreComponent;
