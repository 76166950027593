export enum PlayerDetailsSectionsTypes {
    PLAYER_STATISTICS = "player_details_statistics",
    PLAYER_BEST_SCORES = "player_best_scores",
    PLAYER_PLAYED_GAMES = "player_played_games",
    PLAYER_DESCRIPTION = "player_played_games",
    PLAYER_AWARDS = "player_awards",
}


export default PlayerDetailsSectionsTypes;
