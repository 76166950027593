import React from "react";


import UseLanguage from "@LocalizationStates/Language";

import UseLoggedUser from "@AuthenticationStates/User";
import UseLogUserOutAction from "@AuthenticationStatesActions/LogOut";


import UseRoute from "@Hooks/Routes/RouteData";
import RoutesType from "@RoutesTypes";


import Dictionary from "@GlobalData/Localization/Dictionary";


import { NavLink, Redirect } from "react-router-dom";


import { Col } from "react-bootstrap";

import { BiLogOut, FaUser } from "react-icons/all";


import "./index.scss";


import ColumnProps from "@BootstrapInterfaces/Column";


export interface HeaderUserZoneComponentProps extends ColumnProps {}


export const HeaderUserZoneComponent: React.FC<HeaderUserZoneComponentProps> = ({
    xs= 2, lg = 1
}) : JSX.Element | null => {


    const ActiveLanguage = UseLanguage();

    const {
        user_logout_button_title: LOGOUT_BUTTON_TITLE
    } = Dictionary[ActiveLanguage];


    const ActiveUser = UseLoggedUser();

    const LogUserOutHandler = UseLogUserOutAction();


    const USER_BUTTON_ROUTE_NAME: RoutesType = ActiveUser ? "PROFILE" : "LOGIN";

    const UserButtonRoute = UseRoute(USER_BUTTON_ROUTE_NAME);


    if (!UserButtonRoute) return <Redirect to={"/"} />;


    const { routeURL: USER_BUTTON_URL, routeTitle: USER_BUTTON_TITLE } = UserButtonRoute;


    return (
        <Col xs={xs} lg={lg} className={"header-user-zone-component header-user-zone"}>

            <NavLink to={`/${USER_BUTTON_URL}`} title={USER_BUTTON_TITLE}>
                <FaUser />
            </NavLink>

            {!!ActiveUser &&
            <span title={LOGOUT_BUTTON_TITLE} onClick={LogUserOutHandler} className={"logout-button"}>
                <BiLogOut />
            </span>
            }

        </Col>
    );


};


export default HeaderUserZoneComponent;
